export default {
  top: [
    {
      name: 'Home',
      url: '/account/dashboard',
      icon: 'MdHome',
    },
    // {
    //   divider: true,
    // },
    {
      name: 'Application',
      url: '/account/checkinapp',
      icon: 'MdTabletMac',
    },
    // {
    //   divider: true,
    // },
    {
      name: 'Check-Ins',
      url: '/account/checkins',
      icon: 'MdAssignment',
    },
    {
      name: 'Bookings',
      url: '/account/bookings',
      icon: 'MdDateRange',
    },
    {
      name: 'Page',
      url: '/account/page',
      icon: 'MdWeb',
    },
    {
      name: 'Marketing',
      url: '/account/marketing',
      icon: 'MdFlag',
    },
    // {
    //   name: 'Stats & Reports',
    //   url: '/account/stats',
    //   icon: 'MdEqualizer',
    // },
    {
      name: 'Business',
      // url: '/account/page',
      icon: 'MdStore',
      children: [
        {
          name: 'Menu | Services',
          url: '/account/products',
          icon: 'MdNavigateNext',
        },
        {
          name: 'Customers',
          url: '/account/customers',
          icon: 'MdNavigateNext',
        },
        {
          name: 'Staff',
          url: '/account/staff',
          icon: 'MdNavigateNext',
        },
        {
          name: 'Forms',
          url: '/account/forms',
          icon: 'MdNavigateNext',
        },
      ],
    },
    // {
    //   name: 'Account',
    //   icon: 'MdAccountCircle',
    //   children: [
    {
      divider: true,
    },
    {
      name: 'Your Subscription',
      url: '/account/subscription',
      icon: 'MdBubbleChart',
    },
    {
      name: 'Your Account',
      url: '/account/profile',
      icon: 'MdInsertEmoticon',
    },
    {
      divider: true,
    },
    {
      name: 'Support',
      url: '/account/support',
      icon: 'MdHelpOutline',
    },
    {
      name: 'Log Out',
      url: '/account/logout',
      icon: 'MdExitToApp',
    },
    // {
    //   name: 'Switch Businesses',
    //   url: '/account/switch',
    //   icon: 'MdSync',
    // },
    //   ],
    // },
  ],
  bottom: [
    // {
    //   divider: true,
    // },
  ],
};
