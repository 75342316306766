import React, { Component } from 'react';
import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip, Form, Button } from 'react-bootstrap';
import { MdInfo } from 'react-icons/md';
import { getBusiness, changeBusinessKeyValue, updateCalendarSettings } from '../../../redux/actions/businessActions';

class AppComponent extends Component {
  componentDidMount() {
    this.props.getBusiness(this.props.users.token.business_id, this);
  }
  onChangeBusinessKeyValue(id, key, value) {
    const data = {
      id,
      key,
      value,
    };
    this.props.changeBusinessKeyValue(data);
  }
  onSubmit(e) {
    e.preventDefault();
    const data = {
      businessID: this.props.business.business.id,
      bookingInterval: this.props.business.business.booking_interval,
      bookingSlots: this.props.business.business.booking_slots,
      calendarView: this.props.business.business.calendar_view,
    };
    this.props.updateCalendarSettings(data, this);
  }
  render() {
    const { business } = this.props.business;
    return (
      <>
        <div>
          <div className='flex-1'>
            <Form onSubmit={(e) => this.onSubmit(e)}>
              {/* <div className='display-flex align-items-center justify-content-space-between'>
                <div className='display-flex flex-wrap-nowrap align-items-center justify-content-space-between margin-left-15'>
                  <div>
                    <OverlayTrigger placement='top' overlay={<Tooltip>The view when the calendar is open.</Tooltip>}>
                      <div>Default Calendar View:</div>
                    </OverlayTrigger>
                  </div>
                  <div>
                    <Form.Control
                      as='select'
                      name='calendarView'
                      onChange={(e) => this.onChangeBusinessKeyValue(business.id, 'calendar_view', e.target.value)}
                      value={business.calendar_view ? business.calendar_view : ''}
                      style={{ width: 'auto', marginLeft: '15px' }}
                    >
                      <option value='dayGridMonth'>Month View</option>
                      <option value='timeGridWeek'>Week View</option>
                      <option value='timeGridDay'>Day View</option>
                      <option value='listWeek'>List View</option>
                    </Form.Control>
                  </div>
                </div>
              </div>
              <hr></hr> */}
              {/* <div className='display-flex align-items-center justify-content-space-between'>
                <div className='display-flex align-items-center justify-content-space-between margin-left-15'>
                  <div>
                    <OverlayTrigger
                      placement='top'
                      overlay={<Tooltip>The number of time a slot can be booked before it becomes unavailable.</Tooltip>}
                    >
                      <div>Booking Per Slot:</div>
                    </OverlayTrigger>
                  </div>
                  <div>
                    <Form.Control
                      type='number'
                      name='bookingSlot'
                      value={business.booking_slots ? business.booking_slots : ''}
                      onChange={(e) => this.onChangeBusinessKeyValue(business.id, 'booking_slots', Number(e.target.value))}
                      style={{ width: '100px' }}
                    />
                  </div>
                </div>
              </div>
              <hr></hr> */}
              <div className='display-flex align-items-center justify-content-space-between'>
                <div className='display-flex flex-wrap-nowrap align-items-center justify-content-space-between margin-left-15'>
                  <div>
                    <OverlayTrigger placement='top' overlay={<Tooltip>The time period per slot.</Tooltip>}>
                      <div>
                        <MdInfo size={25} className='color-gray' /> Time Slot Interval:
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div>
                    <Form.Control
                      as='select'
                      name='bookingInterval'
                      onChange={(e) => this.onChangeBusinessKeyValue(business.id, 'booking_interval', Number(e.target.value))}
                      value={business.booking_interval ? business.booking_interval : ''}
                      style={{ width: 'auto', marginLeft: '15px' }}
                    >
                      <option value='15'>15 minutes</option>
                      <option value='30'>30 minutes</option>
                      <option value='45'>45 minutes</option>
                      <option value='60'>60 minutes</option>
                    </Form.Control>
                  </div>
                </div>
              </div>
              <div className='margin-top-15 display-flex justify-content-flex-end'>
                <Button type='submit' className='width-100'>
                  Save
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  business: state.business,
});
export default connect(mapStateToProps, { getBusiness, changeBusinessKeyValue, updateCalendarSettings })(AppComponent);
