import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MdErrorOutline } from 'react-icons/md';

class AppComponent extends Component {
  render() {
    return (
      <div className='height-100vh display-flex align-items-center justify-content-center'>
        <center>
          <div className='padding'>
            <MdErrorOutline size='100' color='#51c1e0' />
          </div>
          <div className='padding'>
            <div className='font-size-large font-weight-bold margin-bottom=15'>OneSpace.Link</div>
            <div className='margin-top-15 margin-bottom-15 color-gray'>
              <b>404.</b> That's an error.
            </div>
            <div className='color-gray'>The requested URL was not found on this server. That's all we know.</div>
          </div>
        </center>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps)(AppComponent);
