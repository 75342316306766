import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Form } from 'react-bootstrap';
import { getForm, deleteForms, updateForm, changeFormKeyValue } from './../../../redux/actions/businessActions';
import ModalDepot from './../components/ModalDepot';
// import SignatureCanvas from 'react-signature-canvas';
import { withSize } from 'react-sizeme';
import { clearErrors } from '../../../redux/actions/siteActions';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class AppComponent extends Component {
  sigPad = {};
  constructor(props) {
    super();
    this.state = {
      isDeleteFormModalShow: false,
      selectedFormID: props.props.state.selectedFormID,
    };
    this.onDeleteForms = this.onDeleteForms.bind(this);
    this.onUpdateForm = this.onUpdateForm.bind(this);
  }
  componentDidMount() {
    this.props.getForm(this.state.selectedFormID);
  }
  openModal = (modalName, modalState, modalData) => {
    switch (modalName) {
      case 'FORM_DELETE':
        modalState ? this.setState({ isDeleteFormModalShow: true }) : this.setState({ isDeleteFormModalShow: false });
        break;
      default:
    }
  };
  onUpdateForm = (e) => {
    e.preventDefault();
    const data = {
      formID: this.state.selectedFormID,
      formTitle: this.props.business.form.name,
      formContent: this.props.business.form.form_content,
      signatureRequired: this.props.business.form.signature_required,
      formBusinessID: this.props.business.form.business_id,
      businessID: this.props.users.token.business_id,
    };
    this.props.updateForm(data, this);
  };
  onDeleteForms = (e) => {
    e.preventDefault();
    const data = {
      formIDArray: this.state.selectedFormID ? [this.state.selectedFormID] : [],
      formBusinessID: this.props.business.form.business_id,
      businessID: this.props.users.token.business_id,
    };
    this.props.deleteForms(data, this);
  };
  onChangeFormKeyValue(id, key, value) {
    const data = {
      id,
      key,
      value,
    };
    this.props.changeFormKeyValue(data);
  }
  modules = {
    toolbar: [
      // [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ color: ['#4285F4', '#EA4335', '#FBBC05', '#34A853', '#000'] }],
      ['link'], //  ['link', 'image'],
      ['clean'],
    ],
  };
  render() {
    const { form } = this.props.business;
    const { errors } = this.props.site;
    return (
      <>
        <div>
          {' '}
          <Form.Control
            type='text'
            name='formTitle'
            placeholder='Form Title'
            value={form.name ? form.name : ''}
            onChange={(e) => this.onChangeFormKeyValue(form.id, 'name', e.target.value)}
            className='margin-bottom-15 width-100'
          />
          {this.props.users.token.business_id === 1 ? (
            <>
              <ReactQuill
                theme='snow'
                name='form_content'
                modules={this.modules}
                value={form.form_content ? form.form_content : ''}
                onChange={(e) => {
                  this.onChangeFormKeyValue(form.id, 'form_content', e);
                }}
                placeholder='Enter your consent/waiver clause here'
                className='margin-bottom-15'
              />
            </>
          ) : (
            <>
              <div className='react-quill' dangerouslySetInnerHTML={{ __html: form.form_content ? form.form_content : '' }} />
            </>
          )}
          <div className='display-flex flex-wrap-nowrap align-items-center margin-bottom-15'>
            <input
              type='checkbox'
              name='signatureRequired'
              onChange={async (e) => {
                if (e.target.value === 'true') {
                  this.onChangeFormKeyValue(form.id, 'signature_required', 0);
                } else {
                  this.onChangeFormKeyValue(form.id, 'signature_required', 1);
                }
              }}
              value={form.signature_required === 1 ? true : false}
              checked={form.signature_required === 1 ? true : false}
              className='largerCheckbox margin-right-15'
            />
            Require Customer's Signature
          </div>
          {/* <div className={form.signature_required ? 'display-block' : 'display-none'}>
            <div style={{ width: this.props.size.width, height: '200px', border: 'solid 1px #ccc', marginBottom: '10px' }}>
              <SignatureCanvas
                canvasProps={{ width: this.props.size.width, height: '200px' }}
                ref={(ref) => {
                  this.sigPad = ref;
                }}
              />
            </div>
            <Button variant='secondary' onClick={() => this.sigPad.clear()} className='margin-bottom-15'>
              Clear Signature
            </Button>
          </div> */}
          <Alert
            variant='warning'
            show={errors.errorUpdateForm ? true : false}
            onClose={() => this.props.clearErrors()}
            dismissible
            style={{ marginTop: '10px' }}
          >
            {errors.errorUpdateForm}
          </Alert>
          <div className='display-flex flex-direction-row flex-wrap-nowrap justify-content-space-between margin-top-15'>
            <Button variant='danger' onClick={(e) => this.openModal('FORM_DELETE', true)}>
              Delete
            </Button>
            <Button onClick={(e) => this.onUpdateForm(e)} className='margin-top-15'>
              Update
            </Button>
          </div>
        </div>
        <ModalDepot
          state={this.state}
          function={{
            openModal: this.openModal,
            onDeleteForms: this.onDeleteForms,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  business: state.business,
  site: state.site,
});
export default connect(mapStateToProps, { getForm, deleteForms, updateForm, changeFormKeyValue, clearErrors })(withSize()(AppComponent));
