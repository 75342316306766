import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Button } from 'react-bootstrap';
import { MdLayers, MdBubbleChart } from 'react-icons/md';
import dateFormat from 'dateformat';

import {
  getStripeSubscriptionProducts,
  getAccountSubscription,
  getCheckedOutSession,
  cancelSubscription,
} from './../../../redux/actions/subscriptionActions';
import ModalDepot from './../components/ModalDepot';
import SubscriptionPlansComponent from './../components/SubscriptionPlansComponent';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      isViewSubscriptionPlanModalShow: false,
      isSubscriptionSuccessModalShow: false,
      isSubscriptionDeleteModalShow: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  componentDidMount() {
    // Stripe's success checkout url is directed here with a sessionID
    // When sessionID exists in the url , we make a call to Stripe to check if that session is valid
    // if sessionid valid, make a stripe retrieve request to retrieve more data to add subscription to database
    const queryParams = queryString.parse(this.props.parentProps.location.search);
    if (queryParams.session_id) {
      const data = {
        sessionID: queryParams.session_id,
        userToken: this.props.users.token,
      };
      this.props.getCheckedOutSession(data, this);
    }
    this.props.getStripeSubscriptionProducts();
    const accountSubscriptionData = {
      accountID: this.props.users.token.account_id,
    };
    this.props.getAccountSubscription(accountSubscriptionData);
  }
  openModal = (modalName, modalState, modalData) => {
    switch (modalName) {
      case 'SUBSCRIPTION_PLANS':
        modalState ? this.setState({ isViewSubscriptionPlanModalShow: true }) : this.setState({ isViewSubscriptionPlanModalShow: false });
        break;
      case 'SUBSCRIPTION_CANCEL':
        if (modalState) {
          this.setState({ isCancelSubscriptionModalShow: true, selectedPlan: modalData });
        } else {
          this.setState({ isCancelSubscriptionModalShow: false });
        }
        break;
      case 'SUBSCRIPTION_SUCCESS':
        modalState ? this.setState({ isSubscriptionSuccessModalShow: true }) : this.setState({ isSubscriptionSuccessModalShow: false });
        break;
      case 'SUBSCRIPTION_DELETE':
        modalState ? this.setState({ isSubscriptionDeleteModalShow: true }) : this.setState({ isSubscriptionDeleteModalShow: false });
        break;
      default:
    }
  };
  render() {
    const { account_subscription } = this.props.subscriptions;
    return (
      <>
        <div className='height-100vh-minus-header'>
          <div className='display-flex flex-direction-column width-100'>
            <div className='display-flex padding'>
              <div className='padding'>
                <MdBubbleChart size={75} className='color-deepskyblue' />
              </div>
              <div className='padding'>
                <div className='margin-bottom-15 font-weight-bold'>Purchased Plan</div>
                {Object.keys(account_subscription).length > 0 ? (
                  <>
                    <div>
                      <table className='table-subscription-overview'>
                        <tbody>
                          <tr>
                            <td className='text-align-right'>Plan</td>
                            <td className='text-transform-capitalize font-weight-bold'>
                              {account_subscription.product_name}
                              <div className='show-on-hover'>
                                <Button variant='secondary' onClick={() => this.openModal('SUBSCRIPTION_CANCEL', true, account_subscription)}>
                                  Cancel Subscription
                                </Button>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className='text-align-right'>Status</td>
                            <td className='text-transform-capitalize font-weight-bold'>
                              <a
                                href='#!'
                                className='color-black cursor-default text-decoration-none'
                                onClick={(e) => {
                                  account_subscription.status !== 'canceled'
                                    ? this.openModal('SUBSCRIPTION_CANCEL', true, account_subscription)
                                    : e.preventDefault();
                                }}
                              >
                                {account_subscription.status}
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>Cost</td>
                            <td>
                              <div className={account_subscription.status === 'canceled' ? 'text-decoration-line-through' : ''}>
                                ${account_subscription.amount / 100}/mo
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className='text-align-right'>Currency</td>
                            <td className='text-transform-uppercase'>{account_subscription.currency}</td>
                          </tr>
                          <tr>
                            <td className='text-align-right'>Since</td>
                            <td>{dateFormat(account_subscription.created_on, 'mmmm dd, yyyy')}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  'You are not subscribed to any plans. Get started below.'
                )}
              </div>
            </div>
            <div>
              <hr></hr>
              {typeof account_subscription === 'object' &&
              Object.keys(account_subscription).length > 0 &&
              account_subscription.status !== 'canceled' ? (
                <>
                  <div className='display-flex flex-direction-column justify-content-flex-start padding'>
                    <div className='margin-bottom-15'>Need a different plan? You can change your plan at any time.</div>
                    <div>
                      <Button variant='secondary' onClick={() => this.openModal('SUBSCRIPTION_PLANS', true)}>
                        <MdLayers size={25} className='color-deepskyblue' /> Change Plans
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className='padding'>
                    <SubscriptionPlansComponent />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <ModalDepot state={this.state} function={{ openModal: this.openModal }} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  subscriptions: state.subscriptions,
  users: state.users,
});
export default connect(mapStateToProps, { getStripeSubscriptionProducts, getAccountSubscription, getCheckedOutSession, cancelSubscription })(
  AppComponent
);
