import React, { Component } from 'react';
import { connect } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import { Alert, Button } from 'react-bootstrap';
import { MdLiveTv, MdPeopleOutline, MdSpeakerPhone } from 'react-icons/md';
import { compareUserPlanToValidPlan, updateSmartSms, getBusiness } from './../../../redux/actions/businessActions';
import { clearErrors } from './../../../redux/actions/siteActions';
import ModalDepot from '../components/ModalDepot';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}
class AppComponent extends Component {
  constructor() {
    super();
    this.state = {
      componentID: 'MARKETING',
      tabIndexValue: 0,
      isSmartSmsEnabled: false,
      isCustomerFilterTableOpen: false,
    };
    this.handleTabChange = this.handleTabChange.bind(this);
    this.openCustomerFilterTable = this.openCustomerFilterTable.bind(this);
    this.openModal = this.openModal.bind(this);
    this.toggleSmartSms = this.toggleSmartSms.bind(this);
    this.setStateSmartSms = this.setStateSmartSms.bind(this);
  }
  componentDidMount = () => {
    this.props.getBusiness(this.props.users.token.business_id, this);
  };
  openModal(modalName, modalState, modalData) {
    switch (modalName) {
      case 'CUSTOMER_FILTER_TABLE':
        if (modalState) {
          this.setState({ isCustomerFilterTableOpen: true });
        } else {
          this.setState({ isCustomerFilterTableOpen: false });
        }
        break;
      default:
    }
  }
  handleTabChange(e, newValue) {
    this.setState({ tabIndexValue: newValue });
    this.props.clearErrors();
  }
  openCustomerFilterTable = () => {
    const data = {
      validPlanPriceIDs: ['price_1HWwpZKzT5gxpfj5bBkg7nnv', 'price_1ITddEKzT5gxpfj5OtfDj99M'],
      token: this.props.users.token,
      action: 'CUSTOMER_FILTER_TABLE',
    };
    this.props.compareUserPlanToValidPlan(data, this);
  };
  handleSmartSmsOnChange = () => {
    const data = {
      validPlanPriceIDs: ['price_1HWwpZKzT5gxpfj5bBkg7nnv', 'price_1ITddEKzT5gxpfj5OtfDj99M'],
      token: this.props.users.token,
      action: 'TOGGLE_SMART_SMS',
    };
    this.props.compareUserPlanToValidPlan(data, this);
  };
  toggleSmartSms = () => {
    const data = {
      isSmartSmsEnabled: this.props.business.business.smart_sms_enabled ? 0 : 1,
      userToken: this.props.users.token,
    };
    this.props.updateSmartSms(data, this);
  };
  setStateSmartSms = (businessData) => {
    this.setState({ isSmartSmsEnabled: businessData.smart_sms_enabled === 1 ? true : false });
  };
  render() {
    const { errors } = this.props.site;
    return (
      <>
        <div>
          <div className='display-flex justify-content-space-evenly'>
            <div className='display-flex justify-content-space-evenly width-100 margin-top-15'>
              <div className='margin-left-15 margin-right-15'>
                <Alert
                  variant='warning'
                  show={errors.errorInvalidPlan ? true : false}
                  onClose={() => this.props.clearErrors()}
                  dismissible
                  className='margin-top-15'
                >
                  {errors.errorInvalidPlan}
                </Alert>
              </div>
              <div className='display-flex justify-content-center align-items-center bg-white border-radius-10'>
                <Tabs
                  value={this.state.tabIndexValue}
                  onChange={(e, i) => this.handleTabChange(e, i)}
                  indicatorColor='primary'
                  // textColor='primary'
                  scrollButtons='auto'
                  // aria-label='scrollable auto tabs example'
                  // variant='scrollable'
                  centered
                >
                  <Tab label='Email Blast' />
                  <Tab label='Smart SMS' />
                  <Tab label='Screen Time' />
                </Tabs>
              </div>
              <TabPanel value={this.state.tabIndexValue} index={0} className='width-100'>
                <div className='display-flex flex-direction-column align-items-center justify-content-center'>
                  <div className='font-size-medium-large margin-bottom-15'>Targeted Advertising</div>
                  <div className='display-flex width-max-640'>
                    We make it easier to get targeted data for email marketing. Our filter table allows you to select your customers by multiple
                    available data points.
                  </div>
                  <div className='margin-top-15'>
                    <div>
                      <MdPeopleOutline size={150} className='color-gray' />
                    </div>
                    <Button onClick={() => this.openCustomerFilterTable()}>Select Customers</Button>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={this.state.tabIndexValue} index={1} className='width-100'>
                <div className='display-flex flex-direction-column align-items-center justify-content-center'>
                  <div className='font-size-medium-large margin-bottom-15'>Doing Heavy Lifting</div>
                  <div className='display-flex width-max-640'>
                    SMS recommendation follows a set of logic to help bring people near and from afar to your business. It does so by automatically
                    sending out relevant text recommendation of your business to previously visited customers and those nearby.
                  </div>
                  <div className='margin-top-15'>
                    <div>
                      <MdSpeakerPhone size={150} className='color-gray' />
                    </div>
                    <div className='display-flex flex-direction-row align-items-center'>
                      <Typography>Disable</Typography>
                      <Switch
                        color='primary'
                        checked={this.state.isSmartSmsEnabled}
                        onChange={() => {
                          this.state.isSmartSmsEnabled ? this.toggleSmartSms() : this.handleSmartSmsOnChange();
                        }}
                      />
                      <Typography>Enable</Typography>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={this.state.tabIndexValue} index={2} className='width-100'>
                <div className='display-flex flex-direction-column align-items-center justify-content-center'>
                  <div className='font-size-medium-large margin-bottom-15'>Showcasing on Digital Displays</div>
                  <div className='display-flex width-max-640'>
                    Control communications on any screen. Keep your content relevant and timely to increase audience engagement. With OSL Screen Time,
                    you can instantly deploy content across many displays.
                  </div>
                  <div className='display-flex flex-direction-column align-items-center margin-top-15'>
                    <div className='margin-bottom-15'>
                      <MdLiveTv size={150} className='color-gray' />
                    </div>
                    <div>Ask about our digital display solutions by texting us at (713) 742-2971.</div>
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
        <ModalDepot
          state={this.state}
          function={{
            openModal: this.openModal,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  business: state.business,
  site: state.site,
});
export default connect(mapStateToProps, { compareUserPlanToValidPlan, updateSmartSms, clearErrors, getBusiness })(AppComponent);
