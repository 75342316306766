/////// SITE
export const EMAIL_TOKEN_LINK = 'EMAIL_TOKEN_LINK';
export const GET_ENV = 'GET_ENV';
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const GET_RESPONSE = 'GET_RESPONSE';
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
////// USERS
export const CHECK_VALID_USER = 'CHECK_VALID_USER';
export const VERIFY_SIGNUP_TOKEN = 'VERIFY_SIGNUP_TOKEN';
export const VERIFY_PASSWORD_TOKEN = 'VERIFY_PASSWORD_TOKEN';
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const CHANGE_USER_KEY_VALUE = 'CHANGE_USER_KEY_VALUE';
////// ACCOUNTS
export const VALIDATE_ACCOUNT = 'VALIDATE_ACCOUNT';
export const GET_ACCOUNTS = 'GET_ACCOUNTS';
////// STAFF
export const ADD_STAFF = 'ADD_STAFF';
export const GET_STAFFS = 'GET_STAFFS';
export const GET_STAFF = 'GET_STAFF';
export const DELETE_STAFFS = 'DELETE_STAFFS';
export const UPDATE_STAFF = 'UPDATE_STAFF';
export const CHANGE_STAFF_KEY_VALUE = 'CHANGE_STAFF_KEY_VALUE';
export const GET_STAFF_ALBUM = 'GET_STAFF_ALBUM';
export const GET_STAFF_MEDIA = 'GET_STAFF_MEDIA';
export const GET_STAFF_HOURS = 'GET_STAFF_HOURS';
export const GET_STAFF_HOUR = 'GET_STAFF_HOUR';
export const DELETE_STAFF_HOURS = 'DELETE_STAFF_HOURS';
export const ADD_STAFF_HOUR = 'ADD_STAFF_HOUR';
export const UPDATE_STAFF_HOUR = 'UPDATE_STAFF_HOUR';
export const CHANGE_STAFF_HOUR_KEY_VALUE = 'CHANGE_STAFF_HOUR_KEY_VALUE';
////// BUSINESS: SEARCH
export const SEARCH_BUSINESSES = 'SEARCH_BUSINESSES';
export const VERIFY_CUSTOMER = 'VERIFY_CUSTOMER';
////// BUSINESSES
export const GET_BUSINESS = 'GET_BUSINESS';
export const GET_USER_ACCOUNTS = 'GET_USER_ACCOUNTS';
export const UPDATE_BUSINESS = 'UPDATE_BUSINESS';
export const CHANGE_BUSINESS_KEY_VALUE = 'CHANGE_BUSINESS_KEY_VALUE';
////// PAGE
export const GET_BUSINESS_ALBUM = 'GET_BUSINESS_ALBUM';
export const GET_BUSINESS_PHOTO = 'GET_BUSINESS_PHOTO';
export const GET_BUSINESS_POSTS = 'GET_BUSINESS_POSTS';
export const GET_BUSINESS_POST = 'GET_BUSINESS_POST';
export const CHANGE_POST_KEY_VALUE = 'CHANGE_POST_KEY_VALUE';
export const GET_BUSINESS_POLICY = 'GET_BUSINESS_POLICY';
export const GET_BUSINESS_POLICIES = 'GET_BUSINESS_POLICIES';
////// STAFF ROLES
export const GET_STAFF_ROLES = 'GET_STAFF_ROLES';
/////// CUSTOMERS
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const GET_CUSTOMER = 'GET_CUSTOMER';
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const DELETE_CUSTOMERS = 'DELETE_CUSTOMERS';
export const CHANGE_CUSTOMER_KEY_VALUE = 'CHANGE_CUSTOMER_KEY_VALUE';
/////// APPOINTMENTS
export const GET_APPOINTMENT = 'GET_APPOINTMENT';
export const GET_APPOINTMENTS = 'GET_APPOINTMENTS';
export const GET_APPOINTMENT_FORMS = 'GET_APPOINTMENT_FORMS';
export const ADD_APPOINTMENT = 'ADD_APPOINTMENT';
export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT';
export const DELETE_APPOINTMENTS = 'DELETE_APPOINTMENTS';
export const CHANGE_APPOINTMENT_KEY_VALUE = 'CHANGE_APPOINTMENT_KEY_VALUE';
export const GET_BOOKED_APPOINTMENTS = 'GET_BOOKED_APPOINTMENTS';
/////// CHECK INS
export const ADD_CHECKIN = 'ADD_CHECKIN';
export const GET_CHECKIN = 'GET_CHECKIN';
export const GET_CHECKINS = 'GET_CHECKINS';
export const GET_CHECKINS_COUNT = 'GET_CHECKINS_COUNT';
export const GET_CHECKINS_HISTORY = 'GET_CHECKINS_HISTORY';
export const UPDATE_CHECKIN = 'UPDATE_CHECKIN';
export const DELETE_CHECKINS = 'DELETE_CHECKINS';
export const CHANGE_CHECKIN_KEY_VALUE = 'CHANGE_CHECKIN_KEY_VALUE';
export const GET_CHECKINS_STATS = 'GET_CHECKINS_STATS';
/////// CHECK OUTS
export const ADD_CHECKOUT_PRODUCTS = 'ADD_CHECKOUT_PRODUCTS';
/////// PRODUCTS
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const DELETE_PRODUCTS = 'DELETE_PRODUCTS';
export const CHANGE_PRODUCT_KEY_VALUE = 'CHANGE_PRODUCT_KEY_VALUE';
export const GET_PRODUCT_ALBUM = 'GET_PRODUCT_ALBUM';
export const GET_PRODUCT_MEDIA = 'GET_PRODUCT_MEDIA';
export const UPDATE_PRODUCT_MEDIA = 'UPDATE_PRODUCT_MEDIA';
export const CHANGE_PRODUCT_MEDIA_KEY_VALUE = 'CHANGE_PRODUCT_MEDIA_KEY_VALUE';
export const GET_PRODUCT_CATEGORIES = 'GET_PRODUCT_CATEGORIES';
export const UPDATE_PRODUCT_CATEGORY = 'UPDATE_PRODUCT_CATEGORY';
/////// HOURS
export const ADD_HOUR = 'ADD_HOUR';
export const GET_HOUR = 'GET_HOUR';
export const GET_HOURS = 'GET_HOURS';
export const UPDATE_HOUR = 'UPDATE_HOUR';
export const DELETE_HOURS = 'DELETE_HOURS';
export const CHANGE_HOUR_KEY_VALUE = 'CHANGE_HOUR_KEY_VALUE';
/////// CHECKINAPP
export const GET_CHECKINAPP = 'GET_CHECKINAPP';
export const UPDATE_CHECKINAPP = 'UPDATE_CHECKINAPP';
export const CHANGE_CHECKINAPP_KEY_VALUE = 'CHANGE_CHECKINAPP_KEY_VALUE';
export const GET_CHECKINAPP_MEDIA = 'GET_CHECKINAPP_MEDIA';
/////// FORMS
export const ADD_FORM = 'ADD_FORM';
export const GET_FORM = 'GET_FORM';
export const GET_FORMS = 'GET_FORMS';
export const UPLOAD_FORM = 'UPLOAD_FORM';
export const UPDATE_FORM = 'UPDATE_FORM';
export const DELETE_FORM = 'DELETE_FORM';
export const DELETE_FORMS = 'DELETE_FORMS';
export const CHANGE_FORM_KEY_VALUE = 'CHANGE_FORM_KEY_VALUE';
/////// CHECKINAPP TOKENS
export const GET_CHECKINAPP_TOKENS = 'GET_CHECKINAPP_TOKENS';
/////// SUBSCRIPTIONS
export const GET_SUBSCRIPTION_PRODUCTS = 'GET_SUBSCRIPTION_PRODUCTS';
export const GET_ACCOUNT_SUBSCRIPTION = 'GET_ACCOUNT_SUBSCRIPTION';
export const GET_CHECKOUT_SESSION = 'GET_CHECKOUT_SESSION';
