import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { MdSave } from 'react-icons/md';
import DatePicker from 'react-datepicker';
import dateFormat from 'dateformat';
import 'react-datepicker/dist/react-datepicker.css';
import { clearErrors } from './../../../redux/actions/siteActions';
import { getHour, updateHour, changeHourKeyValue } from '../../../redux/actions/businessActions';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      hourID: props.props.state.selectedHourID,
      isSubmitDisabled: false,
    };
  }
  componentDidMount() {
    this.props.getHour(this.props.props.state.selectedHourID);
    this.props.clearErrors();
  }
  onChangeHourKeyValue(id, key, value) {
    const data = {
      id,
      key,
      value,
    };
    this.props.changeHourKeyValue(data);
    if (
      this.props.business.business_hour.weekday !== '' &&
      this.props.business.business_hour.start !== '' &&
      this.props.business.business_hour.end !== ''
    ) {
      this.setState({ isSubmitDisabled: false });
    } else {
      this.setState({ isSubmitDisabled: true });
    }
  }
  onSubmit(e) {
    e.preventDefault();
    const data = {
      hourID: this.state.hourID,
      weekday: this.props.business.business_hour.weekday,
      start: this.props.business.business_hour.start,
      end: this.props.business.business_hour.end,
      businessID: this.props.users.token.business_id,
    };
    this.props.updateHour(data, this);
  }
  getDayNumberToDayString() {
    const DayList = () => {
      var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      let dayArray = [];
      days.map((day, i) =>
        dayArray.push(
          <option key={i} value={i}>
            {day}
          </option>
        )
      );
      return dayArray;
    };
    return <DayList />;
  }
  getOpenTime(hour) {
    if (hour.startTime) {
      const splitTime = hour.startTime.split(':');
      const openTime = new Date(new Date().setHours(splitTime[0], splitTime[1], '00'));
      return openTime;
    } else {
      return new Date(new Date().setHours(9, 0, 0));
    }
  }
  getClosedTime(hour) {
    if (hour.endTime) {
      const splitTime = hour.endTime.split(':');
      const endTime = new Date(new Date().setHours(splitTime[0], splitTime[1], '00'));
      return endTime;
    } else {
      return new Date(new Date().setHours(17, 0, 0));
    }
  }
  render() {
    const { business_hour } = this.props.business;
    this.getOpenTime(business_hour);
    return (
      <div className='display-flex width-100 align-items-center justify-content-center'>
        <div className='width-100 '>
          <span className='color-gray'></span>
          <Form noValidate onSubmit={(e) => this.onSubmit(e)} className='margin-top-15'>
            <div className='margin-bottom-15'>Weekday</div>
            <Form.Control
              as='select'
              name='weekday'
              value={business_hour.weekday}
              onChange={(e) => this.onChangeHourKeyValue(business_hour.id, 'weekday', e.target.value)}
            >
              <option value=''>Day</option>
              {this.getDayNumberToDayString()}
            </Form.Control>
            <div className='margin-top-15 margin-bottom-15'>Start Time</div>
            <DatePicker
              withPortal
              name='startTime'
              placeholderText='Start Time'
              selected={this.getOpenTime(business_hour)}
              onChange={(e) => {
                const militaryTime = dateFormat(e, 'HHMM');
                const selectedTime = dateFormat(e, 'HH:MM');
                this.onChangeHourKeyValue(business_hour.id, 'start', militaryTime);
                this.onChangeHourKeyValue(business_hour.id, 'startTime', selectedTime);
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption='Start'
              dateFormat='h:mm aa'
              autoComplete='off'
              className='padding-10 border-radius-5 border-darkgray width-100'
            />
            <div className='margin-top-15 margin-bottom-15'>End Time</div>
            <DatePicker
              withPortal
              name='endTime'
              placeholderText='End Time'
              selected={this.getClosedTime(business_hour)}
              onChange={(e) => {
                const militaryTime = dateFormat(e, 'HHMM');
                const selectedTime = dateFormat(e, 'HH:MM');
                this.onChangeHourKeyValue(business_hour.id, 'end', militaryTime);
                this.onChangeHourKeyValue(business_hour.id, 'endTime', selectedTime);
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption='End'
              dateFormat='h:mm aa'
              autoComplete='off'
              className='padding-10 border-radius-5 border-darkgray width-100'
            />
            <div className='margin-top-15'>
              <Button variant='secondary' type='submit' disabled={this.state.isSubmitDisabled ? true : false} className='width-100'>
                <MdSave size={25} className='color-deepskyblue' /> Save
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  business: state.business,
  users: state.users,
  site: state.site,
});

export default connect(mapStateToProps, { getHour, updateHour, changeHourKeyValue, clearErrors })(AppComponent);
