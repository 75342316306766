import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup } from 'react-bootstrap';
import { MdEdit, MdAddCircle, MdDeleteForever } from 'react-icons/md';
import ModalDepot from '../components/ModalDepot';
import { getBusiness, changeBusinessKeyValue, updateBusiness, getHours, deleteHours } from '../../../redux/actions/businessActions';

class AppComponent extends Component {
  constructor() {
    super();
    this.state = {
      componentID: 'BUSINESS_HOURS_FORM',
      selectedHourID: '',
      isAddHourModalShow: false,
      isUpdateHourModalShow: false,
      isDeleteHourModalShow: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal(modalName, modalState, modalData) {
    switch (modalName) {
      case 'HOUR_ADD':
        modalState ? this.setState({ isAddHourModalShow: true }) : this.setState({ isAddHourModalShow: false });
        break;
      case 'HOUR_UPDATE':
        if (modalState) {
          this.setState({ isUpdateHourModalShow: true });
        } else {
          this.setState({ isUpdateHourModalShow: false });
          this.setState({ selectedHourID: '' });
        }
        break;
      case 'HOUR_DELETE':
        modalState ? this.setState({ isDeleteHourModalShow: true }) : this.setState({ isDeleteHourModalShow: false });
        break;
      default:
    }
  }
  onDeleteHours = (e) => {
    e.preventDefault();
    const data = {
      hourIDArray: [this.state.selectedHourID],
    };
    this.props.deleteHours(data, this);
  };
  render() {
    const { business_hours } = this.props.business;
    return (
      <>
        <div>
          <div className='display-flex margin-bottom-15'>
            <Button variant='secondary' onClick={() => this.openModal('HOUR_ADD', true)} className='width-100'>
              <MdAddCircle size={25} className='color-deepskyblue' /> Add Hours
            </Button>
          </div>
          <div className='flex-1'>
            {business_hours.map((hour, i) => {
              return (
                <div key={i}>
                  <div className='display-flex align-items-center padding-5 '>
                    <div className='flex-1'>{hour.dayName}</div>
                    <div className='flex-1'>
                      <center>{hour.open}</center>
                    </div>
                    <div className='flex-1 display-flex justify-content-center'>—</div>
                    <div className='flex-1 justify-content-left padding-5'>
                      <center>{hour.closed}</center>
                    </div>
                    <div className='flex-1 display-flex justify-content-flex-end'>
                      <ButtonGroup>
                        <Button
                          variant='secondary'
                          onClick={() => {
                            this.setState({ selectedHourID: hour.id });
                            this.openModal('HOUR_UPDATE', true);
                          }}
                        >
                          <div className='white-space-nowrap'>
                            <MdEdit size={25} className='color-deepskyblue' />
                          </div>
                        </Button>
                        <Button
                          variant='secondary'
                          onClick={() => {
                            this.setState({ selectedHourID: hour.id });
                            this.openModal('HOUR_DELETE', true);
                          }}
                        >
                          <div className='white-space-nowrap'>
                            <MdDeleteForever size={25} className='color-red' />
                          </div>
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <ModalDepot state={this.state} function={{ openModal: this.openModal, onDeleteHours: this.onDeleteHours }} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  business: state.business,
});
export default connect(mapStateToProps, { getBusiness, changeBusinessKeyValue, updateBusiness, getHours, deleteHours })(AppComponent);
