import axios from 'axios';
import jwt_decode from 'jwt-decode';
import socketIOClient from 'socket.io-client';
import dateFormat from 'dateformat';

import {
  GET_ERRORS,
  SEARCH_BUSINESSES,
  GET_BUSINESS,
  GET_USER_ACCOUNTS,
  UPDATE_BUSINESS,
  CHANGE_BUSINESS_KEY_VALUE,
  GET_BUSINESS_ALBUM,
  GET_BUSINESS_PHOTO,
  GET_BUSINESS_POSTS,
  GET_BUSINESS_POST,
  GET_BUSINESS_POLICY,
  GET_BUSINESS_POLICIES,
  CHANGE_POST_KEY_VALUE,
  ADD_STAFF,
  GET_STAFFS,
  GET_STAFF,
  DELETE_STAFFS,
  UPDATE_STAFF,
  CHANGE_STAFF_KEY_VALUE,
  GET_STAFF_ROLES,
  GET_STAFF_ALBUM,
  GET_STAFF_MEDIA,
  GET_STAFF_HOURS,
  GET_STAFF_HOUR,
  DELETE_STAFF_HOURS,
  CHANGE_STAFF_HOUR_KEY_VALUE,
  ADD_CHECKIN,
  GET_CHECKIN,
  GET_CHECKINS,
  GET_CHECKINS_COUNT,
  GET_CHECKINS_HISTORY,
  UPDATE_CHECKIN,
  DELETE_CHECKINS,
  CHANGE_CHECKIN_KEY_VALUE,
  // GET_CHECKINS_STATS,
  // ADD_CUSTOMER,
  GET_CUSTOMER,
  GET_CUSTOMERS,
  // UPDATE_CUSTOMER,
  DELETE_CUSTOMERS,
  CHANGE_CUSTOMER_KEY_VALUE,
  // ADD_PRODUCT,
  GET_PRODUCT,
  GET_PRODUCTS,
  CHANGE_PRODUCT_KEY_VALUE,
  GET_PRODUCT_ALBUM,
  GET_PRODUCT_MEDIA,
  UPDATE_PRODUCT_MEDIA,
  CHANGE_PRODUCT_MEDIA_KEY_VALUE,
  DELETE_PRODUCTS,
  GET_APPOINTMENT,
  GET_APPOINTMENTS,
  GET_APPOINTMENT_FORMS,
  UPDATE_APPOINTMENT,
  ADD_APPOINTMENT,
  DELETE_APPOINTMENTS,
  CHANGE_APPOINTMENT_KEY_VALUE,
  GET_BOOKED_APPOINTMENTS,
  GET_HOUR,
  GET_HOURS,
  DELETE_HOURS,
  CHANGE_HOUR_KEY_VALUE,
  GET_CHECKINAPP,
  UPDATE_CHECKINAPP,
  CHANGE_CHECKINAPP_KEY_VALUE,
  GET_CHECKINAPP_MEDIA,
  ADD_FORM,
  UPLOAD_FORM,
  GET_FORM,
  GET_FORMS,
  UPDATE_FORM,
  DELETE_FORMS,
  CHANGE_FORM_KEY_VALUE,
  GET_PRODUCT_CATEGORIES,
} from '../types/types';
import { authenticateUser } from './../actions/userActions';
import setAuthToken from './../../functions/setAuthToken';

// BUSINESSES
export const addBusiness = (data) => (dispatch) => {
  axios
    .post('/api/business/add_business', data)
    .then((res) => {
      window.location.reload();
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const getBusiness = (data, caller) => (dispatch) => {
  axios
    .get(`/api/business/get_business?businessID=${data}`)
    .then((res) => {
      dispatch({ type: GET_BUSINESS, payload: res.data });
      if (caller && caller.state.componentID === 'MARKETING') {
        caller.setStateSmartSms(res.data);
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const getUserAccounts = (data) => (dispatch) => {
  axios
    .get(`/api/business/get-user-accounts?user_id=${JSON.stringify(data)}`)
    .then((res) => {
      dispatch({ type: GET_USER_ACCOUNTS, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const updateBusiness = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/update_business', data)
    .then((res) => {
      const { signPayload } = res.data;
      if (signPayload) {
        localStorage.setItem('loginToken', signPayload.loginToken);
        setAuthToken(signPayload.loginToken); // set token to auth header
        const decoded = jwt_decode(signPayload.loginToken); // decode token to get user data
        dispatch(authenticateUser(decoded)); // set current user authentication
      }
      caller.props.function.openModal('BUSINESS_UPDATE', false);
      dispatch({ type: UPDATE_BUSINESS, payload: res.data });
      window.location.reload();
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const changeBusinessKeyValue = (data) => (dispatch) => {
  dispatch({ type: CHANGE_BUSINESS_KEY_VALUE, payload: data });
};
export const switchAccount = (data) => (dispatch) => {
  axios
    .post('/api/business/switch_user_business', data)
    .then((res) => {
      const { loginToken } = res.data;
      if (loginToken) {
        // set token to local storage
        localStorage.setItem('loginToken', loginToken);
        setAuthToken(loginToken); // set token to auth header
        const decoded = jwt_decode(loginToken); // decode token to get user data
        dispatch(authenticateUser(decoded)); // set current user authentication
        window.location.href = '/business/dashboard';
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const validateBusinessPage = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/validate-business-page', data)
    .then((res) => {
      dispatch({ type: GET_BUSINESS, payload: res.data });
      caller.setState({ businessID: res.data.id });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const getBusinessAlbum = (data) => (dispatch) => {
  axios
    .get(`/api/business/get-business-album?businessID=${data}`)
    .then((res) => {
      dispatch({ type: GET_BUSINESS_ALBUM, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const getBusinessPhoto = (data) => (dispatch) => {
  axios
    .get(`/api/business/get-business-photo?businessID=${data}`)
    .then((res) => {
      dispatch({ type: GET_BUSINESS_PHOTO, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const deleteBusinessMedia = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/delete-business-media', data)
    .then((res) => {
      caller.openModal('BUSINESS_MEDIA_DELETE', false);
      dispatch(getBusinessAlbum(res.data.businessID));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const getBusinessPolicy = (data, caller) => (dispatch) => {
  axios
    .get(`/api/business/get-business-policy?policyID=${data}`)
    .then((res) => {
      dispatch({ type: GET_BUSINESS_POLICY, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const getBusinessPolicies = (data, caller) => (dispatch) => {
  axios
    .get(`/api/business/get-business-policies?businessID=${data}`)
    .then((res) => {
      dispatch({ type: GET_BUSINESS_POLICIES, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const deleteBusinessPolicies = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/delete-business-policies', data)
    .then((res) => {
      dispatch(getBusinessPolicies(res.data.businessID));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const addBusinessPolicies = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/add-business-policies', data)
    .then((res) => {
      dispatch(getBusinessPolicies(res.data.businessID));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
// POSTS
export const getPosts = (data) => (dispatch) => {
  axios
    .get(`/api/business/get-posts?businessID=${data}`)
    .then((res) => {
      dispatch({ type: GET_BUSINESS_POSTS, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const getPost = (data) => (dispatch) => {
  axios
    .get(`/api/business/get-post?postID=${JSON.stringify(data)}`)
    .then((res) => {
      dispatch({ type: GET_BUSINESS_POST, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const getPostCount = (data) => (dispatch) => {
  axios
    .get(`/api/business/get-posts?businessID=${data}`)
    .then((res) => {
      dispatch({ type: GET_BUSINESS_POSTS, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const changePostKeyValue = (data) => (dispatch) => {
  dispatch({ type: CHANGE_POST_KEY_VALUE, payload: data });
};
export const updatePost = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/update-post', data)
    .then((res) => {
      caller.props.function.openModal('POST_UPDATE', false);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const deletePost = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/delete-post', data)
    .then((res) => {
      caller.openModal('CONFIRMATION_DELETE', false);
      caller.props.function.openModal('POST_UPDATE', false);
      dispatch(getPosts(data.businessID));
      // dispatch({ type: DELETE_POST, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
// PERSONS
export const verifyPerson = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/verify-person', data)
    .then(async (res) => {
      console.log('ACTION, VERIFY PERSON', caller, res.data);
      if (res.data.persons) {
        console.log('ACTION, VERIFY WITH PERSONS', caller);
        const props = {
          persons: res.data.persons,
          businessID: res.data.props.businessID,
        };
        caller.onViewVerifiedPersons(props);
      } else {
        console.log('ACTION, VERIFY NO PERSON');
        caller.openModal('PERSON_ADD', true);
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const addPerson = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/add_person', data)
    .then((res) => {
      console.log('ADD PERSON', caller);
      // add person as customer
      dispatch(addCustomer(res.data, caller));
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
// CUSTOMERS
export const verifyCustomer = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/verify-customer', data)
    .then(async (res) => {
      // console.log('VERIFY CUSTOMER EXIST ALREADY', res.data);
      if (res.data.verified) {
        // console.log('VERIFY CUSTOMER EXIST ALREADY', caller.props);
        if (caller.props.props.state.componentID === 'CHECKINAPP_FORM') {
          // console.log('VERIFY CUS CHECKINAPP FORM');
          caller.props.props.function.openModal('PERSON_SELECT_TABLE', false);
          dispatch(getCustomer(res.data.customer.id, caller));
        }
        if (caller.props.props.state.componentID === 'PERSON_VERIFY_FORM') {
          // console.log('VERIFY CUS PERSON SELECT TABLE');
          if (window.confirm('That person is already a customer. Try searching for that customer.')) {
            caller.props.props.function.openModal('PERSON_SELECT_TABLE', false); // close the person verify form
          }
        }
        if (caller.props.props.state.componentID === 'BOOKING_VERIFY_FORM') {
          // console.log('CALLER', caller);
          if (caller.props.props.state.isVerifyByText) {
            // console.log('TEXT BOOKING CODE');
            // caller.props.props.function.openModal('PERSON_SELECT_TABLE', false);
            caller.props.props.function.onSendBookingCode(res.data.customer);
          } else {
            console.log('');
            if (res.data.customer.email === '') {
              // console.log('NO EMAIL, PROMPT EMAIL', res.data.customer);
              // prompt person for email
              await caller.props.props.function.setStateCustomer(res.data.customer);
              // caller.props.props.function.openModal('EMAIL_PROMPT', true);
              // dispatch(getCustomer(res.data.customer.id));
              // dispatch({ type: GET_CUSTOMER, payload: res.data });
            } else {
              // console.log('SEND BOOKING CODE', res.data.customer);
              await caller.props.props.function.setStateCustomer(res.data.customer);
              caller.props.props.function.onSendBookingCode(res.data.customer);
            }
          }
        }
      } else {
        // console.log('ADD THE PERSON AS A CUSTOMER AND THEN CHECKIN', caller, res.data);
        caller.props.props.function.openModal('PERSON_SELECT_TABLE', false);
        dispatch(addCustomer({ person: res.data.person, businessID: res.data.props.businessID }, caller));
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const addCustomer = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/add-customer', data)
    .then(async (res) => {
      // console.log('ADD CUSTOMER', caller);
      dispatch({ type: GET_CUSTOMER, payload: res.data.customer });
      // if (caller.state.componentID === 'PERSON_VERIFY_FORM') {
      //   // verify person from customers table
      //   // customer added
      //   caller.openModal('PERSON_ADD', false); // close person add form
      //   caller.props.props.function.openModal('PERSON_VERIFY', false); // close person verify form
      //   dispatch({ type: ADD_CUSTOMER, payload: res.data.customer });
      //   dispatch(getCustomers(caller.props.props.prop.business.customer.businessID));
      // }
      if (caller.state.componentID === 'CHECKINAPP_FORM') {
        // console.log('ADD CUSTOMER CHECKINAPP_FORM', caller);
        if (caller.props.business.checkinapp.form_enabled) {
          caller.openModal('AGREEMENT_FORM', true);
        } else {
          caller.props.business.customer = res.data.customer;
          caller.onAddCheckIn(caller);
        }
      }
      if (caller.state.componentID === 'PERSON_SELECT_TABLE') {
        // console.log('ADD CUSTOMER PERSON_SELECT_TABLE', caller);
        if (caller.props.props.props.business.checkinapp.form_enabled) {
          caller.props.props.function.openModal('AGREEMENT_FORM', true);
        } else {
          caller.props.props.props.business.customer = res.data.customer;
          caller.props.props.function.onAddCheckIn(caller);
        }
      }
      // NEWLY ADDED PERSON
      if (caller.state.componentID === 'PERSON_ADD_FORM') {
        // console.log('ADD CUSTOMER PERSON_ADD_FORM', caller);
        if (caller.props.props.state.componentID === 'CHECKINAPP_FORM') {
          caller.props.props.function.openModal('PERSON_ADD', false);
          if (caller.props.business.checkinapp.form_enabled) {
            console.log('ADD PERSON_ADD AGREE FORM', caller, res.data);
            caller.props.props.function.openModal('AGREEMENT_FORM', true);
          } else {
            console.log('ADD PERSON_ADD NO FORM', caller, res.data);
            caller.props.business.customer = res.data.customer;
            caller.props.props.function.onAddCheckIn(caller);
          }
        }
        if (caller.props.props.state.componentID === 'PERSON_VERIFY_FORM') {
          await caller.props.props.function.openModal('PERSON_ADD', false);
          if (window.confirm('Customer added successfully')) {
            // console.log('VERIFY CUSTOMER EXIST ALREADY', caller);
            // close the person verify form
            caller.props.props.function.openModal('PERSON_SELECT_TABLE', false);
          }
        }
        if (caller.props.props.state.componentID === 'BOOKING_VERIFY_FORM') {
          // console.log('CLOSING PERSON ADD FORM AND SENDING BOOKING CODE');
          caller.props.props.function.openModal('PERSON_ADD', false);
          caller.props.props.function.onSendBookingCode(res.data.customer);
        }
        // {
        //   caller.props.props.function.openModal('PERSON_ADD', false);
        //   caller.props.props.props.props.function.openModal('PERSON_VERIFY', false);
        //   dispatch({ type: ADD_CUSTOMER, payload: res.data.customer });
        //   dispatch(getCustomers(caller.props.business.business.id));
        // }
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const getCustomer = (data, caller) => (dispatch) => {
  axios
    .get(`/api/business/get-customer?customerID=${data}`)
    .then((res) => {
      console.log('GET CUSTOMER CALLER', caller);
      if (caller.props.props.state.componentID === 'CHECKINAPP_FORM') {
        console.log('CHECKING IN OK', res.data);
        if (caller.props.props.props.business.checkinapp.form_enabled) {
          console.log('CHECKING IN WITH FORM', caller.props);
          caller.props.props.function.openModal('AGREEMENT_FORM', true);
        } else {
          // add check in data, check in customer
          console.log('CHECKING IN NO FORM');
          caller.props.props.props.business.customer = res.data;
          caller.props.props.function.onAddCheckIn(caller);
        }
      }
      dispatch({ type: GET_CUSTOMER, payload: res.data });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const getCustomers = (data) => (dispatch) => {
  axios
    .get(`/api/business/get_customers?businessID=${data}`)
    .then((res) => {
      dispatch({ type: GET_CUSTOMERS, payload: res.data });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const updateCustomer = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/update_customer', data)
    .then((res) => {
      // dispatch({ type: UPDATE_CUSTOMER, payload: res.data });
      dispatch(getCustomer(res.data.id, caller));
      caller.props.props.function.openModal('CUSTOMER_UPDATE', false);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const deleteCustomers = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/delete_customers', data)
    .then((res) => {
      caller.openModal('CUSTOMER_DELETE', false);
      caller.props.props.function.openModal('CUSTOMER_UPDATE', false);
      caller.setState({ selected: [] });
      dispatch({ type: DELETE_CUSTOMERS, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const changeCustomerKeyValue = (data) => (dispatch) => {
  dispatch({ type: CHANGE_CUSTOMER_KEY_VALUE, payload: data });
};
export const sendCustomerOffer = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/send-customer-offer', data)
    .then((res) => {
      caller.props.openModal('BIRTHDAY_WISH_SET', false);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const setCustomerWish = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/set-customer-wish', data)
    .then((res) => {
      caller.props.openModal('BIRTHDAY_WISH_SET', false);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
// BUSINESS SEARCH & BOOKING
export const searchBusinesses = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/search-businesses', data)
    .then((res) => {
      dispatch({ type: SEARCH_BUSINESSES, payload: res.data });
      caller.props.clearErrors();
    })
    .catch((err) => {
      dispatch({ type: SEARCH_BUSINESSES, payload: [] }); // clear search
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const verifyBookingData = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/verify-booking-data', data)
    .then((res) => {
      dispatch(verifyPerson(res.data.bookingData, caller));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const sendBookingCode = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/send-booking-code', data)
    .then((res) => {
      // set is code sent
      // close the email prompt
      if (caller.state.componentID === 'BOOKING_VERIFY_FORM') {
        caller.openModal('EMAIL_PROMPT', false);
        caller.setCodeSentToCustomer(true, res.data.customer);
      } else {
        caller.props.props.function.openModal('EMAIL_PROMPT', false);
        caller.props.props.function.setCodeSentToCustomer(true, res.data.customer);
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const addBookingData = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/add-booking-data', data)
    .then((res) => {
      caller.setBookingSuccess();
      emitSocketToBackend('SOCKET_UPDATE_BOOKINGS');
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
// SUBSCRIBERS
export const getSubscriberCount = (data) => (dispatch) => {
  axios
    .get(`/api/business/get-posts?businessID=${data}`)
    .then((res) => {
      dispatch({ type: GET_BUSINESS_POSTS, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
// PRODUCTS
export const addProduct = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/add_product', data)
    .then((res) => {
      caller.props.props.function.openModal('PRODUCT_ADD', false);
      // dispatch({
      //   type: ADD_PRODUCT,
      //   payload: res.data,
      // });
      dispatch(getProducts(res.data.businessID, caller));
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const getProducts = (data, caller) => (dispatch) => {
  axios
    .get(`/api/business/get_products?businessID=${data}`)
    .then((res) => {
      dispatch({ type: GET_PRODUCTS, payload: res.data });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const getProduct = (data, caller) => (dispatch) => {
  axios
    .get(`/api/business/get_product?productID=${data}`)
    .then((res) => {
      dispatch({ type: GET_PRODUCT, payload: res.data });
      if (caller.state.componentID === 'PRODUCT_UPDATE_FORM') {
        caller.onSetSelectedProductPosition(res.data);
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const getProductCount = (data) => (dispatch) => {
  axios
    .get(`/api/business/get-posts?businessID=${data}`)
    .then((res) => {
      dispatch({ type: GET_BUSINESS_POSTS, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const updateProduct = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/update-product', data)
    .then((res) => {
      console.log('CALLER', caller);
      if (caller.state.componentID === 'PRODUCT_UPDATE_FORM') {
        caller.props.props.function.openModal('PRODUCT_UPDATE', false);
      }
      // dispatch(getProduct(res.data.productID));
      dispatch(getProducts(res.data.businessID, caller));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const deleteProducts = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/delete_products', data)
    .then((res) => {
      caller.openModal('PRODUCT_DELETE', false);
      caller.openModal('PRODUCT_UPDATE', false);
      caller.setState({ selected: [] });
      dispatch({ type: DELETE_PRODUCTS, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const changeProductKeyValue = (data) => (dispatch) => {
  dispatch({ type: CHANGE_PRODUCT_KEY_VALUE, payload: data });
};
export const getProductAlbum = (data, caller) => (dispatch) => {
  axios
    .get(`/api/business/get-product-album?productID=${data}`)
    .then((res) => {
      dispatch({ type: GET_PRODUCT_ALBUM, payload: res.data });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const getProductMedia = (data, caller) => (dispatch) => {
  axios
    .get(`/api/business/get-product-media?mediaID=${data}`)
    .then((res) => {
      dispatch({ type: GET_PRODUCT_MEDIA, payload: res.data });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const deleteProductMedia = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/delete-product-media', data)
    .then((res) => {
      caller.openModal('PRODUCT_MEDIA_DELETE', false);
      caller.props.props.function.openModal('PRODUCT_MEDIA_UPDATE', false);
      dispatch(getProductAlbum(res.data.productID));
      dispatch(getProducts(res.data.businessID));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const setProductMediaMain = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/set-product-media-main', data)
    .then((res) => {
      caller.props.props.function.openModal('PRODUCT_MEDIA_UPDATE', false);
      dispatch(getProductAlbum(res.data.productID));
      dispatch(getProducts(res.data.businessID));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const updateProductMedia = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/update-product-media', data)
    .then((res) => {
      dispatch({ type: UPDATE_PRODUCT_MEDIA, payload: res.data });
      caller.props.props.function.openModal('PRODUCT_MEDIA_UPDATE', false);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const changeProductMediaKeyValue = (data) => (dispatch) => {
  dispatch({ type: CHANGE_PRODUCT_MEDIA_KEY_VALUE, payload: data });
};
export const getProductCategories = (data, caller) => (dispatch) => {
  axios
    .get(`/api/business/get-product-categories?businessID=${data}`)
    .then((res) => {
      dispatch({ type: GET_PRODUCT_CATEGORIES, payload: res.data });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const updateProductCategory = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/update-product-category', data)
    .then((res) => {
      // dispatch({ type: UPDATE_PRODUCT, payload: res.data });
      caller.props.props.function.openModal('PRODUCT_CATEGORY_UPDATE', false);
      // dispatch(getProduct(res.data.productID));
      dispatch(getProducts(res.data.businessID));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const deleteProductCategory = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/delete-product-category', data)
    .then((res) => {
      dispatch(getProducts(data.businessID, caller));
      if (caller.state.componentID === 'PAGE_PRODUCT_COMPONENT') {
        caller.openModal('PRODUCT_CATEGORY_DELETE', false);
      }
      // dispatch({ type: DELETE_PRODUCTS, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
// APPOINTMENTS
export const addAppointment = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/add_appointment', data)
    .then((res) => {
      caller.setState({ isSubmitInProgress: true });
      caller.props.props.function.openModal('APPOINTMENT_ADD_FORM', false);
      caller.props.clearErrors();
      emitSocketToBackend('SOCKET_UPDATE_BOOKINGS');
      dispatch({
        type: ADD_APPOINTMENT,
        payload: res.data,
      });
    })
    .catch((err) => {
      caller.setState({ isSubmitInProgress: false });
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const getAppointment = (data, caller) => (dispatch) => {
  axios
    .get(`/api/business/get_appointment?appointmentID=${data}`)
    .then((res) => {
      dispatch({ type: GET_APPOINTMENT, payload: res.data });
      if (caller.state.componentID === 'APPOINTMENT_UPDATE_FORM') {
        caller.setProducts(res.data.selectedProducts);
        caller.setForms(res.data.selectedForms);
        caller.setCustomer(res.data.selectedCustomer);
        caller.setStaff(res.data.selectedStaff);
        caller.setAppointment(res.data);
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const getAppointments = (data) => (dispatch) => {
  axios
    .get(`/api/business/get_appointments?businessID=${data}`)
    .then((res) => {
      dispatch({ type: GET_APPOINTMENTS, payload: res.data });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const getAppointmentForms = (data, caller) => (dispatch) => {
  axios
    .get(`/api/business/get_appointment_forms?appointment=${JSON.stringify(data)}`)
    .then((res) => {
      if (res.data[0]) {
        dispatch({ type: GET_APPOINTMENT_FORMS, payload: res.data });
      } else {
        // if form is not found, redirect to home page
        window.location.href = '/';
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const submitAppointmentForms = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/submit_appointment_forms', data)
    .then((res) => {
      if (window.confirm('Thank you for confirming and submitting your form(s). Click Ok to close the window or cancel to review.')) {
        window.close();
      } else {
        window.location.reload();
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const updateAppointment = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/update_appointment', data)
    .then((res) => {
      dispatch({ type: UPDATE_APPOINTMENT, payload: res.data });
      caller.props.props.function.openModal('APPOINTMENT_UPDATE_FORM', false);
      emitSocketToBackend('SOCKET_UPDATE_BOOKINGS');
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const deleteAppointments = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/delete_appointments', data)
    .then((res) => {
      caller.openModal('APPOINTMENT_DELETE', false);
      caller.props.props.function.openModal('APPOINTMENT_UPDATE_FORM', false);
      dispatch({ type: DELETE_APPOINTMENTS, payload: res.data });
      emitSocketToBackend('SOCKET_UPDATE_BOOKINGS');
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const changeAppointmentKeyValue = (data) => (dispatch) => {
  dispatch({ type: CHANGE_APPOINTMENT_KEY_VALUE, payload: data });
};
export const updateCalendarSettings = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/update_appointment_settings', data)
    .then((res) => {
      caller.props.props.function.openModal('CALENDAR_SETTINGS_FORM', false);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const getBookedAppointments = (data, caller) => (dispatch) => {
  axios
    .get(`/api/business/get-booked-appointments?businessID=${data}`)
    .then((res) => {
      dispatch({ type: GET_BOOKED_APPOINTMENTS, payload: res.data });
      caller.setState({ isDataLoaded: true });
      // caller.setActiveAppointments(res.data.activeAppointments);
      // caller.setActiveStaff(res.data.activeStaffs);
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
// HOURS
export const addHour = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/add_hour', data)
    .then((res) => {
      caller.setState({ isSubmitInProgress: true });
      caller.props.props.function.openModal('HOUR_ADD', false);
      dispatch(getHours(data.businessID));
      // dispatch({
      //   type: ADD_HOUR,
      //   payload: res.data,
      // });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const getHour = (data) => (dispatch) => {
  axios
    .get(`/api/business/get_hour?hourID=${data}`)
    .then((res) => {
      dispatch({ type: GET_HOUR, payload: res.data });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const getHours = (data) => (dispatch) => {
  axios
    .get(`/api/business/get_hours?businessID=${data}`)
    .then((res) => {
      dispatch({ type: GET_HOURS, payload: res.data });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const updateHour = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/update_hour', data)
    .then((res) => {
      // dispatch({ type: UPDATE_HOUR, payload: res.data });
      dispatch(getHours(data.businessID));
      caller.props.props.function.openModal('HOUR_UPDATE', false);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const deleteHours = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/delete_hours', data)
    .then((res) => {
      caller.openModal('HOUR_DELETE', false);
      caller.openModal('HOUR_UPDATE', false);
      caller.setState({ selected: [] });
      dispatch({ type: DELETE_HOURS, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const changeHourKeyValue = (data) => (dispatch) => {
  dispatch({ type: CHANGE_HOUR_KEY_VALUE, payload: data });
};
// STAFF
export const addStaff = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/add_staff', data)
    .then((res) => {
      caller.props.props.function.openModal('STAFF_ADD', false);
      dispatch(getStaffs(data.businessID));
      dispatch({
        type: ADD_STAFF,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const getStaff = (data) => (dispatch) => {
  axios
    .get(`/api/business/get_staff?staffID=${JSON.stringify(data)}`)
    .then((res) => {
      dispatch({ type: GET_STAFF, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const getStaffs = (data) => (dispatch) => {
  axios
    .get(`/api/business/get_staffs?businessID=${JSON.stringify(data)}`)
    .then((res) => {
      // const defaultStaff = {
      //   id: null, // must be null
      //   first_name: 'Anyone',
      //   last_name: '',
      //   businessHours: business_hours, // set the default business hours for this staff
      // };
      // staffs = [defaultStaff, ...staffs];
      // staffs.map((staff) => {
      //   staff.title = staff.first_name + ' ' + staff.last_name;
      //   // if staff doesn't have business hours, then it will take on the account's business hours
      //   if (staff.businessHours.length <= 0) {
      //     staff.businessHours = business_hours;
      //   }
      //   return staff;
      // });

      dispatch({
        type: GET_STAFFS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const updateStaff = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/update_staff', data)
    .then((res) => {
      dispatch({ type: UPDATE_STAFF, payload: res.data });
      caller.props.props.function.openModal('STAFF_UPDATE', false);
      // dispatch(getStaffs)
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const deleteStaff = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/delete_staff', data)
    .then((res) => {
      caller.openModal('STAFF_DELETE', false);
      caller.openModal('STAFF_UPDATE', false);
      caller.setState({ selected: [] });
      dispatch({
        type: DELETE_STAFFS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: GET_ERRORS, payload: err.response.data });
    });
};
export const changeStaffKeyValue = (data) => (dispatch) => {
  dispatch({ type: CHANGE_STAFF_KEY_VALUE, payload: data });
};
export const getStaffRoles = (data) => (dispatch) => {
  axios
    .get('/api/business/get_staff_roles', data)
    .then((res) => {
      dispatch({ type: GET_STAFF_ROLES, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const getStaffAlbum = (data, caller) => (dispatch) => {
  axios
    .get(`/api/business/get-staff-album?staffID=${data}`)
    .then((res) => {
      dispatch({ type: GET_STAFF_ALBUM, payload: res.data });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const getStaffMedia = (data, caller) => (dispatch) => {
  axios
    .get(`/api/business/get-staff-media?mediaID=${data}`)
    .then((res) => {
      dispatch({ type: GET_STAFF_MEDIA, payload: res.data });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const deleteStaffMedia = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/delete-staff-media', data)
    .then((res) => {
      caller.openModal('STAFF_MEDIA_DELETE', false);
      caller.props.props.function.openModal('STAFF_MEDIA_UPDATE', false);
      dispatch(getStaffAlbum(res.data.staffID));
      dispatch(getStaffs(res.data.businessID));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const setStaffMediaMain = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/set-staff-media-main', data)
    .then((res) => {
      caller.props.props.function.openModal('STAFF_MEDIA_UPDATE', false);
      dispatch(getStaffAlbum(res.data.staffID));
      dispatch(getStaffs(res.data.businessID));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const getStaffHours = (data) => (dispatch) => {
  axios
    .get(`/api/business/get-staff-hours?staffID=${data}`)
    .then((res) => {
      dispatch({ type: GET_STAFF_HOURS, payload: res.data });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const addStaffHour = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/add-staff-hour', data)
    .then((res) => {
      caller.props.props.function.openModal('STAFF_HOUR_ADD', false);
      dispatch(getStaffHours(data.staffID));
      dispatch(getStaffs(data.businessID));
      // dispatch({
      //   type: ADD_HOUR,
      //   payload: res.data,
      // });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const getStaffHour = (data) => (dispatch) => {
  axios
    .get(`/api/business/get-staff-hour?hourID=${data}`)
    .then((res) => {
      dispatch({ type: GET_STAFF_HOUR, payload: res.data });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const updateStaffHour = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/update-staff-hour', data)
    .then((res) => {
      // dispatch({ type: UPDATE_HOUR, payload: res.data });
      caller.props.props.function.openModal('STAFF_HOUR_UPDATE', false);
      dispatch(getStaffHours(data.staffID));
      dispatch(getStaffs(data.businessID));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const deleteStaffHours = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/delete-staff-hours', data)
    .then((res) => {
      caller.openModal('STAFF_HOUR_DELETE', false);
      caller.openModal('STAFF_HOUR_UPDATE', false);
      dispatch({ type: DELETE_STAFF_HOURS, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const changeStaffHourKeyValue = (data) => (dispatch) => {
  dispatch({ type: CHANGE_STAFF_HOUR_KEY_VALUE, payload: data });
};
// export const changeStaffHourKeyValue = (data) => (dispatch) => {
//   dispatch({ type: CHANGE_HOUR_KEY_VALUE, payload: data });
// };
// FORMS
export const addForm = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/add_form', data)
    .then((res) => {
      dispatch({ type: ADD_FORM, payload: res.data });
      caller.props.props.function.openModal('FORM_ADD', false);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const getForm = (data) => (dispatch) => {
  axios
    .get(`/api/business/get_form?formID=${JSON.stringify(data)}`)
    .then((res) => {
      dispatch({ type: GET_FORM, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const getForms = (data) => (dispatch) => {
  axios
    .get(`/api/business/get_forms?businessID=${JSON.stringify(data)}`)
    .then((res) => {
      dispatch({ type: GET_FORMS, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const updateForm = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/update_form', data)
    .then((res) => {
      dispatch({ type: UPDATE_FORM, payload: res.data });
      caller.props.props.function.openModal('FORM_UPDATE', false);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const uploadForm = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/upload_form', data)
    .then((res) => {
      dispatch({ type: UPLOAD_FORM, payload: res.data });
      caller.openModal('FORM_UPLOAD', true);
      caller.clearUploadField();
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const deleteForms = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/delete_forms', data)
    .then((res) => {
      dispatch({ type: DELETE_FORMS, payload: res.data });
      caller.openModal('FORM_DELETE', false);
      caller.props.props.function.openModal('FORM_UPDATE', false);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const changeFormKeyValue = (data) => (dispatch) => {
  dispatch({ type: CHANGE_FORM_KEY_VALUE, payload: data });
};
// CHECKINAPP
export const getCheckinApp = (data, caller) => (dispatch) => {
  axios
    .get(`/api/business/get-checkinapp?businessID=${data}`)
    .then((res) => {
      dispatch({ type: GET_CHECKINAPP, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const updateCheckinApp = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/update-checkinapp', data)
    .then((res) => {
      caller.props.props.function.openModal('CHECKINAPP_CUSTOMIZE', false);
      dispatch({ type: UPDATE_CHECKINAPP, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const changeCheckinAppKeyValue = (data) => (dispatch) => {
  dispatch({ type: CHANGE_CHECKINAPP_KEY_VALUE, payload: data });
};
export const getCheckinAppMedia = (data, caller) => (dispatch) => {
  axios
    .get(`/api/business/get-checkinapp-media?mediaID=${data}`)
    .then((res) => {
      dispatch(getCheckinApp(res.data.business_id, caller));
      dispatch({ type: GET_CHECKINAPP_MEDIA, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const removeCheckinAppMedia = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/remove-checkinapp-media', data)
    .then((res) => {
      caller.openModal('CHECKINAPP_MEDIA_DELETE', false);
      dispatch(getCheckinApp(res.data.businessID, caller));
      // dispatch({ type: GET_CHECKINAPP, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
// CHECKINAPP TOKENS
export const updateCheckinAppPrivateToken = (data) => (dispatch) => {
  axios
    .post('/api/business/update-checkinapp-private-token', data)
    .then((res) => {
      localStorage.setItem('localStorageCheckinAppPrivateToken', res.data.privateToken);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const authenticateCheckinApp = (data, caller) => (dispatch) => {
  axios
    .get(`/api/business/authenticate-checkinapp?checkinAppToken=${data}`)
    .then((res) => {
      if (res.data.success) {
        dispatch({ type: GET_CHECKINAPP, payload: res.data });
        dispatch(getCheckinApp(res.data.business_id, caller));
        if (caller.state.componentID === 'CHECKINAPP_MOBILE' || caller.state.componentID === 'CHECKINAPP') {
          caller.onAuthenticateCheckinApp(true);
          caller.openModal('SPLASH_SCREEN', true);
        }
      } else {
        caller.onAuthenticateCheckinApp(false);
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
// CHECK INS
export const addCheckIn = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/add_checkin', data)
    .then((res) => {
      dispatch({ type: ADD_CHECKIN, payload: res.data });
      dispatch(getCheckIn(res.data.id, caller));
      if (caller.state.componentID === 'CHECKINAPP_FORM') {
        caller.openModal('AGREEMENT_FORM', false);
        caller.openModal('CHECKINAPP_GREETING', true);
        emitSocketToBackend('SOCKET_UPDATE_CHECKINS');
        // set and remove local store from mobile check in
        localStorage.setItem('localStorageCheckinAppMobileCheckinSuccess', true);
        localStorage.setItem('localStorageCheckinAppCheckinID', res.data.id);
        localStorage.removeItem('localStorageCheckinAppPublicToken');
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const getCheckIn = (data, caller) => (dispatch) => {
  axios
    .get(`/api/business/get_checkin?checkinID=${data}`)
    .then((res) => {
      dispatch({ type: GET_CHECKIN, payload: res.data });
      if (caller.state.componentID === 'CHECKIN_UPDATE_FORM') {
        caller.setProducts(res.data.selectedProducts);
        caller.setCustomer(res.data.selectedCustomer);
        caller.setCheckin(res.data);
      }
      if (caller.state.componentID === 'CHECKIN_OVERVIEW') {
        caller.onSetStateCheckinRating(res.data);
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const getCheckIns = (data, caller) => (dispatch) => {
  axios
    .get(`/api/business/get_checkins?data=${JSON.stringify(data)}`)
    .then((res) => {
      dispatch({ type: GET_CHECKINS, payload: res.data });
      if (caller.state.componentID === 'CHECKIN_OVERVIEW') {
        caller.onGetValidPositionFromCheckins(res.data);
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const getCheckinOverview = (data, caller) => (dispatch) => {
  axios
    .get(`/api/business/get-checkin-overview?data=${JSON.stringify(data)}`)
    .then((res) => {
      if (caller.state.componentID === 'CHECKIN_OVERVIEW') {
        dispatch(getCheckinApp(res.data.checkin.business_id, caller));
        dispatch(getCheckIn(res.data.checkin.id, caller));
        const data = {
          businessID: res.data.checkin.business_id,
          sortType: 'Today',
          sortDate: dateFormat(new Date(), 'yyyy-mm-dd'),
          filterType: 'checked-in',
        };
        dispatch(getCheckIns(data, caller));
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const getCheckinList = (data, caller) => (dispatch) => {
  axios
    .get(`/api/business/get_checkin_list?data=${JSON.stringify(data)}`)
    .then((res) => {
      dispatch({ type: GET_CHECKINS, payload: res.data });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const getCheckInsCount = (data, caller) => (dispatch) => {
  axios
    .get(`/api/business/get-checkins-count?data=${JSON.stringify(data)}`)
    .then((res) => {
      dispatch({ type: GET_CHECKINS_COUNT, payload: res.data });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const updateCheckIn = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/update-checkin', data)
    .then((res) => {
      dispatch({ type: UPDATE_CHECKIN, payload: res.data });
      if (caller.state.componentID === 'CHECKIN_UPDATE_FORM') {
        caller.props.props.function.openModal('CHECKIN_UPDATE_FORM', false);
        caller.props.props.function.onGetSelectedCheckins();
        caller.props.props.function.onGetCheckinsCount();
      }
      if (caller.state.componentID === 'CHECK_INS') {
        caller.onGetSelectedCheckins();
        caller.onGetCheckinsCount();
      }
      if (caller.state.componentID === 'CHECKIN_OVERVIEW') {
        caller.openModal('LEAVE_WAITLIST_FORM', false);
      }
      emitSocketToBackend('SOCKET_UPDATE_CHECKINS');
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const updateCheckinRating = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/update-checkin-rating', data)
    .then((res) => {
      dispatch(getCheckIn(res.data.checkinID, caller));
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const deleteCheckIns = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/delete_checkins', data)
    .then((res) => {
      caller.props.openModal('CHECKIN_UPDATE_FORM', false);
      dispatch({ type: DELETE_CHECKINS, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const getCheckInsHistory = (data) => (dispatch) => {
  axios
    .get(`/api/business/get-checkins-history?customerID=${JSON.stringify(data)}`)
    .then((res) => {
      dispatch({ type: GET_CHECKINS_HISTORY, payload: res.data });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const addCheckinProducts = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/add-checkin-products', data)
    .then((res) => {
      // dispatch({ type: ADD_CHECKOUT_PRODUCTS, payload: res.data });
      caller.props.openModal('CHECKOUT_FORM', false);
      caller.props.parentFunction.openModal('CHECKIN_UPDATE_FORM', false);
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const changeCheckinKeyValue = (data) => (dispatch) => {
  dispatch({ type: CHANGE_CHECKIN_KEY_VALUE, payload: data });
};
export const textCheckinNotification = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/text-checkin-notification', data)
    .then((res) => {
      caller.openModal('CHECKIN_TEXT_NOTIFICATION', false);
      // caller.onUpdateCheckIn();
      emitSocketToBackend('SOCKET_UPDATE_CHECKINS');
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const textCheckinGetFeedback = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/text-checkin-feedback-invitation', data)
    .then((res) => {
      caller.openModal('CHECKIN_TEXT_GET_FEEDBACK', false);
      emitSocketToBackend('SOCKET_UPDATE_CHECKINS');
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const getCheckinStats = (data, caller) => (dispatch) => {
  axios
    .get(`/api/business/get-checkin-stats?data=${JSON.stringify(data)}`)
    .then((res) => {
      console.log('RESDATA', res.data);
      if (caller.state.componentID === 'DASHBOARD') {
        caller.onSetCheckinStats(res.data);
      }
      // dispatch({ type: GET_CHECKINS_STATS, payload: res.data });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
// MARKETING
export const updateSmartSms = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/update-smart-sms', data)
    .then((res) => {
      dispatch(getBusiness(res.data.userToken.business_id, caller));
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
// MISC
export const compareUserPlanToValidPlan = (data, caller) => (dispatch) => {
  axios
    .post('/api/business/compare-valid-plan', data)
    .then((res) => {
      if (res.data.action === 'CUSTOMER_FILTER_TABLE') {
        caller.openModal('CUSTOMER_FILTER_TABLE', true);
      } else if (res.data.action === 'TOGGLE_SMART_SMS') {
        caller.toggleSmartSms();
      } else if (res.data.action === 'HANDLE_CALL_UP_STATUS') {
        caller.setStateCallUpStatus();
      } else if (res.data.action === 'HANDLE_TEXT_CHECKIN_NOTIFICATION') {
        caller.handleTextCheckinNotification();
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

const emitSocketToBackend = (socketKeyWord) => {
  // console.log('CALLING SOCKET EMIT FROM ACTION', socketKeyWord);
  // use to update real-time data on all connected devices
  const endPoint =
    process.env.REACT_APP_PRODUCTION_ENV === '1' ? process.env.REACT_APP_PRODUCTION_LOCALSERVER : process.env.REACT_APP_DEVELOPMENT_LOCALSERVER;
  const socket = socketIOClient(endPoint); //192.168.86.46:3000/checkinapp

  if (socketKeyWord === 'SOCKET_UPDATE_CHECKINS') {
    console.log('CALLING SOCKET UPDATE CHECKING FROM ACTION');
    socket.emit('SOCKET_UPDATE_CHECKINS', { action: 'emit' }); // change 'red' to this.state.color
  }

  if (socketKeyWord === 'SOCKET_UPDATE_BOOKINGS') {
    console.log('CALLING SOCKET UPDATE BOOKINGS FROM ACTION');
    socket.emit('SOCKET_UPDATE_BOOKINGS', { action: 'emit' }); // change 'red' to this.state.color
  }

  // force web refresh when a new application version is uploaded, this updates the applications for users
  const currentAppVersion = '2.0.0';
  if (!localStorage.getItem('applicationVersion') || localStorage.getItem('applicationVersion') !== currentAppVersion) {
    // set application version
    localStorage.setItem('applicationVersion', currentAppVersion);
    // refresh web/update application
    window.location.reload();
  }
};
