import React, { Component } from 'react';
import { connect } from 'react-redux';
import PageComponent from './components/PageComponent';
import { validateBusinessPage } from '../../redux/actions/businessActions';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      vanityName: props.match.params.vanity,
      businessID: '',
    };
  }
  componentDidMount() {
    const data = {
      vanityName: this.props.match.params.vanity,
    };
    this.props.validateBusinessPage(data, this);
  }
  render() {
    return this.state.businessID ? (
      <>
        <div className='display-flex'>
          <PageComponent props={this.props} state={this.state} users={{}} />
        </div>
      </>
    ) : (
      <></>
      // <div className='display-flex justify-content-center align-items-center bg-orangered padding height-100vh color-white'>
      //   <div className='padding color-white'>
      //     <div className='margin-top-25 margin-bottom-50'>
      //       <center>
      //         <MdDoNotDisturbOff size='100' color='#fff' />
      //       </center>
      //     </div>
      //     <center>
      //       <div className='font-size-large margin-bottom-15'>The business page you're trying to reach is not available.</div>
      //       <div className='margin-bottom-50'>Please make sure the link is correct or contact the business.</div>
      //     </center>
      //   </div>
      // </div>
    );
  }
}

const mapStateToProps = (state) => ({
  business: state.business,
});
export default connect(mapStateToProps, { validateBusinessPage })(AppComponent);
