import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, ButtonGroup, Button, ToggleButton } from 'react-bootstrap';
import phoneFormatter from 'phone-formatter';
import { MdAnnouncement, MdPhonelinkRing } from 'react-icons/md';
import { compareUserPlanToValidPlan } from './../../../redux/actions/businessActions';
import { getPublicEnv, clearErrors } from './../../../redux/actions/siteActions';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      notificationType: 'text',
    };
  }
  handleCallUpStatus = () => {
    const data = {
      validPlanPriceIDs: [
        'price_1HWwovKzT5gxpfj5JBTdZ6vX',
        'price_1ITdRDKzT5gxpfj5AiXicffz',
        'price_1ITdSwKzT5gxpfj5CsPBjOLJ',
        'price_1HWwnxKzT5gxpfj5agK8tAjw',
        'price_1ITddEKzT5gxpfj5OtfDj99M',
        'price_1HWwpZKzT5gxpfj5bBkg7nnv',
      ],
      token: this.props.users.token,
      action: 'HANDLE_TEXT_CHECKIN_NOTIFICATION',
    };
    this.props.compareUserPlanToValidPlan(data, this);
  };
  handleTextCheckinNotification = (e) => {
    this.state.notificationType === 'call'
      ? (window.location.href = `tel:${this.props.props.state.selectedCheckin.phone}`)
      : this.props.props.function.onTextCheckinNotification();
  };
  render() {
    const { errors } = this.props.site;
    return (
      <>
        <div className='display-flex flex-direction-row'>
          <ButtonGroup toggle className='display-flex flex-wrap-nowrap'>
            <ToggleButton
              variant='secondary'
              key={1}
              type='radio'
              name='radio'
              value='text'
              checked={this.state.notificationType === 'text'}
              onChange={async (e) => {
                // switch checkin status
                this.setState({
                  notificationType: 'text',
                });
                this.props.clearErrors();
              }}
            >
              <MdAnnouncement size={20} className='color-deepskyblue' />
              Via Text
            </ToggleButton>
            <ToggleButton
              variant='secondary'
              key={2}
              type='radio'
              name='radio'
              value={'call'}
              checked={this.state.notificationType === 'call'}
              onChange={async (e) => {
                // switch checkin status
                this.setState({
                  notificationType: 'call',
                });
                this.props.clearErrors();
              }}
            >
              <MdPhonelinkRing size={20} className='color-deepskyblue' />
              Via Call
            </ToggleButton>
          </ButtonGroup>
          <div className='flex-1'>
            <div className='flex-table margin-top-15'>
              <div className='flex-table-row'>
                <div className='flex-table-cell padding text-align-right'>
                  <b>Recipient:</b>
                </div>
                <div className='flex-table-cell padding text-transform-capitalize'>
                  {phoneFormatter.format(this.props.props.state.selectedCheckin.phone + '', '(NNN) NNN-NNNN')}
                  <br />
                  {this.props.props.state.selectedCheckin
                    ? this.props.props.state.selectedCheckin.first_name + ' ' + this.props.props.state.selectedCheckin.last_name
                    : ''}
                  <br />
                </div>
              </div>
              {this.state.notificationType === 'text' ? (
                <div className='flex-table-row'>
                  <div className='flex-table-cell padding text-align-right'>
                    <b>Message:</b>
                  </div>
                  <div className='flex-table-cell padding'>
                    {this.props.props.state.selectedCheckin ? this.props.props.state.selectedCheckin.business_name + ':' : ''} We're ready for you.
                    Please head back and see the host to be served. To cancel, visit <span className='color-deepskyblue'>[cancel link]</span>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className='margin-left-15 margin-right-15'>
              <Alert
                variant='warning'
                show={errors.errorInvalidPlan ? true : false}
                onClose={() => this.props.clearErrors()}
                dismissible
                className='margin-top-15'
              >
                {errors.errorInvalidPlan}
              </Alert>
            </div>
            <div className='display-flex margin-top-15'>
              <Button
                onClick={(e) => {
                  // this.state.notificationType === 'call'
                  //   ? (window.location.href = `tel:${this.props.props.state.selectedCheckin.phone}`)
                  //   : this.props.props.function.onTextCheckinNotification();
                  this.handleCallUpStatus();
                }}
                className='display-flex width-100'
              >
                {this.state.notificationType === 'call' ? 'Call' : 'Send Text'}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  users: state.users,
  site: state.site,
});
export default connect(mapStateToProps, { getPublicEnv, compareUserPlanToValidPlan, clearErrors })(AppComponent);
