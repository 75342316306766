import React, { Component, forwardRef } from 'react';
import { Alert, Form, Button, ToggleButton, ButtonGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dateFormat from 'dateformat';
// import { normalize } from 'phone-prettify';

import { getAppointment, changeAppointmentKeyValue, updateAppointment, deleteAppointments } from '../../../redux/actions/businessActions';
import { clearErrors } from '../../../redux/actions/siteActions';
import ModalDepot from '../components/ModalDepot';
import { MdEdit, MdDeleteForever } from 'react-icons/md';
import {} from 'react-icons/io';
import { FiBox } from 'react-icons/fi';

const ReadonlyInput = forwardRef(({ value, onClick }, ref) => (
  <Button variant='secondary' onClick={onClick} style={{ padding: '5px', marginRight: '15px' }}>
    {value}
  </Button>
));

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'APPOINTMENT_UPDATE_FORM',
      isSendForms: false,
      appointmentID: props.props.state ? props.props.state.selectedAppointmentID : 0,
      appointment: {},
      selectedProducts: [],
      selectedProductIDsArray: [],
      selectedForms: [],
      selectedFormIDsArray: [],
      selectedCustomer: {},
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
      isSelectCustomerModalShow: false,
      isSelectProductsModalShow: false,
      isSelectFormsModalShow: false,
      isSelectStaffModalShow: false,
      isDeleteAppointmentModalShow: false,
      isViewCustomerProfileModalShow: false,
      isSubmitDisabled: false,
      isReserved: false,
    };
    this.onUpdateAppointment = this.onUpdateAppointment.bind(this);
    this.openModal = this.openModal.bind(this);
    this.setCustomer = this.setCustomer.bind(this);
    this.setProducts = this.setProducts.bind(this);
    this.setForms = this.setForms.bind(this);
    this.setStaff = this.setStaff.bind(this);
    this.onDeleteAppointments = this.onDeleteAppointments.bind(this);
    this.setAppointment = this.setAppointment.bind(this);
  }
  componentDidMount() {
    this.props.getAppointment(this.state.appointmentID, this);
  }
  setCustomer = async (customer) => {
    await this.setState({ selectedCustomer: customer });
  };
  setProducts = async (products) => {
    var selectedProductIDs = [];
    products.forEach((product) => {
      selectedProductIDs.push(product.id);
    });
    await this.setState({ selectedProducts: products, selectedProductIDsArray: selectedProductIDs });
  };
  setForms = async (forms) => {
    var selectedFormIDs = [];
    forms.forEach((form) => {
      selectedFormIDs.push(form.id);
    });
    await this.setState({ selectedForms: forms, selectedFormIDsArray: selectedFormIDs });
    // this.onChangeAppointmentKeyValue(this.props.business.appointment.id, 'selectedForms', forms);
  };
  setStaff(staff) {
    this.setState({ selectedStaff: staff });
  }
  setAppointment = (appointment) => {
    this.setState({ appointment });
  };
  openModal(modalName, modalState, modalData) {
    switch (modalName) {
      case 'CUSTOMER_SELECT_TABLE':
        if (modalState) {
          this.setState({ isSelectCustomerModalShow: true });
        } else {
          this.setState({ isSelectCustomerModalShow: false });
        }
        break;
      case 'CUSTOMER_VIEW_PROFILE':
        if (modalState) {
          this.setState({ isViewCustomerProfileModalShow: true });
        } else {
          this.setState({ isViewCustomerProfileModalShow: false });
        }
        break;
      case 'PRODUCTS_SELECT_TABLE':
        if (modalState) {
          this.setState({ isSelectProductsModalShow: true });
        } else {
          this.setState({ isSelectProductsModalShow: false });
        }
        break;
      case 'STAFF_SELECT_TABLE':
        if (modalState) {
          this.setState({ isSelectStaffModalShow: true });
        } else {
          this.setState({ isSelectStaffModalShow: false });
        }
        break;
      case 'FORMS_SELECT_TABLE':
        if (modalState) {
          this.setState({ isSelectFormsModalShow: true });
        } else {
          this.setState({ isSelectFormsModalShow: false });
        }
        break;
      case 'APPOINTMENT_DELETE':
        modalState ? this.setState({ isDeleteAppointmentModalShow: true }) : this.setState({ isDeleteAppointmentModalShow: false });
        break;
      default:
    }
  }
  onChangeAppointmentKeyValue(id, key, value) {
    const data = {
      id,
      key,
      value,
    };
    this.props.changeAppointmentKeyValue(data);
  }
  onUpdateAppointment(e) {
    e.preventDefault();
    // const standardProductPrices = this.props.props.props.subscriptions.products[2].prices;
    // const plusProductPrices = this.props.props.props.subscriptions.products[3].prices;

    // const allowablePriceIDs = [];
    // standardProductPrices.map((price) => {
    //   return allowablePriceIDs.push(price.id);
    // });
    // plusProductPrices.map((price) => {
    //   return allowablePriceIDs.push(price.id);
    // });
    // if (allowablePriceIDs.includes(this.props.users.token.price_id)) {
    var startDateTime = new Date(this.state.appointment.startDate).setHours(
      new Date(this.state.appointment.startTime).getHours(),
      new Date(this.state.appointment.startTime).getMinutes(),
      0
    );
    var endDateTime = new Date(this.state.appointment.endDate).setHours(
      new Date(this.state.appointment.endTime).getHours(),
      new Date(this.state.appointment.endTime).getMinutes(),
      0
    );
    const startDateTimeUTC = dateFormat(startDateTime, "yyyy-mm-dd'T'HH:MM:ss");
    const endDateTimeUTC = dateFormat(endDateTime, "yyyy-mm-dd'T'HH:MM:ss");
    const data = {
      appointmentID: this.state.appointmentID,
      startDate: startDateTimeUTC,
      endDate: endDateTimeUTC,
      selectedCustomer: this.state.selectedCustomer,
      selectedProducts: this.state.selectedProducts,
      selectedForms: this.state.selectedForms,
      selectedStaff: this.state.selectedStaff,
      notes: this.state.appointment.notes,
      status: this.state.appointment.status,
      flag: this.state.appointment.status === 'booked' ? 'confirmed' : this.state.appointment.flag,
      isSendForms: this.state.isSendForms,
    };

    this.props.updateAppointment(data, this);
    // } else {
    //   this.props.props.function.openModal('SUBSCRIPTION_PLANS', true);
    // }
    //     }
    //   }
    // }
  }
  onDeleteAppointments = (e) => {
    e.preventDefault();
    const standardProductPrices = this.props.props.props.subscriptions.products[1].prices;
    const plusProductPrices = this.props.props.props.subscriptions.products[0].prices;

    const allowablePriceIDs = [];
    standardProductPrices.map((price) => {
      return allowablePriceIDs.push(price.id);
    });
    plusProductPrices.map((price) => {
      return allowablePriceIDs.push(price.id);
    });
    if (allowablePriceIDs.includes(this.props.users.token.price_id)) {
      const data = {
        appointmentIDArray: [this.state.appointmentID],
      };
      this.props.deleteAppointments(data, this);
      // bug: currentPage doesn't go down when deleting the last item on a paginated page
      // this fix the bug that causes error when deleting the last item on that particular page
      // if (this.state.currentPage > Math.ceil((this.state.appointments.length - 1) / this.state.currentPageSize) && this.state.currentPage !== 1) {
      //   this.setState({ currentPage: this.state.currentPage - 1 });
      // }
    } else {
      this.props.props.function.openModal('SUBSCRIPTION_PLANS', true);
    }
  };
  render() {
    const { errors } = this.props.site;
    var unconfirmedForms = [];
    if (this.state.selectedForms) {
      unconfirmedForms = this.state.selectedForms.filter((form) => {
        if (!form.date_confirmed) return unconfirmedForms;
        return '';
      });
    }
    if (this.props.props.props.business_hours && this.props.props.props.business_hours.length > 0) {
      var minHour = null,
        maxHour = null;
      if (this.props.props.props.business_hours && this.props.props.props.business_hours.length > 0) {
        minHour = Math.min.apply(
          Math,
          this.props.props.props.business_hours.map((hour) => {
            return hour.start;
          })
        );
        maxHour = Math.max.apply(
          Math,
          this.props.props.props.business_hours.map((hour) => {
            return hour.end;
          })
        );
      }
    }
    return this.state.appointment.constructor === Object && Object.keys(this.state.appointment).length !== 0 ? (
      <>
        <div className='display-flex width-100 align-items-center justify-content-center'>
          <div className='width-100'>
            {this.state.appointment.status === 'booked' ? (
              <>
                <div className='border-darkorange border-radius-5 padding margin-bottom-15'>
                  <div className='margin-bottom-15 font-size-normal'>Awaiting your confirmation</div>
                  <span>
                    Please review and confirm this booking when you're ready.
                    <br />
                    <br />
                    If you make changes to this requested booking, you should notify this customer before confirming.
                  </span>
                </div>
              </>
            ) : (
              ''
            )}
            <div className='flex-table'>
              <div className='flex-table-row'>
                <div className='flex-table-cell font-size-normal padding text-align-right'>Type:</div>
                <div className='flex-table-cell padding'>
                  {this.state.appointment.selectedCustomer && this.state.appointment.selectedCustomer.first_name
                    ? 'Appointment/Reservation'
                    : 'Event/Holiday'}
                </div>
              </div>
              <div className='flex-table-row'>
                <div className='flex-table-cell font-size-normal padding text-align-right'>Date &amp; Time:</div>
                <div className='flex-table-cell display-flex flex-wrap-nowrap align-items-center padding'>
                  <DatePicker
                    withPortal
                    name='startDate'
                    minDate={new Date()}
                    selected={new Date(this.state.appointment.startDate).getTime()}
                    onChange={(e) => {
                      // if
                      this.setState({
                        appointment: {
                          ...this.state.appointment,
                          startDate: e,
                          // startTime: this.state.appointment.startTime,
                          // endTime: this.state.appointment.endTime,
                          // endDate:
                          //   new Date(this.state.appointment.startDate) > new Date(this.state.appointment.endDate)
                          //     ? e
                          //     : new Date(this.state.appointment.endDate),
                        },
                      });
                      // go to date on calendar
                      var data = {
                        date: e,
                      };
                      this.props.props.function.handleDateClick(data);
                    }}
                    dateFormat='EEE, MMM d'
                    customInput={<ReadonlyInput />}
                  />
                  <DatePicker
                    withPortal
                    selected={new Date(this.state.appointment.startTime).getTime()}
                    name='startTime'
                    onChange={(e) => {
                      this.setState({
                        appointment: {
                          ...this.state.appointment,
                          startTime: e,
                          // startDate: this.state.appointment.startDate,
                          // endTime: this.state.appointment.endTime,
                          // endDate: this.state.appointment.endDate,
                        },
                      });
                      // this.onChangeAppointmentKeyValue(this.state.appointment.id, 'start', e);
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    minTime={minHour}
                    maxTime={maxHour}
                    // timeIntervals={this.props.props.props.business.booking_interval}
                    timeIntervals={'05'}
                    timeCaption='Start'
                    dateFormat='h:mm aa'
                    customInput={<ReadonlyInput />}
                  />
                  <div className='margin-right-15'> to </div>
                  <DatePicker
                    withPortal
                    selected={new Date(this.state.appointment.endTime).getTime()}
                    name='endTime'
                    onChange={(e) => {
                      this.setState({
                        appointment: {
                          ...this.state.appointment,
                          endTime: e,
                          // startDate: this.state.appointment.startDate,
                          // startTime: this.state.appointment.startTime,
                          // endDate: this.state.appointment.endDate,
                        },
                      });
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    minTime={minHour}
                    maxTime={maxHour}
                    // timeIntervals={this.props.props.props.business.booking_interval}
                    timeIntervals={'05'}
                    timeCaption='End'
                    dateFormat='h:mm aa'
                    customInput={<ReadonlyInput />}
                  />
                  <div id='EndDatePicker'>
                    <DatePicker
                      withPortal
                      name='endDate'
                      minDate={new Date()}
                      selected={new Date(this.state.appointment.endDate).getTime()}
                      onChange={(e) => {
                        this.setState({
                          appointment: {
                            ...this.state.appointment,
                            // startDate:
                            //   new Date(this.state.appointment.startDate) > new Date(this.state.appointment.endDate)
                            //     ? e
                            //     : new Date(this.state.appointment.endDate),
                            // startTime: this.state.appointment.startTime,
                            // endTime: this.state.appointment.endTime,
                            endDate: e,
                          },
                        });
                      }}
                      dateFormat='EEE, MMM d'
                      customInput={<ReadonlyInput />}
                    />
                  </div>
                </div>
              </div>
              {this.state.appointment.selectedCustomer && this.state.selectedCustomer.id ? (
                <div className='flex-table-row'>
                  <div className='flex-table-cell font-size-normal padding text-align-right'>Status:</div>
                  <div className='flex-table-cell padding'>
                    <ButtonGroup toggle>
                      {this.state.appointment.status === 'booked' ? (
                        <ToggleButton
                          variant='secondary'
                          key={1}
                          type='radio'
                          name='radio'
                          value='customer'
                          checked={this.state.appointment.status === 'booked'}
                          onChange={async (e) => {
                            await this.setState({ appointment: { ...this.state.appointment, flag: 'requested' } });
                          }}
                          onClick={async () => {
                            await this.setState({ appointment: { ...this.state.appointment, flag: 'requested' } });
                          }}
                        >
                          <div className='display-flex align-items-center justify-content-center'>
                            <div className='margin-left-5'>Awaiting Confirmation</div>
                          </div>
                        </ToggleButton>
                      ) : (
                        <>
                          <ToggleButton
                            variant='secondary'
                            key={2}
                            type='radio'
                            name='radio'
                            value='customer'
                            checked={this.state.appointment.flag === 'confirmed'}
                            onChange={async (e) => {
                              await this.setState({ appointment: { ...this.state.appointment, flag: 'confirmed' } });
                            }}
                            onClick={async () => {
                              await this.setState({ appointment: { ...this.state.appointment, flag: 'confirmed' } });
                            }}
                          >
                            <div className='display-flex align-items-center justify-content-center'>
                              <div className='display-flex align-items-center'>
                                {/* <MdDone size={25} className='color-deepskyblue' /> */}
                                Confirmed
                              </div>
                            </div>
                          </ToggleButton>
                          <ToggleButton
                            variant='secondary'
                            key={3}
                            type='radio'
                            name='radio'
                            value='customer'
                            checked={this.state.appointment.flag === 'canceled'}
                            onChange={async (e) => {
                              await this.setState({ appointment: { ...this.state.appointment, flag: 'canceled' } });
                            }}
                            onClick={async () => {
                              await this.setState({ appointment: { ...this.state.appointment, flag: 'canceled' } });
                            }}
                          >
                            <div className='display-flex align-items-center justify-content-center'>
                              <div className='display-flex align-items-center'>
                                {/* <MdBlock size={25} className='color-gold' /> */}
                                Canceled
                              </div>
                            </div>
                          </ToggleButton>
                          <ToggleButton
                            variant='secondary'
                            key={4}
                            type='radio'
                            name='radio'
                            value='customer'
                            checked={this.state.appointment.flag === 'no-show'}
                            onChange={async (e) => {
                              await this.setState({ appointment: { ...this.state.appointment, flag: 'no-show' } });
                            }}
                            onClick={async () => {
                              await this.setState({ appointment: { ...this.state.appointment, flag: 'no-show' } });
                            }}
                          >
                            <div className='display-flex align-items-center justify-content-center'>
                              <div className='display-flex align-items-center'>
                                {/* <MdHighlightOff size={25} className='color-tomato' /> */}
                                No-show
                              </div>
                            </div>
                          </ToggleButton>
                        </>
                      )}
                    </ButtonGroup>
                  </div>
                </div>
              ) : (
                ''
              )}
              {this.state.appointment.selectedCustomer && this.state.selectedCustomer.id ? (
                <>
                  <div className='flex-table-row'>
                    <div className='flex-table-cell font-size-normal padding text-align-right'>Customer:</div>
                    <div className='flex-table-cell padding'>
                      {this.state.selectedCustomer.id ? (
                        <>
                          <div>
                            <Button
                              variant='secondary'
                              onClick={() => this.openModal('CUSTOMER_VIEW_PROFILE', true)}
                              style={{ padding: '15px', color: '#019fe8', border: 'solid 1px #019fe8' }}
                            >
                              <span className='text-transform-capitalize'>{this.state.selectedCustomer.first_name}</span>{' '}
                              <span className='text-transform-capitalize'>{this.state.selectedCustomer.last_name}</span>
                              {/* -{' '}{normalize(this.state.selectedCustomer.phone)} */}
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          <Button variant='secondary' onClick={() => this.openModal('CUSTOMER_SELECT_TABLE', true)}>
                            Select
                          </Button>
                        </>
                      )}
                      <></>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
              <div className='flex-table-row'>
                <div className='flex-table-cell font-size-normal padding text-align-right'>
                  {this.state.appointment.status === 'booked'
                    ? 'Requested'
                    : this.state.appointment.selectedCustomer && this.state.appointment.selectedCustomer.first_name
                    ? 'Assigned'
                    : 'Applied'}{' '}
                  Staff:
                </div>
                <div className='flex-table-cell padding'>
                  <div>
                    {this.state.selectedStaff.constructor === Object && Object.keys(this.state.selectedStaff).length !== 0 ? (
                      <>
                        <div className='display-flex align-items-center'>
                          {this.state.selectedStaff.first_name + ' ' + this.state.selectedStaff.last_name}
                          <Button variant='secondary' onClick={() => this.openModal('STAFF_SELECT_TABLE', true)} style={{ marginLeft: '10px' }}>
                            <MdEdit size={25} className='color-deepskyblue' />
                          </Button>
                          <Button variant='secondary' onClick={(e) => this.setState({ selectedStaff: {} })} style={{ marginLeft: '15px' }}>
                            X
                          </Button>
                        </div>
                      </>
                    ) : (
                      <div>
                        <Button variant='secondary' onClick={() => this.openModal('STAFF_SELECT_TABLE', true)}>
                          {this.state.appointment.selectedCustomer && this.state.appointment.selectedCustomer.first_name ? 'Anyone' : 'All'}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='flex-table-row'>
                <div className='flex-table-cell font-size-normal padding text-align-right'>
                  {this.state.appointment.status === 'booked' ? ' Requested' : ''} Products &amp; Services:
                </div>
                <div className='flex-table-cell padding'>
                  <Button variant='secondary' onClick={() => this.openModal('PRODUCTS_SELECT_TABLE', true)}>
                    {this.state.selectedProducts.length > 0 ? 'Change' : 'Select'}
                  </Button>
                  {this.state.selectedProducts &&
                    this.state.selectedProducts.map((product, i) => {
                      const price = product.price !== null ? product.price.split('.') : product.price;
                      const ProductMedia = () => {
                        const product_media_source = product.product_media_source
                          ? '/api/static/media/product/thumbnail/' + product.product_media_source
                          : '';
                        return product_media_source ? (
                          <div style={{ width: 'fit-content' }}>
                            <img
                              alt='product_thumbnail'
                              src={product_media_source}
                              // width='50px'
                              className='thumbnail border-radius-5'
                              // style={{ width: 'fit-content' }}
                            />
                          </div>
                        ) : (
                          <div className='padding-5 border-radius-5 border' style={{ width: 'fit-content' }}>
                            <FiBox size={38} className='color-whitesmoke' />
                          </div>
                        );
                      };
                      return (
                        <div key={i}>
                          <div className='display-flex flex-direction-column padding-10 cursor-default'>
                            <div className='display-flex color-black'>
                              <div className='display-flex flex-direction-row flex-wrap-nowrap'>
                                <ProductMedia />
                                <div className='display-flex justify-content-space-between align-items-center margin-left-15'>
                                  <div className='display-flex justify-content-space-between padding-right-10 padding-bottom-5'>
                                    <div>{product.name} </div>
                                    {product.price ? (
                                      <div>
                                        <span>
                                          {product.time ? product.time + ' minutes |' : ''} <sup className='font-size-smaller'>$</sup>
                                          <span className='font-size-medium-large color-black'>{price[0]}</span>
                                          <sup className='font-size-smaller'>{price[1]}</sup>
                                        </span>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className='flex-table-row'>
                <div className='flex-table-cell font-size-normal padding text-align-right'>Forms:</div>
                <div className='flex-table-cell padding'>
                  {this.state.selectedForms && this.state.selectedForms.length > 0 ? (
                    <>
                      <div>
                        <Button variant='secondary' onClick={(e) => this.openModal('FORMS_SELECT_TABLE', true)} style={{ marginBottom: '5px' }}>
                          Select Forms
                        </Button>
                        <table>
                          <tbody>
                            {this.state.selectedForms.map((form, i) => {
                              return (
                                <tr key={i}>
                                  <td style={{ verticalAlign: 'top' }}>{i + 1}. </td>
                                  <td>
                                    {form.name}
                                    <br />
                                    <span className='font-size-smallest'>
                                      Viewed/signed by customer:
                                      {form.date_confirmed ? (
                                        <>
                                          <a href={'/appointment/' + form.appointment_id + '/' + form.uuid} target='_blank' rel='noopener noreferrer'>
                                            Yes
                                          </a>
                                        </>
                                      ) : (
                                        'Not Yet'
                                      )}
                                    </span>
                                    <br />
                                    <br />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {unconfirmedForms && unconfirmedForms.length > 0 ? (
                          <>
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <input
                                      type='checkbox'
                                      name='isSendForms'
                                      onChange={async (e) => {
                                        this.setState({ isSendForms: !this.state.isSendForms });
                                      }}
                                      checked={this.state.isSendForms ? true : false}
                                      className='checkbox margin-right-15'
                                    />
                                  </td>
                                  <td>
                                    <span className='font-size-smaller'>
                                      Email/text customer a link to the attached forms on{' '}
                                      {this.state.appointment.status === 'booked' ? 'confirm' : 'save'}?
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <br />
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    </>
                  ) : (
                    <Button variant='secondary' onClick={(e) => this.openModal('FORMS_SELECT_TABLE', true)}>
                      Select Forms
                    </Button>
                  )}
                </div>
              </div>

              <div className='flex-table-row'>
                <div className='flex-table-cell font-size-normal padding text-align-right'>Notes:</div>
                <div className='flex-table-cell padding'>
                  <Form.Control
                    as='textarea'
                    rows='3'
                    type='text'
                    name='notes'
                    placeholder={this.state.selectedCustomer.id ? 'Notes' : 'Reason'}
                    onChange={(e) => {
                      this.onChangeAppointmentKeyValue(this.state.appointment.id, 'notes', e.target.value);
                    }}
                    value={this.state.appointment.notes ? this.state.appointment.notes : ''}
                    style={{ padding: '5px' }}
                  />
                </div>
              </div>
            </div>

            <Alert
              variant='warning'
              show={errors.errorUpdateAppointment ? true : false}
              onClose={() => this.props.clearErrors()}
              dismissible
              className='margin-top-15'
            >
              {errors.errorUpdateAppointment}
            </Alert>
            <div className='display-flex flex-direction-row flex-wrap-nowrap justify-content-space-between'>
              {this.state.appointment.status === 'booked' ? (
                <div className='display-flex align-items-center'>
                  <div>
                    <Button variant='secondary' onClick={(e) => this.openModal('APPOINTMENT_DELETE', true)}>
                      <MdDeleteForever size={25} className='color-tomato' />
                    </Button>
                  </div>
                  <div className='flex-2 margin-left-15'>
                    <Button
                      onClick={(e) => this.onUpdateAppointment(e)}
                      disabled={this.state.isSubmitDisabled ? true : false}
                      className='display-flex width-100'
                    >
                      Confirm Booking
                    </Button>
                  </div>
                </div>
              ) : (
                <div className='display-flex align-items-center'>
                  <div>
                    <Button variant='secondary' onClick={(e) => this.openModal('APPOINTMENT_DELETE', true)}>
                      <MdDeleteForever size={25} className='color-tomato' />
                    </Button>
                  </div>
                  <div className='flex-2 margin-left-15'>
                    <Button
                      // variant='secondary'
                      onClick={(e) => {
                        const eventDate = {
                          date: new Date(this.props.business.appointment.start),
                        };
                        this.props.props.function.handleDateClick(eventDate);
                        this.onUpdateAppointment(e);
                      }}
                      disabled={this.state.isSubmitDisabled ? true : false}
                      className='display-flex width-100'
                    >
                      Save
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <ModalDepot
          state={this.state}
          props={this.props}
          function={{
            openModal: this.openModal,
            setCustomer: this.setCustomer,
            setProducts: this.setProducts,
            setAppointment: this.setAppointment,
            setForms: this.setForms,
            setStaff: this.setStaff,
            onDeleteAppointments: this.onDeleteAppointments,
          }}
        />
      </>
    ) : (
      ''
    );
  }
}
const mapStateToProps = (state) => ({
  business: state.business,
  users: state.users,
  site: state.site,
});

export default connect(mapStateToProps, { clearErrors, getAppointment, changeAppointmentKeyValue, updateAppointment, deleteAppointments })(
  AppComponent
);
