import React, { Component } from 'react';
import { connect } from 'react-redux';

class AppComponent extends Component {
  constructor() {
    super();
    this.onLogout = this.onLogout.bind(this);
  }
  componentDidMount() {
    this.onLogout();
  }

  onLogout(e) {
    console.log('THIS PROPS', this.props);
    localStorage.clear();
    this.props.parentProps.logoutUser();
    this.props.parentProps.history.push('/login');
  }
  render() {
    return (
      <>
        <div>TEMPLATE</div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps)(AppComponent);
