import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { MdContentCopy } from 'react-icons/md';

import { getCustomers } from '../../../../redux/actions/businessActions';
import { clearErrors } from './../../../../redux/actions/siteActions';
class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'CUSTOMER_SELECT_TABLE',
      currentPage: 1,
      currentPageSize: 100000,
      selectedCustomers: [],
      totalEmailFound: 0,
      copiedEmails: [],
    };
  }
  componentDidMount() {
    this.props.getCustomers(this.props.users.token.business_id);
  }
  onBirthMonthChange = (event) => {
    const { value } = event.target;
    this.onBirthMonthFilter(value);
  };
  onBirthYearChange = (event) => {
    const { value } = event.target;
    this.onBirthYearFilter(value);
  };
  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        selectedCustomers: [...this.state.selectedCustomers, row],
        totalEmailFound: row.email ? this.state.totalEmailFound + 1 : this.state.totalEmailFound,
        copiedEmails: row.email && this.state.copiedEmails.includes(row.email) ? this.state.copiedEmails : [...this.state.copiedEmails, row.email],
      }));
    } else {
      this.setState(() => ({
        selectedCustomers: this.state.selectedCustomers.filter((customer) => customer.id !== row.id),
        totalEmailFound: row.email ? (this.state.totalEmailFound ? this.state.totalEmailFound - 1 : 0) : this.state.totalEmailFound,
        copiedEmails: this.state.copiedEmails.filter((email) => row.email !== email),
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    var totalEmailCount = 0;
    if (isSelect) {
      var availableEmails = [];
      rows.forEach((row) => {
        if (row.email) {
          totalEmailCount = totalEmailCount + 1;
          availableEmails = [...availableEmails, row.email];
        }
      });
      this.setState(() => ({
        selectedCustomers: rows,
        totalEmailFound: totalEmailCount,
        copiedEmails: availableEmails,
      }));
    } else {
      this.setState(() => ({
        selectedCustomers: [],
        totalEmailFound: 0,
        copiedEmails: [],
      }));
    }
  };
  render() {
    const { customers } = this.props.business;

    const columns = [
      {
        dataField: 'search_fields',
        text: 'Name',
        align: 'left',
        headerAlign: 'left',
        formatter: (cell, row) => {
          return (
            <>
              <div className='display-flex align-items-center white-space-nowrap'>
                <div className='text-align-left'>
                  <div className='margin-bottom-5 text-transform-capitalize padding'>
                    {row.first_name ? row.first_name : ''} {row.last_name ? row.last_name : ''}
                  </div>
                  {/* <div>{phoneFormatter.format(row.phone + '', '(NNN) NNN-NNNN')}</div> */}
                </div>
              </div>
            </>
          );
        },
      },
      // {
      //   dataField: 'month',
      //   text: 'Birth Month',
      //   align: 'left',
      //   headerAlign: 'left',
      //   filter: customFilter(),
      //   filterRenderer: (onFilter, column) => {
      //     this.onBirthMonthFilter = onFilter;
      //     return null;
      //   },
      // },
      // {
      //   dataField: 'year',
      //   text: 'Birth Year',
      //   align: 'left',
      //   headerAlign: 'left',
      //   filter: customFilter(),
      //   filterRenderer: (onFilter, column) => {
      //     this.onBirthYearFilter = onFilter;
      //     return null;
      //   },
      // },
      {
        dataField: 'email',
        text: 'Email',
        align: 'right',
        headerAlign: 'right',
        formatter: (cell, row) => {
          return <div className='padding-25'>{row.email ? 'Yes' : ''}</div>;
        },
      },
    ];
    const paginationOptions = {
      onPageChange: (page, sizePerPage) => {
        this.setState({ currentPage: page });
        this.setState({ currentPageSize: sizePerPage });
      },
      page: this.state.currentPage,
      sizePerPage: this.state.currentPageSize,
      hideSizePerPage: true,
    };
    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
      style: { backgroundColor: '#e0e0e0' },
      hideSelectColumn: false,
    };
    const NoDataIndication = (props) => {
      if (props.searchProps.searchText.length > 0) {
        return (
          <div className='padding color-black'>
            Your search - <span className='font-weight-bold'>{props.searchProps.searchText}</span> - did not match any data.
          </div>
        );
      } else {
        return (
          <div className='padding display-flex justify-content-center align-items-center'>
            <center>
              <div className='font-size-large font-weight-bold margin-bottom-5'>No Customer Found</div>
              <br />
              <div className='margin-bottom-15'>
                Tip: When customers are available, you'll be able to selectively retrieve their data by filterable points. Make sure to use the
                correct filter and/or correct terms in order to get desirable results.
              </div>
            </center>
          </div>
        );
      }
    };
    return (
      <>
        <div>
          <ToolkitProvider keyField='id' data={customers} columns={columns} search>
            {(toolkitprops) => {
              return (
                <div>
                  <div>
                    {this.state.selectedCustomers.length > 0 && this.state.totalEmailFound > 0 ? (
                      <>
                        <div className='display-flex align-items-center justify-content-center padding'>
                          <Button
                            variant='secondary'
                            onClick={async () => {
                              // alert(this.state.copiedEmails);
                              navigator.clipboard.writeText(this.state.copiedEmails);
                              alert('Emails has been copied to your clipboard. You can now paste it into your email outgoing field.');
                            }}
                            style={{ marginLeft: '10px' }}
                          >
                            <div className='display-flex align-items-center justify-content-center'>
                              <MdContentCopy size={25} color='#019fe8' />
                              Copy Email{this.state.totalEmailFound > 1 ? 's' : ''} From Results
                            </div>
                          </Button>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='display-flex flex-direction-row justify-content-space-evenly align-items-stretch'>
                    <div className='flex-1 padding'>
                      <div className='font-weight-bold margin-bottom-10'>Filter</div>
                      {/* <select
                        id='select-filter-column-month'
                        className='filter select-filter form-control placeholder-selected'
                        onChange={this.filterBirthMonth}
                      >
                        <option value='selected'>Birth Month</option>
                        {console.log('thisprops', this.props)}
                      </select> */}
                      <input
                        onChange={this.onBirthMonthChange}
                        placeholder='Birth Month'
                        className='filter select-filter form-control placeholder-selected margin-top-15'
                      />
                      <input
                        onChange={this.onBirthYearChange}
                        placeholder='Birth Year'
                        className='filter select-filter form-control placeholder-selected margin-top-15'
                      />
                    </div>
                    <div className='flex-2 padding'>
                      <div className='font-weight-bold margin-bottom-10'>Customer</div>
                      <BootstrapTable
                        keyField='id'
                        // headerClasses={'display-none'}
                        classes='customer-filter-table'
                        {...toolkitprops.baseProps}
                        data={customers}
                        columns={columns}
                        selectRow={selectRow}
                        filter={filterFactory()}
                        pagination={paginationFactory(paginationOptions)}
                        noDataIndication={<NoDataIndication data={customers} searchProps={toolkitprops.searchProps} />}
                      />
                    </div>
                  </div>
                </div>
              );
            }}
          </ToolkitProvider>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  business: state.business,
  users: state.users,
  site: state.site,
});

export default connect(mapStateToProps, { getCustomers, clearErrors })(AppComponent);
