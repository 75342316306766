import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, ButtonGroup, ToggleButton } from 'react-bootstrap';
import hideEmail from '../../../functions/hideEmail';
import { verifyBookingData, sendBookingCode, addBookingData } from './../../../redux/actions/businessActions';
import { clearErrors } from './../../../redux/actions/siteActions';
import ModalDepot from '../components/ModalDepot';
import { normalize } from 'phone-prettify';
import dateFormat from 'dateformat';
import TextField from '@material-ui/core/TextField';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'BOOKING_VERIFY_FORM',
      firstName: '',
      dialCode: '1',
      phone: '',
      customer: {},
      bookingCode: '',
      bookingNotes: props.props.state.bookingNotes,
      isAddPersonModalShow: false,
      IsCodeSentToCustomer: false,
      isPromptEmailModalShow: false,
      isVerifyByText: true, // text or email
      selectedStaff: props.props.state.selectedStaff,
      businessID: props.props.state.businessID,
      selectedBookingDate: {
        start: props.props.state.selectedBookingDate.date,
        end: new Date(
          new Date(props.props.state.selectedBookingDate.date).setHours(
            new Date(props.props.state.selectedBookingDate.date).getHours(),
            new Date(props.props.state.selectedBookingDate.date).getMinutes() + props.props.props.business.business.booking_interval,
            0
          )
        ),
      },
      IsBookingSuccess: false,
    };
    this.onChange = this.onChange.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onSendBookingCode = this.onSendBookingCode.bind(this);
    this.setStateCustomer = this.setStateCustomer.bind(this);
    this.setCodeSentToCustomer = this.setCodeSentToCustomer.bind(this);
    this.onAddBookingData = this.onAddBookingData.bind(this);
    this.onViewVerifiedPersons = this.onViewVerifiedPersons.bind(this);
  }
  async onChange(e, targetName) {
    if (targetName) {
      await this.setState({ [targetName]: e });
    } else {
      await this.setState({ [e.target.name]: e.target.value.trim() });
    }
  }
  openModal = (modalName, modalState, modalData) => {
    switch (modalName) {
      case 'PERSON_ADD':
        if (modalState) {
          this.setState({ isAddPersonModalShow: true });
        } else {
          this.setState({ isAddPersonModalShow: false });
        }
        break;
      case 'EMAIL_PROMPT':
        if (modalState) {
          this.setState({ isPromptEmailModalShow: true });
        } else {
          this.setState({ isPromptEmailModalShow: false });
          this.props.clearErrors();
        }
        break;
      case 'PERSON_SELECT_TABLE':
        modalState ? this.setState({ isPersonSelectModalShow: true }) : this.setState({ isPersonSelectModalShow: false });
        break;
      default:
    }
  };
  onViewVerifiedPersons = async (props) => {
    await this.setState({ verifiedPersons: props.persons, businessID: props.businessID });
    this.openModal('PERSON_SELECT_TABLE', true);
  };
  onVerifyBookingData = () => {
    const data = {
      firstName: this.state.firstName,
      dialCode: this.state.dialCode,
      phone: this.state.phone.slice(this.state.dialCode.length),
      isVerifyByText: this.state.isVerifyByText,
      businessID: this.state.businessID,
      bookingData: {
        startDateTime: new Date(this.state.selectedBookingDate.start).getTime(),
        endDateTime: new Date(this.state.selectedBookingDate.end).getTime(),
        selectedProducts: this.props.props.state.selectedProductIDsArray,
        businessID: this.state.businessID,
      },
    };
    this.props.verifyBookingData(data, this);
  };
  onSendBookingCode = (customer) => {
    const bookingData = {
      start: this.state.selectedBookingDate.start,
      end: this.state.selectedBookingDate.end,
      staffID: this.state.selectedStaff.id,
    };
    const data = {
      customer,
      isVerifyByText: this.state.isVerifyByText,
      bookingData,
    };
    this.props.sendBookingCode(data, this);
  };
  setCodeSentToCustomer = (status, customer) => {
    this.setState({ IsCodeSentToCustomer: status, customer });
  };
  setStateCustomer = (customer) => {
    this.setState({ customer });
  };
  onAddBookingData = () => {
    var startDateTime = new Date(this.state.selectedBookingDate.start).setHours(
      new Date(this.state.selectedBookingDate.start).getHours(),
      new Date(this.state.selectedBookingDate.start).getMinutes(),
      0
    );
    var endDateTime = new Date(this.state.selectedBookingDate.end).setHours(
      new Date(this.state.selectedBookingDate.end).getHours(),
      new Date(this.state.selectedBookingDate.end).getMinutes(),
      0
    );
    const startDateTimeUTC = dateFormat(startDateTime, "yyyy-mm-dd'T'HH:MM:ss");
    const endDateTimeUTC = dateFormat(endDateTime, "yyyy-mm-dd'T'HH:MM:ss");
    const data = {
      bookingData: {
        businessID: this.state.businessID,
        customer: this.state.customer,
        productsArray: this.props.props.state.selectedProducts,
        staffID: this.state.selectedStaff.id,
        start: startDateTimeUTC,
        end: endDateTimeUTC,
        notes: this.state.bookingNotes,
        color: '#ff4550',
        status: 'booked',
        bookingCode: this.state.bookingCode,
      },
    };
    this.props.addBookingData(data, this);
  };
  setBookingSuccess = () => {
    this.setState({ IsBookingSuccess: true });
  };
  render() {
    const { errors } = this.props.site;
    return this.state.IsBookingSuccess ? (
      <>
        <center>
          <div className='font-size-larger padding-5'>Thank you for booking with us!</div>
        </center>
        <div className='hr-text'>Powered by One Space Link</div>
        <div className='margin-bottom-15 font-size-medium padding'>
          Please note that this is not a booking confirmation.
          <br />
          <br />
          Please do not go to our location until you receive your booking confirmation via text or email.
        </div>
        <Button
          onClick={() => {
            window.location.reload();
          }}
          className='width-100'
        >
          Done
        </Button>
      </>
    ) : this.state.IsCodeSentToCustomer ? (
      <>
        <center>
          <div className='margin-bottom-15 font-size-medium'>
            Enter the code we've sent you at:
            <br />
            <b>{!this.state.isVerifyByText ? hideEmail(this.state.customer.email) : normalize(this.state.customer.phone)}</b>
          </div>
        </center>
        <div className='margin-top-bottom-15'>
          {/* <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip>
                1. It may take a few minutes for the code to arrive.
                <br />
                <br />
                2. Please make sure your email or phone number is correct. Your email is partially hidden for security purposes.
                <br />
                <br />
                If any information is incorrect, please text us at (713) 742-2971 or email us at support@onespace.link.
              </Tooltip>
            }
          >
            <span className='color-blue'>(Didn't receive a code?)</span>
          </OverlayTrigger> */}
        </div>
        <TextField
          label='Verication code'
          type='number'
          name='bookingCode'
          margin='normal'
          variant='outlined'
          value={this.state.bookingCode}
          onChange={(e) => this.setState({ bookingCode: e.target.value.trim() })}
          inputProps={{ style: { fontSize: 17 } }} // font size of input text
          InputLabelProps={{ style: { fontSize: 17 } }} // font size of input label
          className='display-flex'
        />
        <div>
          <Alert variant='warning' show={errors.message ? true : false} onClose={() => this.props.clearErrors()} dismissible>
            {errors.message}
          </Alert>
        </div>
        <div>
          <Button onClick={(e) => this.onAddBookingData(e)} className='width-100'>
            Complete Booking Request
          </Button>
        </div>
      </>
    ) : (
      <>
        <div>
          <div className='padding-10 display-flex align-items-center justify-content-center'>
            <div>
              <center>
                <div className='margin-bottom-10 font-size-medium'>Booking Overview</div>
              </center>
              <div className='flex-table'>
                <div className='flex-table-row-even-spacing'>
                  <div className='flex-table-cell padding text-align-right white-space-nowrap'>Date &amp; Time:</div>
                  <div className='flex-table-cell padding'>
                    <span className='font-size-small'>
                      {dateFormat(this.state.selectedBookingDate.start, 'dddd, mmmm d')}
                      <br />
                      {dateFormat(this.state.selectedBookingDate.start, 'H:MMtt')}
                    </span>
                  </div>
                </div>
                <div className='flex-table-row-even-spacing'>
                  <div className='flex-table-cell padding text-align-right'>Location:</div>
                  <div className='flex-table-cell padding'>
                    <span className='font-size-small'>{this.props.props.props.business.business.business_name}</span>
                    <br />
                    <span className='font-size-small'>{this.props.props.props.business.business.address}</span>
                    <br />
                    <span className='font-size-small'>
                      {this.props.props.props.business.business.city}, {this.props.props.props.business.business.state}{' '}
                      {this.props.props.props.business.business.zip}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='hr-text font-size-medium font-weight-bold'>Verify Your Booking</div>
        </div>
        {/* <TextField
          label='Enter Your First Name'
          type='text'
          name='firstName'
          margin='normal'
          variant='outlined'
          value={this.state.firstName}
          onChange={this.onChange}
          inputProps={{ style: { fontSize: 15 } }} // font size of input text
          InputLabelProps={{ style: { fontSize: 15 } }} // font size of input label
          className='display-flex'
        /> */}
        <div className='display-flex justify-content-center margin-bottom-15'>Enter your phone number</div>
        <div className='margin-bottom-10'>
          <PhoneInput
            country={'us'}
            value={this.state.phone}
            onChange={(value, data, event, formattedValue) => {
              this.setState({ phone: value, dialCode: data.dialCode });
            }}
            countryCodeEditable={false}
            disableDropdown={true}
          />
        </div>
        <center>
          <div className='margin-top-10'>How would you like to receive your verification?</div>
        </center>
        <div className='display-flex align-items-center justify-content-center width-auto padding'>
          <ButtonGroup toggle>
            <ToggleButton
              variant='secondary'
              key={1}
              type='radio'
              name='radio'
              value='customer'
              checked={!this.state.isVerifyByText ? false : true}
              onChange={async (e) => {
                await this.setState({ isVerifyByText: true });
              }}
              onClick={async () => {
                await this.setState({ isVerifyByText: true });
              }}
            >
              <div className='display-flex align-items-center justify-content-center'>
                <div className='margin-left-5'>Via Text (SMS)</div>
              </div>
            </ToggleButton>
            <ToggleButton
              variant='secondary'
              key={2}
              type='radio'
              name='radio'
              value='reserve'
              checked={!this.state.isVerifyByText}
              onChange={async (e) => {
                await this.setState({ isVerifyByText: false });
              }}
            >
              <div className='display-flex align-items-center justify-content-center'>
                <div className='margin-left-5'>Via Email</div>
              </div>
            </ToggleButton>
          </ButtonGroup>
        </div>
        {/* <TextField
          label='Mobile Number'
          type='number'
          name='phone'
          margin='normal'
          variant='outlined'
          value={this.state.phone}
          onChange={(e) => this.setState({ phone: e.target.value.trim() })}
          inputProps={{ style: { fontSize: 15 } }} // font size of input text
          InputLabelProps={{ style: { fontSize: 15 } }} // font size of input label
          className='display-flex'
        /> */}
        <div>
          <Alert variant='warning' show={errors.bookingVerifyMessage ? true : false} onClose={() => this.props.clearErrors()} dismissible>
            {errors.bookingVerifyMessage}
          </Alert>
        </div>
        <div className='display-flex justify-content-flex-end'>
          <Button onClick={(e) => this.onVerifyBookingData()} className='width-100'>
            Next
          </Button>
        </div>
        <ModalDepot
          state={this.state}
          props={this.props}
          function={{
            openModal: this.openModal,
            setStateCustomer: this.setStateCustomer,
            onSendBookingCode: this.onSendBookingCode,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  site: state.site,
});
export default connect(mapStateToProps, { verifyBookingData, sendBookingCode, addBookingData, clearErrors })(AppComponent);
