import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MdAssignmentReturned, MdAssignmentTurnedIn } from 'react-icons/md';
import { Button } from 'reactstrap';
import ButtonGroup from 'reactstrap/lib/ButtonGroup';

class AppComponent extends Component {
  render() {
    return (
      <>
        <div className='display-flex'>
          <div className='margin-bottom-15 font-size-medium'>You'd be out of the queue.</div>
          <div className='margin-bottom-15'>
            To get back in line, you'd have to check in again at {this.props.props.props.business.checkin.business_name}.
          </div>
          <div className='display-flex justify-content-space-between'>
            <ButtonGroup className='display-flex width-100'>
              <Button
                variant='secondary'
                onClick={(e) => this.props.props.function.openModal('LEAVE_WAITLIST_FORM', false)}
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
              >
                <MdAssignmentTurnedIn size={25} className='color-deepskyblue' /> Stay
              </Button>
              <Button
                variant='secondary'
                onClick={(e) => this.props.props.function.onLeaveWaitlist()}
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
              >
                <MdAssignmentReturned size={25} className='color-tomato' />
                Leave
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps)(AppComponent);
