import React, { Component } from 'react';
import { connect } from 'react-redux';

class AppComponent extends Component {
  componentDidMount = async () => {
    await localStorage.removeItem('localStorageCheckinAppMobileCheckinSuccess'); // reset successful checkin
    await localStorage.removeItem('localStorageCheckinAppCheckinID'); // remove successful checkin data
    await localStorage.setItem('localStorageCheckinPublicToken', this.props.match.params.public_token); // set the public token
    await localStorage.setItem('localStorageCheckinTimeStamp', new Date()); // set the time when the check-in starts
    window.location.href = '/checkinapp/mobile'; // redirect to mobile check in page
  };
  render() {
    return (
      <>
        <div></div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps)(AppComponent);
