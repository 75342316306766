import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Form } from 'react-bootstrap';
import { uploadForm, addForm } from './../../../redux/actions/businessActions';
import { clearErrors } from '../../../redux/actions/siteActions';
import ModalDepot from '../components/ModalDepot';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import SignatureCanvas from 'react-signature-canvas';
import { withSize } from 'react-sizeme';
import { MdAddCircle } from 'react-icons/md';

class AppComponent extends Component {
  signPad = {};
  constructor(props) {
    super();
    this.state = {
      selectedFile: null,
      isUploadPassFormModalShow: false,
      formTitle: '',
      formContent: '',
      appointmentAttach: '',
      checkInAttach: '',
      signatureRequired: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal(modalName, modalState, modalData) {
    switch (modalName) {
      case 'FORM_UPLOAD':
        modalState ? this.setState({ isUploadPassFormModalShow: true }) : this.setState({ isUploadPassFormModalShow: false });
        break;
      default:
    }
  }
  clearUploadField() {
    this.uploadInput.value = '';
  }
  onChangeChooseFile = (e) => {
    this.setState({ selectedFile: e.target.files[0] });
  };
  // onUploadForm(e) {
  //   const data = new FormData();
  //   data.append('businessID', this.props.users.token.business_id);
  //   data.append('file', this.state.selectedFile);
  //   this.props.uploadForm(data, this);
  // }
  onAddForm = (e) => {
    e.preventDefault();
    const data = {
      formTitle: this.state.formTitle,
      formContent: this.state.formContent,
      signatureRequired: this.state.signatureRequired,
      businessID: this.props.users.token.business_id,
    };
    this.props.addForm(data, this);
  };
  modules = {
    toolbar: [
      // [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ color: ['#4285F4', '#EA4335', '#FBBC05', '#34A853', '#000'] }],
      ['link'], //  ['link', 'image'],
      ['clean'],
    ],
  };
  render() {
    const { errors } = this.props.site;
    return (
      <>
        <div ref={this.refCallback}>
          <Form.Control
            type='text'
            name='formTitle'
            placeholder='Form Title'
            value={this.state.formTitle}
            onChange={(e) => {
              this.setState({ formTitle: e.target.value });
            }}
            className='margin-bottom-15 width-100'
          />
          <ReactQuill
            theme='snow'
            name='formContent'
            modules={this.modules}
            value={this.state.formContent}
            onChange={(e) => {
              this.setState({ formContent: e });
            }}
            placeholder='Enter your consent/waiver clause here'
            className='margin-bottom-15'
          />
          <div className='display-flex flex-wrap-nowrap align-items-center margin-bottom-15'>
            <input
              type='checkbox'
              name='signatureRequired'
              onChange={async (e) => {
                e.target.value === 'true' ? this.setState({ signatureRequired: 0 }) : this.setState({ signatureRequired: 1 });
              }}
              value={this.state.signatureRequired ? true : false}
              checked={this.state.signatureRequired ? true : false}
              className='largerCheckbox margin-right-15'
            />
            Require Customer's Signature
          </div>
          {/* <div className={this.state.signatureRequired ? 'display-block' : 'display-none'}>
            <div style={{ width: this.props.size.width, height: '200px', border: 'solid 1px #ccc', marginBottom: '10px' }}>
              <SignatureCanvas
                canvasProps={{ width: this.props.size.width, height: '200px' }}
                ref={(ref) => {
                  this.signPad = ref;
                }}
              />
            </div>
            <Button variant='secondary' onClick={() => this.signPad.clear()} className='margin-top-15'>
              Clear Signature
            </Button>
          </div> */}
          <div>Note: Consent/waiver forms cannot be updated or edited once it is created.</div>
          <Alert
            variant='warning'
            show={errors.errorAddForm ? true : false}
            onClose={() => this.props.clearErrors()}
            dismissible
            style={{ marginTop: '10px' }}
          >
            {errors.errorAddForm}
          </Alert>
          <div className='display-flex justify-content-space-between margin-top-15'>
            <Button variant='secondary' onClick={(e) => this.props.props.function.openModal('FORM_ADD', false)}>
              Cancel
            </Button>{' '}
            <Button variant='secondary' onClick={(e) => this.onAddForm(e)}>
              <MdAddCircle size={25} className='color-deepskyblue' />
              Create
            </Button>
          </div>
          {/* {' '}
 
        <div className='display-flex flex-direction-row border-style-dotted border-radius-5 border-light-gray'>
          <div className='flex-1 padding text-align-right'>
            <input
              type='file'
              name='file'
              accept='.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf'
              ref={(ref) => (this.uploadInput = ref)}
              onChange={(e) => this.onChangeChooseFile(e)}
            />
          </div>
          <div className='flex-1 align-items-center justify-content-center display-flex margin-left-15'>
            <Button type='submit' onClick={(e) => this.onUploadForm(e)}>
              Upload
            </Button>
          </div>
        </div> */}
        </div>
        <ModalDepot state={this.state} function={{ openModal: this.openModal }} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  business: state.business,
  users: state.users,
  site: state.site,
});
export default connect(mapStateToProps, { clearErrors, addForm, uploadForm })(withSize()(AppComponent));
