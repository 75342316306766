import React, { Component } from 'react';
import { connect } from 'react-redux'; // connecting redux to component

class AppComponent extends Component {
  render() {
    return (
      <div className='display-flex'>
        <div className='font-size-medium margin-bottom-15'>
          <div className='padding-bottom-10 font-weight-bold' style={{ fontFamily: 'CeraBasic' }}>
            Terms of Service Agreement
          </div>
          <div>
            Our service agreement will vary depending on the plan you choose. Certain plans offer a 14-days trial period limited to new customers. You
            may cancel anytime before the trial period is up to avoid monthly charges. You can change other services, features, and equipment without
            affecting your agreement. The full text of the service agreement is on onespacelink.com. Please read it carefully. Contact us by text ‪via
            (713) 742-2971 if you have any questions or to make any changes to your account.
          </div>
        </div>
        <div className='font-size-medium margin-bottom-15 margin-top-15'>
          <div className='padding-bottom-10 font-weight-bold' style={{ fontFamily: 'CeraBasic' }}>
            Check-In (Ad-Supported) Plan @ $9.99/mo
          </div>
          <div>
            Our promotional plan to support local businesses and is ad-supported. All check-in plans come with an optional provision of check-in
            device if you agree to a 1 year term agreement. Let us know in advance if you want this option. You may cancel within 30 days of start of
            1-year agreement by texting us to avoid early termination fees. Device returns and inspection is required for full cancellation.
            Otherwise, you may use your own device, subscribe normally, and cancel anytime.
          </div>
        </div>
        <div className='font-size-medium margin-bottom-15 margin-top-15'>
          <div className='padding-bottom-10 font-weight-bold' style={{ fontFamily: 'CeraBasic' }}>
            Check-In (Without Ads) Plan @ $19.99/mo
          </div>
          <div>
            Comes with an optional provision of check-in tablet device if you agree to a 1-year term agreement. Similar terms and conditions to the
            Check-In (Ad-Supported) Plan. This plan is without ads and customer check-in sms notification is included. However, online booking,
            reservation scheduling, and birthday marketing are not included.
          </div>
        </div>
        <div className='font-size-medium margin-bottom-15 margin-top-15'>
          <div className='padding-bottom-10 font-weight-bold' style={{ fontFamily: 'CeraBasic' }}>
            Check-In, Online Booking, Scheduling Plan @ $27.99/mo
          </div>
          <div>
            Let your business check in and schedule customer appointments as well as allow your customer to book online. Check-ins customers without
            ads. Sms notification is included for online booking and scheduling. Enjoy scheduling for additional staff members with no additional fee.
            Terms and conditions apply.
          </div>
        </div>
        <div className='font-size-medium margin-bottom-15 margin-top-15'>
          <div className='padding-bottom-10 font-weight-bold' style={{ fontFamily: 'CeraBasic' }}>
            Everything + Marketing @ $99.99/mo
          </div>
          <div>
            Includes all services and features from plans mentioned above. In addition, marketing services become available under this plan. General
            or personalize promotional offer can be sent to customers based on settings.
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps, {})(AppComponent);
