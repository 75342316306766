import React, { Component } from 'react';
import { Form, Alert, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

import { addPerson, getBusiness } from '../../../redux/actions/businessActions';
import { clearErrors } from '../../../redux/actions/siteActions';
import ModalDepot from '../components/ModalDepot';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'PERSON_ADD_FORM',
      businessID:
        Object.keys(props.business.business).length !== 0 && props.business.business.constructor === Object
          ? props.business.business.id
          : props.users.token.business_id,
      firstName: props.props.state.firstName ? props.props.state.firstName : '',
      lastName: '',
      dialCode: props.props.state.dialCode ? props.props.state.dialCode : '1',
      phone: props.props.state.phone ? props.props.state.phone : '',
      email: '',
      birthDay: '',
      birthMonth: '',
      birthYear: '',
      isEmailRequired: false,
      isSubmitInProgress: false,
      isCheckinAppGreetingModalShow: false,
      isPersonAddSuccessModalShow: false,
      isTermsModalShow: false,
      isPolicyModalShow: false,
      checkInState: false,
      checkInMessage: '',
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.openModal = this.openModal.bind(this);
  }
  async onChange(e) {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value.trim() });
  }
  componentDidMount() {
    if (this.props.props.state.componentID === 'PAGE_BOOKING_COMPONENT') {
      this.setState({ isEmailRequired: true });
    }
    this.props.getBusiness(this.state.businessID ? this.state.businessID : this.props.users.token.business_id, this);
  }
  onSubmit(e) {
    e.preventDefault();
    const data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      dialCode: this.state.dialCode,
      phone: this.state.phone.slice(this.state.dialCode.length),
      email: this.state.email,
      isEmailRequired: this.state.isEmailRequired,
      birthMonth: this.state.birthMonth,
      birthDay: this.state.birthDay,
      birthYear: this.state.birthYear,
      businessID: this.state.businessID ? this.state.businessID : this.props.users.token.business_id,
    };
    this.props.addPerson(data, this);
  }
  openModal(modalName, modalState, modalData) {
    switch (modalName) {
      case 'CHECKINAPP_GREETING':
        modalState ? this.setState({ isCheckinAppGreetingModalShow: true }) : this.setState({ isCheckinAppGreetingModalShow: false });
        break;
      case 'PERSON_ADD_SUCCESS':
        modalState ? this.setState({ isPersonAddSuccessModalShow: true }) : this.setState({ isPersonAddSuccessModalShow: false });
        break;
      case 'TERMS_COMPONENT':
        modalState ? this.setState({ isTermsModalShow: true }) : this.setState({ isTermsModalShow: false });
        break;
      case 'POLICY_COMPONENT':
        modalState ? this.setState({ isPolicyModalShow: true }) : this.setState({ isPolicyModalShow: false });
        break;
      default:
    }
  }
  render() {
    const { errors } = this.props.site;
    const Birthday = () => {
      var daysArray = [];
      daysArray.push(
        <option key={0} defaultValue>
          Day
        </option>
      );
      for (let i = 1; i <= 31; i++) {
        daysArray.push(
          <option key={i} value={i}>
            {i}
          </option>
        );
      }
      return daysArray;
    };
    const Birthyear = () => {
      var yearsArray = [];
      const currentYear = new Date().getFullYear();
      const limitYear = currentYear - 115;
      yearsArray.push(
        <option key={0} defaultValue>
          Year
        </option>
      );
      for (let i = currentYear; i >= limitYear; i--) {
        yearsArray.push(
          <option key={i} value={i}>
            {i}
          </option>
        );
      }
      return yearsArray;
    };
    return (
      <>
        <div className='display-flex width-100 align-items-center justify-content-center'>
          {this.props.props.state.componentID === 'PAGE_BOOKING_COMPONENT' ? (
            <div className='margin-bottom-15'>
              Once you're registered, you can simply use your first name and phone number for future verification.
            </div>
          ) : this.props.props.state.componentID === 'PERSON_VERIFY_FORM' ? (
            <div className='font-size-large'>New Customer</div>
          ) : (
            ''
          )}
          <Form noValidate onSubmit={(e) => this.onSubmit(e)}>
            <div className='display-flex flex-direction-column'>
              <div className='display-flex flex-direction-row flex-wrap-nowrap'>
                <div className='flex-1 margin-right-5'>
                  {/* <div className='margin-bottom-5'>First Name*</div> */}
                  {/* <Form.Control type='text' name='firstName' value={this.state.firstName} onChange={this.onChange} /> */}
                  <TextField
                    label='First Name*'
                    type='text'
                    name='firstName'
                    margin='normal'
                    variant='outlined'
                    focused={this.state.firstName ? false : true}
                    value={this.state.firstName}
                    onChange={this.onChange}
                    inputProps={{ style: { fontSize: 17 } }} // font size of input text
                    InputLabelProps={{ style: { fontSize: 17 } }} // font size of input label
                  />
                </div>
                <div className='flex-1 margin-left-5'>
                  <TextField
                    label='Last Name*'
                    type='text'
                    name='lastName'
                    margin='normal'
                    variant='outlined'
                    focused={this.state.lastName ? false : true}
                    value={this.state.lastName}
                    onChange={this.onChange}
                    inputProps={{ style: { fontSize: 17 } }} // font size of input text
                    InputLabelProps={{ style: { fontSize: 17 }, shrink: true }} // font size of input label
                  />
                </div>
              </div>
              <div className='display-flex'>
                <PhoneInput
                  country={'us'}
                  value={this.state.phone}
                  onChange={(value, data, event, formattedValue) => {
                    this.setState({ phone: value, dialCode: data.dialCode });
                  }}
                  countryCodeEditable={false}
                  disableDropdown={true}
                />
                {/* <TextField
                  label='Mobile Number*'
                  type='number'
                  name='phone'
                  margin='normal'
                  variant='outlined'
                  value={this.state.phone}
                  onChange={this.onChange}
                  className='display-flex'
                  inputProps={{ style: { fontSize: 17 } }} // font size of input text
                  InputLabelProps={{ style: { fontSize: 17 } }} // font size of input label
                /> */}
              </div>
              <div className='display-flex'>
                <TextField
                  label='Email'
                  type='text'
                  name='email'
                  margin='normal'
                  variant='outlined'
                  // focused={this.state.email ? false : true}
                  value={this.state.email}
                  onChange={this.onChange}
                  className='display-flex'
                  inputProps={{ style: { fontSize: 17 } }} // font size of input text
                  InputLabelProps={{ style: { fontSize: 17 }, shrink: true }} // font size of input label
                />
              </div>
              <div className='display-flex margin-bottom-5 font-size-large text-align-center justify-content-center'>Birthday</div>
              <div className='display-flex flex-direction-row  margin-bottom-15 margin-top-15'>
                <div className='display-flex flex-1'>
                  <TextField
                    select
                    label='Month'
                    name='birthMonth'
                    // focused={this.state.birthMonth ? false : true}
                    value={this.state.birthMonth}
                    onChange={(e) => this.onChange(e)}
                    SelectProps={{
                      native: true,
                    }}
                    variant='outlined'
                    inputProps={{ style: { fontSize: 17 }, width: '30%' }} // font size of input text
                    InputLabelProps={{ style: { fontSize: 17 } }} // font size of input label
                    className='display-flex'
                  >
                    <option defaultValue>Month</option>
                    <option value='01'>Jan</option>
                    <option value='02'>Feb</option>
                    <option value='03'>Mar</option>
                    <option value='04'>Apr</option>
                    <option value='05'>May</option>
                    <option value='06'>Jun</option>
                    <option value='07'>Jul</option>
                    <option value='08'>Aug</option>
                    <option value='09'>Sep</option>
                    <option value='10'>Oct</option>
                    <option value='11'>Nov</option>
                    <option value='12'>Dec</option>
                  </TextField>
                </div>
                <div className='display-flex flex-1 margin-left-5 margin-right-5'>
                  <TextField
                    select
                    label='Day'
                    name='birthDay'
                    value={this.state.birthDay}
                    // focused={this.state.birthDay ? false : true}
                    onChange={(e) => this.onChange(e)}
                    SelectProps={{
                      native: true,
                    }}
                    variant='outlined'
                    inputProps={{ style: { fontSize: 17 } }} // font size of input text
                    InputLabelProps={{ style: { fontSize: 17 } }} // font size of input label
                    className='display-flex'
                  >
                    <Birthday />
                  </TextField>
                </div>
                <div className='display-flex flex-1'>
                  <TextField
                    select
                    label='Year'
                    name='birthYear'
                    value={this.state.birthYear}
                    // focused={this.state.birthYear ? false : true}
                    onChange={(e) => this.onChange(e)}
                    SelectProps={{
                      native: true,
                    }}
                    variant='outlined'
                    inputProps={{ style: { fontSize: 17 } }} // font size of input text
                    InputLabelProps={{ style: { fontSize: 17 } }} // font size of input label
                    className='display-flex'
                  >
                    <Birthyear />
                  </TextField>
                </div>
              </div>
              {/* <div className='margin-bottom-5'>Email{this.props.props.state.componentID === 'PAGE_BOOKING_COMPONENT' ? '*' : ''}</div> */}
              {/* <Form.Control type='text' name='email' onChange={this.onChange} value={this.state.email} className='margin-bottom-15' /> */}
              <div className='display-flex'>
                <Alert
                  variant='warning'
                  show={errors.errorNewCustomer ? true : false}
                  onClose={() => this.props.clearErrors()}
                  dismissible
                  className='flex-grow-1'
                  style={{ fontSize: '15px' }}
                >
                  {errors.errorNewCustomer}
                </Alert>
              </div>
              <Button
                type='submit'
                disabled={this.state.isSubmitInProgress ? true : false}
                className='width-100'
                style={{ padding: '20px', fontSize: '1.5em' }}
              >
                Register
              </Button>
              <div className='display-flex justify-content-center margin-top-15'>
                By registering, you agree to our{' '}
                <a
                  href='#!'
                  onClick={(e) => {
                    e.preventDefault();
                    this.openModal('TERMS_COMPONENT', true);
                  }}
                  className='margin-left-5 margin-right-5'
                >
                  Terms of Services
                </a>{' '}
                and{' '}
                <span className='whitespace-nowrap'>
                  <a
                    href='#!'
                    onClick={(e) => {
                      e.preventDefault();
                      this.openModal('POLICY_COMPONENT', true);
                    }}
                    className='margin-left-5 margin-right-5'
                  >
                    Privacy Policy
                  </a>
                </span>
              </div>
            </div>
          </Form>
        </div>
        <ModalDepot
          state={this.state}
          props={this.props}
          function={{
            openModal: this.openModal,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  business: state.business,
  users: state.users,
  site: state.site,
});

export default connect(mapStateToProps, { addPerson, getBusiness, clearErrors })(AppComponent);
