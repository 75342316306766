import React, { Component } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import phoneFormatter from 'phone-formatter';
import queryString from 'query-string';
import { MdDelete, MdPeople, MdPhoneAndroid } from 'react-icons/md';
import { getCustomers, deleteCustomers } from './../../../redux/actions/businessActions';
import { getStripeSubscriptionProducts } from './../../../redux/actions/subscriptionActions';
import ModalDepot from './../components/ModalDepot';
import { FiPlus } from 'react-icons/fi';

class ComponentTags extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'CUSTOMERS',
      actionParam: props.parentProps ? queryString.parse(props.parentProps.history.location.search).action : '',
      currentPage: 1,
      currentPageSize: 1000,
      isVerifyPersonModalShow: false,
      isViewSubscriptionPlanModalShow: false,
      isViewCustomerProfileModalShow: false,
      selectedCustomer: {},
      selected: [],
    };
    this.openModal = this.openModal.bind(this);
    this.onPlanCheckModal = this.onPlanCheckModal.bind(this);
  }
  componentDidMount() {
    this.props.getStripeSubscriptionProducts();
    this.props.getCustomers(this.props.users.token.business_id);
    if (this.state.actionParam === 'update-wish') {
      this.openModal('BIRTHDAY_WISH_SET', true);
    } else if (this.state.actionParam === 'add-customer') {
      this.openModal('CUSTOMER_ADD', true);
    }
  }
  openModal = (modalName, modalState, modalData) => {
    switch (modalName) {
      case 'PERSON_VERIFY':
        modalState ? this.setState({ isVerifyPersonModalShow: true }) : this.setState({ isVerifyPersonModalShow: false });
        break;
      case 'CUSTOMER_VIEW_PROFILE':
        if (modalState) {
          this.setState({ isViewCustomerProfileModalShow: true });
        } else {
          this.setState({ isViewCustomerProfileModalShow: false });
        }
        break;
      case 'SUBSCRIPTION_PLANS':
        if (modalState) {
          this.setState({ isViewSubscriptionPlanModalShow: true });
        } else {
          this.setState({ isViewSubscriptionPlanModalShow: false });
        }
        break;
      default:
    }
  };
  onPlanCheckModal = (modalName, plan) => {
    this.openModal(modalName, true);
  };
  render() {
    const { customers } = this.props.business;
    const { SearchBar } = Search;
    var letterHeader = '';
    const columns = [
      {
        dataField: 'search_fields',
        text: 'Name',
        align: 'left',
        headerAlign: 'left',
        headerClasses: 'display-none',
        formatter: (cell, row) => {
          let letterHeaderValue = '';
          if (letterHeader !== row.first_name.toLowerCase().charAt(0)) {
            letterHeader = row.first_name.toLowerCase().charAt(0);
            letterHeaderValue = row.first_name.toUpperCase().charAt(0);
          }
          return (
            <>
              {letterHeaderValue ? (
                <div className='display-flex align-items-center justify-content-space-between padding font-size-small border-bottom-gray bg-whitesmoke'>
                  <div className='font-size-medium'>{letterHeaderValue}</div>
                </div>
              ) : (
                ''
              )}
              <div className='display-flex flex-direction-column padding'>
                <div className='text-align-left'>
                  <div className='margin-bottom-5 text-transform-capitalize'>
                    {row.first_name} {row.last_name}
                  </div>
                </div>
                <div>
                  <MdPhoneAndroid size='25' /> {phoneFormatter.format(row.phone + '', '(NNN) NNN-NNNN')}
                </div>
              </div>
            </>
          );
        },
      },
    ];
    const paginationOptions = {
      onPageChange: (page, sizePerPage) => {
        this.setState({ currentPage: page });
        this.setState({ currentPageSize: sizePerPage });
      },
      page: this.state.currentPage,
      sizePerPage: this.state.currentPageSize,
      hideSizePerPage: true,
    };
    const selectRowProp = {
      mode: 'checkbox',
      selected: this.state.selected,
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          this.setState(() => ({
            selected: [...this.state.selected, row.id],
          }));
        } else {
          this.setState(() => ({
            selected: this.state.selected.filter((x) => x !== row.id),
          }));
        }
      },
      onSelectAll: (isSelect, rows, e) => {
        const ids = rows.map((r) => r.id);
        if (isSelect) {
          this.setState({ selected: ids });
        } else {
          this.setState({ selected: [] });
        }
      },
      hideSelectColumn: true,
    };
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        e.preventDefault();
        this.setState({ selectedCustomer: row });
        this.openModal('CUSTOMER_VIEW_PROFILE', true);
      },
    };
    const NoDataIndication = (props) => {
      if (props.searchProps.searchText.length > 0) {
        return (
          <div className='padding color-black'>
            Your search - <span className='font-weight-bold'>{props.searchProps.searchText}</span> - did not match any data.
          </div>
        );
      } else {
        return (
          <div className='padding display-flex justify-content-center align-items-center'>
            <center>
              <MdPeople size='200px' />
              <div className='font-size-large font-weight-bold margin-bottom-5'>No Customers Yet</div>
              <br />
              Customers can be added manually or when they check in.
            </center>
          </div>
        );
      }
    };
    const DataTable = () => {
      return (
        <div>
          <ToolkitProvider keyField='id' data={customers} columns={columns} search>
            {(toolkitprops) => {
              return (
                <div>
                  <div className='display-flex flex-direction-column justify-content-center align-items-center padding'>
                    <div className='display-flex flex-direction-row flex-wrap-nowrap align-items-center justify-content-space-between'>
                      <div className='width-100'>
                        <SearchBar placeholder='Search' tableId='Search' {...toolkitprops.searchProps} />
                      </div>
                      <div className='margin-left-15 white-space-nowrap'>
                        <Button onClick={() => this.onPlanCheckModal('PERSON_VERIFY')} style={{ padding: '5px' }}>
                          <FiPlus size='25px' className='color-white' /> Add Customer
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={this.state.selected.length ? 'padding color-white' : 'display-none'}>
                      <OverlayTrigger placement='top' overlay={<Tooltip>Delete</Tooltip>}>
                        <button className='border-radius-5 padding-5 margin-3' onClick={(e) => this.onPlanCheckModal('CUSTOMER_DELETE')}>
                          <MdDelete size='25px' />
                        </button>
                      </OverlayTrigger>
                    </div>
                    <BootstrapTable
                      keyField='id'
                      classes='customer-select-table'
                      headerClasses={customers.length > 0 ? '' : 'display-none'}
                      {...toolkitprops.baseProps}
                      pagination={paginationFactory(paginationOptions)}
                      noDataIndication={<NoDataIndication data={customers} searchProps={toolkitprops.searchProps} />}
                      selectRow={selectRowProp}
                      rowEvents={rowEvents}
                    />
                  </div>
                </div>
              );
            }}
          </ToolkitProvider>
        </div>
      );
    };
    return (
      <>
        <DataTable />
        <ModalDepot state={this.state} prop={this.props} function={{ openModal: this.openModal }} />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  subscriptions: state.subscriptions,
  business: state.business,
  users: state.users,
  site: state.site,
});

export default connect(mapStateToProps, { getCustomers, deleteCustomers, getStripeSubscriptionProducts })(ComponentTags);
