import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import phoneFormatter from 'phone-formatter';
import { MdPersonAdd } from 'react-icons/md';
import { FaUserCircle } from 'react-icons/fa';

import { getCustomers } from '../../../../redux/actions/businessActions';
import ModalDepot from './../../components/ModalDepot';
import { clearErrors } from './../../../../redux/actions/siteActions';
class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'CUSTOMER_SELECT_TABLE',
      currentPage: 1,
      currentPageSize: 5000,
      isAddCustomerModalShow: false,
      isVerifyPersonModalShow: false,
      isSubmitDisabled: true,
      nameLetterArray: [],
    };
    this.openModal = this.openModal.bind(this);
  }
  componentDidMount() {
    this.props.getCustomers(this.props.users.token.business_id);
  }
  openModal(modalName, modalState, modalData) {
    switch (modalName) {
      case 'CUSTOMER_ADD':
        if (modalState) {
          this.setState({ isAddCustomerModalShow: true });
        } else {
          this.setState({ isAddCustomerModalShow: false });
          this.props.clearErrors();
        }
        break;
      case 'PERSON_VERIFY':
        modalState ? this.setState({ isVerifyPersonModalShow: true }) : this.setState({ isVerifyPersonModalShow: false });
        break;
      default:
    }
  }
  render() {
    const { customers } = this.props.business;
    const { SearchBar } = Search;
    var letterHeader = '';
    const columns = [
      {
        dataField: 'search_fields',
        text: '',
        align: 'left',
        headerAlign: 'left',
        formatter: (cell, row) => {
          let letterHeaderValue = '';
          if (letterHeader !== row.first_name.toLowerCase().charAt(0)) {
            letterHeader = row.first_name.toLowerCase().charAt(0);
            letterHeaderValue = row.first_name.toUpperCase().charAt(0);
          }
          const staff_media_source = row.staff_media_source ? '/api/static/media/staff/thumbnail/' + row.staff_media_source : '';
          return (
            <>
              {letterHeaderValue ? (
                <div className='display-flex align-items-center justify-content-space-between padding font-weight-bold font-size-small border-bottom-gray bg-whitesmoke'>
                  <div>
                    <center>{letterHeaderValue}</center>
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className='display-flex align-items-center white-space-nowrap'>
                <div className='margin-right-10'>
                  <div className='padding-5 border-radius-35'>
                    {staff_media_source ? (
                      <>
                        <img alt='staff_thumbnail' src={staff_media_source} className='thumbnail-40 border-radius-25' />
                      </>
                    ) : (
                      <>
                        <FaUserCircle size={50} className='color-whitesmoke border-gray border-radius-25' />
                      </>
                    )}
                  </div>
                </div>
                <div className='text-align-left'>
                  <div className='margin-bottom-5 text-transform-capitalize'>
                    {row.first_name ? row.first_name : ''} {row.last_name ? row.last_name : ''}
                  </div>
                  <div>{phoneFormatter.format(row.phone + '', '(NNN) NNN-NNNN')}</div>
                </div>
              </div>
            </>
          );
        },
      },
    ];
    const paginationOptions = {
      onPageChange: (page, sizePerPage) => {
        this.setState({ currentPage: page });
        this.setState({ currentPageSize: sizePerPage });
      },
      page: this.state.currentPage,
      sizePerPage: this.state.currentPageSize,
      hideSizePerPage: true,
    };
    const selectRow = {
      mode: 'radio',
      clickToSelect: true,
      onSelect: (row, isSelect, rowIndex, e) => {
        this.props.setCustomer(row);
        this.props.openModal('CUSTOMER_SELECT_TABLE', false);
        this.setState({ isSubmitDisabled: false });
      },
      style: { backgroundColor: '#e0e0e0' },
      hideSelectColumn: true,
    };
    const NoDataIndication = (props) => {
      if (props.searchProps.searchText.length > 0) {
        return (
          <div className='padding color-black'>
            Your search - <span className='font-weight-bold'>{props.searchProps.searchText}</span> - did not match any data.
          </div>
        );
      } else {
        return (
          <div className='padding display-flex justify-content-center align-items-center'>
            <center>
              <FaUserCircle size='200px' />
              <div className='font-size-large font-weight-bold margin-bottom-5'>No Customers Yet</div>
              <br />
              <div className='margin-bottom-15'> Customers can be added manually or when they check in.</div>
            </center>
          </div>
        );
      }
    };
    return (
      <>
        <div>
          <ToolkitProvider keyField='id' data={customers} columns={columns} search>
            {(toolkitprops) => {
              return (
                <div>
                  <div className='display-flex flex-direction-column flex-wrap-nowrap'>
                    <Button variant='secondary' onClick={() => this.openModal('PERSON_VERIFY', true)}>
                      <MdPersonAdd size={25} className='color-deepskyblue' /> New Customer
                    </Button>
                    <div className='width-100 margin-top-15 margin-bottom-15'>
                      <SearchBar placeholder='Search' tableId='Search' {...toolkitprops.searchProps} />
                    </div>
                  </div>
                  <BootstrapTable
                    keyField='id'
                    headerClasses={'display-none'}
                    classes='customer-select-table'
                    {...toolkitprops.baseProps}
                    data={customers}
                    columns={columns}
                    selectRow={selectRow}
                    pagination={paginationFactory(paginationOptions)}
                    noDataIndication={<NoDataIndication data={customers} searchProps={toolkitprops.searchProps} />}
                  />
                </div>
              );
            }}
          </ToolkitProvider>
        </div>
        <ModalDepot state={this.state} function={{ openModal: this.openModal }} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  business: state.business,
  users: state.users,
  site: state.site,
});

export default connect(mapStateToProps, { getCustomers, clearErrors })(AppComponent);
