import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { MdDeleteForever, MdStars } from 'react-icons/md';
import { connect } from 'react-redux';

import ModalDepot from '../components/ModalDepot';
import { getStaffMedia, deleteStaffMedia, setStaffMediaMain } from './../../../redux/actions/businessActions';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'STAFF_MEDIA_UPDATE_FORM',
      isDeleteStaffMediaModalShow: false,
      selectedStaffMedia: props.props.state.selectedStaffMedia,
      selectedStaff: props.props.state.selectedStaff,
      staffMediaDescription: '',
    };
    this.openModal = this.openModal.bind(this);
    this.onDeleteStaffMedia = this.onDeleteStaffMedia.bind(this);
    this.onSetStaffMediaMain = this.onSetStaffMediaMain.bind(this);
  }
  componentDidMount() {
    this.props.getStaffMedia(this.state.selectedStaffMedia.id);
  }
  openModal(modalName, modalState, modalData) {
    switch (modalName) {
      case 'STAFF_MEDIA_DELETE':
        modalState ? this.setState({ isDeleteStaffMediaModalShow: true }) : this.setState({ isDeleteStaffMediaModalShow: false });
        break;
      default:
    }
  }
  onSetStaffMediaMain(e) {
    const data = {
      staffMediaID: this.state.selectedStaffMedia.id,
      staffID: this.state.selectedStaff.id,
      businessID: this.props.users.token.business_id,
    };
    this.props.setStaffMediaMain(data, this);
  }
  onDeleteStaffMedia(e) {
    e.preventDefault();
    const data = {
      staffMediaIDArray: this.state.selectedStaffMedia ? [this.state.selectedStaffMedia.id] : this.state.selected,
      staffID: this.state.selectedStaff.id,
      businessID: this.props.users.token.business_id,
    };
    this.props.deleteStaffMedia(data, this);
  }
  onUpdateStaffMedia(e) {
    e.preventDefault();
    const data = {
      staffMediaID: this.state.selectedStaffMedia.id,
      staffID: this.state.selectedStaff.id,
      businessID: this.props.users.token.business_id,
      staffMediaDescription: this.props.business.staff_media.description,
    };
    this.props.updateStaffMedia(data, this);
  }
  onChangeStaffMediaKeyValue(id, key, value) {
    const data = {
      id,
      key,
      value,
    };
    this.props.changeStaffMediaKeyValue(data);
  }
  render() {
    const { staff_media } = this.props.business;
    let mediaPath = '/api/static/media/staff/preview/';
    var fileSource = '';
    if (Object.keys(staff_media).length !== 0) {
      if (staff_media.is_image) {
        fileSource = mediaPath + staff_media.source;
      } else {
        let sourceNameOnly = staff_media.source.substr(0, staff_media.source.lastIndexOf('.'));
        fileSource = mediaPath + sourceNameOnly + '.jpg';
      }
    }

    return staff_media ? (
      <>
        <div>
          <img src={fileSource} className='width-100 border-radius-25' alt='' />
          <div className='margin-top-15'>
            <div className='display-flex justify-content-space-between margin-top-15'>
              <Button
                variant='secondary'
                onClick={() => {
                  this.openModal('STAFF_MEDIA_DELETE', true);
                }}
              >
                <MdDeleteForever size={25} className='color-tomato' /> Delete
              </Button>
              {!staff_media.is_main ? (
                <Button variant='secondary' onClick={(e) => this.onSetStaffMediaMain(e)}>
                  <MdStars size={25} className='color-deepskyblue' /> Set Main
                </Button>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <ModalDepot state={this.state} props={this.props} function={{ openModal: this.openModal, onDeleteStaffMedia: this.onDeleteStaffMedia }} />
      </>
    ) : (
      <>LOADING</>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  business: state.business,
});
export default connect(mapStateToProps, { getStaffMedia, deleteStaffMedia, setStaffMediaMain })(AppComponent);
