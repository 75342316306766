import React, { Component } from 'react';
import { Alert, Form, Button, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/creatable';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { getProduct, getProductCategories, changeProductKeyValue, updateProduct } from '../../../redux/actions/businessActions';
import { clearErrors } from './../../../redux/actions/siteActions';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'PRODUCT_UPDATE_FORM',
      selectedProductID: props.props.state.selectedProduct.id,
      selectedProductCategory: props.props.state.selectedProduct.category,
      selectedProductPosition: props.props.state.selectedProduct.position,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onSetSelectedProductPosition = this.onSetSelectedProductPosition.bind(this);
  }
  componentDidMount() {
    this.props.getProduct(this.state.selectedProductID, this);
    this.props.getProductCategories(this.props.users.token.business_id, this);
  }
  onChangeProductKeyValue(id, key, value) {
    const data = {
      id,
      key,
      value,
    };
    this.props.changeProductKeyValue(data);
  }
  onSetSelectedProductCategory = (product) => {
    this.setState({ selectedProductPosition: { id: product.position, value: product.position, label: product.position } });
  };
  onSetSelectedProductPosition = (product) => {
    this.setState({ selectedProductPosition: { id: product.position, value: product.position, label: product.position } });
  };
  onSubmit = (e) => {
    e.preventDefault();
    const data = {
      product: this.props.business.product,
    };
    this.props.updateProduct(data, this);
  };
  modules = {
    toolbar: [
      // [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      [{ color: ['#4285F4', '#EA4335', '#FBBC05', '#34A853', '#000'] }],
      ['link'],
      ['clean'],
    ],
  };
  handleChange = (newValue) => {
    this.setState({ category: newValue });
  };
  render() {
    const { product, product_categories } = this.props.business;
    const { errors } = this.props.site;
    let categoryOptions = [];
    product_categories.map((category) => {
      categoryOptions.push({ id: category.id, value: category.name, label: category.name });
      return categoryOptions;
    });
    let positionOptions = [];
    Array.from(new Array(50), (e, i) => {
      positionOptions.push({ id: i, value: i, label: i });
      return positionOptions;
    });
    return (
      <>
        <div className='display-flex align-items-center justify-content-center'>
          <Form noValidate onSubmit={(e) => this.onSubmit(e)} className='width-100'>
            <div>
              <div className='justify-contents-space-between'>
                <div>
                  <ButtonGroup toggle className='display-flex margin-bottom-10'>
                    <ToggleButton
                      variant='secondary'
                      key={1}
                      type='radio'
                      name='radio'
                      value={product.type}
                      checked={product.type === 'service' ? true : false}
                      onChange={(e) => {
                        this.onChangeProductKeyValue(product.id, 'type', 'service');
                      }}
                    >
                      Service
                    </ToggleButton>
                    <ToggleButton
                      variant='secondary'
                      key={2}
                      type='radio'
                      name='radio'
                      value={product.type}
                      checked={product.type === 'product' ? true : false}
                      onChange={(e) => {
                        this.onChangeProductKeyValue(product.id, 'type', 'product');
                      }}
                    >
                      Product | Menu Item
                    </ToggleButton>
                  </ButtonGroup>
                </div>
                <div className='display-flex justify-content-space-between align-items-center margin-bottom-10'>
                  <div className='flex-2 margin-right-10'>
                    <div className='margin-bottom-5'>Category</div>
                    <CreatableSelect
                      value={this.state.selectedProductCategory}
                      onChange={(e) => {
                        if (e) {
                          this.setState({ selectedProductCategory: e });
                          this.onChangeProductKeyValue(product.id, 'category', e);
                        }
                      }}
                      options={categoryOptions}
                      placeholder='Select or Type New Category'
                    />
                  </div>
                  <div className='flex-1'>
                    <div className='margin-bottom-5'>Rank</div>
                    <CreatableSelect
                      value={this.state.selectedProductPosition}
                      onChange={(e) => {
                        this.setState({ selectedProductPosition: e });
                        this.onChangeProductKeyValue(product.id, 'position', e);
                      }}
                      options={positionOptions}
                      placeholder='Select or Enter'
                    />
                  </div>
                </div>
                <div className='margin-bottom-5'>Name</div>
                <Form.Row>
                  <Form.Control
                    type='text'
                    name='name'
                    value={product.name ? product.name : ''}
                    onChange={(e) => this.onChangeProductKeyValue(product.id, 'name', e.target.value)}
                    className='margin-bottom-15 width-100'
                  />
                </Form.Row>
                <div className='margin-bottom-5'>Price</div>
                <Form.Row>
                  <Form.Control
                    type='number'
                    name='price'
                    value={product.price ? product.price : ''}
                    onChange={(e) => this.onChangeProductKeyValue(product.id, 'price', e.target.value)}
                    className='margin-bottom-15'
                  />
                </Form.Row>
                {product.type === 'service' ? (
                  <>
                    <div className='margin-bottom-5'>Duration (minutes)</div>
                    <Form.Row>
                      <Form.Control
                        type='text'
                        name='time'
                        value={product.duration ? product.duration : ''}
                        onChange={(e) => this.onChangeProductKeyValue(product.id, 'duration', e.target.value)}
                        className='margin-bottom-15'
                      />
                    </Form.Row>{' '}
                  </>
                ) : (
                  ''
                )}
                <div className='margin-bottom-5'>Description</div>
                <ReactQuill
                  theme='snow'
                  name='description'
                  modules={this.modules}
                  value={product.description ? product.description : ''}
                  onChange={(e) => {
                    var description = e === '<p><br></p>' ? '' : e;
                    this.onChangeProductKeyValue(product.id, 'description', description);
                  }}
                  placeholder='Description'
                  className='margin-bottom-15'
                />
                <Alert variant='warning' show={errors.errorUpdateProduct ? true : false} onClose={() => this.props.clearErrors()} dismissible>
                  {errors.errorUpdateProduct}
                </Alert>
                <div className='display-flex'>
                  <Button type='submit' className='width-100'>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  business: state.business,
  users: state.users,
  site: state.site,
});

export default connect(mapStateToProps, { getProduct, getProductCategories, changeProductKeyValue, updateProduct, clearErrors })(AppComponent);
