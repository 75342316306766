import { GET_USER, AUTHENTICATE_USER, CHANGE_USER_KEY_VALUE, VALIDATE_ACCOUNT, UPDATE_USER } from '../types/types';
import isEmpty from '../../functions/isEmpty';

const initialState = {
  user: {},
  account: {},
  token: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    // USER: AUTHENTICATIONS
    case VALIDATE_ACCOUNT:
      return {
        ...state,
        account: action.payload,
      };
    case AUTHENTICATE_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        token: action.payload,
      };
    // USER
    case GET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case CHANGE_USER_KEY_VALUE:
      state.user[action.payload.key] = action.payload.value;
      return {
        ...state,
        user: state.user,
      };
    case UPDATE_USER:
      return {
        ...state,
      };
    default:
      return state;
  }
}
