import React, { Component } from 'react';
import { Button, ButtonGroup, Carousel } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  MdEdit,
  MdRemoveRedEye,
  MdShare,
  MdAddAPhoto,
  MdMap,
  MdPhone,
  MdPhoneAndroid,
  MdQueryBuilder,
  MdLibraryBooks,
  MdDeleteForever,
  MdContentCopy,
} from 'react-icons/md';
import queryString from 'query-string';
import { normalize } from 'phone-prettify';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import ModalDepot from './ModalDepot';
import PageProductsComponent from './PageProductsComponent';
import mapImage from './../../../assets/images/business_map.jpg';
import businessImage from './../../../assets/images/business_placeholder.jpg';
import {
  getBusiness,
  getBusinessAlbum,
  getHours,
  getBusinessPolicies,
  addBusinessPolicies,
  deleteBusinessPolicies,
  deleteBusinessMedia,
} from '../../../redux/actions/businessActions';
import { RiQrCodeLine } from 'react-icons/ri';
// import { getBusiness, getBusinessPhoto, getProductCount, getPostCount, getSubscriberCount } from '../../../redux/actions/businessActions';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'PAGE_COMPONENT',
      actionParam: props.props.parentProps ? queryString.parse(props.props.parentProps.history.location.search).action : '',
      tabParam: props.props.parentProps ? queryString.parse(props.props.parentProps.history.location.search).tab : '',
      isEditBusinessModalShow: false,
      isHoursFormModalShow: false,
      isAddMediaModalShow: false,
      isCustomerCalendarModalShow: false,
      isSelectFormsModalShow: false,
      isSharePageModalShow: false,
      isViewBusinessPolicyShow: false,
      isDeleteBusinessMediaShow: false,
      isBusinessPageModalShow: false,
      isPageQRcodeModalShow: false,
      isUploadProgress: false,
      selectedTab: 'products',
      selectedForms: [],
      allowAddForm: true,
      selectedBusinessPolicyID: '',
      selectedBusinessMediaID: '',
    };
    this.openModal = this.openModal.bind(this);
    this.onUploadProgress = this.onUploadProgress.bind(this);
    this.onCancelUpload = this.onCancelUpload.bind(this);
    this.setForms = this.setForms.bind(this);
    this.onDeleteBusinessMedia = this.onDeleteBusinessMedia.bind(this);
  }
  componentDidMount() {
    if (this.state.actionParam === 'update-business') {
      this.openModal('BUSINESS_UPDATE', true);
    }
    this.props.getBusiness(this.props.state.businessID, this);
    this.props.getBusinessAlbum(this.props.state.businessID);
    this.props.getHours(this.props.state.businessID);
    this.props.getBusinessPolicies(this.props.state.businessID);
  }
  onUploadProgress() {
    this.setState({ isUploadProgress: true });
  }
  onCancelUpload() {
    this.setState({ isUploadProgress: false });
  }
  openModal(modalName, modalState, modalData) {
    switch (modalName) {
      case 'BUSINESS_MEDIA_ADD':
        modalState ? this.setState({ isAddMediaModalShow: true }) : this.setState({ isAddMediaModalShow: false });
        break;
      case 'BUSINESS_UPDATE':
        modalState ? this.setState({ isEditBusinessModalShow: true }) : this.setState({ isEditBusinessModalShow: false });
        break;
      case 'SHARE_PAGE':
        modalState ? this.setState({ isSharePageModalShow: true }) : this.setState({ isSharePageModalShow: false });
        break;
      case 'HOURS_FORM':
        modalState ? this.setState({ isHoursFormModalShow: true }) : this.setState({ isHoursFormModalShow: false });
        break;
      case 'FORMS_SELECT_TABLE':
        modalState ? this.setState({ isSelectFormsModalShow: true }) : this.setState({ isSelectFormsModalShow: false });
        break;
      case 'BUSINESS_VIEW_POLICY':
        modalState ? this.setState({ isViewBusinessPolicyShow: true }) : this.setState({ isViewBusinessPolicyShow: false });
        break;
      case 'BUSINESS_MEDIA_DELETE':
        modalState ? this.setState({ isDeleteBusinessMediaShow: true }) : this.setState({ isDeleteBusinessMediaShow: false });
        break;
      case 'BUSINESS_PAGE_PREVIEW':
        modalState ? this.setState({ isBusinessPageModalShow: true }) : this.setState({ isBusinessPageModalShow: false });
        break;
      case 'PAGE_QRCODE':
        modalState ? this.setState({ isPageQRcodeModalShow: true }) : this.setState({ isPageQRcodeModalShow: false });
        break;
      default:
    }
  }
  openLivePage() {
    if (!this.props.business.business.vanity_name) {
      if (window.confirm('A vanity name is required to view your live booking page. Would you like to get one now?')) {
        this.openModal('BUSINESS_UPDATE', true);
      }
    } else {
      this.openModal('BUSINESS_PAGE_PREVIEW', true);
      // const livePageUrl = '/page/' + this.props.business.business.vanity_name;
      // window.open(livePageUrl, '_blank');
      // window.location.href = livePageUrl;
    }
  }
  onSelectTabs(e) {
    this.setState({ selectedTab: e });
    // window.location.href = `/account/page?tab=${e}`;
  }
  setForms(forms) {
    const formIDs = forms.map((form) => {
      return form.id;
    }, []);
    const data = {
      businessID: this.props.state.businessID,
      formIDArray: formIDs,
    };
    this.props.addBusinessPolicies(data, this);
  }
  onDeleteBusinessPolicies(e) {
    const data = {
      businessID: this.props.state.businessID,
      policyIDArray: this.state.selectedBusinessPolicyID ? [this.state.selectedBusinessPolicyID] : [],
    };
    this.props.deleteBusinessPolicies(data, this);
  }
  onDeleteBusinessMedia() {
    const data = {
      businessMediaIDArray: [this.state.selectedBusinessMediaID],
      businessID: this.props.business.business.id,
    };
    this.props.deleteBusinessMedia(data, this);
  }
  render() {
    const { business, business_hours, business_policies, business_album } = this.props.business;
    return (
      <>
        <div className='width-100'>
          <div className='page-header'>
            {!this.props.liveMode ? (
              <div className='display-flex justify-content-center align-items-center border-bottom padding'>
                <div className='display-flex justify-content-space-between align-items-center flex-wrap-nowrap padding-5 width-980'>
                  <div className='display-flex align-items-center'>
                    <div className='display-flex flex-direction-column margin-right-15'>
                      <div className='display-flex align-items-center font-size-medium'>Manage Business Page</div>
                      <div>Let customers discover your business by adding products &amp; services and sharing your page on social networks</div>
                      {business.vanity_url ? (
                        <div className='margin-top-15'>
                          <b>Your Page Vanity URL:</b> {this.props.business.business.vanity_url}
                          <br />
                          <br />
                          <ButtonGroup>
                            <Button
                              variant='secondary'
                              onClick={() => {
                                navigator.clipboard.writeText(this.props.business.business.vanity_url);
                                alert('URL has been copied');
                              }}
                            >
                              <div className='display-flex align-items-center justify-content-center'>
                                <MdContentCopy size={25} color='#019fe8' />
                                Copy URL
                              </div>
                            </Button>
                            <Button variant='secondary' onClick={() => this.openModal('PAGE_QRCODE', true)}>
                              <div className='display-flex align-items-center justify-content-center'>
                                <RiQrCodeLine size={25} color='#019fe8' /> QR Code
                              </div>
                            </Button>
                            <Button
                              variant='secondary'
                              onClick={() => {
                                this.openLivePage();
                              }}
                            >
                              <div className='display-flex align-items-center justify-content-center'>
                                <MdRemoveRedEye size={25} color='#019fe8' />
                                Public View
                              </div>
                            </Button>
                          </ButtonGroup>
                        </div>
                      ) : (
                        <div className='margin-top-15'>
                          <div className='margin-bottom-10'>
                            Give your business a vanity name and get a url for customer booking and menu/services viewing.
                          </div>
                          <Button variant='secondary' onClick={() => this.openModal('BUSINESS_UPDATE', true)}>
                            <MdEdit color='#019fe8' /> Create Vanity Name and URL
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className='display-flex justify-content-center'>
            <div className='display-flex width-980'>
              <div className='page-master'>
                <div className='display-flex justify-content-center'>
                  <div className='display-flex flex-direction-column'>
                    <div className='display-flex justify-content-center'>
                      <div id='business-avatar'>
                        {business_album.length > 0 ? (
                          <>
                            <div className='padding position-relative'>
                              <Carousel touch={true} controls={true} interval={null}>
                                {business_album.map((media, i) => {
                                  const mainMedia = `/api/static/media/business/preview/${media.source}`;
                                  return (
                                    <Carousel.Item key={i}>
                                      <img
                                        alt='product_thumbnail'
                                        src={mainMedia}
                                        className='width-100 border-radius-5'
                                        style={{ objectFit: 'cover', width: '100%', height: '300px' }}
                                      />
                                      {this.props.token && !this.props.liveMode ? (
                                        <ButtonGroup className='position-absolute' style={{ right: '30%', bottom: '15%' }}>
                                          <Button onClick={() => this.openModal('BUSINESS_MEDIA_ADD', true)}>
                                            <div className='display-flex flex-wrap-nowrap align-items-center justify-content-center'>
                                              <MdAddAPhoto size={25} className='margin-right-10' />
                                              <span className='white-space-nowrap'> Add Photo</span>
                                            </div>
                                          </Button>
                                          <Button
                                            variant='secondary'
                                            onClick={async () => {
                                              await this.setState({ selectedBusinessMediaID: media.id });
                                              this.openModal('BUSINESS_MEDIA_DELETE', true);
                                            }}
                                          >
                                            <MdDeleteForever size={25} className='color-tomato' />
                                          </Button>
                                        </ButtonGroup>
                                      ) : (
                                        ''
                                      )}
                                    </Carousel.Item>
                                  );
                                })}
                              </Carousel>
                            </div>
                          </>
                        ) : (
                          <div className='padding position-relative'>
                            <img
                              alt='business_placeholder'
                              src={businessImage}
                              className='width-100 border-radius-5'
                              style={{ objectFit: 'cover', width: '100%', height: '300px' }}
                            />
                            {this.props.token ? (
                              <ButtonGroup className='position-absolute' style={{ right: '40%', bottom: '15%' }}>
                                <Button variant='secondary' onClick={() => this.openModal('BUSINESS_MEDIA_ADD', true)}>
                                  <div className='display-flex flex-wrap-nowrap align-items-center justify-content-center'>
                                    <MdAddAPhoto color='#019fe8' size={20} />
                                    <span className='white-space-nowrap'>Add Photos</span>
                                  </div>
                                </Button>
                              </ButtonGroup>
                            ) : (
                              ''
                            )}
                          </div>
                        )}
                      </div>
                      <div className='padding display-flex flex-direction-column border-bottom-gray'>
                        <div className='display-flex justify-content-space-between margin-bottom-5'>
                          <div className='font-size-medium-large'>{business.business_name}</div>
                          <div>
                            {this.props.token && !this.props.liveMode ? (
                              <Button variant='secondary' onClick={() => this.openModal('BUSINESS_UPDATE', true)}>
                                <MdEdit color='#019fe8' /> Edit
                              </Button>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <div>
                          {business.address}
                          <br />
                          {business.city}, {business.state}, {business.zip}
                        </div>
                      </div>
                      {/* <div id='business-overview' className='padding-10 justify-content-flex-start '>
                    <div>
                      <div className='display-flex flex-direction-row margin-top-15'>
                        <div className='flex-1 display-flex flex-direction-column justify-content-center align-items-center padding-10 text-align-center'>
                          <div className='font-weight-bold'>123</div>
                          Subscribers
                        </div>
                        <div className='flex-1 flex-direction-column display-flex justify-content-center align-items-center padding-10 text-align-center'>
                          <div className='font-weight-bold'>123</div>
                          Post
                        </div>
                        <div className='flex-1 display-flex flex-direction-column justify-content-center align-items-center padding-10 text-align-center'>
                          <div className='font-weight-bold'>123</div>
                          Likes
                        </div>
                      </div>
                    </div>
                  </div> */}
                    </div>
                  </div>
                </div>
                <div className='width-100'>
                  <PageProductsComponent state={this.state} props={this.props} token={this.props.token} liveMode={this.props.liveMode} />
                </div>
              </div>
              <div className='padding page-slave'>
                <div className='position-relative'>
                  <img
                    src={mapImage}
                    className='width-100 border-radius-5 border-darkgray cursor-pointer'
                    alt='Map &amp; Directions'
                    onClick={() => {
                      const mapAddress = encodeURIComponent(business.address + ',' + business.city + ',' + business.state + ',' + business.zip);
                      const newAddress = 'https://maps.google.com/maps?q=' + mapAddress;
                      window.location.href = newAddress;
                    }}
                  />
                  <ButtonGroup className='position-absolute' style={{ right: '15%', bottom: '10%' }}>
                    <Button
                      variant='secondary'
                      onClick={() => {
                        const mapAddress = encodeURIComponent(business.address + ',' + business.city + ',' + business.state + ',' + business.zip);
                        const newAddress = 'https://maps.google.com/maps?q=' + mapAddress;
                        window.location.href = newAddress;
                      }}
                    >
                      <div>
                        {business.business_name}
                        <br />
                        <div className='font-size-smallest'>
                          {business.address}
                          <br />
                          {business.city + ',' + business.state + ',' + business.zip}
                        </div>
                      </div>
                    </Button>
                    <Button
                      variant='secondary'
                      onClick={() => {
                        const mapAddress = encodeURIComponent(business.address + ',' + business.city + ',' + business.state + ',' + business.zip);
                        const newAddress = 'https://maps.google.com/maps/dir/?api=1&destination=' + mapAddress;
                        window.location.href = newAddress;
                      }}
                    >
                      <MdMap size={25} className='color-deepskyblue' />
                    </Button>
                  </ButtonGroup>
                </div>
                <div className='margin-top-15'>Contact &amp; Business Hours</div>
                <hr></hr>
                <div className='display-flex justify-content-space-between align-items-center'>
                  <div>
                    <MdPhoneAndroid size={25} className='color-gray' />
                    {normalize(business.phone)}
                  </div>
                  <div>
                    <Button
                      variant='secondary'
                      onClick={() => {
                        window.location.href = `tel:${business.phone}`;
                      }}
                    >
                      <MdPhone size={25} className='color-deepskyblue' /> Call
                    </Button>
                  </div>
                </div>
                <hr></hr>
                <div>
                  {this.props.token && !this.props.liveMode ? (
                    <div className='display-flex justify-content-flex-end'>
                      <Button variant='secondary' onClick={() => this.openModal('HOURS_FORM', true)}>
                        <div className='display-flex align-items-center justify-content-center'>
                          <MdQueryBuilder size={25} color='#019fe8' /> Hours
                        </div>
                      </Button>
                    </div>
                  ) : (
                    ''
                  )}
                  {business_hours.map((hour, i) => {
                    return (
                      <div key={i}>
                        <div className='display-flex justify-content-space-between align-items-center padding-5 '>
                          <div className='flex-3 '>{hour.dayName}</div>
                          <div className='flex-1 white-space-nowrap '>{hour.open}</div>
                          <div className='flex-1 display-flex justify-content-center '>—</div>
                          <div className='flex-1 white-space-nowrap '>{hour.closed}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <hr></hr>
                <div className='display-flex justify-content-space-between align-items-center'>
                  <div>Social &amp; Share</div>
                  <Button variant='secondary' onClick={() => this.openModal('SHARE_PAGE', true)}>
                    <div className='display-flex align-items-center justify-content-center'>
                      <MdShare size={25} className='color-deepskyblue' />
                      Share
                    </div>
                  </Button>
                </div>
                <hr></hr>
                {business.about && business.about !== '<p><br></p>' ? (
                  <>
                    <div className='display-flex justify-content-space-between align-items-center'>
                      <div>About</div>
                      {this.props.token ? (
                        <div>
                          <Button variant='secondary' onClick={() => this.openModal('BUSINESS_UPDATE', true)}>
                            <MdEdit color='#019fe8' /> Edit
                          </Button>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <hr></hr>
                    <div id='business-about'>
                      <div className='react-quill' dangerouslySetInnerHTML={{ __html: business.about ? business.about : '' }} />
                    </div>
                    <hr></hr>
                  </>
                ) : (
                  ''
                )}
                {business_policies.length > 0 || this.props.token ? (
                  <>
                    <div className='display-flex justify-content-space-between align-items-center'>
                      <div>Additional Information</div>
                      {this.props.token && !this.props.liveMode ? (
                        <div>
                          <Button variant='secondary' onClick={() => this.openModal('FORMS_SELECT_TABLE', true)}>
                            <MdLibraryBooks size={25} className='color-deepskyblue' /> Add Info
                          </Button>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <hr></hr>
                    {business_policies.map((policy, i) => {
                      return (
                        <div key={i}>
                          <div className='display-flex justify-content-space-between margin-bottom-10'>
                            <a
                              href='#!'
                              onClick={async () => {
                                await this.setState({ selectedBusinessPolicyID: policy.id });
                                this.openModal('BUSINESS_VIEW_POLICY', true);
                              }}
                            >
                              {policy.name}
                            </a>
                            {this.props.token && !this.props.liveMode ? (
                              <Button
                                variant='secondary'
                                onClick={async (e) => {
                                  await this.setState({ selectedBusinessPolicyID: policy.id });
                                  this.onDeleteBusinessPolicies(e);
                                }}
                              >
                                <MdDeleteForever size={25} className='color-red' />
                              </Button>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
        <ModalDepot
          state={this.state}
          props={this.props}
          function={{
            openModal: this.openModal,
            onCancelUpload: this.onCancelUpload,
            onUploadProgress: this.onUploadProgress,
            onDeleteBusinessMedia: this.onDeleteBusinessMedia,
            setForms: this.setForms,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  business: state.business,
});
export default connect(mapStateToProps, {
  getBusiness,
  getBusinessAlbum,
  getHours,
  getBusinessPolicies,
  addBusinessPolicies,
  deleteBusinessPolicies,
  deleteBusinessMedia,
})(AppComponent);
// export default connect(mapStateToProps, { getBusiness, getBusinessPhoto, getProductCount, getPostCount, getSubscriberCount })(AppComponent);
