import React, { Component } from 'react';
import { connect } from 'react-redux'; // connecting redux to component
import Container from 'react-bootstrap/Container';

class AppComponent extends Component {
  render() {
    return (
      <div className='display-flex'>
        <Container>
          <h1 className='h1_custom_index'>Terms of Use</h1>
          <div className='font-size-18'>
            <div className='h3_custom_index'>Acceptance of Terms</div>
            <br />
            Please read the following important Terms of Use Agreement (the Agreement) carefully. The Agreement governs your access to and use of the
            websites owned or managed by Pink Dot Media and One Space. or its Affiliates (Pink Dot Media and One Space) and that link to or reference
            the Agreement (each individually a Website and collectively the Websites), and constitutes a binding legal agreement between you, as a
            user of the Website, and Pink Dot Media and One Space, the provider of the Website. Your access to and use of the Website requires your
            compliance with the Agreement and the terms and conditions contained herein. By accessing and/or using the Website, you agree to be bound
            by the Agreement. If at any time you do not accept all the terms and conditions of this Agreement, you must immediately discontinue use of
            the Website. This Agreement sets forth Pink Dot Media and One Space's terms and conditions with respect to its operation of the Websites;
            other policies and agreements govern Pink Dot Media and One Space's non-Internet operations.&nbsp;
            <br />
            <br />
            <strong>To access or use the Website you must be over 13 years of age.</strong>
            <div>
              <br />
            </div>
            <div className='h3_custom_index'>Affiliates</div>
            <div>
              As used in the Agreement, the term Affiliates means, any other Person that directly or indirectly controls, is controlled by, or is
              under common control with Pink Dot Media and One Space. The term control (including, with correlative meanings, the terms controlled by
              and under common control with) as applied to any Person means the possession, direct or indirect, of the power to direct or cause the
              direction of the management and policies of such Person, whether through the ownership of voting securities or other ownership interest.
              The term Person means any individual, partnership, corporation, limited liability company, unincorporated organization or association,
              any trust, or any other business entity.
              <p>
                <span className='h3_custom_index'>
                  <span className='h3_custom_index'>Products and Services Offered/Discussed on Website</span>
                  <br />
                </span>
                Certain products or services offered by or discussed on the Website or the Websites may be governed by additional terms (Additional
                Terms) presented in conjunction with those products or services. You must agree to these Additional Terms before using those products
                or services. In the event of an irreconcilable inconsistency between the Additional Terms and this Agreement, the Additional Terms
                will control.
                <br />
                <br />
                While the Website may contain information about certain products or services, including information on pricing and availability of
                certain products and services, such information is an estimate and is provided for informational purposes only and is not an offer by
                Pink Dot Media and One Space. The actual price of certain products and services may differ from what appears on the Website and
                certain products and services may not be available in all areas.
              </p>
              <br />
              <p>
                <span className='h3_custom_index'>Amendments to the Agreement</span>
                <br />
                Pink Dot Media and One Space reserves the right, in its sole discretion, to modify, alter, or otherwise change the Agreement at any
                time and without notice. All modified terms and conditions will be effective after the modified terms and conditions have been posted
                on the Website. Please review the Agreement periodically for changes. If any modified terms and conditions are not acceptable to you,
                you must immediately discontinue use of the Website. Further, if any modified terms and conditions are not acceptable to you, your
                sole remedy is to cease using the Website. By continuing to access or use the Website after any posted modification to the Agreement,
                you agree to be bound by such changes.
                <br />
                <br />
                <span className='h3_custom_index'>Privacy</span>
                <br />
                Certain personally identifiable information may be collected from you. When you use the Website, Pink Dot Media and One Space may
                monitor your usage of the Website. By using the Website, you agree that Pink Dot Media and One Space may collect, use and disclose
                certain information you provide during the use of the Website, and in some cases information that is provided by other parties.
                <br />
                <br />
                Any applicable privacy policy for the Website or Websites will not be considered an Additional Term. In the event of an irreconcilable
                inconsistency between the privacy policy and this Agreement, the terms of this Agreement will control. You acknowledge and agree that
                Pink Dot Media and One Space may collect and disclose certain personally identifiable information if required to do so by law or in
                the good faith belief that such preservations or disclosure is reasonably necessary to: (a) comply with legal process or other
                governmental inquiry; (b) enforce the Agreement; (c) take action regarding suspected illegal activities; (d) protect Pink Dot Media
                and One Space's rights, reputation, and property, or that of Pink Dot Media and One Space's affiliates, or the public. If you use the
                Website outside of the United States, you consent to having your personal data transferred to and processed in the United States.
                <br />
                <br />
                <span className='h3_custom_index'>International Use and U.S. Export Controls</span>
                <br />
                Accessing materials and/or Content on the Website by certain persons in certain countries may not be lawful, and Pink Dot Media and
                One Space makes no representation that materials and/or Content on the Website are appropriate or available for use in locations
                outside the United States. If you choose to access the Website from outside the United States, you do so at your own risk and
                initiative, and you are responsible for compliance with any applicable national and local laws. You agree to comply with all
                applicable laws regarding the transmission of technical data exported from the United States or the country in which you reside. No
                Content or any other materials associated with the Website may be downloaded or otherwise exported or re-exported to countries or
                persons prohibited under export control laws, including but not limited to countries against which the United states has embargoed
                goods, or to anyone on the U.S. Treasury Department list of Specially Designated Nationals and Blocked Persons or the U.S. Commerce
                Department's Table of Deny Orders. You are responsible for compliance with the laws of your local jurisdiction regarding the import,
                export, or re-export of any such materials. By using and/or downloading any Content or other materials from the Website or Websites,
                you represent and warrant that you are not located in, under the control of, or a national or resident of any such country to which
                such import, export, or re-export is prohibited and that you are not a person or entity to which such export is prohibited.
                <br />
                <br />
                <span className='h3_custom_index'>Content, Software, and Downloads Available Through The Website</span>
                <br />
                You understand that the Website provides you with information, data, text, sound, photographs, graphics, images, video, software,
                messages or other material (collectively, Content), and that such Content may be provided by third parties (Third Party Content). You
                agree that Pink Dot Media and One Space, in its sole discretion, may remove and discard any Content within the Website, for any
                reason. Any software that is made available to view and/or download on the Website, including podcasts, audio streaming, or video
                streaming, is protected by copyright laws and international treaty provisions. Your use of any software is governed by the terms of
                any end user license agreement which accompanies or is included with the software. Pink Dot Media and One Space is not responsible or
                liable for any software owned or controlled by third parties.
                <br />
                <br />
                <span className='h3_custom_index'>Links to Third Party Sites Outside of the Service</span>
                <br />
                The Website may provide, links to Internet sites or resources operated by third parties. Because Pink Dot Media and One Space has no
                control over such sites and resources, you acknowledge and agree that Pink Dot Media and One Space is not responsible for the
                availability, accuracy, completeness, timeliness, or reliability of such external sites or resources. Pink Dot Media and One Space
                does not endorse, and is not responsible or liable for any content, advertising, products, services or other materials on or available
                from such sites or resources. If you choose to access any third party site, you do so at your own risk. You further acknowledge and
                agree that Pink Dot Media and One Space will not be responsible or liable, directly or indirectly, for any damage or loss caused or
                alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any
                such site or resource. The presence of a link to a third-party site does not constitute or imply Pink Dot Media and One Space's
                endorsement, sponsorship, or recommendation of the third party or of the content, products, services, or material obtained on, or
                available through, the third party site.
                <br />
                <br />
                <span className='h3_custom_index'>Restrictions</span>
                <br />
                The Website may be used and accessed for lawful purposes only. You agree to abide by all applicable local, state, national and foreign
                laws and regulations in connection with your use of the Website. In addition, you agree not to do any of the following while using or
                accessing the Website:
              </p>
              <ul>
                <li>
                  upload, post, email or otherwise transmit any viruses, worms, defects, Trojan horses or other computer code that may interrupt,
                  destroy, limit the functionality of the Website, or interfere with the access of any user to the Website;
                </li>
                <li>
                  circumvent, disable or otherwise interfere with security related features of the Website or features that prevent or restrict use or
                  copying of any Content or features that enforce limitations on the use of the Website;
                </li>
                <li>
                  attempt to decipher, decompile, disassemble, reverse engineer, or otherwise attempt to discover or determine the source code of any
                  aspect of the Website
                </li>
                <li>reformat or frame any portion of the Website</li>
                <li>
                  use manual or automated software, devices, scripts robots, other device, means or processes to access, scrape, crawl, retrieve,
                  index or spider any portion of the Website or Content
                </li>
                <li>
                  attempt to probe, scan or test the vulnerability of any Pink Dot Media and One Space system or network or breach or impair or
                  circumvent any security or authentication measures protecting the Website or the Content
                </li>
                <li>
                  interfere with or disrupt the service or servers or networks connected to the service, or disobey any requirements, procedures,
                  policies or regulations of networks connected to the Website
                </li>
                <li>
                  use any device, software or routine that interferes with the proper working of the Website, or otherwise attempt to interfere with
                  the proper working of the Website
                </li>
                <li>
                  take any action that imposes or may impose, in Pink Dot Media and One Space's sole discretion, an unreasonable or disproportionately
                  large load on Pink Dot Media and One Space's technology infrastructure or otherwise make excessive traffic demands of the Website
                </li>
                <li>
                  attempt to gain unauthorized access to the Website, computer systems, or networks connected to the Website through hacking, password
                  mining or any other means
                </li>
                <li>use the Website in any way that competes with Pink Dot Media and One Space</li>
                <li>encourage or instruct any other individual to do any of the foregoing</li>
              </ul>
              Pink Dot Media and One Space will determine, in its sole discretion, whether you have engaged in prohibited conduct; Pink Dot Media and
              One Space's determination will be final and unreviewable, and you waive any and all rights you may have to challenge Pink Dot Media and
              One Space's decision.
            </div>
            <div>
              <br />
            </div>
            <div className='h3_custom_index'>Website Modification, Availability, Termination, Cancellation</div>
            <div>
              The Website (or any portion of the Website or Websites) may be modified, updated, interrupted, suspended or terminated at any time, for
              any reason, without notice to you. You agree that Pink Dot Media and One Space will not be liable to you or to any third party for any
              modification, update, interruption, suspension or termination of the Website or any portion thereof. Pink Dot Media and One Space may
              suspend or terminate your access to a Website or the Websites at any time, in Pink Dot Media and One Space's sole discretion, with or
              without notice, if you fail to comply with any provision of the Agreement, or for any other reason, or for no reason at all. Any
              suspension, termination or cancellation will not affect your obligations to Pink Dot Media and One Space under the Agreement (including
              but not limited to ownership, indemnification, disclaimers of warranty and limitation of liability, choice of law and venue), which by
              their sense and context are intended to survive such suspension, termination or cancellation. Pink Dot Media and One Space will not be
              liable to you or any other person for termination of the Website or termination of your use of the Website.
            </div>
            <div>
              <br />
            </div>
            <div className='h3_custom_index'>Ownership</div>
            <div>
              The Website and the Content are protected by copyright, trademark, service marks, patents or other proprietary rights and laws. Pink Dot
              Media, its licensors, and/or third parties exclusively own all right, title and interest in and to the Website(s) and the Content
              including all associated intellectual property rights. You may not remove, alter or obscure any copyright, trademark, service mark or
              other proprietary rights notices incorporated in or accompanying the Website or the Content. You may not use, copy, adapt, modify,
              prepare derivative works based upon, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream,
              broadcast or otherwise exploit the Website(s) or the Content except as expressly permitted in the Agreement.
            </div>
            <div>
              <br />
            </div>
            <div className='h3_custom_index'>Testimonials</div>
            <div>
              The Website may contain comments and statements by third parties about Pink Dot Media and One Space or products and services obtained
              through Pink Dot Media. Pink Dot Media and One Space does not represent or warrant that any statements or comments by third parties are
              accurate or that you will have a similar experience. Pink Dot Media and One Space explicitly disclaims any liability for reliance on any
              comments or statements made by third parties.
            </div>
            <div>
              <br />
            </div>
            <div className='h3_custom_index'>Disclaimer of Warranties and Limitations of Liability</div>
            <div>The Website and Content included therein, are provided on an AS IS and AS AVAILABLE basis without any warranties of any kind.</div>
            <div>
              <br />
            </div>
            <div>
              YOUR USE OF, AND RELIANCE ON, ANY INFORMATION OBTAINED FROM OR THROUGH THE WEBSITE OR WEBSITES IS AT YOUR OWN RISK. Pink Dot Media and
              One Space MAKES NO REPRESENTATIONS AND EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY, RELATING
              TO THE WEBSITE, THE OPERATION OF THE WEBSITE, OR THE INFORMATION, CONTENT OR MATERIAL INCLUDED ON THE WEBSITE, INCLUDING BUT NOT LIMITED
              TO THE WARRANTIES OF TITLE, MERCHANTIABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF PROPRIETARY RIGHTS, COURSE OF
              DEALING OR COURSE OF PERFORMANCE. FURTHER, Pink Dot Media and One Space MAKES NO WARRANTY THAT (i) THE WEBSITE WILL MEET YOUR
              REQUIREMENTS, (ii) THE WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) THE RESULTS OR INFORMATION THAT MAY BE
              OBTAINED FROM THE USE OF THE WEBSITE WILL BE ACCURATE OR RELIABLE, (iv) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER
              MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE WEBSITE WILL MEET YOUR EXPECTATIONS, (v) ANY ERRORS IN THE SOFTWARE OR WEBSITE WILL BE
              CORRECTED, OR THAT (vi) ITS SERVERS WILL BE FREE OF VIRUSES AND/OR OTHER HARMFUL COMPONENTS. YOUR USE OF THE WEBSITE OR WEBSITES IS AT
              YOUR SOLE DISCRETION AND RISK. ANY MATERIAL OR CONTENT DOWNLOADED OR OTHERWISE ACCESSED OR OBTAINED THROUGH THE USE OF THE WEBSITE IS
              DONE AT YOUR OWN DISCRETION AND RISK AND YOU AGREE THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM, MOBILE
              DEVICE, OR LOSS OF DATA THAT RESULTS FROM YOUR USE OF THE WEBSITE. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
              FROM Pink Dot Media and One Space OR THROUGH OR FROM THE WEBSITE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE AGREEMENT. Pink
              Dot Media and One Space, ITS AFFILIATES, LICENSORS, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, OR SHAREHOLDERS (COLLECTIVELY THE Pink Dot
              Media and One Space ENTITIES) WILL NOT BE RESPONSIBLE FOR ANY PERSONAL INJURY, PROPERTY DAMAGE, OR OTHER HARM THAT RESULTS FROM YOUR USE
              OF THE WEBSITE. ADDITIONALLY, YOU EXPRESSLY UNDERSTAND AND AGREE THAT IN NO EVENT WILL THE Pink Dot Media and One Space ENTITIES BE
              LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, COMPENSATORY, CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHATSOEVER, WHETHER
              THE DAMAGES ARE FORESEEABLE AND WHETHER OR NOT THE Pink Dot Media and One Space ENTITIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES, INCLUDING BUT NOT LIMITED TO: (i) DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR BUSINESS INTERUPTION OR OTHER INTANGIBLE
              LOSSES OF ANY KIND; (ii) THE USE OR THE INABILITY TO USE OF THE WEBSITE; (iii) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES
              RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHED OR OBTAINED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE WEBSITE;
              (iv) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (v) ERRORS OR INACCURACIES CONTAINED ON THE WEBSITE OR ANY
              CONTENT, INFORMATION, PRODUCTS, OR SERVICES OBTAINED THROUGH THE WEBSITE; (vi) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE WEBSITE;
              (vii) ANY PROPERTY DAMAGE INCLUDING DAMAGE TO YOUR COMPUTER OR COMPUTER SYSTEM CAUSED BY VIRUSES OR OTHER HARMFUL COMPONENTS, DURING OR
              ON ACCOUNT OF ACCESS TO OR USE OF THE WEBSITE OR ANY SITE TO WHICH IT PROVIDES LINKS; OR (v) ANY DAMAGES OTHERWISE ARISING OUT OF THE
              USE OF THE WEBSITE OR OTHERWISE RELATED TO THE WEBSITE. THE LIMITATIONS OF LIABILITY WILL APPLY REGARDLESS OF THE FORM OF ACTION,
              WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE.
            </div>
            <div>
              <br />
            </div>
            <div>
              Your sole and exclusive right and remedy in case of dissatisfaction with the Website or any other grievance will be your discontinuation
              of access to, or use of the Website.
            </div>
            <div>
              <br />
            </div>
            <div className='h3_custom_index'>Exclusions</div>
            <div>
              Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or
              incidental damages, so the above limitation may not apply to you.
            </div>
            <div>
              <br />
            </div>
            <div className='h3_custom_index'>Indemnity</div>
            <div>
              You agree to defend, indemnify, and hold Pink Dot Media and One Space, its subsidiaries, affiliates, officers, directors, employees,
              agents, licensors, partners, representatives, and shareholders harmless from and against any and all claims, actions, demands,
              liabilities, damages, losses, costs, and expenses, including, without limitation, reasonable attorneys' fees and costs, arising out of
              or in any way connected with (i) your access to or use of the Website; (ii) your violation of any provision of the Agreement; or (iii)
              your violation of any third party right, including without limitation, any intellectual property right, publicity, confidentiality,
              property or privacy right. Pink Dot Media and One Space reserves the right, at your expense, to assume the exclusive defense and control
              of any matter for which you are required to indemnify Pink Dot Media and One Space and you agree to cooperate with Pink Dot Media and
              One Space's defense of these claims. You agree not to settle any matter without the prior written consent of Pink Dot Media and One
              Space. Pink Dot Media and One Space will use reasonable efforts to notify you of any such claim, demands, action or proceeding within a
              reasonable time after becoming aware of it.
            </div>
            <div>
              <br />
            </div>
            <div className='h3_custom_index'>Choice of Law and Venue</div>
            <div>The Agreement will be governed by Louisiana law, without regard to its conflicts of laws principles.</div>
            <div>
              <br />
            </div>
            <div>
              FOR ANY CLAIM BROUGHT BY EITHER PARTY, YOU AGREE TO SUBMIT AND CONSENT TO THE PERSONAL AND EXCLUSIVE JURISDICTION IN, AND THE EXCLUSIVE
              VENUE OF, THE STATE AND FEDERAL COURTS LOCATED WITHIN EAST BATON ROUGE PARISH, LOUISIANA.
            </div>
            <div>
              <br />
            </div>
            <div className='h3_custom_index'>General</div>
            <div>
              The Agreement is the entire agreement between you and Pink Dot Media and One Space concerning the Website and supersedes all prior or
              contemporaneous communications between you and Pink Dot Media and One Space with respect to the Website. If any provision of the
              Agreement is deemed invalid by a court of competent jurisdiction, then such provision will be deemed superseded by a valid, enforceable
              provision that matches, as closely as possible, the original provisions, and the remaining provisions will remain in full force and
              effect. No waiver of any term of the Agreement will be deemed a further or continuing waiver of such term or any other term, and Pink
              Dot Media and One Space's failure to assert any right or provision under the Agreement will not constitute a waiver of such right or
              provisions.
            </div>
            <div>
              <br />
            </div>
            <div>
              The Agreement is not assignable, transferable or sublicensable by you except with Pink Dot Media and One Space's prior written consent,
              but may be assigned or transferred by Pink Dot Media and One Space without restriction. Any assignment attempted to be made in violation
              of the Agreement will be void.&nbsp;
            </div>
            <div>
              <br />
            </div>
            <div>
              The prevailing party in any proceeding arising under the Agreement will be entitled to receive reimbursement of its reasonable expenses
              and attorneys' fees.
            </div>
            <div>
              <br />
            </div>
            <div>
              You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the
              Website or the Agreement must be filed within (1) year after such claim or cause of action arose or be forever barred.
            </div>
            <div>
              <br />
            </div>
            <div className='h3_custom_index'>Disclaimer</div>
            <div>
              Usage of this web site or any web site maintained by Pink Dot Media and One Space constitutes acceptance of the following terms and
              conditions:&nbsp;
            </div>
            <div>
              All text, graphics, design, selection, and arrangement of information on this site is protected by U.S. and international copyright laws
              - &copy;2018 Pink Dot Media and One Space Advertising Company. All rights reserved.
              <br />
              <br />
              Any duplication, modification, or use of any material provided on this web site, whether protected by copyright or not, of any kind for
              commercial purposes is strictly prohibited.
              <br />
              <br />
              Pink Dot Media and One Space Advertising Company accepts no liability for any loss or damage, consequential or otherwise, incurred in
              reliance on the material in these pages. The information on this site is for information purposes only and is not intended as
              professional advice of any kind.
              <br />
              <br />
              "Pink Dot Media and One Space," "Pink Dot Media and One Space Graphics," and "Interstate Logos" are registered trademarks of Pink Dot
              Media and One Space Advertising Company. Any unauthorized use of these trademarks, or other trademarks owned by Pink Dot Media and One
              Space Advertising Company, is strictly prohibited.
            </div>
          </div>
          <br />
          <br />
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps, {})(AppComponent);
