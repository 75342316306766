import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { normalize } from 'phone-prettify';

import { searchBusinesses } from './../../redux/actions/businessActions';
import { clearErrors } from './../../redux/actions/siteActions';
import ModalDepot from './components/ModalDepot';
import { MdSearch } from 'react-icons/md';

class AppComponent extends Component {
  constructor() {
    super();
    this.state = {
      selectedBusiness: '',
      searchTerm: '',
      isCustomerCalendarModalShow: false,
    };
  }
  openModal = (modalName, modalState, modalData) => {
    switch (modalName) {
      case 'BUSINESS_CALENDAR':
        if (modalState) {
          this.setState({ isCustomerCalendarModalShow: true });
        } else {
          this.setState({ isCustomerCalendarModalShow: false });
        }
        break;
      default:
    }
  };
  onSearchBusiness = (e) => {
    e.preventDefault();
    const data = {
      searchTerm: this.state.searchTerm,
    };
    this.props.searchBusinesses(data, this);
  };
  async onChange(e) {
    await this.setState({ [e.target.name]: e.target.value });
  }
  render() {
    const { businesses } = this.props.business;
    const { errors } = this.props.site;
    return (
      <>
        {/* <div className='padding align-items-center margin-top-15'>
          <center>
            <a href='/' className='text-decoration-none color-black'>
              <img src={logo} className='logo' alt='logo' style={{ height: '30px' }} />
            </a>
          </center>
        </div> */}
        <div className='display-flex justify-content-center align-items-center padding'>
          <div className='width-100'>
            <div className='margin-bottom-15'>
              <Form noValidate onSubmit={(e) => this.onSearchBusiness(e)} className='display-flex flex-direction-row margin-top-15'>
                <div className='display-flex flex-wrap-nowrap'>
                  <input
                    className='businessSearchBar'
                    type='text'
                    name='searchTerm'
                    placeholder='Search Business or Locations'
                    aria-label='Search'
                    value={this.state.searchTerm}
                    onChange={(e) => this.onChange(e)}
                  />
                  <div className='businessSearchButton'>
                    <Button
                      type='submit'
                      style={{
                        borderTopRightRadius: '20px',
                        borderBottomRightRadius: '20px',
                        borderBottomLeftRadius: '0px',
                        borderTopLeftRadius: '0px',
                      }}
                    >
                      <MdSearch size={20} />
                    </Button>
                  </div>
                </div>
              </Form>
              {/* <Form noValidate onSubmit={(e) => this.onSearchBusiness(e)} className='display-flex flex-direction-row margin-top-15'>
                <div className='width-100'>
                  <Form.Control
                    type='text'
                    name='searchTerm'
                    placeholder='Search Business by Name or Address'
                    value={this.state.searchTerm}
                    className='margin-bottom-15 width-100'
                    onChange={(e) => this.onChange(e)}
                  />
                </div>
                {/* <div>
                  <Button type='submit'>Search</Button>{' '}
                </div> 
              </Form> */}
            </div>
            <div>
              {businesses.map((business, i) => {
                return (
                  <div key={i}>
                    <div
                      className='border border-radius-5 box-shadow-light padding margin-bottom-15'
                      onClick={() => {
                        this.setState({ selectedBusiness: business });
                        this.openModal('BUSINESS_CALENDAR', true);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <div className='margin-bottom-15 font-weight-bold font-size-medium'>
                        {i + 1}. {business.business_name} <Button>Book</Button>
                      </div>
                      {normalize(business.phone)}
                      <br />
                      {business.address}
                      <br />
                      {business.city}, {business.state} {business.zip}
                    </div>
                  </div>
                );
              })}
            </div>
            <div>{errors.message}</div>
          </div>
        </div>
        <ModalDepot state={this.state} data={this.state} function={{ openModal: this.openModal }} />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  users: state.users,
  business: state.business,
  site: state.site,
});
export default connect(mapStateToProps, { searchBusinesses, clearErrors })(AppComponent);
