import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Form } from 'react-bootstrap';
import Uppy from '@uppy/core';
import Dashboard from '@uppy/react/lib/Dashboard';
import XHRUpload from '@uppy/xhr-upload';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {};
    var allowedFileTypes = ['image/*', '.jpg', '.jpeg', '.png'];
    this.uppy = Uppy({
      autoProceed: false,
      debug: true,
      restrictions: {
        maxFileSize: 20 * 1000000, //20MB
        maxNumberOfFiles: 1,
        allowedFileTypes,
      },
      onBeforeFileAdded: (currentFile, files) => {
        // execute when adding a file
      },
      onBeforeUpload: (currentFile, files) => {
        // execute when right before file upload
        // this.props.addMedia(currentFile);
      },
    })
      // .use(Url, {
      //   companionUrl: 'http://localhost:3000'
      // })
      // .use(Dropbox, { companionUrl: 'https://localhost:3000', host: 'http://localhost:3000' })
      .use(XHRUpload, {
        id: 'XHRUpload',
        endpoint: '/api/business/add-business-media',
        method: 'POST',
        formData: true,
        // metaFields: ['size'] // uncomment if want to restrict meta fields to only send this value
      })
      .on('file-added', (file) => {
        this.uppy.setFileMeta(file.id, {
          userID: this.props.users.token.user_id,
          businessID: this.props.users.token.business_id,
        });
      })
      .on('upload', (data) => {
        this.props.props.function.onUploadProgress();
      })
      .on('upload-progress', (data) => {})
      .on('cancel-all', (data) => {
        this.props.props.function.onCancelUpload();
      })
      .on('restriction-failed', (file, error) => {
        // do some customized logic like showing system notice to users
      })
      .on('upload-error', (file, err, res) => {
        this.uppy.setState({ info: { message: res.body.errorUploadMedia } });
        this.uppy.removeFile(file.id);
        // this.uppy.reset();
      })
      .on('info-visible', () => {
        // const info = this.uppy.getState().info;
        // info: {
        //  isHidden: false,
        //  type: 'error',
        //  message: 'Failed to upload',
        //  details: 'Error description'
        // }
        // alert(`${info.message} ${info.details}`);
      })
      .on('upload-success', (file, response) => {})
      .on('complete', (res) => {
        res.successful.forEach((response) => {
          window.location.reload();
          // window.location.href = '/account/page?tab=posts';
          // this.props.function.openModal('POST_ADD', false);
        });
        if (res.failed.length > 0) {
          // this.setState({ errors: res.failed[0].response.body });
          return false;
        }
      });
  }
  componentDidMount() {}
  componentWillUnmount() {
    this.uppy.close();
  }
  render() {
    return (
      <div>
        <Dashboard id='mediaUpload' uppy={this.uppy} plugins={['Url']} width='100%' proudlyDisplayPoweredByUppy={false} />
        {/* <Form.Control
          as='textarea'
          rows='3'
          type='text'
          name='description'
          placeholder='Description'
          value={this.state.post_description}
          onChange={(e) => this.setState({ post_description: e.target.value })}
          className='margin-top-15'
        /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps)(AppComponent);
