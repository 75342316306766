import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { getPublicEnv } from './../../../redux/actions/siteActions';

class AppComponent extends Component {
  constructor(props) {
    super();
  }
  componentDidMount() {
    this.props.getPublicEnv();
  }
  render() {
    return (
      <div>
        <div className='flex-table'>
          <div className='flex-table-row'>
            <div className='flex-table-cell padding text-align-right'>
              <b>To:</b>
            </div>
            <div className='flex-table-cell padding'>
              {this.props.props.state.selectedCheckin
                ? this.props.props.state.selectedCheckin.first_name + ' ' + this.props.props.state.selectedCheckin.last_name
                : ''}
              {this.props.props.state.selectedCheckin.email ? (
                <>
                  <br />
                  {this.props.props.state.selectedCheckin.email}
                </>
              ) : (
                <>
                  <br />
                  {this.props.props.state.selectedCheckin.phone}
                </>
              )}
            </div>
          </div>
          <div className='flex-table-row'>
            <div className='flex-table-cell padding text-align-right'>
              <b>Message:</b>
            </div>
            <div className='flex-table-cell padding'>
              {this.props.props.state.selectedCheckin.email ? (
                <>
                  Your feedback is important to us. We would love to hear about your recent visit. Tell us how we did:{' '}
                  <span className='color-deepskyblue'>[feedback link]</span>
                </>
              ) : (
                <>
                  {this.props.props.state.selectedCheckin.business_name}: How was your experience with us? Let us know:
                  <span className='color-deepskyblue'>[feedback link]</span>
                </>
              )}
            </div>
          </div>

          <div className='display-flex justify-content-flex-end margin-top-15'>
            <Button
              onClick={(e) => {
                this.props.props.function.onTextCheckinGetFeedback();
              }}
              className='display-flex width-100'
            >
              Send {this.props.props.state.selectedCheckin.email ? 'Email' : 'Text'}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  users: state.users,
  site: state.site,
});
export default connect(mapStateToProps, { getPublicEnv })(AppComponent);
