import React, { Component } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { confirmSubscriptionSwitch } from './../../../redux/actions/subscriptionActions';
import { clearErrors } from './../../../redux/actions/siteActions';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      userPassword: '',
      isConfirmedPlanSwitch: false,
    };
    this.onConfirmPlanSwitch = this.onConfirmPlanSwitch.bind(this);
    this.onSetStateConfirmedSwitch = this.onSetStateConfirmedSwitch.bind(this);
  }
  onConfirmPlanSwitch() {
    // check if the password is correct, if so, update and switch plan
    const userValues = {
      password: this.state.userPassword,
      accountID: this.props.users.token.account_id,
    };
    this.props.confirmSubscriptionSwitch(userValues, this);
  }
  onSetStateConfirmedSwitch = () => {
    this.setState({ isConfirmedPlanSwitch: true });
  };
  render() {
    const { errors } = this.props.site;
    return (
      <>
        <center>
          <div className='font-size-medium font-weight-bold margin-bottom-15'>{this.props.props.state.selectedSubscriptionPlan.name} Plan</div>
          Enter your password to confirm.
          <Form.Control
            type='password'
            name='userPassword'
            autoComplete='on'
            value={this.state.userPassword}
            placeholder='Account Password'
            onChange={(e) => this.setState({ userPassword: e.target.value })}
            className='margin-bottom-15 margin-top-15'
          />
          <Alert variant='warning' show={errors.message ? true : false} onClose={() => this.props.clearErrors()} dismissible>
            {errors.message}
          </Alert>
          <div className='display-flex justify-content-flex-end'>
            <Button
              variant='primary'
              onClick={(e) => {
                e.stopPropagation();
                this.onConfirmPlanSwitch(e);
              }}
              disabled={this.state.isConfirmedPlanSwitch ? this.state.isConfirmedPlanSwitch : false}
              className='display-flex'
            >
              Switch Now
            </Button>
          </div>
        </center>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  site: state.site,
});
export default connect(mapStateToProps, { confirmSubscriptionSwitch, clearErrors })(AppComponent);
