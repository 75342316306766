import axios from 'axios';
import jwt_decode from 'jwt-decode';
import setAuthToken from './../../functions/setAuthToken';
import { GET_ERRORS, GET_USER, AUTHENTICATE_USER, CHANGE_USER_KEY_VALUE, VALIDATE_ACCOUNT } from '../types/types';
import { getErrors, getResponse } from './../actions/siteActions';

// VERIFICATION
export const checkUserVerification = (data) => (dispatch) => {
  axios
    .post('/api/user/check-user-verification', data)
    .then((res) => {
      const path = res.data.success ? '/started/login' : '/started/signup';
      localStorage.setItem('localStorageUserEmail', res.data.email);
      window.location.href = path;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const validateSignupToken = (data) => (dispatch) => {
  axios
    .post('/api/user/validate-signup-token', data)
    .then((res) => {
      const { isTokenValid, tokenType, token } = res.data;
      if (isTokenValid) {
        window.location.href = '/welcome/' + tokenType + '/' + token;
      } else {
        window.location.href = '/login';
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const validateAccount = (data) => (dispatch) => {
  axios
    .get(`/api/user/validate-account?data=${JSON.stringify(data)}`)
    .then((res) => {
      const { success, loginToken, isUserVerified, isTokenValid } = res.data;
      if (success && loginToken) {
        localStorage.setItem('loginToken', loginToken);
        setAuthToken(loginToken); // set token to auth header
        const decoded = jwt_decode(loginToken); // decode token to get user data
        dispatch(authenticateUser(decoded)); // set current user authentication
        // window.location.href = '/account/dashboard';
        dispatch({
          type: VALIDATE_ACCOUNT,
          payload: res.data,
        });
      } else {
        if (isUserVerified) {
          dispatch({
            type: VALIDATE_ACCOUNT,
            payload: res.data,
          });
        } else {
          if (isTokenValid) {
            dispatch({
              type: VALIDATE_ACCOUNT,
              payload: res.data,
            });
          } else {
            window.location.href = '/signup';
          }
        }
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
// SIGN UP | LOGIN | LOGOUT
export const signUpUser = (userData) => (dispatch) => {
  axios
    .post('/api/user/signup-user', userData)
    .then((res) => {
      const { success, tokenType, token } = res.data;
      if (success && token && tokenType) {
        window.location.href = '/welcome/' + tokenType + '/' + token;
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const loginUser = (data) => (dispatch) => {
  axios
    .post('/api/user/login-user', data)
    .then((res) => {
      // save to local storage
      const { tokenType, token, loginToken } = res.data;
      if (loginToken) {
        // set token to local storage
        localStorage.setItem('loginToken', loginToken);
        localStorage.removeItem('localStorageUserPassword');
        setAuthToken(loginToken); // set token to auth header
        const decoded = jwt_decode(loginToken); // decode token to get user data
        dispatch(authenticateUser(decoded)); // set current user authentication
      } else {
        if (tokenType && token) {
          window.location.href = '/welcome/' + tokenType + '/' + token;
        } else {
          window.location.href = '/started/signup';
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const logoutUser = () => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem('loginToken');
  // Remove auth header for future requests
  setAuthToken(false);
  //Set current user to {} which will set isAuthenticated to false
  dispatch(authenticateUser({}));
};
// AUTHENTICATION
export const authenticateUser = (decoded) => {
  return {
    type: AUTHENTICATE_USER,
    payload: decoded,
  };
};
// RESET PASSWORD
export const resetPassword = (data) => (dispatch) => {
  axios
    .post('/api/user/reset-password', data)
    .then((res) => {
      const { loginToken } = res.data;
      if (loginToken) {
        localStorage.setItem('loginToken', loginToken);
        setAuthToken(loginToken); // set token to auth header
        const decoded = jwt_decode(loginToken); // decode token to get user data
        dispatch(authenticateUser(decoded)); // set current user authentication
      } else {
        dispatch(getResponse(res));
      }
    })
    .catch((err) => {
      dispatch(getErrors(err));
    });
};
export const verifyPasswordToken = (data) => (dispatch) => {
  axios
    .post('/api/user/verify-password-token', data)
    .then((res) => {
      dispatch(getResponse(res));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
// USER
export const changeUserKeyValue = (data) => (dispatch) => {
  dispatch({ type: CHANGE_USER_KEY_VALUE, payload: data });
};
export const updateUser = (data, caller) => (dispatch) => {
  axios
    .post('/api/user/update_user', data)
    .then((res) => {
      const { loginToken } = res.data;
      if (loginToken) {
        localStorage.setItem('loginToken', loginToken);
        // setAuthToken(loginToken); // set token to auth header
        const decoded = jwt_decode(loginToken); // decode token to get user data
        dispatch(authenticateUser(decoded)); // set current user authentication
        caller.openModal('PROFILE_UPDATE', true);
      }
      // update user data in current token
      // dispatch({ type: UPDATE_USER, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
  // update user settings
  // update user settings
};
export const getUser = (data) => (dispatch) => {
  axios
    .get(`/api/user/get_user?userID=${JSON.stringify(data)}`)
    .then((res) => {
      dispatch({ type: GET_USER, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
