import React, { Component } from 'react';
import { Alert, Form, Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import dateFormat from 'dateformat';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addStaffHour } from '../../../redux/actions/businessActions';
import { clearErrors, clearResponse } from '../../../redux/actions/siteActions';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedStaff: props.props.state.selectedStaff,
      weekday: '',
      start: new Date().setHours(9, 0, 0),
      end: new Date().setHours(17, 0, 0),
      isSubmitInProgress: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  async onChange(e, targetName) {
    if (targetName) {
      await this.setState({ [targetName]: e });
    } else {
      await this.setState({ [e.target.name]: e.target.value });
    }
  }
  onSubmit(e) {
    e.preventDefault();
    let newStart = dateFormat(this.state.start, 'HHMM');
    let newEnd = dateFormat(this.state.end, 'HHMM');
    const data = {
      weekday: this.state.weekday,
      start: newStart,
      end: newEnd,
      businessID: this.props.users.token.business_id,
      staffID: this.state.selectedStaff.id,
    };
    this.props.addStaffHour(data, this);
  }
  render() {
    const { errors, response } = this.props.site;
    const DayList = () => {
      var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      let dayArray = [];
      days.map((day, i) =>
        dayArray.push(
          <option key={i} value={i}>
            {day}
          </option>
        )
      );
      return dayArray;
    };

    return (
      <div className='display-flex width-100 align-items-center justify-content-center'>
        <div className='width-100'>
          <Form onSubmit={(e) => this.onSubmit(e)} className='margin-top-15'>
            <div className='margin-bottom-15'>Day of the Week</div>
            <Form.Control as='select' name='weekday' value={this.state.weekday} onChange={(e) => this.onChange(e)}>
              <option value=''>Day</option>
              <DayList />
            </Form.Control>{' '}
            <div className='margin-top-15 margin-bottom-5'>Time Open</div>
            <DatePicker
              withPortal
              name='start'
              selected={this.state.start}
              onChange={(e) => {
                this.onChange(e, 'start');
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption='Start'
              dateFormat='h:mm aa'
              autoComplete='off'
              className='padding-10 border-radius-5 border-darkgray width-100'
            />{' '}
            <div className='margin-top-15 margin-bottom-5'>Time Closed</div>
            <DatePicker
              withPortal
              name='end'
              placeholderText=''
              selected={this.state.end}
              onChange={(e) => {
                this.onChange(e, 'end');
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption='End'
              dateFormat='h:mm aa'
              autoComplete='off'
              className='padding-10 border-radius-5 border-darkgray width-100'
            />
            <div className='margin-top-15'>
              <Alert
                variant={errors.message ? 'warning' : 'success'}
                show={errors.message || response.message ? true : false}
                onClose={() => (errors.message ? this.props.clearErrors() : this.props.clearResponse())}
                dismissible
              >
                {errors.message ? errors.message : response.message ? response.message : ''}
              </Alert>
              <Button type='submit' disabled={this.state.isSubmitInProgress ? true : false} className='width-100 margin-top-15'>
                Add
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  business: state.business,
  users: state.users,
  site: state.site,
});

export default connect(mapStateToProps, { addStaffHour, clearErrors, clearResponse })(AppComponent);
