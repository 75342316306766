import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import combineRootReducers from './combine/combineRootReducers';

const initialState = {};
const middleware = [thunk];
const store = createStore(
  combineRootReducers,
  initialState,
  compose(
    applyMiddleware(...middleware)
    // window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
    // this causes error in windows edge browser
    // something to do with Unable to get property 'apply' of undefined or null reference
    // comment the line below in production, uncomment in development for redux tools in Chrome inspect dev
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export default store;
