import axios from 'axios';
import { GET_ERRORS, CLEAR_ERRORS, GET_RESPONSE, CLEAR_RESPONSE, EMAIL_TOKEN_LINK, GET_ENV } from '../types/types';

export const getPublicEnv = () => (dispatch) => {
  axios
    .get(`/api/site/get_env`)
    .then((res) => {
      dispatch({ type: GET_ENV, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const emailResetLink = (email, history) => (dispatch) => {
  axios
    .post('/api/site/email_reset_password_link', email)
    .then((res) => {
      history.push('/login');
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const emailTokenLink = (data, caller) => (dispatch) => {
  axios
    .post('/api/site/email-token-link', data)
    .then((res) => {
      window.setTimeout(() => {
        caller.setState({ isSendDisable: false });
      }, 5000);
      dispatch({
        type: EMAIL_TOKEN_LINK,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const emailOut = (data, caller) => (dispatch) => {
  axios
    .post('/api/site/email-out', data)
    .then((res) => {
      caller.openModal('EMAIL_SUCCESS', true);
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const getErrors = (err) => (dispatch) => {
  dispatch({
    type: GET_ERRORS,
    payload: err.response.data,
  });
};
export const clearErrors = () => (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
export const getResponse = (res) => (dispatch) => {
  dispatch({
    type: GET_RESPONSE,
    payload: res.data,
  });
};
export const clearResponse = () => (dispatch) => {
  dispatch({
    type: CLEAR_RESPONSE,
  });
};
