import React, { Component } from 'react';
import { Button, Form, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';

import { checkUserVerification } from '../../redux/actions/userActions';
import isEmail from 'validator/lib/isEmail';
import logo from './../../assets/images/logo.png';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      isEmailValid: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  async componentDidMount() {
    if (this.props.users.isAuthenticated) {
      window.location.href = '/account/dashboard';
      // this.props.history.push('/account/dashboard');
    }
    if (localStorage.getItem('localStorageUserEmail')) {
      if (localStorage.getItem('localStorageUserEmail') === 'undefined') {
        this.setState({ email: '' });
        this.setState({ isEmailValid: false });
      } else {
        if (isEmail(localStorage.getItem('localStorageUserEmail'))) {
          await this.setState({ email: localStorage.getItem('localStorageUserEmail') });
          await this.setState({ isEmailValid: true });
          // this.onSubmit();
        } else {
          this.setState({ isEmailValid: false });
        }
      }
    }
  }
  onChange(e) {
    this.setState({ email: e.target.value });
    if (isEmail(e.target.value)) {
      this.setState({ isEmailValid: true });
    } else {
      this.setState({ isEmailValid: false });
    }
  }
  onSubmit(e) {
    if (e) e.preventDefault();
    const dataPack = {
      email: this.state.email,
    };
    this.props.checkUserVerification(dataPack);
  }
  onCreateNewAccount(e) {
    if (localStorage.getItem('localStorageUserEmail')) {
      localStorage.removeItem('localStorageUserEmail');
    }
    window.location.href = '/signup';
  }
  render() {
    return (
      <div className='display-flex'>
        <Container>
          <center>
            <div className='padding border-radius-5 width-max-640 margin-top-50'>
              <div
                className='display-flex align-items-center justify-content-center padding margin-15 border-radius-5 margin-bottom-50 bg-whitesmoke box-shadow-light'
                style={{ borderRadius: '50%', width: '150px', height: '150px' }}
              >
                <a href='/'>
                  <img src={logo} className='logo-static' alt='logo' />
                </a>
              </div>
              <div className='margin-bottom-15 font-size-medium-large'>Sign In</div>
              <Form onSubmit={this.onSubmit}>
                <TextField
                  label='Enter Your Email'
                  type='email'
                  name='email'
                  margin='normal'
                  variant='outlined'
                  onChange={this.onChange}
                  value={this.state.email}
                  inputProps={{ style: { fontSize: 17 } }} // font size of input text
                  InputLabelProps={{ style: { fontSize: 17 } }} // font size of input label
                  className='bg-white width-100'
                />
                <div className='display-flex margin-top-15'>
                  <Button variant='primary' type='submit' disabled={this.state.isEmailValid ? false : true} className='width-100'>
                    <span>Next</span>
                  </Button>
                </div>
              </Form>
              <div className='hr-text margin-top-25 margin-bottom-25'>OR</div>
              <a href='#!' onClick={(e) => this.onCreateNewAccount(e)} className='width-100'>
                <span className='font-size-normal'>Sign Up for an Account</span>
              </a>
              {/* <div className='margin-top-25 color-gray font-size-medium'>
                For Password? <a href='/forgot_password'>Reset it</a>
              </div> */}
            </div>
          </center>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  site: state.site,
});

export default connect(mapStateToProps, { checkUserVerification })(Login);
