import React, { Component } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { MdArrowForward } from 'react-icons/md';
import { connect } from 'react-redux';
import { clearErrors } from './../../../redux/actions/siteActions';
import TextField from '@material-ui/core/TextField';

class AppComponent extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
    };
    this.onSubmitEmail = this.onSubmitEmail.bind(this);
  }
  onSubmitEmail = async () => {
    this.props.props.state.customer.email = await this.state.email;
    console.log('CUSTOMER DATAAA', this.props.props.state.customer);
    this.props.props.function.onSendBookingCode(this.props.props.state.customer);
  };
  render() {
    const { errors } = this.props.site;
    return (
      <>
        <div className='display-flex width-100'>
          <TextField
            label='Email address'
            name='email'
            margin='normal'
            variant='outlined'
            value={this.state.email}
            onChange={(e) => this.setState({ email: e.target.value })}
            inputProps={{ style: { fontSize: 17 } }} // font size of input text
            InputLabelProps={{ style: { fontSize: 17 } }} // font size of input label
            className='display-flex width-100'
          />
          {/* <Form.Control
            type='text'
            name='email'
            placeholder=''
            value={this.state.email}
            onChange={(e) => this.setState({ email: e.target.value })}
            className='margin-top-15 margin-bottom-15'
          /> */}
          <br />
          <Alert
            variant='warning'
            show={errors.bookingError ? true : false}
            onClose={() => this.props.clearErrors()}
            dismissible
            className='display-flex'
          >
            {errors.bookingError}
          </Alert>
          <Button onClick={() => this.onSubmitEmail()} className='width-100'>
            Next <MdArrowForward size={25} className='color-deepskyblue' />
          </Button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  site: state.site,
});
export default connect(mapStateToProps, { clearErrors })(AppComponent);
