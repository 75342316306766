import {
  SEARCH_BUSINESSES,
  GET_BUSINESS,
  GET_USER_ACCOUNTS,
  UPDATE_BUSINESS,
  GET_BUSINESS_ALBUM,
  GET_BUSINESS_PHOTO,
  GET_BUSINESS_POSTS,
  GET_BUSINESS_POST,
  GET_BUSINESS_POLICY,
  GET_BUSINESS_POLICIES,
  CHANGE_POST_KEY_VALUE,
  CHANGE_BUSINESS_KEY_VALUE,
  CHANGE_STAFF_KEY_VALUE,
  ADD_STAFF,
  GET_STAFFS,
  GET_STAFF,
  DELETE_STAFFS,
  UPDATE_STAFF,
  GET_STAFF_ROLES,
  GET_STAFF_ALBUM,
  GET_STAFF_MEDIA,
  ADD_STAFF_HOUR,
  GET_STAFF_HOUR,
  GET_STAFF_HOURS,
  CHANGE_STAFF_HOUR_KEY_VALUE,
  UPDATE_STAFF_HOUR,
  DELETE_STAFF_HOURS,
  ADD_CHECKIN,
  GET_CHECKIN,
  GET_CHECKINS,
  GET_CHECKINS_COUNT,
  GET_CHECKINS_HISTORY,
  UPDATE_CHECKIN,
  DELETE_CHECKINS,
  CHANGE_CHECKIN_KEY_VALUE,
  ADD_CUSTOMER,
  GET_CUSTOMER,
  GET_CUSTOMERS,
  UPDATE_CUSTOMER,
  CHANGE_CUSTOMER_KEY_VALUE,
  DELETE_CUSTOMERS,
  ADD_PRODUCT,
  GET_PRODUCTS,
  GET_PRODUCT,
  DELETE_PRODUCTS,
  UPDATE_PRODUCT,
  CHANGE_PRODUCT_KEY_VALUE,
  GET_PRODUCT_ALBUM,
  GET_PRODUCT_MEDIA,
  CHANGE_PRODUCT_MEDIA_KEY_VALUE,
  GET_PRODUCT_CATEGORIES,
  ADD_APPOINTMENT,
  GET_APPOINTMENT,
  GET_APPOINTMENTS,
  GET_APPOINTMENT_FORMS,
  UPDATE_APPOINTMENT,
  DELETE_APPOINTMENTS,
  CHANGE_APPOINTMENT_KEY_VALUE,
  GET_BOOKED_APPOINTMENTS,
  GET_HOUR,
  GET_HOURS,
  UPDATE_HOUR,
  ADD_HOUR,
  DELETE_HOURS,
  CHANGE_HOUR_KEY_VALUE,
  GET_CHECKINAPP,
  CHANGE_CHECKINAPP_KEY_VALUE,
  GET_CHECKINAPP_MEDIA,
  ADD_FORM,
  GET_FORM,
  GET_FORMS,
  UPDATE_FORM,
  UPLOAD_FORM,
  GET_CHECKINAPP_TOKENS,
  DELETE_FORMS,
  CHANGE_FORM_KEY_VALUE,
} from '../types/types';

const initialState = {
  checkin: {},
  checkins: [],
  checkins_count: {},
  checkins_history: [],
  checkinapp: {
    business_id: null,
    color: '#cccccc',
    greeting: 'Thank you! We will be with you shortly.',
    font_size: 45,
    font_family: '',
    form_text: '',
    form_id: 0,
  },
  checkinapp_tokens: {},
  customer: {},
  customers: [],
  product: {},
  products: [],
  product_album: [],
  product_media: {},
  product_categories: [],
  product_category: {},
  appointment: {},
  appointments: [],
  booked_appointments: [],
  appointment_forms: [],
  business_hour: {},
  business_hours: [],
  business: {},
  businesses: [],
  business_album: [],
  business_photo: {},
  business_posts: [],
  business_post: {},
  business_policy: {},
  business_policies: [],
  user_accounts: [],
  staff: {},
  staffs: [],
  staff_roles: [],
  staff_album: [],
  staff_media: {},
  staff_hours: [],
  staff_hour: {},
  form: {},
  forms: [],
  checkinapp_media: {},
};
export default function (state = initialState, action) {
  switch (action.type) {
    // BUSINESSES
    case SEARCH_BUSINESSES:
      return {
        ...state,
        businesses: action.payload,
      };
    case GET_BUSINESS:
      return {
        ...state,
        business: action.payload,
      };
    case GET_USER_ACCOUNTS:
      return {
        ...state,
        user_accounts: action.payload,
      };
    case UPDATE_BUSINESS:
      return {
        ...state,
        business: action.payload,
      };
    case CHANGE_BUSINESS_KEY_VALUE:
      state.business[action.payload.key] = action.payload.value;
      return {
        ...state,
        business: state.business,
      };
    case GET_BUSINESS_ALBUM:
      return {
        ...state,
        business_album: action.payload,
      };
    case GET_BUSINESS_PHOTO:
      return {
        ...state,
        business_photo: action.payload,
      };
    // BUSINESS POSTS
    case GET_BUSINESS_POSTS:
      return {
        ...state,
        business_posts: action.payload,
      };
    case GET_BUSINESS_POST:
      return {
        ...state,
        business_post: action.payload,
      };
    case CHANGE_POST_KEY_VALUE:
      state.business_post[action.payload.key] = action.payload.value;
      return {
        ...state,
        business_post: state.business_post,
      };
    case GET_BUSINESS_POLICY:
      return {
        ...state,
        business_policy: action.payload,
      };
    case GET_BUSINESS_POLICIES:
      return {
        ...state,
        business_policies: action.payload,
      };
    // STAFFS
    case ADD_STAFF:
      return {
        ...state,
        staffs: [action.payload, ...state.staffs],
      };
    case GET_STAFFS:
      return {
        ...state,
        staffs: action.payload,
      };
    case GET_STAFF:
      return {
        ...state,
        staff: action.payload,
      };
    case UPDATE_STAFF:
      return {
        ...state,
        staffs: state.staffs.map((staff) => (staff.id === action.payload.id ? action.payload : staff)),
      };
    case DELETE_STAFFS:
      return {
        ...state,
        staffs: state.staffs.filter((staff) => !action.payload.includes(staff.id)),
      };
    case CHANGE_STAFF_KEY_VALUE:
      state.staff[action.payload.key] = action.payload.key === 'role_id' ? Number(action.payload.value) : action.payload.value;
      return {
        ...state,
        staff: state.staff,
      };
    case GET_STAFF_ALBUM:
      return {
        ...state,
        staff_album: action.payload,
      };
    case GET_STAFF_MEDIA:
      return {
        ...state,
        staff_media: action.payload,
      };
    case ADD_STAFF_HOUR:
      return {
        ...state,
        staff_hours: [...state.staff_hours, action.payload],
      };
    case GET_STAFF_HOUR:
      return {
        ...state,
        staff_hour: action.payload,
      };
    case GET_STAFF_HOURS:
      return {
        ...state,
        staff_hours: action.payload,
      };
    case UPDATE_STAFF_HOUR:
      return {
        ...state,
        staff_hours: state.staff_hours.map((hour) => (hour.id === action.payload.id ? action.payload : hour)),
      };
    case DELETE_STAFF_HOURS:
      return {
        ...state,
        staff_hours: state.staff_hours.filter((hour) => !action.payload.includes(hour.id)),
      };
    case CHANGE_STAFF_HOUR_KEY_VALUE:
      state.staff_hour[action.payload.key] = action.payload.value;
      return {
        ...state,
        staff_hour: state.staff_hour,
      };
    // STAFF ROLES
    case GET_STAFF_ROLES:
      return {
        ...state,
        staff_roles: action.payload,
      };
    // CHECK INS
    case ADD_CHECKIN:
      return {
        ...state,
        checkins: [action.payload, ...state.checkins],
      };
    case GET_CHECKIN:
      return {
        ...state,
        checkin: action.payload,
      };
    case GET_CHECKINS:
      return {
        ...state,
        checkins: action.payload ? action.payload : state.checkins,
      };
    case GET_CHECKINS_COUNT:
      return {
        ...state,
        checkins_count: action.payload ? action.payload : state.checkins_count,
      };
    case GET_CHECKINS_HISTORY:
      return {
        ...state,
        checkins_history: action.payload ? action.payload : state.checkins_history,
      };
    case UPDATE_CHECKIN:
      return {
        ...state,
        checkins: state.checkins.map((checkin) => (checkin.id === action.payload.id ? action.payload : checkin)),
      };
    case DELETE_CHECKINS:
      return {
        ...state,
        checkins: state.checkins.filter((checkin) => !action.payload.includes(checkin.id)),
      };
    case CHANGE_CHECKIN_KEY_VALUE:
      state.checkin[action.payload.key] = action.payload.value;
      return {
        ...state,
        checkin: state.checkin,
      };
    // CUSTOMERS
    case ADD_CUSTOMER:
      return {
        ...state,
        customers: [action.payload, ...state.customers],
      };
    case GET_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      };
    case GET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      };
    case UPDATE_CUSTOMER:
      return {
        ...state,
        customers: state.customers.map((customer) => (customer.id === action.payload.id ? action.payload : customer)),
      };
    case DELETE_CUSTOMERS:
      return {
        ...state,
        customers: state.customers.filter((customer) => !action.payload.includes(customer.id)),
      };
    case CHANGE_CUSTOMER_KEY_VALUE:
      state.customer[action.payload.key] = action.payload.value;
      return {
        ...state,
        customer: state.customer,
      };
    // PRODUCTS
    case ADD_PRODUCT:
      return {
        ...state,
        products: [action.payload, ...state.products],
      };
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case GET_PRODUCT:
      return {
        ...state,
        product: action.payload,
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        products: state.products.map((product) => (product.id === action.payload.id ? action.payload : product)),
      };
    case DELETE_PRODUCTS:
      return {
        ...state,
        products: state.products.filter((product) => !action.payload.includes(product.id)),
      };
    case CHANGE_PRODUCT_KEY_VALUE:
      state.product[action.payload.key] = action.payload.value;
      return {
        ...state,
        product: state.product,
      };
    case GET_PRODUCT_ALBUM:
      return {
        ...state,
        product_album: action.payload,
      };
    case GET_PRODUCT_MEDIA:
      return {
        ...state,
        product_media: action.payload,
      };
    case CHANGE_PRODUCT_MEDIA_KEY_VALUE:
      state.product_media[action.payload.key] = action.payload.value;
      return {
        ...state,
        product_media: state.product_media,
      };
    case GET_PRODUCT_CATEGORIES:
      return {
        ...state,
        product_categories: action.payload,
      };
    // APPOINTMENTS
    case ADD_APPOINTMENT:
      return {
        ...state,
        appointments: [action.payload, ...state.appointments],
      };
    case GET_APPOINTMENT:
      return {
        ...state,
        appointment: action.payload ? action.payload : state.appointment,
      };
    case GET_APPOINTMENTS:
      return {
        ...state,
        appointments: action.payload,
      };
    case GET_APPOINTMENT_FORMS:
      return {
        ...state,
        appointment_forms: action.payload,
      };
    case UPDATE_APPOINTMENT:
      return {
        ...state,
        appointments: state.appointments.map((appointment) => (appointment.id === action.payload.id ? action.payload : appointment)),
      };
    case DELETE_APPOINTMENTS:
      return {
        ...state,
        appointments: state.appointments.filter((appointment) => !action.payload.includes(appointment.id)),
      };
    case CHANGE_APPOINTMENT_KEY_VALUE:
      state.appointment[action.payload.key] = action.payload.value;
      return {
        ...state,
        appointment: state.appointment,
      };
    case GET_BOOKED_APPOINTMENTS:
      return {
        ...state,
        booked_appointments: action.payload,
      };
    // HOURS
    case ADD_HOUR:
      return {
        ...state,
        business_hours: [...state.business_hours, action.payload],
      };
    case GET_HOUR:
      return {
        ...state,
        business_hour: action.payload,
      };
    case GET_HOURS:
      return {
        ...state,
        business_hours: action.payload,
      };
    case UPDATE_HOUR:
      return {
        ...state,
        business_hours: state.business_hours.map((hour) => (hour.id === action.payload.id ? action.payload : hour)),
      };
    case DELETE_HOURS:
      return {
        ...state,
        business_hours: state.business_hours.filter((hour) => !action.payload.includes(hour.id)),
      };
    case CHANGE_HOUR_KEY_VALUE:
      state.business_hour[action.payload.key] = action.payload.value;
      return {
        ...state,
        hour: state.business_hour,
      };
    // CHECKIN APP
    case GET_CHECKINAPP:
      return {
        ...state,
        checkinapp: action.payload ? action.payload : state.checkinapp,
      };
    case CHANGE_CHECKINAPP_KEY_VALUE:
      state.checkinapp[action.payload.key] = action.payload.value;
      return {
        ...state,
        checkinapp: state.checkinapp,
      };
    case GET_CHECKINAPP_MEDIA:
      return {
        ...state,
        checkinapp_media: action.payload ? action.payload : state.checkinapp,
      };
    // FORMS
    case ADD_FORM:
      return {
        ...state,
        forms: [action.payload, ...state.forms],
      };
    case GET_FORM: {
      return {
        ...state,
        form: action.payload,
      };
    }
    case GET_FORMS:
      return {
        ...state,
        forms: action.payload,
      };
    case UPDATE_FORM:
      return {
        ...state,
        forms: state.forms.map((form) => (form.id === action.payload.id ? action.payload : form)),
      };
    case UPLOAD_FORM:
      return {
        ...state,
        forms: [action.payload, ...state.forms],
      };
    case DELETE_FORMS:
      return {
        ...state,
        forms: state.forms.filter((form) => !action.payload.includes(form.id)),
      };
    case CHANGE_FORM_KEY_VALUE:
      state.form[action.payload.key] = action.payload.value;
      return {
        ...state,
        form: state.form,
      };
    // CHECKINAPP TOKENS
    case GET_CHECKINAPP_TOKENS:
      return {
        ...state,
        checkinapp_tokens: action.payload,
      };
    default:
      return state;
  }
}
