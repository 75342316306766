import React, { Component } from 'react';
import { connect } from 'react-redux';
import CheckinAppForm from './forms/CheckinAppForm';
import ModalDepot from './components/ModalDepot';
import { authenticateCheckinApp, getCheckIn } from '../../redux/actions/businessActions';
import { getPublicEnv } from './../../redux/actions/siteActions';
import { FcExpired } from 'react-icons/fc';
import { FcInspection, FcSurvey } from 'react-icons/fc';
import dateFormat from 'dateformat';
import { Button } from 'react-bootstrap';
import compareTime from './../../functions/compareTime';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'CHECKINAPP_MOBILE',
      isSplashModalShow: false,
      isCheckinAppAuthenticated: false,
      isCheckinMobileAuthenticated: false,
      expireMinuteLimit: 300,
    };
    this.openModal = this.openModal.bind(this);
    this.onAuthenticateCheckinApp = this.onAuthenticateCheckinApp.bind(this);
  }
  componentDidMount = async () => {
    // compare check in timestamp with current timestamp, authenticate pass only if it has not reached expired time limit
    this.props.getPublicEnv();
    if (localStorage.getItem('localStorageCheckinTimeStamp')) {
      const timeStarted = new Date(localStorage.getItem('localStorageCheckinTimeStamp'));
      const timeNow = new Date();
      const timeDifferenceInMinutes = compareTime(timeStarted, timeNow, 'minute');

      if (timeDifferenceInMinutes <= this.state.expireMinuteLimit) {
        if (localStorage.getItem('localStorageCheckinPublicToken')) {
          const publicToken = localStorage.getItem('localStorageCheckinPublicToken');
          this.props.authenticateCheckinApp(publicToken, this);
        }
        await this.setState({ isCheckinMobileAuthenticated: true });
      } else {
        await this.setState({ isCheckinMobileAuthenticated: false });
      }
      // get the checkin id to display for success page
      const checkinID = localStorage.getItem('localStorageCheckinAppCheckinID');
      if (checkinID) {
        await this.props.getCheckIn(checkinID, this);
      }
    } else {
      this.setState({ isCheckinMobileAuthenticated: false });
    }
  };
  openModal(modalName, modalState) {
    switch (modalName) {
      case 'SPLASH_SCREEN':
        modalState ? this.setState({ isSplashModalShow: true }) : this.setState({ isSplashModalShow: false });
        break;
      default:
    }
  }
  onAuthenticateCheckinApp(isAuthenticated) {
    this.setState({ isCheckinMobileAuthenticated: isAuthenticated });
  }
  render() {
    if (this.state.isCheckinMobileAuthenticated) {
      if (localStorage.getItem('localStorageCheckinAppMobileCheckinSuccess')) {
        return (
          <>
            <div className='display-flex flex-direction-column align-items-flex-start'>
              <div className='margin-bottom-50'></div>
              <div className='footer-sticky-top bg-white display-flex flex-direction-column align-items-center justify-content-center padding border-bottom'>
                <div className='font-size-large'>
                  {this.props.business.checkinapp && this.props.business.checkinapp.business
                    ? this.props.business.checkinapp.business.business_name
                    : ''}
                </div>
                {/* <div>{this.props.business.checkinapp.full_address}</div> */}
              </div>
              <div className='display-flex flex-direction-column align-items-center justify-content-center'>
                <div className='flex-grow-1 padding border-radius-5'>
                  <FcInspection size={100} />
                </div>
                <div className='flex-grow-1'>
                  <center>
                    <div className='margin-top-15 margin-bottom-15'>
                      <div className='font-size-medium'>Thank You for Checking In!</div>
                    </div>
                  </center>
                </div>
                <div className='margin-bottom-15'>{this.props.business.checkinapp.greeting}</div>
                <div className='display-flex padding-5 bg-whitesmoke'></div>
                <div className='display-flex flex-direction-column padding align-items-center justify-content-center'>
                  <div className='font-size-medium margin-bottom-15 font-weight-bold'>
                    {this.props.business.checkin.first_name} {this.props.business.checkin.last_name}
                  </div>
                  <div className='font-size-medium'>{dateFormat(this.props.business.checkin.created_time * 1000, 'mm/dd/yy - h:MM TT')}</div>
                </div>
                <div className='display-flex padding-5 bg-whitesmoke'></div>
                <div className='padding'>Check your place on the list</div>
                <div className='margin-bottom-50'>
                  <Button
                    variant='secondary'
                    onClick={() => {
                      this.props.history.push('/ck/' + this.props.business.checkin.public_id);
                    }}
                  >
                    <FcSurvey size={30} className='color-deepskyblue' />
                    Your Position
                  </Button>
                </div>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className='display-flex flex-direction-column align-items-flex-start justify-content-center'>
              <CheckinAppForm state={this.state} props={this.props} />
            </div>
            <ModalDepot
              state={this.state}
              props={this.props}
              function={{ openModal: this.openModal, onAuthenticateCheckinApp: this.onAuthenticateCheckinApp }}
            />
          </>
        );
      }
    } else {
      return (
        <>
          <div className='display-flex justify-content-center align-items-center padding'>
            <div className='padding'>
              <div className='margin-top-25 margin-bottom-50'>
                <center>
                  <FcExpired size='100' color='#f00' />
                </center>
              </div>
              <center>
                <div className='font-size-large margin-bottom-15'>Check-in &amp; Waitlist Expired</div>
                <div className='margin-bottom-50'>To get back in line, please scan the QR code located at the destination's front desk.</div>
              </center>
            </div>
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  business: state.business,
  site: state.site,
});
export default connect(mapStateToProps, { authenticateCheckinApp, getCheckIn, getPublicEnv })(AppComponent);
