import React, { Component } from 'react';
import { Form, Button, OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import { connect } from 'react-redux';
import { MdDeleteForever, MdHelp } from 'react-icons/md';
import TextField from '@material-ui/core/TextField';

import {
  getForms,
  updateCheckinApp,
  uploadForm,
  changeCheckinAppKeyValue,
  getCheckinAppMedia,
  removeCheckinAppMedia,
} from './../../../redux/actions/businessActions';
import { clearErrors } from '../../../redux/actions/siteActions';
import ModalDepot from '../components/ModalDepot';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'CHECKINAPP_CUSTOMIZE_FORM',
      selectedFile: null,
      isAddCheckinMediaModalShow: false,
      isDeleteCheckinMediaModalShow: false,
    };
    this.onUpdateCheckinApp = this.onUpdateCheckinApp.bind(this);
    this.openModal = this.openModal.bind(this);
    this.clearUploadField = this.clearUploadField.bind(this);
    this.onCancelUpload = this.onCancelUpload.bind(this);
    this.onGetCheckinAppMedia = this.onGetCheckinAppMedia.bind(this);
    this.onRemoveCheckinAppMedia = this.onRemoveCheckinAppMedia.bind(this);
  }
  componentDidMount() {
    this.props.getForms(this.props.users.token.business_id);
    if (this.props.business.checkinapp.media_id) {
      this.props.getCheckinAppMedia(this.props.business.checkinapp.media_id, this);
    }
  }
  openModal(modalName, modalState, modalData) {
    switch (modalName) {
      case 'CHECKINAPP_MEDIA_ADD':
        modalState ? this.setState({ isAddCheckinMediaModalShow: true }) : this.setState({ isAddCheckinMediaModalShow: false });
        break;
      case 'CHECKINAPP_MEDIA_DELETE':
        modalState ? this.setState({ isDeleteCheckinMediaModalShow: true }) : this.setState({ isDeleteCheckinMediaModalShow: false });
        break;
      default:
    }
  }
  onUploadProgress = () => {
    this.setState({ isUploadProgress: true });
  };
  onCancelUpload = () => {
    this.setState({ isUploadProgress: false });
  };
  onChangeCheckinAppKeyValue(id, key, value) {
    const data = {
      id,
      key,
      value,
    };
    this.props.changeCheckinAppKeyValue(data);
  }
  onGetCheckinAppMedia(mediaID) {
    this.props.getCheckinAppMedia(mediaID, this);
  }
  onUpdateCheckinApp(e) {
    e.preventDefault();
    const data = {
      checkinapp: this.props.props.props.business.checkinapp,
      token: this.props.users.token,
    };
    this.props.updateCheckinApp(data, this);
  }
  clearUploadField() {
    this.uploadInput.value = '';
  }
  onRemoveCheckinAppMedia() {
    const data = {
      businessID: this.props.users.token.business_id,
    };
    this.props.removeCheckinAppMedia(data, this);
  }
  render() {
    const { forms, checkinapp, checkinapp_media } = this.props.business;
    const { errors } = this.props.site;
    return (
      <>
        <div>
          <div className='display-flex flex-direction-column'>
            <div className='display-flex flex-direction-column'>
              <div className='font-weight-bold margin-top-15 margin-bottom-15'>Business Name: Font and Size</div>
              <Form.Control
                as='select'
                name='fontFamily'
                onChange={(e) => this.onChangeCheckinAppKeyValue(0, 'font_family', e.target.value)}
                value={checkinapp.font_family ? checkinapp.font_family : ''}
              >
                <option value=''>Select Font. Use Slider to Change Size</option>
                <option value='AlexBrush' style={{ fontFamily: 'AlexBrush' }}>
                  AlexBrush
                </option>
                <option value='ProximaNova' style={{ fontFamily: 'ProximaNova' }}>
                  Sans
                </option>
                <option value='PoiretOne' style={{ fontFamily: 'PoiretOne' }}>
                  Modern
                </option>
                <option value='Yellowtail' style={{ fontFamily: 'Yellowtail' }}>
                  Penmanship
                </option>
              </Form.Control>
              <RangeSlider
                min={45}
                max={100}
                name='fontSize'
                value={checkinapp.font_size}
                onChange={(e) => this.onChangeCheckinAppKeyValue(0, 'font_size', Number(e.target.value))}
              />
            </div>
            <div className='display-flex justify-content-space-between align-items-center margin-bottom-15'>
              <div className='font-weight-bold'>
                Logo{' '}
                <OverlayTrigger
                  placement='top'
                  overlay={<Tooltip>Having a logo will replace the business name. Max dimension height: 150px</Tooltip>}
                >
                  <span className='white-space-nowrap'>
                    <MdHelp size={20} className='color-gray' />
                  </span>
                </OverlayTrigger>
              </div>
              <Button variant='secondary' onClick={() => this.openModal('CHECKINAPP_MEDIA_ADD', true)}>
                Upload logo
              </Button>
              {checkinapp_media && checkinapp_media.id ? (
                <Button
                  variant='secondary'
                  onClick={() => {
                    this.openModal('CHECKINAPP_MEDIA_DELETE', true);
                  }}
                >
                  <MdDeleteForever size={20} className='color-tomato' />
                </Button>
              ) : (
                ''
              )}
            </div>
            {checkinapp.media && checkinapp.media.id ? (
              <>
                <img
                  alt='checkinapp_preview'
                  src={checkinapp.media.preview}
                  className='border-radius-5 margin-bottom-15'
                  style={{ display: 'block', maxHeight: '150px', width: '100%', height: '100%', objectFit: 'contain' }}
                />
              </>
            ) : (
              ''
            )}
            <div className='display-flex align-items-center justify-content-center margin-bottom-15'>
              <div className='display-flex font-weight-bold margin-right-15'>
                Header Text
                <OverlayTrigger placement='top' overlay={<Tooltip>Display custom notes for customers on your check-in page</Tooltip>}>
                  <span className='white-space-nowrap'>
                    <MdHelp size={20} className='color-gray' />
                  </span>
                </OverlayTrigger>
              </div>
              <div className='flex-grow-1'>
                <TextField
                  label='Header Text'
                  multiline
                  rows='3'
                  name='headerText'
                  margin='normal'
                  variant='outlined'
                  value={
                    this.props.props.props.business.checkinapp.header_text
                      ? this.props.props.props.business.checkinapp.header_text
                      : 'Welcome, Please Check In'
                  }
                  onChange={(e) => this.onChangeCheckinAppKeyValue(0, 'header_text', e.target.value)}
                  InputLabelProps={{ style: { fontSize: 17 }, shrink: true }} // font size of input label
                  className='display-flex'
                />
              </div>
            </div>
            <div className='display-flex align-items-center justify-content-center margin-bottom-15'>
              <div className='display-flex font-weight-bold margin-right-15'>
                Post Greeting
                <OverlayTrigger placement='top' overlay={<Tooltip>Message will display to customer after check-in</Tooltip>}>
                  <span className='white-space-nowrap'>
                    <MdHelp size={20} className='color-gray' />
                  </span>
                </OverlayTrigger>
              </div>
              <div className='flex-grow-1'>
                <Form.Control
                  type='text'
                  name='greeting'
                  placeholder='Thank you! We will be with you shortly.'
                  value={
                    this.props.props.props.business.checkinapp.greeting
                      ? this.props.props.props.business.checkinapp.greeting
                      : 'Thank you! We will be with you shortly.'
                  }
                  onChange={(e) => this.onChangeCheckinAppKeyValue(0, 'greeting', e.target.value)}
                />
              </div>
            </div>
            <div className='display-flex flex-direction-row'>
              <div className='font-weight-bold margin-top-15 margin-bottom-15'>Preferences</div>
              <div className='display-flex flex-wrap-nowrap align-items-center'>
                <input
                  type='checkbox'
                  name='formEnabled'
                  onChange={(e) => {
                    this.onChangeCheckinAppKeyValue(0, 'form_enabled', Number(!this.props.props.props.business.checkinapp.form_enabled));
                  }}
                  value={this.props.props.props.business.checkinapp.form_enabled === 1 ? 1 : 0}
                  checked={this.props.props.props.business.checkinapp.form_enabled === 1 ? 1 : 0}
                  className='largerCheckbox margin-right-15'
                />
                Include Form
              </div>
              <div>
                {this.props.props.props.business.checkinapp.form_enabled ? (
                  <>
                    <div className='display-flex margin-15'>
                      <Form.Control
                        as='select'
                        name='formID'
                        onChange={(e) => {
                          this.onChangeCheckinAppKeyValue(0, 'form_id', Number(e.target.value));
                        }}
                        value={this.props.props.props.business.checkinapp.form_id ? this.props.props.props.business.checkinapp.form_id : 1}
                        className='flex-1'
                      >
                        <option value='1'>Default Form</option>
                        {forms.map((form, i) => {
                          return (
                            <option key={i} value={form.id}>
                              {form.name}
                            </option>
                          );
                        })}
                      </Form.Control>
                      <Button
                        variant='secondary'
                        onClick={() => (window.location.href = '/account/forms?action=add-form')}
                        style={{ marginLeft: '10px' }}
                      >
                        New
                      </Button>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
              <div className='display-flex flex-wrap-nowrap align-items-center margin-top-15'>
                <input
                  type='checkbox'
                  name='partyEnabled'
                  onChange={(e) => {
                    this.onChangeCheckinAppKeyValue(0, 'party_enabled', Number(!this.props.props.props.business.checkinapp.party_enabled));
                  }}
                  value={this.props.props.props.business.checkinapp.party_enabled === 1 ? 1 : 0}
                  checked={this.props.props.props.business.checkinapp.party_enabled === 1 ? 1 : 0}
                  className='largerCheckbox margin-right-15'
                />
                Include Party Size Selection
              </div>
              <div className='display-flex flex-wrap-nowrap align-items-center margin-top-15'>
                <input
                  type='checkbox'
                  name='serviceEnabled'
                  onChange={(e) => {
                    this.onChangeCheckinAppKeyValue(0, 'service_enabled', Number(!this.props.props.props.business.checkinapp.service_enabled));
                  }}
                  value={this.props.props.props.business.checkinapp.service_enabled === 1 ? 1 : 0}
                  checked={this.props.props.props.business.checkinapp.service_enabled === 1 ? 1 : 0}
                  className='largerCheckbox margin-right-15'
                />{' '}
                Include Products &amp; Services Selection
              </div>
              <div className='display-flex flex-wrap-nowrap align-items-center margin-top-15'>
                <input
                  type='checkbox'
                  name='staffEnabled'
                  onChange={(e) => {
                    this.onChangeCheckinAppKeyValue(0, 'staff_enabled', Number(!this.props.props.props.business.checkinapp.staff_enabled));
                  }}
                  value={this.props.props.props.business.checkinapp.staff_enabled === 1 ? 1 : 0}
                  checked={this.props.props.props.business.checkinapp.staff_enabled === 1 ? 1 : 0}
                  className='largerCheckbox margin-right-15'
                />{' '}
                Include Staff Selection
              </div>
              <div className='display-flex flex-wrap-nowrap align-items-center margin-top-15'>
                <input
                  type='checkbox'
                  name='notesEnabled'
                  onChange={(e) => {
                    this.onChangeCheckinAppKeyValue(0, 'notes_enabled', Number(!this.props.props.props.business.checkinapp.notes_enabled));
                  }}
                  value={this.props.props.props.business.checkinapp.notes_enabled === 1 ? 1 : 0}
                  checked={this.props.props.props.business.checkinapp.notes_enabled === 1 ? 1 : 0}
                  className='largerCheckbox margin-right-15'
                />
                Include Notes Input
              </div>
              {/* <div className='display-flex flex-wrap-nowrap align-items-center margin-top-15'>
                <input
                  type='checkbox'
                  name='tempEnabled'
                  onChange={(e) => {
                    this.onChangeCheckinAppKeyValue(0, 'temp_enabled', Number(!this.props.props.props.business.checkinapp.temp_enabled));
                  }}
                  value={this.props.props.props.business.checkinapp.temp_enabled === 1 ? 1 : 0}
                  checked={this.props.props.props.business.checkinapp.temp_enabled === 1 ? 1 : 0}
                  className='largerCheckbox margin-right-15'
                />
                Allow Temperature Input
              </div> */}
              <div className='font-weight-bold margin-bottom-15 margin-top-15'>Features</div>
              <div className='display-flex flex-wrap-nowrap align-items-center'>
                <input
                  type='checkbox'
                  onChange={(e) => {
                    this.onChangeCheckinAppKeyValue(
                      0,
                      'auto_feedback_enabled',
                      Number(!this.props.props.props.business.checkinapp.auto_feedback_enabled)
                    );
                  }}
                  value={this.props.props.props.business.checkinapp.auto_feedback_enabled === 1 ? 1 : 0}
                  checked={this.props.props.props.business.checkinapp.auto_feedback_enabled === 1 ? 1 : 0}
                  className='largerCheckbox margin-right-15'
                />{' '}
                Include Customer Feedback Request
                <OverlayTrigger
                  placement='top'
                  overlay={
                    <Tooltip>
                      Improve business performance through customer feedback.
                      <br />
                      <br />
                      When enabled, a request will be sent automatically to customers for feedback a few hours after their checked-in time.
                    </Tooltip>
                  }
                >
                  <span className='white-space-nowrap'>
                    <MdHelp size={20} className='color-gray' />
                  </span>
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <Alert
            variant='warning'
            show={errors.errorUpdateCheckinApp ? true : false}
            onClose={() => this.props.clearErrors()}
            dismissible
            style={{ padding: '10px', marginTop: '15px' }}
            className='display-flex'
          >
            {errors.errorUpdateCheckinApp}
          </Alert>
          <div className='display-flex margin-top-15'>
            <Button onClick={(e) => this.onUpdateCheckinApp(e)} style={{ width: '100%' }}>
              Save
            </Button>
          </div>
        </div>
        <ModalDepot
          state={this.state}
          props={this.props}
          function={{
            openModal: this.openModal,
            onCancelUpload: this.onCancelUpload,
            onUploadProgress: this.onUploadProgress,
            onGetCheckinAppMedia: this.onGetCheckinAppMedia,
            onRemoveCheckinAppMedia: this.onRemoveCheckinAppMedia,
          }}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  business: state.business,
  users: state.users,
  site: state.site,
});
export default connect(mapStateToProps, {
  clearErrors,
  updateCheckinApp,
  changeCheckinAppKeyValue,
  getForms,
  uploadForm,
  getCheckinAppMedia,
  removeCheckinAppMedia,
})(AppComponent);
