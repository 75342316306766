import React, { Component } from 'react';
import { Alert, Button, Form, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';

import { emailTokenLink } from '../../redux/actions/siteActions';
import { clearErrors, clearResponse } from './../../redux/actions/siteActions';
import isEmail from 'validator/lib/isEmail';
import { MdHttps, MdRefresh } from 'react-icons/md';

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      isValidEmail: false,
      email: '',
      isSendDisable: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.users.isAuthenticated) {
      this.props.history.push('/account/dashboard');
    }
  }
  onChange(e) {
    this.setState({ email: e.target.value });
    if (isEmail(e.target.value)) {
      this.setState({ isValidEmail: true });
    } else {
      this.setState({ isValidEmail: false });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ isSendDisable: true });
    const data = {
      email: this.state.email,
      tokenType: 'password',
    };
    this.props.emailTokenLink(data, this);
  }
  render() {
    const { errors } = this.props.site;
    const { response } = this.props.site;
    return (
      <div className='display-flex bg-whitesmoke'>
        <Container>
          <center>
            <div className='container-box-small'>
              <MdHttps size='100' color='#019fe8' />
              <div className='font-size-large margin-bottom-15'>Forgot password?</div>
              <br />
              <br />
              <div className='color-gray font-size-medium'>We will send you instructions to reset your account.</div>
              <Form onSubmit={this.onSubmit} className='margin-top-15'>
                <Form.Group>
                  <TextField
                    label='Enter Your Email'
                    type='email'
                    name='email'
                    margin='normal'
                    variant='outlined'
                    onChange={this.onChange}
                    value={this.state.email}
                    inputProps={{ style: { fontSize: 17 } }} // font size of input text
                    InputLabelProps={{ style: { fontSize: 17 } }} // font size of input label
                    className='display-flex bg-white color-white'
                  />
                  <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
                </Form.Group>
                <Alert
                  variant={errors.message ? 'warning' : 'success'}
                  show={errors.message || response.message ? true : false}
                  onClose={() => {
                    if (errors.message) {
                      this.props.clearErrors();
                    } else {
                      this.props.clearResponse();
                    }
                    this.setState({ isSendDisable: false });
                  }}
                  dismissible
                >
                  {errors.message ? errors.message : response.message ? response.message : ''}
                </Alert>
                <Button
                  type='submit'
                  disabled={!this.state.isValidEmail ? true : this.state.isSendDisable ? true : false}
                  style={{ width: '100%' }}
                  className='btn btn-primary'
                >
                  {this.state.isSendDisable ? <MdRefresh size='30' className='processing' /> : ''} Send Reset Instructions
                </Button>
              </Form>
              <br />
              <span className='color-gray font-size-medium'>
                Remember your password? <a href='/login'>Log in</a>
              </span>
            </div>
          </center>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  site: state.site,
  users: state.users,
});
export default connect(mapStateToProps, { emailTokenLink, clearErrors, clearResponse })(ForgotPassword);
