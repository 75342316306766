import React, { Component } from 'react';
import { Button, Form, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';

import { checkUserVerification } from '../../redux/actions/userActions';
import isEmail from 'validator/lib/isEmail';
import logo from './../../assets/images/logo.png';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      isValidEmail: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.emailInput.focus();
    if (this.props.users.isAuthenticated) {
      this.props.history.push('/account/dashboard');
    }
    if (localStorage.getItem('localStorageUserEmail')) {
      if (localStorage.getItem('localStorageUserEmail') === 'undefined') {
        this.setState({ email: '', isValidEmail: false });
      } else {
        this.setState({ email: localStorage.getItem('localStorageUserEmail'), isValidEmail: true });
      }
    }
  }
  onChange(e) {
    this.setState({ email: e.target.value });
    if (isEmail(e.target.value)) {
      this.setState({ isValidEmail: true });
    } else {
      this.setState({ isValidEmail: false });
    }
  }
  onSubmit(e) {
    e.preventDefault();
    const data = {
      email: this.state.email,
    };
    this.props.checkUserVerification(data);
  }

  render() {
    return (
      <div className='display-flex bg-whitesmoke'>
        <Container>
          <center>
            <div className='container-box-small'>
              <div
                className='display-flex align-items-center justify-content-center padding margin-15 bg-white box-shadow-light'
                style={{ borderRadius: '50%', width: '150px', height: '150px' }}
              >
                <a href='/'>
                  <img src={logo} className='logo-static' alt='logo' />
                </a>
              </div>
              <div className='font-size-large margin-bottom-15'>Hi there, let's sign up!</div>
              <Form onSubmit={(e) => this.onSubmit(e)}>
                <TextField
                  label='Enter Your Email'
                  ref={(input) => (this.emailInput = input)}
                  type='email'
                  name='email'
                  margin='normal'
                  variant='outlined'
                  onChange={this.onChange}
                  value={this.state.email}
                  inputProps={{ style: { fontSize: 17 } }} // font size of input text
                  InputLabelProps={{ style: { fontSize: 17 } }} // font size of input label
                  className='display-flex bg-white'
                />
                <Button
                  type='submit'
                  disabled={this.state.isValidEmail ? false : true}
                  style={{ width: '100%', marginTop: '15px' }}
                  className='btn btn-primary'
                >
                  <span>Next</span>
                </Button>
              </Form>
              <br />
              <span>
                <a href='/login'>Already have an account?</a>
              </span>
            </div>
          </center>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps, { checkUserVerification })(Login);
