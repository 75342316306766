import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { MdAddCircle, MdCheckCircle, MdShoppingBasket } from 'react-icons/md';
import { FiBox } from 'react-icons/fi';
import ModalDepot from './../../components/ModalDepot';
import Truncate from 'react-truncate';

import { getProducts } from '../../../../redux/actions/businessActions';
import { clearErrors } from './../../../../redux/actions/siteActions';

class AppComponent extends PureComponent {
  constructor(props) {
    super();
    this.state = {
      componentID: 'PRODUCT_SELECT_TABLE',
      currentPage: 1,
      currentPageSize: 1000,
      isAddProductModalShow: false,
      isViewProductAlbumModalShow: false,
      isSubmitDisabled: true,
      expandedText: [],
      truncateText: [],
      selectedProductIDsArray: props.props.state.selectedProductIDsArray ? props.props.state.selectedProductIDsArray : [],
      selectedProducts: props.props.state.selectedProducts
        ? props.props.state.selectedProducts
        : props.props.props
        ? props.props.props.business.checkin.selectedProducts
        : [],
      selectedProduct: {},
    };
    this.openModal = this.openModal.bind(this);
    this.toggleProductDescription = this.toggleProductDescription.bind(this);
  }
  componentDidMount() {
    this.props.getProducts(this.props.users.token.business_id);
  }
  openModal = (modalName, modalState, modalData) => {
    switch (modalName) {
      case 'PRODUCT_ADD':
        if (modalState) {
          this.setState({ isAddProductModalShow: true });
        } else {
          this.setState({ isAddProductModalShow: false });
          this.props.clearErrors();
        }
        break;
      case 'PRODUCT_VIEW_ALBUM':
        if (modalState) {
          this.setState({ isViewProductAlbumModalShow: true });
        } else {
          this.setState({ isViewProductAlbumModalShow: false });
          this.props.clearErrors();
        }
        break;
      default:
    }
  };
  onSelect = async (row, isSelect) => {
    // if (isSelect) {
    //   await this.setState({ selectedProducts: [...this.state.selectedProducts, e] });
    // } else {
    //   await this.setState({ selectedProducts: this.state.selectedProducts.filter((product) => product.id !== e.id) });
    // }
    if (isSelect) {
      this.setState(() => ({
        selectedProductIDsArray: [...this.state.selectedProductIDsArray, row.id],
        selectedProducts: [...this.state.selectedProducts, row],
      }));
      if (this.props.token) {
        this.setState({ selectedProduct: row });
        this.openModal('PRODUCT_UPDATE', true);
      }
    } else {
      this.setState(() => ({
        selectedProductIDsArray: this.state.selectedProductIDsArray.filter((id) => id !== row.id),
        selectedProducts: this.state.selectedProducts.filter((product) => product.id !== row.id),
      }));
      if (this.props.token) {
        this.setState({ selectedProduct: row });
        this.openModal('PRODUCT_UPDATE', true);
      }
    }
  };
  toggleProductDescription = async (i) => {
    let expandedTexts = [...this.state.expandedText];
    let expandText = !expandedTexts[i];
    expandedTexts[i] = expandText;
    await this.setState({ expandedText: expandedTexts });
  };
  render() {
    const { products } = this.props.business;
    const { expandedText, truncateText } = this.state;
    const { SearchBar } = Search;
    var productCategoryID = '';
    const columns = [
      {
        dataField: 'search_fields',
        text: '',
        align: 'left',
        formatter: (cell, row, i) => {
          let categoryHeader = '';
          let categorySubheading = '';
          let nonCategoryHeader = null;
          if (productCategoryID !== row.category.id) {
            productCategoryID = row.category.id;
            categoryHeader = row.category.value;
            categorySubheading = row.category.subheading;
            if (!row.category_id) {
              nonCategoryHeader = true;
            }
          }

          // let categoryHeader = '';
          // let categorySubheading = '';
          // let nonCategoryHeader = null;
          // // if the product category is not in array, push to the array and display it once
          // if (!productCategories.includes(row.category.id)) {
          //   productCategories.push(row.category.id);
          //   categoryHeader = row.category.value;
          //   categorySubheading = row.category.subheading;
          //   if (!row.category_id) {
          //     nonCategoryHeader = true;
          //   }
          // }

          var price = [];
          if (row.price.includes('.')) {
            price = row.price.split('.');
            if (price[0] === '') {
              price[0] = 0;
            } else {
              price[0].toLocaleString();
            }
          } else {
            price[0] = row.price;
          }

          const ProductImage = React.memo(function Image({ props }) {
            return <img alt='product_thumbnail' src={props.product_media_thumbnail} width='60px' className='thumbnail border-radius-5' />;
          });
          return (
            <>
              {categoryHeader ? (
                <div className='display-flex padding-bottom-15 padding-top-15 padding-left-15 border-bottom-gray bg-whitesmoke border-radius-5'>
                  <div className='display-flex  font-size-medium'>{categoryHeader}</div>
                  {categorySubheading ? <div className='display-flex margin-top-5 color-gray'>{categorySubheading}</div> : ''}
                </div>
              ) : nonCategoryHeader ? (
                <div className='display-flex align-items-center justify-content-space-between padding-bottom-15 padding-top-15 font-size-small border-bottom-gray'></div>
              ) : (
                ''
              )}
              <div className='display-flex flex-direction-column padding-10 cursor-default'>
                <div className='display-flex color-black'>
                  <div className='display-flex flex-direction-row flex-wrap-nowrap'>
                    {row.product_media_source ? (
                      <div>
                        <a
                          href='#!'
                          onClick={(e) => {
                            e.stopPropagation(); // prevent click through on select row or rowEvents
                            this.setState({ selectedProduct: row });
                            this.openModal('PRODUCT_VIEW_ALBUM', true);
                          }}
                        >
                          <div style={{ width: 'fit-content' }}>
                            <ProductImage props={row} />
                          </div>
                        </a>
                      </div>
                    ) : (
                      <div className='padding-5' style={{ width: 'fit-content' }}>
                        <FiBox size={38} className='color-white' />
                      </div>
                    )}
                    <div className='display-flex justify-content-space-between align-items-center margin-left-15'>
                      <div className='display-flex justify-content-space-between padding-right-10 padding-bottom-5'>
                        <div className='color-black'>{row.name} </div>
                        {row.price ? (
                          <div>
                            <span>
                              {row.time ? row.time + ' minutes |' : ''}
                              <sup className='font-size-smaller'>$</sup>
                              <span className='font-size-medium-large color-black'>{price[0]}</span>
                              <sup className='font-size-smaller'>{price[1]}</sup>
                            </span>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div>
                        <div className='display-flex padding-right-10 text-align-left font-size-small'>
                          <Truncate
                            lines={!expandedText[i] && 1}
                            ellipsis={
                              <span>
                                ...{' '}
                                <a
                                  href='#!'
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.toggleProductDescription(i);
                                  }}
                                >
                                  Show more
                                </a>
                              </span>
                            }
                            className='color-gray'
                          >
                            <div className='react-quill' dangerouslySetInnerHTML={{ __html: row.description ? row.description : '' }} />
                          </Truncate>
                          {!truncateText[i] && expandedText[i] && (
                            <span>
                              {' '}
                              <a
                                href='#!'
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.toggleProductDescription(i);
                                }}
                              >
                                Show less
                              </a>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <>
                          {this.state.selectedProductIDsArray.includes(row.id) ? (
                            <>
                              <ButtonGroup>
                                {this.state.selectedProduct.id === row.id ? (
                                  <Button
                                    variant='secondary'
                                    id={this.props.props.state.selectedPlan}
                                    onClick={(e) => {
                                      this.props.props.function.setProducts(this.state.selectedProducts);
                                      this.props.props.function.openModal('PRODUCTS_SELECT_TABLE', false);
                                    }}
                                  >
                                    <span className='white-space-nowrap'>Done</span>
                                  </Button>
                                ) : (
                                  ''
                                )}
                                <Button
                                  variant='primary'
                                  onClick={(e) => {
                                    e.stopPropagation(); // prevent click through on select row or rowEvents
                                    this.setState(() => ({
                                      selectedProductIDsArray: this.state.selectedProductIDsArray.filter((id) => id !== row.id),
                                      selectedProducts: this.state.selectedProducts.filter((product) => product.id !== row.id),
                                      selectedProduct: { id: row.id },
                                    }));
                                  }}
                                >
                                  <MdCheckCircle size={20} />
                                </Button>
                              </ButtonGroup>
                            </>
                          ) : (
                            <>
                              <ButtonGroup>
                                {this.state.selectedProduct.id === row.id ? (
                                  <>
                                    <Button
                                      variant='secondary'
                                      id={this.props.props.state.selectedPlan}
                                      onClick={(e) => {
                                        this.props.props.function.setProducts(this.state.selectedProducts);
                                        this.props.props.function.openModal('PRODUCTS_SELECT_TABLE', false);
                                      }}
                                    >
                                      <span className='white-space-nowrap'>Done</span>
                                    </Button>
                                  </>
                                ) : (
                                  ''
                                )}
                                <Button
                                  variant='secondary'
                                  onClick={(e) => {
                                    e.stopPropagation(); // prevent click through on select row or rowEvents
                                    this.setState(() => ({
                                      selectedProductIDsArray: [...this.state.selectedProductIDsArray, row.id],
                                      selectedProducts: [...this.state.selectedProducts, row],
                                      selectedProduct: { id: row.id },
                                    }));
                                  }}
                                >
                                  <div className='display-flex flex-wrap-nowrap align-items-center justify-content-center'>Select</div>
                                </Button>
                              </ButtonGroup>
                            </>
                          )}
                        </>
                      </div>
                      {/* <ProductOptions row={row} /> */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        },
      },
    ];
    const paginationOptions = {
      onPageChange: (page, sizePerPage) => {
        this.setState({ currentPage: page });
        this.setState({ currentPageSize: sizePerPage });
      },
      page: this.state.currentPage,
      sizePerPage: this.state.currentPageSize,
      hideSizePerPage: true,
    };
    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: false,
      onSelect: (row, isSelect, rowIndex, e) => {
        this.props.function.onSelect(row, isSelect);
        this.onSelect(row, isSelect);
      },
      // onSelect: (row, isSelect, rowIndex, e) => {
      //   if (isSelect) {
      //     this.setState(() => ({
      //       selectedProductIDsArray: [...this.state.selectedProductIDsArray, row.id],
      //       selectedProducts: [...this.state.selectedProducts, row],
      //     }));
      //     if (this.props.token) {
      //       this.setState({ selectedProduct: row });
      //       this.openModal('PRODUCT_UPDATE', true);
      //     }
      //   } else {
      //     this.setState(() => ({
      //       selectedProductIDsArray: this.state.selectedProductIDsArray.filter((id) => id !== row.id),
      //       selectedProducts: this.state.selectedProducts.filter((product) => product.id !== row.id),
      //     }));
      //     if (this.props.token) {
      //       this.setState({ selectedProduct: row });
      //       this.openModal('PRODUCT_UPDATE', true);
      //     }
      //   }
      // },
      // selected: this.state.selectedProducts.map((product) => {
      //   return product.id;
      // }),
      selected: this.state.selectedProducts,
      hideSelectColumn: true,
      hideSelectAll: true,
    };
    const NoDataIndication = (props) => {
      if (props.searchProps.searchText.length > 0) {
        return (
          <div className='padding color-black'>
            Your search - <span className='font-weight-bold'>{props.searchProps.searchText}</span> - did not match any data.
          </div>
        );
      } else {
        return (
          <div className='padding display-flex justify-content-center align-items-center'>
            <center>
              <MdShoppingBasket size='200px' />
              <div className='font-size-large font-weight-bold margin-bottom-5'>No items Yet</div>
              <br />
              {this.props.token ? (
                <div>
                  <div className='margin-bottom-15'>Let customers see what you're offering.</div>
                  <br />
                  <Button variant='secondary' onClick={() => this.openModal('PRODUCT_ADD', true)}>
                    <MdAddCircle size={25} className='color-deepskyblue' />
                    Add Your First Item
                  </Button>
                </div>
              ) : (
                ''
              )}
            </center>
          </div>
        );
      }
    };
    const ProductsTable = (props) => {
      return (
        <>
          <BootstrapTable
            keyField='id'
            classes='products-table'
            headerClasses={props.props.baseProps.data.length > 0 ? '' : 'display-none'}
            {...props.props.baseProps}
            data={props.props.baseProps.data}
            columns={props.props.baseProps.columns}
            selectRow={selectRowProp}
            pagination={paginationFactory(paginationOptions)}
            noDataIndication={<NoDataIndication data={props.props.baseProps.data} searchProps={props.props.searchProps} />}
          />
        </>
      );
    };
    return (
      <>
        <ToolkitProvider keyField='id' data={products} columns={columns} search>
          {(toolkitprops) => {
            return (
              <div>
                <div className='display-flex flex-direction-column flex-wrap-nowrap'>
                  {this.props.props.state.componentID === 'APPOINTMENT_ADD_FORM' || this.props.props.state.componentID === 'CHECKIN_UPDATE_FORM' ? (
                    <>
                      <Button variant='secondary' onClick={() => this.openModal('PRODUCT_ADD', true)}>
                        <MdAddCircle size={25} className='color-deepskyblue' /> Add Item
                      </Button>
                    </>
                  ) : (
                    ''
                  )}
                  <div className='width-100 margin-top-15 margin-bottom-15'>
                    <SearchBar placeholder='Search Products &amp; Services' tableId='Search' {...toolkitprops.searchProps} />
                  </div>
                </div>
                <ProductsTable props={toolkitprops} />
              </div>
            );
          }}
        </ToolkitProvider>
        <ModalDepot state={this.state} props={this.props} function={{ openModal: this.openModal }} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  business: state.business,
  users: state.users,
  site: state.site,
});

export default connect(mapStateToProps, { getProducts, clearErrors })(AppComponent);
