import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { MdCheckCircle, MdShoppingBasket } from 'react-icons/md';
import ModalDepot from '../../components/ModalDepot';

import { getForms } from '../../../../redux/actions/businessActions';
import { clearErrors } from '../../../../redux/actions/siteActions';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'FORM_SELECT_TABLE',
      currentPage: 1,
      currentPageSize: 1000,
      isAddFormModalShow: false,
      isSubmitDisabled: true,
      selectedFormIDsArray: props.props.state.selectedFormIDsArray ? props.props.state.selectedFormIDsArray : [],
      selectedForms: props.props.state ? props.props.state.selectedForms : [],
      selectedForm: {},
    };
    this.openModal = this.openModal.bind(this);
  }
  componentDidMount() {
    this.props.getForms(this.props.users.token.business_id);
  }
  openModal = (modalName, modalState, modalData) => {
    switch (modalName) {
      case 'FORM_ADD':
        if (modalState) {
          this.setState({ isAddFormModalShow: true });
        } else {
          this.setState({ isAddFormModalShow: false });
          this.props.clearErrors();
        }
        break;
      default:
    }
  };
  onSelect = async (row, isSelect) => {
    if (isSelect) {
      await this.setState(() => ({
        selectedFormIDsArray: [...this.state.selectedFormIDsArray, row.id],
        selectedForms: [...this.state.selectedForms, row],
      }));
    } else {
      this.state.selectedForms.filter(async (form) => {
        if (form.id === row.id) {
          if (form.date_confirmed) {
            alert('Forms which has been viewed/signed by customers cannot be removed.');
          } else {
            await this.setState({ selectedForms: this.state.selectedForms.filter((form) => form.id !== row.id) });
          }
        }
      });
      if (this.props.token) {
        // this.setState({ selectedForm: row });
        // this.openModal('FROM_UPDATE', true);
      }
    }
  };
  render() {
    const { forms } = this.props.business;
    const { SearchBar } = Search;
    const paginationOptions = {
      onPageChange: (page, sizePerPage) => {
        this.setState({ currentPage: page });
        this.setState({ currentPageSize: sizePerPage });
      },
      page: this.state.currentPage,
      sizePerPage: this.state.currentPageSize,
      hideSizePerPage: true,
    };
    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      onSelect: (row, isSelect, rowIndex, e) => {
        // this.onSelect(row, isSelect);
        // this.props.function.onSelect(row, isSelect);
        // this.onSelect(row, isSelect);
      },
      selected: this.state.selectedForms,
      // style: { backgroundColor: '#e0e0e0' },
      hideSelectColumn: true,
      hideSelectAll: true,
    };
    const NoDataIndication = (props) => {
      if (props.searchProps.searchText.length > 0) {
        return (
          <div className='padding color-black'>
            Your search - <span className='font-weight-bold'>{props.searchProps.searchText}</span> - did not match any data.
          </div>
        );
      } else {
        return (
          <div className='padding display-flex justify-content-center align-items-center'>
            <center>
              <MdShoppingBasket size='200px' />
              <div className='font-size-large font-weight-bold margin-bottom-5'>No Forms Yet</div>
              <br />
              <div className='margin-bottom-15'>Adding your forms so you can select them for check-ins and appointments.</div>
              <br />
            </center>
          </div>
        );
      }
    };
    const columns = [
      {
        dataField: 'name',
        text: '',
        align: 'left',
        formatter: (cell, row) => {
          const RowSelectOption = (data) => {
            return (
              <div>
                <>
                  {this.state.selectedFormIDsArray.includes(data.row.id) ? (
                    <>
                      <ButtonGroup>
                        {this.state.selectedForm.id === data.row.id ? (
                          <Button
                            variant='secondary'
                            onClick={(e) => {
                              this.props.props.function.setForms(this.state.selectedForms);
                              this.props.props.function.openModal('FORMS_SELECT_TABLE', false);
                            }}
                          >
                            <span className='white-space-nowrap'>Done</span>
                          </Button>
                        ) : (
                          ''
                        )}
                        <Button
                          variant='primary'
                          onClick={(e) => {
                            e.stopPropagation(); // prevent click through on select row or rowEvents
                            this.setState(() => ({
                              selectedFormIDsArray: this.state.selectedFormIDsArray.filter((id) => id !== data.row.id),
                              selectedForms: this.state.selectedForms.filter((form) => form.id !== data.row.id),
                              selectedForm: { id: data.row.id },
                            }));
                          }}
                        >
                          <MdCheckCircle size={20} />
                        </Button>
                      </ButtonGroup>
                    </>
                  ) : (
                    <>
                      <ButtonGroup>
                        {this.state.selectedForm.id === data.row.id ? (
                          <>
                            <Button
                              variant='secondary'
                              onClick={(e) => {
                                this.props.props.function.setForms(this.state.selectedForms);
                                this.props.props.function.openModal('FORMS_SELECT_TABLE', false);
                              }}
                            >
                              <span className='white-space-nowrap'>Done</span>
                            </Button>
                          </>
                        ) : (
                          ''
                        )}
                        <Button
                          variant='secondary'
                          onClick={(e) => {
                            e.stopPropagation(); // prevent click through on select row or rowEvents
                            this.setState(() => ({
                              selectedFormIDsArray: [...this.state.selectedFormIDsArray, data.row.id],
                              selectedForms: [...this.state.selectedForms, data.row],
                              selectedForm: { id: data.row.id },
                            }));
                          }}
                        >
                          <div className='display-flex flex-wrap-nowrap align-items-center justify-content-center'>Select</div>
                        </Button>
                      </ButtonGroup>
                    </>
                  )}
                </>
              </div>
            );
          };

          return (
            <>
              <div className='display-flex flex-direction-column padding-10 cursor-default'>
                <div className='display-flex color-black font-size-smaller'>
                  <div className='display-flex justify-content-space-between align-items-center margin-left-15'>
                    <div className='font-size-medium color-black'>{row.name}</div>
                    <div>
                      <RowSelectOption row={row} />
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        },
      },
    ];
    const DataTable = () => {
      return (
        <>
          <div>
            <ToolkitProvider keyField='id' data={forms} columns={columns} search>
              {(toolkitprops) => {
                return (
                  <div>
                    <div className='display-flex flex-direction-column flex-wrap-nowrap justify-content-center align-items-center'>
                      <div className='width-100 margin-top-15'>
                        <SearchBar placeholder='Search' tableId='Search' {...toolkitprops.searchProps} />
                      </div>
                    </div>
                    <BootstrapTable
                      keyField='id'
                      classes='selectTable'
                      headerClasses={forms.length > 0 ? '' : 'display-none'}
                      {...toolkitprops.baseProps}
                      data={forms}
                      columns={columns}
                      selectRow={selectRow}
                      pagination={paginationFactory(paginationOptions)}
                      noDataIndication={<NoDataIndication data={forms} searchProps={toolkitprops.searchProps} />}
                    />
                  </div>
                );
              }}
            </ToolkitProvider>
          </div>
        </>
      );
    };
    return (
      <>
        <div>
          <DataTable />
          <ModalDepot state={this.state} props={this.props} function={{ openModal: this.openModal }} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  business: state.business,
  users: state.users,
  site: state.site,
});

export default connect(mapStateToProps, { getForms, clearErrors })(AppComponent);
