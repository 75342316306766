import React, { Component } from 'react';
import { connect } from 'react-redux';
import { QRCode } from 'react-qrcode-logo';
import { getBusiness } from '../../redux/actions/businessActions';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'PAGE_QR_CODE_PRINT',
    };
  }
  componentDidMount = () => {
    console.log('PROPSPSSS', this.props);
    this.props.getBusiness(this.props.users.token.business_id, this);
    window.print();
  };
  render() {
    const { business } = this.props.business;

    if (business.id) {
      return (
        <>
          <div className='display-flex flex-direction-column justify-content-center align-items-center'>
            <div className='border-radius-5'>
              <div
                className='padding bg-black color-white border-radius-top-25px'
                style={{ fontSize: localStorage.getItem('checkinQRprintsize') ? localStorage.getItem('checkinQRprintsize') * '12' : '3vw' }}
              >
                <center>Visit Our Page</center>
              </div>
              <div className='width-auto padding bg-white'>
                <center>
                  <QRCode
                    value={business.vanity_url}
                    qrStyle='dots'
                    size={localStorage.getItem('checkinQRprintsize') ? localStorage.getItem('checkinQRprintsize') * 125 : 250}
                    eyeRadius={[
                      [10, 0, 10, 10], // top left
                      [10, 0, 10, 10], // top right
                      [10, 0, 10, 10], // bottom left
                    ]}
                    // logoImage={this.props.business.media ? this.props.business.media.preview : ''}
                    // logoWidth={localStorage.getItem('checkinQRprintsize') ? (localStorage.getItem('checkinQRprintsize') * 125) / 2.5 : 250 / 2.5}
                    // logoHeight={
                    //   checkinapp.media
                    //     ? ((localStorage.getItem('checkinQRprintsize') ? (localStorage.getItem('checkinQRprintsize') * 125) / 2.5 : 250 / 2.5) *
                    //         checkinapp.media.height) /
                    //       checkinapp.media.width
                    //     : ''
                    // }
                  />
                </center>
              </div>
              <div className='shape-triangle-container'>
                <div className='shape-triangle-middle-top'></div>
              </div>
              <div
                className='padding bg-black color-white border-radius-bottom-25px'
                style={{
                  fontSize: localStorage.getItem('checkinQRprintsize') ? localStorage.getItem('checkinQRprintsize') * '6' : '3vw',
                  backgroundColor: 'blue !important',
                }}
              >
                <center>
                  For Digital Menu &amp; Services
                  <div className='font-size-large'>Scan Me</div>
                </center>
              </div>
              <div className='display-flex justify-content-center'>
                <div
                  className='padding margin-top-15'
                  style={{ fontSize: localStorage.getItem('checkinQRprintsize') ? localStorage.getItem('checkinQRprintsize') * '6' : '3vw' }}
                >
                  <div className='display-flex align-items-center justify-content-center font-size-medium font-weight-bold margin-top-15 margin-bottom-15'>
                    DIGITAL MENU &amp; SERVICES
                  </div>
                  1. Open the camera on your phone or download the QR scanner app
                  <br />
                  2. Aim it at the QR Code
                  <br />
                  3. Tap the banner that appears
                  <br />
                  4. Enjoy our menu &amp; services
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <center>
            <div className='display-flex flex-direction-column align-items-center justify-content-center height-100vh'>
              <div className='font-size-largest margin-bottom-50'>
                QR CODE PAGE RESTART REQUIRED.
                <br />
                <br />
                PLEASE CANCEL PRINT AND REFRESH THIS PAGE UNTIL YOU SEE THE QR CODE APPEAR ON YOUR PRINT PAGE.
              </div>
            </div>
          </center>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  business: state.business,
  users: state.users,
});
export default connect(mapStateToProps, { getBusiness })(AppComponent);
