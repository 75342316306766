import React, { Component, forwardRef } from 'react';
import { ButtonGroup, Button, ToggleButton, DropdownButton, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
// import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import {
  MdAirlineSeatReclineNormal,
  MdCheck,
  MdDoNotDisturb,
  MdCancel,
  MdPeople,
  MdNotificationsActive,
  MdSubject,
  MdShoppingBasket,
  MdTv,
} from 'react-icons/md';
import { FaUserCircle } from 'react-icons/fa';
import dateFormat from 'dateformat';
import queryString from 'query-string';
import DatePicker from 'react-datepicker';
import socketIOClient from 'socket.io-client';
import TimeAgo from 'react-timeago';
// import phoneFormatter from 'phone-formatter';
import getOrdinalNumber from './../../../functions/getOrdinalNumber';
import ModalDepot from './../components/ModalDepot';
import {
  getCheckIns,
  deleteCheckIns,
  updateCheckIn,
  getCheckInsCount,
  textCheckinNotification,
  textCheckinGetFeedback,
} from '../../../redux/actions/businessActions';
import compareTime from './../../../functions/compareTime';
import 'react-datepicker/dist/react-datepicker.css';

const ReadonlyInput = forwardRef(({ value, onClick, props }, ref) => (
  <Button
    variant='secondary'
    onClick={onClick}
    style={props.state.selectedSortType !== 'Today' ? { border: 'solid 1px #019fe8', background: '#bcefff' } : {}}
  >
    {value}
  </Button>
));
class DisplayTable extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'CHECK_INS',
      actionParam: props.parentProps ? queryString.parse(props.parentProps.history.location.search).action : '',
      currentPage: 1,
      currentPageSize: 10000,
      partyPositionInLine: 0,
      isUpdateCheckinModalShow: false,
      isTextCheckinNotificationModalShow: false,
      isTextCheckinGetFeedbackModalShow: false,
      isViewCheckinFeedbackModalShow: false,
      isViewCheckinTableNumberModalShow: false,
      isWaitlistMonitorModalShow: false,
      isCheckinSettingsModalShow: false,
      selectedcheckinID: '',
      selectedCheckin: {},
      checkins: [],
      selected: [],
      selectedSortType: localStorage.getItem('localStorageCheckinsSelectedSortType')
        ? localStorage.getItem('localStorageCheckinsSelectedSortType')
        : 'Today',
      selectedSortDate: localStorage.getItem('localStorageCheckinsSelectedSortDate')
        ? new Date(localStorage.getItem('localStorageCheckinsSelectedSortDate'))
        : new Date(),
      selectedFilterType: 'checked-in',
      checkinExpireTimeHour: 48,
      checkins_count: {},
      endpoint:
        process.env.REACT_APP_PRODUCTION_ENV === '1' ? process.env.REACT_APP_PRODUCTION_LOCALSERVER : process.env.REACT_APP_DEVELOPMENT_LOCALSERVER,
    };
    this.openModal = this.openModal.bind(this);
    this.onGetSelectedCheckins = this.onGetSelectedCheckins.bind(this);
    this.onGetCheckinsCount = this.onGetCheckinsCount.bind(this);
  }
  componentDidMount() {
    this.onGetSelectedCheckins();
    this.onGetCheckinsCount();
    const socket = socketIOClient(this.state.endpoint);
    socket.on('SOCKET_UPDATE_CHECKINS', () => {
      console.log('CHECKIN CLIENT SOCKET HAS UPDATED');
      const data = {
        businessID: this.props.users.token.business_id,
        sortType: this.state.selectedSortType,
        sortDate: dateFormat(this.state.selectedSortType === 'Today' ? new Date() : this.state.selectedSortDate, 'yyyy-mm-dd'),
        filterType: this.state.selectedFilterType,
      };
      this.props.getCheckIns(data, this);
    });
  }
  openModal = (modalName, modalState, modalData) => {
    switch (modalName) {
      case 'CHECKIN_UPDATE_FORM':
        modalState ? this.setState({ isUpdateCheckinModalShow: true }) : this.setState({ isUpdateCheckinModalShow: false, selectedcheckinID: '' });
        break;
      case 'DELETE':
        // modalState ? this.setState({ isDeleteProductModalShow: true }) : this.setState({ isDeleteProductModalShow: false });
        break;
      case 'CHECKIN_TEXT_NOTIFICATION':
        modalState ? this.setState({ isTextCheckinNotificationModalShow: true }) : this.setState({ isTextCheckinNotificationModalShow: false });
        break;
      case 'CHECKIN_TEXT_GET_FEEDBACK':
        modalState ? this.setState({ isTextCheckinGetFeedbackModalShow: true }) : this.setState({ isTextCheckinGetFeedbackModalShow: false });
        break;
      case 'CHECKIN_VIEW_FEEDBACK':
        modalState ? this.setState({ isViewCheckinFeedbackModalShow: true }) : this.setState({ isViewCheckinFeedbackModalShow: false });
        break;
      case 'CHECKIN_VIEW_TABLE_NUMBER':
        modalState ? this.setState({ isViewCheckinTableNumberModalShow: true }) : this.setState({ isViewCheckinTableNumberModalShow: false });
        break;
      case 'CHECKIN_WAITLIST_MONITOR':
        modalState ? this.setState({ isWaitlistMonitorModalShow: true }) : this.setState({ isWaitlistMonitorModalShow: false });
        break;
      case 'CHECKIN_SETTINGS':
        modalState ? this.setState({ isCheckinSettingsModalShow: true }) : this.setState({ isCheckinSettingsModalShow: false });
        break;
      default:
    }
  };
  onGetSelectedCheckins() {
    // store the sort type and date
    localStorage.setItem('localStorageCheckinsSelectedSortType', this.state.selectedSortType); // set the time when the check-in starts
    localStorage.setItem('localStorageCheckinsSelectedSortDate', this.state.selectedSortDate); // set the time when the check-in starts
    const data = {
      businessID: this.props.users.token.business_id,
      sortType: this.state.selectedSortType,
      sortDate:
        this.state.selectedSortType === 'Today' ? dateFormat(new Date(), 'yyyy-mm-dd') : dateFormat(this.state.selectedSortDate, 'yyyy-mm-dd'),
      filterType: this.state.selectedFilterType,
    };
    this.props.getCheckIns(data, this);
  }
  onDeleteCheckIn = (e) => {
    e.preventDefault();
    const data = {
      checkinIDArray: this.state.selectedcheckinID ? [this.state.selectedcheckinID] : this.state.selected,
    };
    this.props.deleteCheckIns(data, this);
    // bug: currentPage doesn't go down when deleting the last item on a paginated page
    // this fix the bug that causes error when deleting the last item on that particular page
    if (this.state.currentPage > Math.ceil((this.props.users.accounts.length - 1) / this.state.currentPageSize) && this.state.currentPage !== 1) {
      this.setState({ currentPage: this.state.currentPage - 1 });
    }
  };
  onGetCheckinsCount() {
    const data = {
      businessID: this.props.users.token.business_id,
      sortType: this.state.selectedSortType,
      sortDate: dateFormat(this.state.selectedSortDate, 'yyyy-mm-dd'),
      filterType: this.state.selectedFilterType,
    };
    this.props.getCheckInsCount(data);
  }
  onTextCheckinNotification = async () => {
    await this.setState({
      selectedCheckin: {
        ...this.state.selectedCheckin,
        notified_time: Date.parse(new Date()) / 1000,
        status: 'ready',
      },
    });
    const data = {
      checkin: this.state.selectedCheckin,
    };
    this.props.textCheckinNotification(data, this);
  };
  onTextCheckinGetFeedback = () => {
    const data = {
      checkin: this.state.selectedCheckin,
    };
    this.props.textCheckinGetFeedback(data, this);
  };
  onUpdateCheckIn = () => {
    const data = {
      checkin: this.state.selectedCheckin,
    };
    this.props.updateCheckIn(data, this);
  };
  FilterByComponent(checkins_count) {
    const radios = [
      {
        name: 'Checked-In',
        value: 'checked-in',
        icon: <MdCheck size={25} className='color-deepskyblue' />,
        totalAmount: checkins_count.totalCheckins ? checkins_count.totalCheckins : 0,
      },
      {
        name: 'Seated',
        value: 'seated',
        icon: <MdAirlineSeatReclineNormal size={25} className='color-deepskyblue' />,
        totalAmount: checkins_count.totalSeated ? checkins_count.totalSeated : 0,
      },
      {
        name: 'Canceled',
        value: 'canceled',
        icon: <MdDoNotDisturb size={25} className='color-deepskyblue' />,
        totalAmount: checkins_count.totalCanceled ? checkins_count.totalCanceled : 0,
      },
    ];
    return (
      <div className='display-flex'>
        <ButtonGroup toggle className='display-flex flex-wrap-nowrap'>
          {radios.map((radio, idx) => (
            <ToggleButton
              variant='secondary'
              key={idx}
              type='radio'
              name='radio'
              value={radio.value}
              checked={radio.value === this.state.selectedFilterType}
              onChange={async (e) => {
                await this.setState({ selectedFilterType: e.currentTarget.value });
                this.onGetSelectedCheckins();
              }}
            >
              <div className='display-flex flex-wrap-nowrap align-items-center justify-content-center'>
                <div className='margin-left-5'>
                  <div className='padding-5 border-radius-5 margin-left-5 bg-deepskyblue color-white'>{radio.totalAmount}</div>
                  {radio.name}
                </div>{' '}
              </div>
            </ToggleButton>
          ))}
        </ButtonGroup>
      </div>
    );
  }
  render() {
    const { SearchBar, ClearSearchButton } = Search;
    const { checkins } = this.props.business;
    var partyPositionInLine = 0;
    const selectRowProp = {
      mode: 'checkbox',
      selected: this.state.selected,
      hideSelectColumn: true,
      hideSelectAll: true,
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          this.setState(() => ({
            selected: [...this.state.selected, row.id],
          }));
        } else {
          this.setState(() => ({
            selected: this.state.selected.filter((x) => x !== row.id),
          }));
        }
      },
      onSelectAll: (isSelect, rows, e) => {
        const ids = rows.map((r) => r.id);
        if (isSelect) {
          this.setState({ selected: ids });
        } else {
          this.setState({ selected: [] });
        }
      },
    };
    const rowEvents = {
      onClick: async (e, row, rowIndex) => {
        await this.setState({
          selectedcheckinID: row.id,
          selectedCheckin: row,
        });
        this.openModal('CHECKIN_UPDATE_FORM', true);
      },
    };
    const paginationOptions = {
      onPageChange: (page, sizePerPage) => {
        this.setState({ currentPage: page, currentPageSize: sizePerPage });
      },
      custom: false,
      page: this.state.currentPage,
      sizePerPage: this.state.currentPageSize,
      hideSizePerPage: true,
      showTotal: true,
    };
    const checkinColumns = [
      {
        dataField: 'id',
        text: 'Status',
        headerAlign: 'center',
        align: 'center',
        // sort: true,
        formatter: (cell, row, rowIndex, data) => {
          const radios = [
            { name: 'Checked-In', value: 'checked-in', icon: <MdCheck size={25} className='color-deepskyblue' /> },
            { name: 'Ready', value: 'ready', icon: <MdNotificationsActive size={25} className='color-darkorange' /> },
            { name: 'Seated', value: 'seated', icon: <MdAirlineSeatReclineNormal size={25} className='color-darkcyan' /> },
            { name: 'Canceled', value: 'canceled', icon: <MdCancel size={25} className='color-tomato' /> },
          ];
          // temp fixes for partyPositionInLine when searching
          if (partyPositionInLine - rowIndex > 0) {
            partyPositionInLine = 0;
          }
          if (row.status === radios[0].value) {
            partyPositionInLine = partyPositionInLine + 1;
          }
          // let rowNumber = (this.state.currentPage - 1) * this.state.currentPageSize + (rowIndex + 1);
          // return <>{rowNumber}</>;
          return (
            <>
              <div className='display-flex'>
                <>
                  <DropdownButton
                    variant={
                      row.status === radios[0].value
                        ? 'primary'
                        : row.status === radios[1].value
                        ? 'info'
                        : row.status === radios[2].value
                        ? 'dark'
                        : ''
                    }
                    disabled={this.state.selectedSortType === 'Today' ? false : true}
                    title={
                      <>
                        <div style={{ textAlign: 'center' }}>
                          {row.status === radios[0].value ? (
                            <>
                              <div>
                                <span className='font-size-smaller'>{radios[0].name}</span>
                                <div className='font-size-smallest'>
                                  <div className='display-flex justify-content-center align-items-center'>
                                    {dateFormat(row.created_time * 1000, 'h:MM TT')}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : row.status === radios[1].value ? (
                            <div>
                              <span>{radios[1].name}</span>
                              <div className='font-size-smallest'>
                                <div className='display-flex justify-content-center align-items-center'>
                                  {dateFormat(row.readied_time * 1000, 'h:MM TT')}
                                </div>
                              </div>
                            </div>
                          ) : row.status === radios[2].value ? (
                            <div className='color-white'>
                              <span>{radios[2].name}</span>
                              <div className='display-flex justify-content-center align-items-center font-size-smallest'>
                                {dateFormat(row.serviced_time * 1000, 'h:MM TT')}
                              </div>
                            </div>
                          ) : (
                            <div className='color-gray'>
                              <div className='color-graynode'>{radios[3].name}</div>
                              <div className='display-flex justify-content-center align-items-center font-size-smallest'>
                                {dateFormat(row.canceled_time * 1000, 'h:MM TT')}
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    }
                    onClick={(e) => {
                      e.stopPropagation(); // stop click from expanding row
                    }}
                    style={row.status === 'seated' ? { border: 'solid 0px #fff', borderRadius: '5px' } : {}}
                  >
                    <ButtonGroup toggle className='display-flex flex-wrap-nowrap'>
                      {radios.map((radio, idx) => (
                        <ToggleButton
                          variant='secondary'
                          key={idx}
                          type='radio'
                          name='radio'
                          value={radio.value}
                          checked={radio.value === '' + row.status}
                          onChange={async (e) => {
                            // switch checkin status
                            await this.setState({
                              selectedCheckin: {
                                ...row,
                                status: e.currentTarget.value,
                                readied_time: e.currentTarget.value === radios[1].value ? Date.parse(new Date()) / 1000 : null,
                                serviced_time: e.currentTarget.value === radios[2].value && !row.serviced_time ? Date.parse(new Date()) / 1000 : null,
                                canceled_time: e.currentTarget.value === radios[3].value ? Date.parse(new Date()) / 1000 : null,
                                cancel_by: e.currentTarget.value === radios[3].value ? 'host' : null,
                              },
                            });
                            this.onUpdateCheckIn();
                          }}
                        >
                          <div>{radio.icon}</div>
                          <div className='display-flex flex-wrap-nowrap white-space-nowrap align-items-center justify-content-center'>
                            {radio.name}
                          </div>
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </DropdownButton>
                  {this.state.selectedSortType === 'Today' && row.status === radios[0].value ? (
                    <div className='display-flex justify-content-center align-items-center margin-top-5 font-size-small text-transform-capitalize'>
                      {partyPositionInLine === 1 ? 'Next in Line' : getOrdinalNumber(partyPositionInLine)}
                    </div>
                  ) : (
                    ''
                  )}
                  {row.status === radios[3].value ? (
                    <>
                      <div className='display-flex justify-content-center text-transform-capitalize'>By {row.cancel_by}</div>
                    </>
                  ) : (
                    ''
                  )}
                </>
              </div>
            </>
          );
        },
      },
      {
        dataField: 'search_fields',
        text: 'Party',
        headerAlign: 'left',
        align: 'left',
        // sort: true,
        formatter: (cell, row, rowIndex) => {
          return (
            <div className='display-flex text-align-left color-black padding-left-5'>
              <div>
                <div className='display-flex align-items-center font-size-medium margin-bottom-5'>
                  <div
                    className={
                      row.status === 'checked-in' && this.state.selectedSortType === 'Today'
                        ? 'font-weight-bolder'
                        : row.status === 'canceled'
                        ? 'text-decoration-line-through'
                        : ''
                    }
                  >
                    <span className='text-transform-capitalize'>
                      {row.first_name} {row.last_name}{' '}
                      {this.state.selectedSortType === 'Today' ? (
                        row.is_new_customer ? (
                          <>
                            <span className='color-tomato font-style-italic'>New!</span>
                          </>
                        ) : (
                          <span className='color-gray font-style-italic'></span>
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                  {/* <span className='font-size-smaller'>ID:{row.id}</span> */}
                </div>
                {row.party_size > 1 ? (
                  <div>
                    <OverlayTrigger placement='top' overlay={<Tooltip>Party Size</Tooltip>}>
                      <MdPeople size={15} className='color-gray margin-right-5' />
                    </OverlayTrigger>
                    Party of {row.party_size}
                  </div>
                ) : (
                  ''
                )}
                <div>
                  {/* <MdCheck size={15} className={row.status === 'checked-in' ? 'color-deepskyblue' : 'color-lightgray'} /> */}
                  {/* {dateFormat(row.created_time * 1000, this.state.selectedSortType === 'All' ? 'm/dd/yy - h:MMtt' : 'h:MMtt')} */}
                  {/* {row.readied_time ? (
                    <>
                      <MdNotificationsActive
                        size={15}
                        className={
                          row.status === 'checked-in' ? 'color-deepskyblue' : row.status === 'ready' ? 'color-darkorange' : 'color-lightgray'
                        }
                      />
                      {dateFormat(row.readied_time * 1000, 'h:MMtt')}
                    </>
                  ) : (
                    <></>
                  )}
                  {row.serviced_time ? (
                    <>
                      <MdAirlineSeatReclineNormal size={15} className='color-lightgray' />
                      {dateFormat(row.serviced_time * 1000, 'h:MMtt')}
                    </>
                  ) : (
                    <></>
                  )}
                  {row.canceled_time ? (
                    <>
                      <MdCancel size={15} className='color-lightgray' />
                      {dateFormat(row.canceled_time * 1000, 'h:MMtt')}
                    </>
                  ) : (
                    <></>
                  )} */}
                </div>
                {/* <div className='margin-top-10'></div> */}
                {/* <span style={{ fontWeight: 'normal' }}>{phoneFormatter.format(row.phone + '', '(NNN) NNN-NNNN')}</span> */}
                {row.staff_id ? (
                  <div className='display-flex align-items-center'>
                    <span className='white-space-nowrap'>
                      <OverlayTrigger placement='top' overlay={<Tooltip>Selected Staff</Tooltip>}>
                        <FaUserCircle size={15} className='color-gray margin-right-5' />
                      </OverlayTrigger>
                      {row.staff_first_name} {row.staff_last_name}
                    </span>
                  </div>
                ) : (
                  <></>
                )}{' '}
                {row.selectedProducts && row.selectedProducts.length > 0 ? (
                  <>
                    <div className='flex-table'>
                      <div className='flex-table-row'>
                        <div className='flex-table-cell'>
                          <OverlayTrigger placement='top' overlay={<Tooltip>Selected Products &amp; Services</Tooltip>}>
                            <MdShoppingBasket size={15} className='color-gray' />
                          </OverlayTrigger>
                        </div>
                        <div className='flex-table-cell padding-3'>
                          {row.selectedProducts.map((product, i) => {
                            return (
                              <span key={i}>
                                {product.name} {i < row.selectedProducts.length - 1 ? '—' : ''}{' '}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {row.notes ? (
                  <div className='flex-table'>
                    <div className='flex-table-row'>
                      <div className='flex-table-cell'>
                        <OverlayTrigger placement='top' overlay={<Tooltip>Notes &amp; Requests</Tooltip>}>
                          <MdSubject size={15} className='color-gray' />
                        </OverlayTrigger>
                      </div>
                      <div className='flex-table-cell padding-3'>"{row.notes}"</div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          );
        },
      },
      {
        dataField: 'notified',
        text: 'Call Up',
        // <MdPhoneAndroid size={25} className='color-deepskyblue' />,
        headerAlign: 'center',
        align: 'center',
        // sort: true,
        formatter: (cell, row) => {
          var notifiedTimeDifferenceInMinutes = compareTime(new Date(row.notified_time * 1000), new Date(), 'minute');
          var timeDifferenceInHours = compareTime(new Date(row.created_time * 1000), new Date(), 'hour');
          return (
            <div>
              <input
                type='checkbox'
                onChange={(e) => {
                  e.stopPropagation(); // prevent click through on select row or rowEvents
                  this.setState({ selectedCheckin: row });
                  this.openModal('CHECKIN_TEXT_NOTIFICATION', true);
                }}
                onClick={(e) => {
                  e.stopPropagation(); // prevent click through on select row or rowEvents
                }}
                checked={row.notified ? true : false}
                disabled={
                  row.notified || row.status === 'seated' || row.status === 'canceled' || timeDifferenceInHours > this.state.checkinExpireTimeHour
                    ? true
                    : false
                }
                className='checkin-checkbox'
              />
              {row.notified && row.notified_time ? (
                <div className='display-flex align-items-center justify-content-center font-size-smaller'>
                  {notifiedTimeDifferenceInMinutes > 60 ? (
                    dateFormat(row.notified_time * 1000, 'h:MM TT')
                  ) : (
                    <TimeAgo date={new Date(row.notified_time * 1000)} />
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          );
        },
      },
      // {
      //   dataField: 'feedback_sent',
      //   text: 'Feedback',
      //   // <MdRateReview size={25} className='color-deepskyblue' />,
      //   headerAlign: 'center',
      //   align: 'center',
      //   // sort: true,
      //   formatter: (cell, row) => {
      //     return (
      //       <div className='display-flex justify-content-center'>
      //         {row.feedback_sent || row.rating || row.feedback ? (
      //           <div>
      //             {
      //               row.rating === null ? (
      //                 <input
      //                   type='checkbox'
      //                   onChange={(e) => {
      //                     e.stopPropagation(); // prevent click through on select row or rowEvents
      //                     this.setState({ selectedCheckin: row });
      //                     this.openModal('CHECKIN_TEXT_GET_FEEDBACK', true);
      //                   }}
      //                   onClick={(e) => {
      //                     e.stopPropagation(); // prevent click through on select row or rowEvents
      //                   }}
      //                   checked={row.feedback_sent ? true : false}
      //                   disabled={row.feedback_sent ? true : false}
      //                   className='checkin-checkbox'
      //                 />
      //               ) : row.rating ? (
      //                 <>
      //                   <div className='padding-5 bg-darkcyan color-white border-radius-5' onClick={(e) => e.stopPropagation()}>
      //                     {row.feedback === null ? (
      //                       <OverlayTrigger placement='left' overlay={<Tooltip>{row.first_name} had a great experience!</Tooltip>}>
      //                         <MdThumbUp size={15} className='color-white' />
      //                       </OverlayTrigger>
      //                     ) : (
      //                       <>
      //                         <OverlayTrigger
      //                           placement='left'
      //                           overlay={
      //                             <Tooltip>
      //                               {row.first_name}'s feedback': {row.feedback}
      //                             </Tooltip>
      //                           }
      //                         >
      //                           <MdRateReview size={15} className='color-white' />
      //                         </OverlayTrigger>
      //                       </>
      //                     )}
      //                   </div>
      //                 </>
      //               ) : (
      //                 <div className='padding-5 bg-tomato color-white border-radius-5' onClick={(e) => e.stopPropagation()}>
      //                   {row.feedback === null ? (
      //                     <OverlayTrigger placement='left' overlay={<Tooltip>{row.first_name} had a poor experience</Tooltip>}>
      //                       <MdThumbDown size={15} className='color-white' />
      //                     </OverlayTrigger>
      //                   ) : (
      //                     <>
      //                       <OverlayTrigger
      //                         placement='left'
      //                         overlay={
      //                           <Tooltip>
      //                             {row.first_name} feedback: "{row.feedback}"
      //                           </Tooltip>
      //                         }
      //                       >
      //                         <MdRateReview size={15} className='color-white' />
      //                       </OverlayTrigger>
      //                     </>
      //                   )}
      //                 </div>
      //               )
      //             }
      //           </div>
      //         ) : (
      //           <>
      //             <input
      //               type='checkbox'
      //               onChange={(e) => {
      //                 e.stopPropagation(); // prevent click through on select row or rowEvents
      //                 this.setState({ selectedCheckin: row });
      //                 this.openModal('CHECKIN_TEXT_GET_FEEDBACK', true);
      //               }}
      //               onClick={(e) => {
      //                 e.stopPropagation(); // prevent click through on select row or rowEvents
      //               }}
      //               checked={row.feedback_sent ? true : false}
      //               disabled={row.feedback_sent ? true : false}
      //               className='checkin-checkbox'
      //             />
      //           </>
      //         )}
      //       </div>
      //     );
      //   },
      // },
    ];
    const NoDataIndication = () => {
      return (
        <>
          <div className='padding display-flex justify-content-center align-items-center'>
            <center>
              {this.state.selectedSortType === 'Today' ? (
                <>
                  <div className='font-size-large font-weight-bold margin-top-15 margin-bottom-15'>Awaiting Check-Ins</div>
                  Customer check-ins will appear here when available for today.
                </>
              ) : (
                <>
                  <div className='font-size-large font-weight-bold margin-top-15 margin-bottom-15'>No One Checked In</div>
                  on {dateFormat(this.state.selectedSortDate, 'mmmm d, yyyy')}
                </>
              )}
            </center>
          </div>
        </>
      );
    };
    const DataTableCheckins = () => {
      return (
        <>
          <ToolkitProvider keyField='id' data={checkins} columns={checkinColumns} search>
            {(toolkitprops) => {
              return (
                <>
                  <div className='display-flex width-100 padding'>
                    <div className='flex-1 margin-right-10'>
                      <SearchBar placeholder='Search Check-Ins &amp; Waitlisted' tableId='DisplaySearch' {...toolkitprops.searchProps} />
                    </div>
                    <div className='search-field'>
                      <ClearSearchButton {...toolkitprops.searchProps} />
                    </div>
                  </div>
                  <div className='padding' style={{ paddingTop: '0' }}>
                    <div className='display-flex justify-content-space-between align-items-center'>
                      {/* <div className='flex-1'>
                        <Form.Control
                          as='select'
                          name='selectedSortType'
                          value={this.state.selectedSortType}
                          onChange={async (e) => {
                            await this.setState({
                              selectedSortType: e.target.value,
                              selectedSortDate: e.target.value === 'Today' ? new Date() : this.state.selectedSortDate,
                            });
                            this.onGetSelectedCheckins();
                          }}
                        >
                          <option value='Today'>Today</option>
                          <option value='Date'>Date</option>
                        </Form.Control>
                      </div> */}
                      {/* <option value='All'>All</option> */}
                      <div className='display-inline-flex'>
                        {this.state.selectedSortType === 'Date' || this.state.selectedSortType === 'Today' ? (
                          <div className='margin-right-10'>
                            <DatePicker
                              withPortal
                              name='startDate'
                              selected={this.state.selectedSortType === 'Today' ? new Date() : this.state.selectedSortDate}
                              maxDate={new Date()}
                              onChange={async (e) => {
                                const today = new Date();
                                const selectedDate = new Date(e);
                                if (
                                  today.getMonth() === selectedDate.getMonth() &&
                                  today.getDate() === selectedDate.getDate() &&
                                  today.getFullYear() === selectedDate.getFullYear()
                                ) {
                                  await this.setState({ selectedSortDate: e, selectedSortType: 'Today' });
                                } else {
                                  await this.setState({ selectedSortDate: e, selectedSortType: 'Date' });
                                }
                                this.onGetSelectedCheckins();
                              }}
                              dateFormat='EEE, MMMM d, yyyy'
                              customInput={<ReadonlyInput props={this} />}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                        <div>
                          <ButtonGroup toggle>
                            <ToggleButton
                              variant='secondary'
                              key={1}
                              type='radio'
                              name='radio'
                              value={this.state.selectedSortType}
                              checked={this.state.selectedSortType === 'Today'}
                              onClick={async (e) => {
                                await this.setState({
                                  selectedSortType: 'Today',
                                  selectedSortDate: new Date(),
                                });
                                this.onGetSelectedCheckins();
                              }}
                            >
                              Today
                            </ToggleButton>
                          </ButtonGroup>
                        </div>
                      </div>
                      <div className='display-inline-flex margin-left-10'>
                        <Button
                          variant='secondary'
                          onClick={async () => {
                            if (this.state.selectedSortType !== 'Today') {
                              window.alert('Select Today in order to display waitlist for clients');
                            } else {
                              await this.setState({ checkins: this.props.business.checkins });
                              this.openModal('CHECKIN_WAITLIST_MONITOR', true);
                            }
                          }}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <MdTv size={15} className='color-darkgray' />
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                  {({ paginationProps, paginationTableProps }) => ( */}
                  <div>
                    <>
                      <BootstrapTable
                        headerClasses={checkins.length > 0 ? '' : 'display-none'}
                        classes='checkins-table'
                        noDataIndication={checkins.length <= 0 ? <NoDataIndication /> : ''}
                        filter={filterFactory()}
                        selectRow={selectRowProp}
                        rowEvents={rowEvents}
                        // {...paginationTableProps}
                        {...toolkitprops.baseProps}
                        pagination={paginationFactory(paginationOptions)}
                      />
                    </>

                    {/* <PaginationListStandalone {...paginationProps} /> */}
                  </div>
                  {/* <div className='padding'>{this.FilterByComponent(checkin.count)}</div> */}
                  {/* )}
                </PaginationProvider> */}
                </>
              );
            }}
          </ToolkitProvider>
        </>
      );
    };
    return (
      <div className='height-100vh-minus-header'>
        <DataTableCheckins />
        <ModalDepot
          state={this.state}
          props={this.props}
          function={{
            openModal: this.openModal,
            onGetSelectedCheckins: this.onGetSelectedCheckins,
            onGetCheckinsCount: this.onGetCheckinsCount,
            onTextCheckinNotification: this.onTextCheckinNotification,
            onTextCheckinGetFeedback: this.onTextCheckinGetFeedback,
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  business: state.business,
  users: state.users,
  site: state.site,
});

export default connect(mapStateToProps, {
  getCheckIns,
  deleteCheckIns,
  updateCheckIn,
  getCheckInsCount,
  textCheckinNotification,
  textCheckinGetFeedback,
})(DisplayTable);
