import React, { Component } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { UncontrolledDropdown, DropdownToggle } from 'reactstrap';
import { Header, SidebarNav, PageContent, Page } from '../components';
import SideNavigationRoutes from './SideNavigationRoutes';
import routes from '../routes';
import jwt_decode from 'jwt-decode';
import store from './../redux/redux_store';
import { authenticateUser, logoutUser } from './../redux/actions/userActions';
import setAuthToken from './../functions/setAuthToken';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import DropdownItem from 'react-bootstrap/DropdownItem';

// import handleKeyAccessibility, { handleClickAccessibility } from '../vibe/helpers/handleTabAccessibility';
const MOBILE_SIZE = 992;
if (localStorage.loginToken) {
  setAuthToken(localStorage.loginToken); // set auth token header auth
  const decoded = jwt_decode(localStorage.loginToken); // decode token and get user data and expiration
  store.dispatch(authenticateUser(decoded)); // set current user and isAuthenticated
  // automatically log user out after some time check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // store the current url user is on
    localStorage.setItem('localStorageUserLastUrl', window.location.href);
    store.dispatch(logoutUser()); //logout user
    // clear current profiles
    window.location.href = '/'; // redirect to login page
  }
}
class DashboardLayout extends Component {
  constructor(props) {
    super();
    this.state = {
      sidebarCollapsed: false,
      isMobile: window.innerWidth <= MOBILE_SIZE,
      showChat1: false,
    };
    this.onLogout = this.onLogout.bind(this);
    this.toggleSideCollapse = this.toggleSideCollapse.bind(this);
  }
  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ sidebarCollapsed: false, isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };
  componentDidUpdate(prev) {
    if (this.state.isMobile && prev.location.pathname !== this.props.location.pathname) {
      this.toggleSideCollapse(true);
    }
  }
  componentDidMount() {
    // if user is not authenticated and tries to access these pages, push user to login page
    if (!this.props.users.isAuthenticated) {
      this.props.history.push('/login');
    }
    window.addEventListener('resize', this.handleResize);
    // document.addEventListener('keydown', handleKeyAccessibility);
    // document.addEventListener('click', handleClickAccessibility);
  }
  UNSAFE_componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  toggleSideCollapse = (collapseState) => {
    if (collapseState) {
      if (this.state.isMobile) {
        // if it's mobile, close the the sidebar once its opened
        this.setState((prevState) => ({ sidebarCollapsed: !collapseState }));
      } else {
        this.setState({ sidebarCollapsed: collapseState });
      }
    } else {
      this.setState((prevState) => ({ sidebarCollapsed: !prevState.sidebarCollapsed }));
    }
  };
  closeChat = () => {
    this.setState({ showChat1: false });
  };
  onLogout(e) {
    localStorage.clear();
    this.props.logoutUser();
    this.props.history.push('/login');
  }
  render() {
    const { sidebarCollapsed } = this.state;
    const sidebarCollapsedClass = sidebarCollapsed ? 'side-menu-collapsed' : '';
    return (
      <div className={`app ${sidebarCollapsedClass} width-100`}>
        <div className='display-flex height-100 align-content-flex-start'>
          <SidebarNav
            nav={SideNavigationRoutes}
            logoText={this.props.users.token.business_name}
            isSidebarCollapsed={sidebarCollapsed}
            toggleSidebar={this.toggleSideCollapse}
            {...this.props}
          />
          <Page>
            <Header toggleSidebar={this.toggleSideCollapse} isSidebarCollapsed={sidebarCollapsed} routes={routes} {...this.props}>
              <UncontrolledDropdown nav inNavbar className='width-100'>
                {/* <a href='#!' onClick={() => this.onLogout()}>
                  Log Out
                </a> */}
                <DropdownToggle nav className='float-right'>
                  {/* <Avatar round size='50' color='#2fb457' name={this.props.users.token.first_name + ' ' + this.props.users.token.last_name} /> */}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={() => (window.location.href = '/account/switch')}>Switch Account</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => this.onLogout()}>Confirm Logout</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Header>
            <PageContent>
              <Switch>
                {routes.map((page, key) => (
                  <Route exact path={page.path} key={key}>
                    <page.component page={page} parentProps={this.props} function={{ toggleSideCollapse: this.toggleSideCollapse }} />
                  </Route>
                  // <Route exact path={page.path} component={page.component} key={key} />
                ))}
                <Redirect to='/404' />
              </Switch>
            </PageContent>
          </Page>
        </div>
        {/* <Footer>
          <div className='width-100 padding'>
            <center>
              <span>Copyright © 2020. All rights reserved.</span>{' '}
              <span>
                <a href='#!'>Terms</a> | <a href='#!'>Privacy Policy</a>
              </span>
            </center>
          </div>
        </Footer> */}
        {/* <Chat.Container>
            {this.state.showChat1 && <Chat.ChatBox name='Messages' status='online' image={avatar1} close={this.closeChat} />}
          </Chat.Container> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  site: state.site,
});

export default connect(mapStateToProps, { logoutUser })(DashboardLayout);
