import React, { Component } from 'react';
import { connect } from 'react-redux';

import logo from './../../assets/images/logo.png';
import newsroom_art from './../../assets/images/newsroom_art.jpg';
import ethics_art from './../../assets/images/ethics_art.jpg';
import contact_art from './../../assets/images/contact_art.jpg';

class AppComponent extends Component {
  render() {
    return (
      <>
        <div>
          <div className='STICKYHEADER display-flex width-max-980 margin-auto padding'>
            <div className='display-flex flex-direction-column'>
              <div className='display-flex justify-content-space-evenly padding'>
                <a href='#mission'>Core and Mission</a> | <a href='#newsroom'>Newsroom</a> | <a href='#ethics'>Ethics</a> |{' '}
                <a href='#contact'>Contact</a>
              </div>
              <hr></hr>
              <div className='margin-top-50' id='mission'>
                <div className='display-flex align-items-center justify-content-center margin-top-25'>
                  <img src={logo} alt='logo' style={{ height: '150px' }} />
                </div>
                <div className='display-flex justify-content-center font-size-large margin-15'>CORE AND MISSION</div>
                <div>
                  One Space Link makes applications that help businesses build and manage better relationships with their customers. We are dedicated
                  to developing simple and manageable system for businesses of all sizes.
                </div>
              </div>
              <div>
                <div className='display-flex align-items-center justify-content-center margin-top-50' id='newsroom'>
                  <img src={newsroom_art} alt='newsroom' className='border-radius-25' />
                </div>
                <div className='display-flex justify-content-center font-size-large margin-15'>NEWSROOM</div>
                <div>View our headlines and archives.</div>

                <div className='font-size-medium margin-top-15'>One Space Link Offers Help for Businesses Struggling with COVID-19 Pandemic</div>
                <div className='margin-top-25 margin-bottom-25'>
                  <a
                    href='https://www.prnewswire.com/news-releases/one-space-link-offers-help-for-businesses-struggling-with-covid-19-pandemic-301148308.html'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    https://www.prnewswire.com/news-releases/one-space-link-offers-help-for-businesses-struggling-with-covid-19-pandemic-301148308.html
                  </a>
                </div>
              </div>
              <div>
                <div className='display-flex align-items-center justify-content-center' id='ethics'>
                  <img src={ethics_art} alt='ethics' />
                </div>
                <div className='display-flex justify-content-center font-size-large margin-15'>ETHICS</div>
                <div>
                  One Space Link conducts business ethically, honestly, and in full compliance with the law. We believe that how we conduct ourselves
                  is as critical to Apple’s success as making the best products in the world. Our Business Conduct and Compliance policies are
                  foundational to how we do business and how we put our values into practice every day.
                </div>
                <div className='margin-15'>
                  Overview:
                  <ul className='services-overview-ul'>
                    <li>
                      <b>Our Business Conduct Policy</b>: Honesty, Respect, Confidentiality, and Compliance
                    </li>
                    <li>
                      <b>Compliance at One Space Link</b>
                    </li>
                    <li>
                      <b>Bringing Policy to Life</b>
                    </li>
                  </ul>
                </div>
                <div className='font-size-medium'>Conducting Ethical Business</div>
                <div className='margin-top-25 margin-bottom-25'>
                  Our Business Conduct Policy sets out our ethical requirements for our employees. Each of our employees must certify that they have
                  read and understand the policy when they join Apple, and again each year. Violations of the policy are taken seriously and may
                  result in disciplinary action, up to and including termination of employment.
                </div>
                <div className='font-size-medium'>Compliance</div>
                <div className='margin-top-25 margin-bottom-25'>
                  A number of compliance functions are deeply integrated into our business organization. One Space Link's Business Conduct team
                  focuses on Business Conduct, Political Compliance, Export and Sanctions Compliance, Health Compliance, Antitrust Compliance, and
                  Anti-Corruption Compliance.
                </div>
                <div className='font-size-medium'>Employees at Work</div>
                <div className='margin-top-25 margin-bottom-25'>
                  Every OSL employee is required to complete mandatory annual Business Conduct training. We offer a range of additional resources for
                  employees to address questions and concerns, including the Business Conduct Helpline and the Business Conduct website.
                </div>
              </div>
              <div>
                <div className='display-flex align-items-center justify-content-center' id='contact'>
                  <img src={contact_art} alt='contact' />
                </div>
                <div className='display-flex justify-content-center font-size-large margin-15'>CONTACT</div>
                <div>Need service or support? Start your request online and we’ll find you a solution.</div>
                <div className='margin-15'></div>
                <div className='font-size-medium'>Email Us</div>
                <div className='margin-top-25 margin-bottom-25'>U.S. email support: support@onespace.link</div>
                <div className='font-size-medium'>Message Us</div>
                <div className='margin-top-25 margin-bottom-25'>U.S. mobile support: 1-713-742-2971</div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps)(AppComponent);
