import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button } from 'react-bootstrap';
// import SignatureCanvas from 'react-signature-canvas';
import { SizeMe, withSize } from 'react-sizeme';
import dateFormat from 'dateformat';

import { getAppointmentForms, submitAppointmentForms } from './../../redux/actions/businessActions';
import { clearErrors } from './../../redux/actions/siteActions';

class AppComponent extends Component {
  signPads = [];
  constructor(props) {
    super();
    this.state = {
      currentDate: new Date().toLocaleString(),
      checkboxValues: [],
      unconfirmedFormsCount: 0,
    };
  }
  componentDidMount() {
    const data = {
      id: this.props.match.params.id,
      uuid: this.props.match.params.uuid,
    };
    this.props.getAppointmentForms(data, this);
  }
  onSubmitForms = (e) => {
    e.preventDefault();
    const data = {
      checkboxValues: this.state.checkboxValues,
      formCount: this.state.unconfirmedFormsCount,
    };
    this.props.submitAppointmentForms(data);
  };
  render() {
    const { appointment_forms } = this.props.business;
    const { errors } = this.props.site;

    const confirmedForms = appointment_forms.filter((form) => {
      if (form.date_confirmed) {
        return form;
      }
      return '';
    }, []);

    const unconfirmedForms = appointment_forms.filter((form) => {
      if (!form.date_confirmed) {
        return form;
      }
      return '';
    }, []);

    if (appointment_forms) {
      return (
        <>
          <div className='display-flex justify-content-center width-100 bg-whitesmoke'>
            <div className='width-100 padding' style={{ borderBottom: 'solid 1px #ccc' }}>
              <center>
                <span className='font-size-larger'>Appointment Forms</span>
              </center>
            </div>
            <div className='display-flex-direction-column padding'>
              {unconfirmedForms.length > 0 ? (
                <>
                  {unconfirmedForms.map((form, i) => {
                    // const formWidth = this.props.size.width * 0.9;
                    return (
                      <div key={i}>
                        <SizeMe>
                          {({ size }) => (
                            <>
                              <center>
                                <div className='font-size-larger'>{form ? form.name : ''}</div>
                              </center>
                              <div key={i} className='border-radius-5 bg-white padding border margin-15' style={{ marginBottom: '50px' }}>
                                <div className='react-quill' dangerouslySetInnerHTML={{ __html: form.form_content ? form.form_content : '' }} />
                                {form.date_confirmed ? (
                                  ''
                                ) : (
                                  <>
                                    {form.signature_required ? (
                                      <div className='color-red margin-bottom-15'>
                                        Signature required - check the box below to electronically sign:
                                      </div>
                                    ) : (
                                      <div className='color-red margin-bottom-15'>Check the box to confirm:</div>
                                    )}
                                  </>
                                )}
                                <table>
                                  <tbody>
                                    <tr>
                                      <td valign='bottom'>
                                        <input
                                          type='checkbox'
                                          checked={
                                            form.date_confirmed ? true : this.state.checkboxValues[i] ? this.state.checkboxValues[i].checked : false
                                          }
                                          onChange={() => {
                                            var newCheckboxValues = this.state.checkboxValues;
                                            newCheckboxValues[i] = {
                                              checked: this.state.checkboxValues[i] ? !this.state.checkboxValues[i].checked : true,
                                              formID: form.form_id,
                                            };
                                            this.setState({ checkboxValues: newCheckboxValues });
                                          }}
                                          className='largerCheckbox margin-right-15'
                                        />
                                      </td>
                                      <td>
                                        {form.signature_required ? (
                                          <>
                                            By checking this box, I agree to electronically sign to indicate my acceptance to the terms and conditions
                                            above. This electronic signature is a representation of my signature - just the same as a pen-and-paper
                                            signature.
                                          </>
                                        ) : (
                                          <>I have reviewed and agree to the form above.</>
                                        )}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td colSpan='2'>
                                        {form.signature_required ? (
                                          <>
                                            <div style={{ height: '75px', borderBottom: 'solid 1px #ccc' }}>
                                              <div
                                                id={'signature_field_' + i}
                                                className='padding color-blue'
                                                style={{
                                                  fontFamily: 'PhotographSignature',
                                                  fontSize: '4em',
                                                  width: 'auto',
                                                  whiteSpace: 'nowrap',
                                                  display:
                                                    this.state.checkboxValues[i] && this.state.checkboxValues[i].checked === true ? 'block' : 'none',
                                                }}
                                              >
                                                {form.first_name} {form.last_name}
                                              </div>
                                            </div>
                                            <div className='display-flex justify-content-space-between padding'>
                                              <div>
                                                {form.first_name} {form.last_name}
                                              </div>
                                              <div>{dateFormat(this.state.currentDate, 'mm/d/yyyy, h:MMtt')}</div>
                                            </div>
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </>
                          )}
                        </SizeMe>
                      </div>
                    );
                  })}
                  <div className='padding'>
                    <Alert
                      variant='warning'
                      show={errors.errorSubmitForms ? true : false}
                      onClose={() => this.props.clearErrors()}
                      dismissible
                      style={{ marginTop: '10px' }}
                    >
                      {errors.errorSubmitForms}
                    </Alert>
                    <Button
                      onClick={(e) => {
                        this.setState({ unconfirmedFormsCount: unconfirmedForms.length });
                        this.onSubmitForms(e);
                      }}
                    >
                      Confirm and Submit
                    </Button>
                  </div>
                </>
              ) : (
                ''
              )}
              {confirmedForms.length > 0 ? (
                <>
                  {confirmedForms.map((form, i) => {
                    return (
                      <div key={i}>
                        <SizeMe>
                          {({ size }) => (
                            <>
                              <center>
                                <div className='font-size-larger'>{form ? form.name : ''}</div>
                              </center>
                              <div key={i} className='border-radius-5 bg-white padding margin-15' style={{ marginBottom: '50px' }}>
                                {/*style={{ width: formWidth }}*/}
                                <div className='react-quill' dangerouslySetInnerHTML={{ __html: form.form_content ? form.form_content : '' }} />
                                {form.signature_required ? (
                                  <>
                                    Electronically Signed:
                                    <div
                                      className='padding color-blue'
                                      style={{
                                        fontFamily: 'PhotographSignature',
                                        fontSize: '4em',
                                        width: 'auto',
                                        whiteSpace: 'nowrap',
                                        borderBottom: 'solid 1px #ccc',
                                      }}
                                    >
                                      {form.first_name} {form.last_name}
                                    </div>
                                    <div className='display-flex justify-content-space-between padding'>
                                      <div>
                                        {form.first_name} {form.last_name}
                                      </div>
                                      <div>
                                        <span>Signed on: {dateFormat(form.date_confirmed, 'mm/dd/yyyy, h:MMtt')}</span>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <span>
                                    Reviewed and confirmed by {form.first_name} {form.last_name} has on:{' '}
                                    {dateFormat(form.date_confirmed, 'mm/dd/yyyy, h:MMtt')}
                                  </span>
                                )}
                              </div>
                            </>
                          )}
                        </SizeMe>
                      </div>
                    );
                  })}
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        </>
      );
    } else {
      return <>Forms Unavailable</>;
    }
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  business: state.business,
  site: state.site,
});
export default connect(mapStateToProps, { getAppointmentForms, submitAppointmentForms, clearErrors })(withSize()(AppComponent));
