import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Alert } from 'react-bootstrap';
import dateFormat from 'dateformat';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import TextField from '@material-ui/core/TextField';

import ModalDepot from './ModalDepot';
import staff_placeholder from './../../../assets/images/staff_placeholder.png';
import { getBookedAppointments, getHours, addBookingData, verifyPerson, getBusiness, getStaffs } from '../../../redux/actions/businessActions';
import { clearErrors } from '../../../redux/actions/siteActions';
import { FiBox } from 'react-icons/fi';
import { MdAddCircle } from 'react-icons/md';

class AppComponent extends Component {
  calendarResourceRef = React.createRef();
  calendarDayGridRef = React.createRef();

  constructor(props) {
    super();
    this.state = {
      componentID: 'PAGE_BOOKING_COMPONENT',
      businessID: props.props.state.selectedBusiness.id,
      selectedStaff: {
        id: null,
        first_name: 'Anyone',
        last_name: '',
        businessHours: props.props.props.business.business_hours,
        // set the default business hours for this staff
      },
      selectedProducts: props.props.state.selectedProducts,
      selectedProductIDsArray: props.props.state.selectedProductIDsArray,
      isAddPersonModalShow: false,
      isVerifyBookingModalShow: false,
      isPromptEmailModalShow: false,
      isSelectProductsModalShow: false,
      customer: {},
      firstName: '',
      lastName: '',
      phone: '',
      bookingNotes: '',
      IsCodeSentToCustomer: false,
      IsBookingSuccess: false,
      isDataLoaded: false,
      minBookingDate: new Date(new Date(new Date().setDate(new Date().getDate() + 1)).setHours(0, 0, 0, 0)), // set the minimum booking date
      availableBookingDates: [],
      selectedBookingDate: {},
      availableBookingTimes: [],
      selectedBookingTime: {},
      isBookingError: false,
    };
    this.onChange = this.onChange.bind(this);
    this.openModal = this.openModal.bind(this);
    this.handleDateClick = this.handleDateClick.bind(this);
    this.setProducts = this.setProducts.bind(this);
  }
  componentDidMount = () => {
    this.props.getStaffs(this.state.businessID);
    this.props.getBookedAppointments(this.state.businessID, this);
  };
  componentDidUpdate = (prevProps, prevState) => {
    // this runs every time state changes
    // get available dates
    const selectedDate =
      Object.keys(this.state.selectedBookingDate).length !== 0 && this.state.selectedBookingDate.constructor === Object
        ? this.state.selectedBookingDate
        : this.state.availableBookingDates[0];
    if (selectedDate) {
      // pass in available booking dates
      this.getAvailableTimesForDate(selectedDate, this.props.business.booked_appointments);
    }
  };
  setProducts(products) {
    this.onChange(products, 'selectedProducts');
    var selectedProductIDs = [];
    products.forEach((product) => {
      selectedProductIDs.push(product.id);
    });
    this.setState({ selectedProductIDsArray: selectedProductIDs });
  }
  openModal = (modalName, modalState) => {
    switch (modalName) {
      case 'PERSON_ADD':
        if (modalState) {
          this.setState({ isAddPersonModalShow: true });
        } else {
          this.setState({ isAddPersonModalShow: false });
          this.props.clearErrors();
        }
        break;
      case 'EMAIL_PROMPT':
        if (modalState) {
          this.setState({ isPromptEmailModalShow: true });
        } else {
          this.setState({ isPromptEmailModalShow: false });
          this.props.clearErrors();
        }
        break;
      case 'PERSON_VERIFY':
        if (modalState) {
          this.setState({ isVerifyBookingModalShow: true });
        } else {
          this.setState({ isVerifyBookingModalShow: false });
          this.props.clearErrors();
        }
        break;
      case 'PRODUCTS_SELECT_TABLE':
        modalState ? this.setState({ isSelectProductsModalShow: true }) : this.setState({ isSelectProductsModalShow: false });
        break;
      default:
    }
  };
  onChange = async (e, targetName) => {
    if (targetName) {
      await this.setState({ [targetName]: e });
    } else {
      if (e.target.name === 'firstName') {
        await this.setState({ [e.target.name]: e.target.value.trim() });
      } else {
        await this.setState({ [e.target.name]: e.target.value });
      }
    }
  };
  handleDateClick = async (e) => {
    let miniCalendar = this.calendarDayGridRef.current.getApi(); // change mini calendar to selected date
    await miniCalendar.gotoDate(e.date);
    await this.setState({
      availableBookingDates: [],
      availableBookingTimes: [],
      selectedBookingDate: e,
      selectedBookingTime: {},
      isBookingError: false,
    });
  };
  handleTimeClick = (e) => {
    this.setState({ selectedBookingDate: e, selectedBookingTime: e, isBookingError: false });
  };
  handleStaffClick = async (staff) => {
    await this.setState({
      availableBookingDates: [],
      availableBookingTimes: [],
      selectedStaff: staff,
      // selectedBookingDate: {},
      selectedBookingDate:
        Object.keys(this.state.selectedBookingDate).length !== 0 && this.state.selectedBookingDate.constructor === Object
          ? this.state.selectedBookingDate
          : {},
      selectedBookingTime: {},
      isBookingError: false,
    });
  };
  getDateString = (e) => {
    // get the date string for every date shown
    var dayDate = new Date(e.date);
    let dayYear = dayDate.getFullYear();
    let dayMonth = (dayDate.getMonth() + 1).toString().padStart(2, '0');
    let dayDay = dayDate.getDate().toString().padStart(2, '0');
    let dayDateString = dayYear + '-' + dayMonth + '-' + dayDay;
    return dayDateString;
  };
  disableNonBusinessDays = async (e) => {
    const dateString = this.getDateString(e);
    let nonBusinessDays = await document.querySelectorAll('.non-business-day');
    nonBusinessDays.forEach(async (day) => {
      await day.classList.remove('non-business-day');
    });
    var nonBusinessDateCell = await document.querySelector('.fc-daygrid-day[data-date="' + dateString + '"]');
    if (nonBusinessDateCell) {
      await nonBusinessDateCell.classList.add('non-business-day');
    }
  };
  enableBusinessDays = async (e) => {
    var dayDate = new Date(e.date);
    let dayYear = dayDate.getFullYear();
    let dayMonth = (dayDate.getMonth() + 1).toString().padStart(2, '0');
    let dayDay = dayDate.getDate().toString().padStart(2, '0');
    let dayDateString = dayYear + '-' + dayMonth + '-' + dayDay;

    // add the class and make cursor pointer
    let businessDays = await document.querySelectorAll('.is-business-day');
    businessDays.forEach(async (day) => {
      await day.classList.remove('is-business-day');
    });
    var businessDateCell = await document.querySelector('.fc-daygrid-day[data-date="' + dayDateString + '"]');
    if (businessDateCell) {
      await businessDateCell.classList.add('is-business-day');
    }
  };
  handleDayCellClassNames = (e) => {
    // this runs every time page renders
    const businessDays = this.getStaffBusinessDays(); // get the business days for the selected staff
    if (businessDays.includes(e.dow)) {
      this.enableBusinessDays(e); // if date is a business day, enable it
    } else {
      this.disableNonBusinessDays(e); // if date is not a business day, disable it
    }
  };
  getAvailableBookingDates = (daysArray) => {
    const staffBookingDates = [];
    daysArray.map(async (day) => {
      var calendarDate = new Date(day.date).getTime();
      var minBookingDate = new Date(this.state.minBookingDate).getTime();
      if (calendarDate >= minBookingDate) {
        // get only days equal or greater the minimum/start booking date
        const staffBusinessDays = this.getStaffBusinessDays();
        if (staffBusinessDays.includes(day.dow)) {
          staffBookingDates.push(day);
        }
      }
    });

    // auto highlight earliest booking date if a date has not been selected
    if (this.state.availableBookingDates.length === 0 && staffBookingDates.length !== 0) {
      this.setState({ availableBookingDates: staffBookingDates });
    }
  };
  getStaffBusinessDays = () => {
    let businessDaysArray = [];
    // get the working days depending on the selected staff
    let businessHours =
      this.state.selectedStaff && this.state.selectedStaff.businessHours
        ? this.state.selectedStaff.businessHours
        : this.props.business.business_hours;
    businessHours.forEach((hour) => {
      // check if business days already contain weekday, if so, skip, otherwise push
      if (!businessDaysArray.includes(hour.weekday)) {
        businessDaysArray.push(hour.weekday);
      }
    });
    return businessDaysArray;
  };
  getAvailableTimesForDate = async (selectedDate, booked_appointments) => {
    let businessHours =
      this.state.selectedStaff && this.state.selectedStaff.businessHours
        ? this.state.selectedStaff.businessHours
        : this.props.business.business_hours;

    var dateTime = [];
    var unavalableTimes = [];
    var availableTimes = [];
    var selectedBookingDate = {};

    if (this.state.availableBookingTimes.length === 0) {
      // get only the hours,minslot,maxslot for the selectedDate
      businessHours.forEach((hour) => {
        // get start and end date time of the business hour for the selected date
        let selectedDOW = new Date(selectedDate.date).getDay();
        if (hour.weekday === selectedDOW) {
          const startTime = hour.startTime.split(':');
          const hourStart = startTime[0];
          const minuteStart = startTime[1];
          const endTime = hour.endTime.split(':');
          const hourEnd = endTime[0];
          const minuteEnd = endTime[1];
          const newStartTime = new Date(new Date(selectedDate.date).setHours(hourStart, minuteStart, 0));
          const newEndTime = new Date(new Date(selectedDate.date).setHours(hourEnd, minuteEnd, 0));
          dateTime.push({
            startTime: newStartTime,
            endTime: newEndTime,
          });
        }
      });

      dateTime.forEach((time) => {
        for (
          var d = time.startTime;
          d < time.endTime;
          d = new Date(new Date(d).setMinutes(d.getMinutes() + this.props.business.business.booking_interval))
        ) {
          unavalableTimes.push(d);
        }
      });

      if (unavalableTimes.length > 0) {
        unavalableTimes.forEach((time) => {
          const result = booked_appointments.filter(
            (appt) => new Date(time).getTime() >= new Date(appt.start).getTime() && new Date(time).getTime() < new Date(appt.end).getTime()
          );
          if (result.length <= 0) {
            availableTimes.push({ date: time });
          }
        });
      }

      if (availableTimes.length > 0 && dateTime.length > 0) {
        await this.setState({ availableBookingTimes: availableTimes });
        selectedBookingDate = availableTimes[0];
      } else {
        selectedBookingDate = selectedDate.date;
      }
    } else {
      // if the selectedBookingDate exists
      // and if it is mid night, then set the time period to exactly mid night
      // else it takes the selected date
      // const selectedBookingDate =
      //   Object.keys(this.state.selectedBookingDate).length !== 0 && this.state.selectedBookingDate.constructor === Object
      //     ? new Date(this.state.selectedBookingDate.date).getHours() === 0 && new Date(this.state.selectedBookingDate.date).getMinutes() === 0
      //       ? new Date(
      //           new Date(this.state.selectedBookingDate.date).setHours(
      //             new Date(this.state.availableBookingTimes[0].date).getHours(),
      //             new Date(this.state.availableBookingTimes[0].date).getMinutes(),
      //             0
      //           )
      //         )
      //       : this.state.selectedBookingDate.date
      //     : this.state.availableBookingTimes[0].date;
      selectedBookingDate =
        this.state.availableBookingTimes.length > 0 ? this.state.availableBookingTimes[0].date : this.state.selectedBookingDate.date;
    }
    this.highlightSelectedDate(selectedBookingDate);
  };
  highlightSelectedDate = async (date) => {
    // remove highlights
    var days = await document.querySelectorAll('.selectedDate');
    days.forEach(async (day) => {
      await day.classList.remove('selectedDate');
    });
    if (date) {
      var selectedDate = new Date(Object.keys(this.state.selectedBookingDate).length !== 0 ? this.state.selectedBookingDate.date : date);

      let year = selectedDate.getFullYear();
      let month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
      let day = selectedDate.getDate().toString().padStart(2, '0');
      let selectedDateString = year + '-' + month + '-' + day;

      var dayDate = new Date(date);
      let dayYear = dayDate.getFullYear();
      let dayMonth = (dayDate.getMonth() + 1).toString().padStart(2, '0');
      let dayDay = dayDate.getDate().toString().padStart(2, '0');
      let dayDateString = dayYear + '-' + dayMonth + '-' + dayDay;

      // match the date format with the calendar's elements format
      if (selectedDateString === dayDateString) {
        // re-established highlight
        var selectedDateCell = await document.querySelector('.fc-daygrid-day[data-date="' + dayDateString + '"]');
        if (selectedDateCell) {
          await selectedDateCell.classList.add('selectedDate');
        }
      }
    }
  };
  getDaysArray = (startDate, endDate) => {
    for (var dateArray = [], date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
      dateArray.push({ date: new Date(date), dow: new Date(date).getDay() });
    }
    return dateArray;
  };
  render() {
    const { isDataLoaded } = this.state;
    var { business_hours, staffs } = this.props.business;
    // const { errors } = this.props.site;
    const defaultStaff = {
      id: null, // must be null
      first_name: 'Anyone',
      last_name: '',
      businessHours: business_hours, // set the default business hours for this staff
    };
    staffs = [defaultStaff, ...staffs];
    staffs.map((staff) => {
      staff.title = staff.first_name + ' ' + staff.last_name;
      // if staff doesn't have business hours, then it will take on the account's business hours
      if (staff.businessHours.length <= 0) {
        staff.businessHours = business_hours;
      }
      return staff;
    });
    // set the date which can be booked, one day ahead
    return isDataLoaded ? (
      this.state.IsBookingSuccess ? (
        <>
          <center>
            <div className='font-size-larger padding'>Thank you for booking with us!</div>
            <hr></hr>
            <div className='margin-bottom-15'>You'll received a confirmation email of your booking details once it is approved.</div>
            <Button
              variant='secondary'
              onClick={() => {
                this.props.props.function.openModal('PAGE_BOOKING_COMPONENT', false);
                this.props.props.function.onClearSelectedProducts();
              }}
              className='width-100'
            >
              Done
            </Button>
          </center>
        </>
      ) : (
        <>
          <div className='width-max-980 margin-left-auto margin-right-auto'>
            <div className='hr-text font-size-medium font-weight-bold'>Select Staff</div>
            <div className='staff-selection display-flex flex-wrap-nowrap overflow-x-auto' style={{ whiteSpace: 'nowrap', overflow: 'auto' }}>
              {staffs.map((staff, i) => {
                const staff_media_source = staff.staff_media_source ? '/api/static/media/staff/thumbnail/' + staff.staff_media_source : '';
                return (
                  <div key={i}>
                    <div className='margin-15'>
                      <center>
                        <div>
                          <a
                            href='#!'
                            onClick={async (e) => {
                              e.stopPropagation(); // prevent click through on select row or rowEvents
                              // reset states upon change of staff
                              this.handleStaffClick(staff);
                            }}
                          >
                            <img
                              alt='product_thumbnail'
                              src={staff_media_source ? staff_media_source : staff_placeholder}
                              width='60px'
                              className={
                                this.state.selectedStaff.id === staff.id
                                  ? 'thumbnail border-radius-25 margin-bottom-5 border-dodgerblue'
                                  : 'thumbnail border-radius-25 margin-bottom-5 border'
                              }
                            />
                          </a>
                        </div>
                        <span className={this.state.selectedStaff.id === staff.id ? 'font-weight-bold' : ''}>
                          {staff.first_name} {staff.last_name.charAt(0)}
                        </span>
                      </center>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className='fc-booking-calendar'>
              <FullCalendar
                // SETTINGS
                ref={this.calendarDayGridRef}
                plugins={[dayGridPlugin, interactionPlugin]}
                height='auto'
                contentHeight='auto'
                headerToolbar={{
                  start: false,
                  center: 'prev title next',
                  end: false,
                }}
                customButtons={{
                  prev: {
                    text: 'Prev',
                    click: (e) => {
                      let miniCalendar = this.calendarDayGridRef.current.getApi();
                      miniCalendar.prev();
                      this.setState({ selectedBookingDate: {} });
                      const daysArray = this.getDaysArray(
                        miniCalendar.getCurrentData().viewApi.activeStart,
                        miniCalendar.getCurrentData().viewApi.activeEnd
                      );

                      this.setState({
                        selectedBookingDate: {},
                        availableBookingDates: [],
                        availableBookingTimes: [],
                      });

                      this.getAvailableBookingDates(daysArray);
                    },
                  },
                  next: {
                    text: 'Next',
                    click: (e) => {
                      let miniCalendar = this.calendarDayGridRef.current.getApi();
                      miniCalendar.next();
                      this.setState({ selectedBookingDate: {} });
                      const daysArray = this.getDaysArray(
                        miniCalendar.getCurrentData().viewApi.activeStart,
                        miniCalendar.getCurrentData().viewApi.activeEnd
                      );

                      this.setState({
                        selectedBookingDate: {},
                        availableBookingDates: [],
                        availableBookingTimes: [],
                      });

                      this.getAvailableBookingDates(daysArray);
                    },
                  },
                }}
                buttonText={{ today: 'Today', month: 'Month', week: 'Week', day: 'Day', list: 'List' }}
                // DATA
                businessHours={this.state.selectedStaff.businessHours ? this.state.selectedStaff.businessHours : business_hours}
                // CONSTRAINS
                selectConstraint='businessHours'
                validRange={{
                  start: this.state.minBookingDate,
                  // end: Date.now() + 7776000, // sets end dynamically to 90 days after now (86400*90)
                }}
                // selectLongPressDelay={50}
                // eventLongPressDelay={500}
                fixedWeekCount={false}
                showNonCurrentDates={false}
                // ACTIONS
                dateClick={(e) => this.handleDateClick(e)}
                dayCellClassNames={(e) => this.handleDayCellClassNames(e)}
                datesSet={async (e) => {
                  const daysArray = this.getDaysArray(e.start, e.end);
                  this.getAvailableBookingDates(daysArray);
                }}
              />
            </div>
            <div className='hr-text font-size-medium font-weight-bold'>Select Available Date &amp; Time</div>
            <div className='fc-booking-times'>
              <div
                className='staff-selection display-flex flex-wrap-nowrap overflow-x-auto'
                style={{ whiteSpace: 'nowrap', overflow: 'auto', padding: '20px' }}
              >
                {this.state.availableBookingTimes.length > 0 ? (
                  <>
                    {this.state.availableBookingTimes.map((time, i) => {
                      return (
                        <div key={i}>
                          <button
                            className={
                              new Date(time.date).getTime() === new Date(this.state.selectedBookingTime.date).getTime()
                                ? 'booking-time-button-selected'
                                : 'booking-time-button'
                            }
                            onClick={(e) => {
                              const data = {
                                date: time.date,
                              };
                              // this.handleTimeClick(time);
                              this.handleTimeClick(data);
                            }}
                          >
                            {dateFormat(time.date, 'h:MM TT')}
                          </button>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div>
                    No available time slots on selected day {dateFormat(this.state.selectedBookingDate.date, 'mm/dd/yyyy')}.<br />
                    Please choose another day.
                  </div>
                )}
              </div>
            </div>
            <div className='hr-text font-size-medium font-weight-bold'>Selected Products &amp; Services</div>
            <div className='fc-booking-items'>
              <div className='display-flex flex-direction-column padding-top-15 padding-bottom-15'>
                {this.state.selectedProducts.map((product, i) => {
                  // const price = product.price.split('.');

                  var price = [];
                  if (product.price.includes('.')) {
                    price = product.price.split('.');
                    if (price[0] === '') {
                      price[0] = 0;
                    } else {
                      price[0].toLocaleString();
                    }
                  } else {
                    price[0] = product.price;
                  }

                  const ProductMedia = () => {
                    const product_media_source = product.product_media_source
                      ? '/api/static/media/product/thumbnail/' + product.product_media_source
                      : '';
                    return product_media_source ? (
                      <a
                        href='#!'
                        onClick={(e) => {
                          e.stopPropagation(); // prevent click through on select row or rowEvents
                          this.setState({ selectedProduct: product });
                          this.openModal('PRODUCT_VIEW_ALBUM', true);
                        }}
                      >
                        <div style={{ width: 'fit-content' }}>
                          <img alt='product_thumbnail' src={product_media_source} width='60px' className='thumbnail border-radius-5' />
                        </div>
                      </a>
                    ) : (
                      <>
                        <div className='padding-5 border-radius-5' style={{ width: 'fit-content' }}>
                          <FiBox size={38} className='color-white' />
                        </div>
                      </>
                    );
                  };
                  return (
                    <div key={i}>
                      <div className='display-flex flex-direction-column padding-10 cursor-default'>
                        <div className='display-flex color-black'>
                          <div className='display-flex flex-direction-row flex-wrap-nowrap'>
                            <ProductMedia />
                            <div className='display-flex justify-content-space-between align-items-center margin-left-15'>
                              <div className='display-flex justify-content-space-between padding-right-10 padding-bottom-5'>
                                <div className='font-size-medium color-black'>{product.name} </div>
                                {product.price ? (
                                  <div>
                                    <span>
                                      {product.time ? product.time + ' minutes |' : ''} <sup className='font-size-smaller'>$</sup>
                                      <span className='font-size-medium-large color-black'>{price[0]}</span>
                                      <sup className='font-size-smaller'>{price[1]}</sup>
                                    </span>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div>
                <a href='#/' onClick={() => this.openModal('PRODUCTS_SELECT_TABLE', true)}>
                  <MdAddCircle size={25} className='color-deepskyblue' /> Edit item(s)
                </a>
              </div>
            </div>
            <div className='padding'>
              <TextField
                label='Notes and Requests (optional)'
                multiline
                rows='3'
                name='bookingNotes'
                margin='normal'
                variant='outlined'
                value={this.state.bookingNotes}
                onChange={(e) => {
                  this.setState({ bookingNotes: e.target.value });
                }}
                inputProps={{ style: { fontSize: 15 } }} // font size of input text
                InputLabelProps={{ style: { fontSize: 15 } }} // font size of input label
                className='display-flex'
              />
            </div>
            <div className='fc-booking-button margin-15' style={{ marginTop: '0px' }}>
              <Alert
                variant='warning'
                show={this.state.isBookingError ? true : false}
                onClose={(e) => this.setState({ isBookingError: false })}
                dismissible
              >
                Please select an available date and time to continue.
              </Alert>
              <Button
                className='display-flex'
                onClick={() => {
                  if (Object.keys(this.state.selectedBookingTime).length !== 0 && this.state.selectedBookingTime.constructor === Object) {
                    this.openModal('PERSON_VERIFY', true);
                  } else {
                    this.setState({ isBookingError: true });
                  }
                }}
              >
                Continue
              </Button>
            </div>
          </div>
          <ModalDepot
            state={this.state}
            props={this.props}
            function={{
              openModal: this.openModal,
              setProducts: this.setProducts,
              setCodeSentToCustomer: this.setCodeSentToCustomer,
              onAddBookingData: this.onAddBookingData,
            }}
          />
        </>
      )
    ) : (
      ''
    );
  }
}
const mapStateToProps = (state) => ({
  business: state.business,
  site: state.site,
  users: state.users,
});
export default connect(mapStateToProps, {
  getBookedAppointments,
  getHours,
  addBookingData,
  verifyPerson,
  getBusiness,
  getStaffs,
  clearErrors,
})(AppComponent);
