import React, { Component } from 'react';
import { connect } from 'react-redux';
import dateFormat from 'dateformat';

class AppComponent extends Component {
  render() {
    return (
      <>
        <div className='margin-bottom-50'>
          <div className='display-flex align-items-center justify-content-center padding'>
            {this.props.state.businessHours && this.props.state.businessHours.length > 0 ? (
              <table className='table-business-hours'>
                {this.props.state.businessHours.map((hour, i) => {
                  switch (hour.weekday) {
                    case 0:
                      hour.weekday = 'Sunday';
                      break;
                    case 1:
                      hour.weekday = 'Monday';
                      break;
                    case 2:
                      hour.weekday = 'Tuesday';
                      break;
                    case 3:
                      hour.weekday = 'Wednesday';
                      break;
                    case 4:
                      hour.weekday = 'Thursday';
                      break;
                    case 5:
                      hour.weekday = 'Friday';
                      break;
                    case 6:
                      hour.weekday = 'Saturday';
                      break;
                    default:
                      break;
                  }

                  let openTime, closeTime;

                  if (Number(hour.start)) {
                    openTime = dateFormat(Number(hour.start), 'h:MM TT');
                    closeTime = dateFormat(Number(hour.end), 'h:MM TT');
                  } else {
                    // openTime = dateFormat(hour.start, 'h:MM');
                    // closeTime = dateFormat(hour.end, 'h:MM');
                    openTime = hour.start;
                    closeTime = hour.end;
                  }

                  return (
                    <tbody key={i}>
                      <tr>
                        <td>{hour.weekday}</td>
                        <td className='white-space-nowrap'>{openTime}</td>
                        <td>—</td>
                        <td className='white-space-nowrap'>{closeTime}</td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
            ) : (
              <>No hours yet.</>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps)(AppComponent);
