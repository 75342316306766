import React, { Component } from 'react';
import { connect } from 'react-redux';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { getCheckinStats } from '../../../redux/actions/businessActions';

import printer_qr_code from './../../../assets/images/printer_qr_code.jpg';
import checkin_with_phone from './../../../assets/images/checkin_with_phone.jpg';
import ipad_device from './../../../assets/images/ipad_device.jpg';
import checkin_with_ipad from './../../../assets/images/checkin_with_ipad.jpg';
import view_checkin_desktop from './../../../assets/images/view_checkin_desktop.jpg';
import vanity_url from './../../../assets/images/vanity_url.jpg';
import booking_with_phone from './../../../assets/images/booking_with_phone.jpg';
import confirm_booking_requests from './../../../assets/images/confirm_booking_requests.jpg';
import in_person_booking from './../../../assets/images/in_person_booking.jpg';
import email_marketing from './../../../assets/images/email_marketing.jpg';
import text_marketing from './../../../assets/images/text_marketing.jpg';
import page_showcasing from './../../../assets/images/page_showcasing.jpg';
import page_branding from './../../../assets/images/page_branding.jpg';
import page_audience from './../../../assets/images/page_audience.jpg';
import display_marketing from './../../../assets/images/display_marketing.jpg';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      componentID: 'DASHBOARD',
      tabIndexValue: 0,
    };
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  openModal(modalName, modalState, modalData) {
    switch (modalName) {
      default:
    }
  }
  handleTabChange(e, newValue) {
    this.setState({ tabIndexValue: newValue });
  }
  render() {
    return (
      <>
        <div className='display-flex align-items-center flex-direction-column' style={{ minHeight: '100%', height: '100%' }}>
          <div className='width-100'>
            <div>
              <div className='display-flex flex-direction-column align-items-center justify-content-center margin-top-10'>
                <div className='margin-top-15 font-size-large'>Welcome, {this.props.users.token.first_name}.</div>
                <div className='font-size-medium-large'>Select an OSL service to get started.</div>
                <div className='display-flex justify-content-space-evenly width-100 margin-top-15'>
                  {/* <AppBar position='static' color='default'> */}
                  <div className='display-flex justify-content-center align-items-center bg-whitesmoke'>
                    <Tabs
                      value={this.state.tabIndexValue}
                      onChange={(e, i) => this.handleTabChange(e, i)}
                      indicatorColor='primary'
                      // textColor='primary'
                      scrollButtons='auto'
                      // aria-label='scrollable auto tabs example'
                      // variant='scrollable'
                      centered
                    >
                      <Tab label='Check-In' />
                      <Tab label='Booking' />
                      <Tab label='Marketing' />
                      <Tab label='Business Page' />
                    </Tabs>
                  </div>
                  {/* </AppBar> */}
                  <TabPanel value={this.state.tabIndexValue} index={0} className='width-100'>
                    <center>
                      <div className='margin-bottom-15 font-size-medium-large'>Ways to Check In Your Customers</div>
                      <div className='display-flex width-100 justify-content-space-evenly'>
                        <div className='bg-whitesmoke border-radius-25 padding margin-top-15'>
                          <div className='padding'>
                            <div className='font-size-large'>CONTACTLESS</div>
                            <div
                              className='bg-whitesmoke color-black padding border-radius-25 margin-bottom-15 margin-top-15 font-size-large'
                              style={{ width: '65px' }}
                            >
                              1
                            </div>
                            <div className='margin-bottom-10 font-weight-bold'>Print Your Check-In QR Code</div>
                            <div>
                              <img src={printer_qr_code} alt='printer_qr_code' className='border-radius-25' />
                            </div>
                            <div className='width-max-360 margin-top-10'>
                              Print out as many check-in QR code as needed and place them in convenient locations.{' '}
                              <a href='/account/checkinapp'>Get your check-in QR code here</a>.
                            </div>
                          </div>
                          <div className='padding'>
                            <div
                              className='bg-whitesmoke color-black padding border-radius-25 margin-bottom-15 margin-top-15 font-size-large'
                              style={{ width: '65px' }}
                            >
                              2
                            </div>
                            <div className='margin-bottom-10 font-weight-bold'>Customers Check In On Their Device</div>
                            <div>
                              <img src={checkin_with_phone} alt='checkin_with_phone' className='border-radius-25' />
                            </div>
                            <div className='width-max-360 margin-top-10'>
                              Customers can scan your QR code to check in using their own phone or devices.
                            </div>
                          </div>
                          <div className='padding'>
                            <div
                              className='bg-whitesmoke color-black padding border-radius-25 margin-bottom-15 margin-top-15 font-size-large'
                              style={{ width: '65px' }}
                            >
                              3
                            </div>
                            <div className='margin-bottom-10 font-weight-bold'>View and Update Customer Check-Ins</div>
                            <div>
                              <img src={view_checkin_desktop} alt='view_checkin_desktop' className='border-radius-25' />
                            </div>
                            <div className='width-max-360 margin-top-10'>
                              Once customer check in, their data will show up on the check-ins page of your account.{' '}
                              <a href='account/checkins'>View and update check-ins</a> from any of your devices.{' '}
                            </div>
                          </div>
                        </div>
                        <div className='bg-whitesmoke border-radius-25 padding margin-top-15'>
                          <div className='padding'>
                            <div className='font-size-large'>NON CONTACTLESS</div>
                            <div
                              className='bg-whitesmoke color-black padding border-radius-25 margin-bottom-15 margin-top-15 font-size-large'
                              style={{ width: '65px' }}
                            >
                              1
                            </div>
                            <div className='margin-bottom-10 font-weight-bold'>Start the Application On Your Device</div>
                            <div>
                              <img src={ipad_device} alt='ipad_device' className='border-radius-25' />
                            </div>
                            <div className='width-max-360 margin-top-10'>
                              Setup and start the check-in application on your own device. On your device,{' '}
                              <a href='/account/checkinapp'>visit the application page</a> to get started.
                            </div>
                          </div>
                          <div className='padding'>
                            <div
                              className='bg-whitesmoke color-black padding border-radius-25 margin-bottom-15 margin-top-15 font-size-large'
                              style={{ width: '65px' }}
                            >
                              2
                            </div>
                            <div className='margin-bottom-10 font-weight-bold'>Customers Check In On Your Device</div>
                            <div>
                              <img src={checkin_with_ipad} alt='checkin_with_phone' className='border-radius-25' />
                            </div>
                            <div className='width-max-360 margin-top-10'>Direct customers to check in when they visit your business.</div>
                          </div>
                          <div className='padding'>
                            <div
                              className='bg-whitesmoke color-black padding border-radius-25 margin-bottom-15 margin-top-15 font-size-large'
                              style={{ width: '65px' }}
                            >
                              3
                            </div>
                            <div className='margin-bottom-10 font-weight-bold'>View and Update Customer Check-Ins</div>
                            <div>
                              <img src={view_checkin_desktop} alt='view_checkin_desktop' className='border-radius-25' />
                            </div>
                            <div className='width-max-360 margin-top-10'>
                              Once customer check in, their data will show up on the check-ins page of your account.{' '}
                              <a href='/account/checkins'>View and update check-ins</a> from any of your devices.
                            </div>
                          </div>
                        </div>
                      </div>
                    </center>
                  </TabPanel>
                  <TabPanel value={this.state.tabIndexValue} index={1} className='width-100'>
                    <center>
                      <div className='font-size-medium-large margin-bottom-15'>All the Ways to Book</div>
                      <div className='display-flex justify-content-space-evenly'>
                        <div className='bg-whitesmoke border-radius-25 padding margin-top-15'>
                          <div className='padding'>
                            <div className='font-size-large'>Online &amp; Mobile Booking</div>
                            <div
                              className='bg-whitesmoke color-black padding border-radius-25 margin-bottom-15 margin-top-15 font-size-large'
                              style={{ width: '65px' }}
                            >
                              1
                            </div>
                            <div className='margin-bottom-10 font-weight-bold'>Share Your Vanity Url or QR Code</div>
                            <div>
                              <img src={vanity_url} alt='vanity_url' className='border-radius-25' />
                            </div>
                            <div className='width-max-360 margin-top-10'>
                              Give customers booking access to your business page with your unique vanity url or QR code.{' '}
                              <a href='/account/checkinapp'>Create or retrieve your vanity url and QR code here</a>.
                            </div>
                          </div>
                          <div className='padding'>
                            <div
                              className='bg-whitesmoke color-black padding border-radius-25 margin-bottom-15 margin-top-15 font-size-large'
                              style={{ width: '65px' }}
                            >
                              2
                            </div>
                            <div className='margin-bottom-10 font-weight-bold'>Customer Book At Their Convenience</div>
                            <div>
                              <img src={booking_with_phone} alt='booking_with_phone' className='border-radius-25' />
                            </div>
                            <div className='width-max-360 margin-top-10'>
                              Customers can visit your vanity url or scan your business page QR code to start booking with your business.
                            </div>
                          </div>
                          <div className='padding'>
                            <div
                              className='bg-whitesmoke color-black padding border-radius-25 margin-bottom-15 margin-top-15 font-size-large'
                              style={{ width: '65px' }}
                            >
                              3
                            </div>
                            <div className='margin-bottom-10 font-weight-bold'>Confirm Customer Booking Request</div>
                            <div>
                              <img src={confirm_booking_requests} alt='confirm_booking_requests' className='border-radius-25' />
                            </div>
                            <div className='width-max-360 margin-top-10'>
                              Customer booking will show up on your booking calendar. From there, you can confirm, decline, or alter the booking as
                              needed. <a href='/account/bookings/'>Check out your booking calendar here</a>.
                            </div>
                          </div>
                        </div>
                        <div className='bg-whitesmoke border-radius-25 padding margin-top-15'>
                          <div className='padding'>
                            <div className='font-size-large'>In-Person/On Call Booking</div>
                            <div
                              className='bg-whitesmoke color-black padding border-radius-25 margin-bottom-15 margin-top-15 font-size-large'
                              style={{ width: '65px' }}
                            >
                              1
                            </div>
                            <div className='margin-bottom-10 font-weight-bold'>Book Customers on Your Devices</div>
                            <div>
                              <img src={in_person_booking} alt='in_person_booking' className='border-radius-25' />
                            </div>
                            <div className='width-max-360 margin-top-10'>
                              You can book customers from your devices when they visit your business. Go to the booking section on your account's side
                              menu to <a href='/account/bookings'>view the booking calendar</a>. Click the plus symbol in the upper right corner to
                              start a new booking.
                            </div>
                          </div>
                        </div>
                      </div>
                    </center>
                  </TabPanel>
                  <TabPanel value={this.state.tabIndexValue} index={2} className='width-100'>
                    <center>
                      <div className='font-size-medium-large margin-bottom-15'>Utilizing Our Marketing Tools</div>
                      <div className='display-flex justify-content-space-evenly'>
                        <div className='bg-whitesmoke border-radius-25 padding margin-top-15'>
                          <div className='padding'>
                            <div className='font-size-large'>Email Blast</div>
                            <div className='margin-bottom-10 font-weight-bold margin-top-10 margin-bottom-10'>Finding Your Target Audience</div>
                            <div>
                              <img src={email_marketing} alt='email_marketing' className='border-radius-25' />
                            </div>
                            <div className='width-max-360 margin-top-10'>
                              Email blast is a great way to get your word out when you need to run an ad or promotional offer to the right audience.
                              To get started, <a href='/account/marketing'>head over to the marketing section</a> from your account side menu.
                            </div>
                          </div>
                        </div>
                        <div className='bg-whitesmoke border-radius-25 padding margin-top-15'>
                          <div className='padding'>
                            <div className='font-size-large'>Smart Text Messaging</div>
                            <div className='margin-bottom-10 font-weight-bold margin-top-10 margin-bottom-10'>Automatic Text Recommendation</div>
                            <div>
                              <img src={text_marketing} alt='text_marketing' className='border-radius-25' />
                            </div>
                            <div className='width-max-360 margin-top-10'>
                              Our messaging system automatically recommends your business to previously visited customers as well as new clients,
                              potentially bring in additional revenue and brand awareness.
                            </div>
                          </div>
                        </div>
                        <div className='bg-whitesmoke border-radius-25 padding margin-top-15'>
                          <div className='padding'>
                            <div className='font-size-large'>Digital Marketing</div>
                            <div className='margin-bottom-10 font-weight-bold margin-top-10 margin-bottom-10'>
                              Putting Your Menu &amp; Services on Display
                            </div>
                            <div>
                              <img src={display_marketing} alt='display_marketing' className='border-radius-25' />
                            </div>
                            <div className='width-max-360 margin-top-10'>
                              Want to put your menus and services on a your business TV screen or multiple displays around your shop for customers to
                              see? Ask about our digital display solutions! Message us at (713) 742-2971.
                            </div>
                          </div>
                        </div>
                      </div>
                    </center>
                  </TabPanel>
                  <TabPanel value={this.state.tabIndexValue} index={3} className='width-100'>
                    <center>
                      <div className='font-size-medium-large margin-bottom-15'>A Better Business Page</div>
                      <div className='display-flex justify-content-space-evenly'>
                        <div className='bg-whitesmoke border-radius-25 padding'>
                          <div className='padding'>
                            <div className='font-size-large'>Grow Your Online Presence</div>
                            <div
                              className='bg-whitesmoke color-black padding border-radius-25 margin-bottom-15 margin-top-15 font-size-large'
                              style={{ width: '65px' }}
                            >
                              1
                            </div>
                            <div className='margin-bottom-10 font-weight-bold'>Showcasing Your Menu &amp; Services</div>
                            <div>
                              <img src={page_showcasing} alt='page_showcasing' className='border-radius-25' />
                            </div>
                            <div className='width-max-360 margin-top-10'>
                              Adding your menu, products, and services allows customers to request a booking or order a product (available on
                              specially requested subscription plan) from your business right from your page.
                            </div>
                          </div>
                          <div className='padding'>
                            <div
                              className='bg-whitesmoke color-black padding border-radius-25 margin-bottom-15 margin-top-15 font-size-large'
                              style={{ width: '65px' }}
                            >
                              2
                            </div>
                            <div className='margin-bottom-10 font-weight-bold'>Develop Your Brand</div>
                            <div>
                              <img src={page_branding} alt='page_branding' className='border-radius-25' />
                            </div>
                            <div className='width-max-360 margin-top-10'>
                              Enjoy total control over your business' reputation with customer reviews and ratings (available on specially requested
                              subscription plan). This feature lets your customer submit reviews for your business where you'll be able to respond
                              quickly to feedback or issues.
                            </div>
                          </div>
                          <div className='padding'>
                            <div
                              className='bg-whitesmoke color-black padding border-radius-25 margin-bottom-15 margin-top-15 font-size-large'
                              style={{ width: '65px' }}
                            >
                              3
                            </div>
                            <div className='margin-bottom-10 font-weight-bold'>Grow Your Audience</div>
                            <div>
                              <img src={page_audience} alt='page_audience' className='border-radius-25' />
                            </div>
                            <div className='width-max-360 margin-top-10'>
                              Customer can get latest news or events from your business by becoming a fan of your business page (available on
                              specially requested subscription plan)
                            </div>
                          </div>
                        </div>
                      </div>
                    </center>
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps, { getCheckinStats })(Dashboard);
