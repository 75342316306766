import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { MdCheckCircle, MdDeleteForever, MdSave } from 'react-icons/md';
import { connect } from 'react-redux';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import ModalDepot from '../components/ModalDepot';
import {
  getProductMedia,
  deleteProductMedia,
  setProductMediaMain,
  updateProductMedia,
  changeProductMediaKeyValue,
} from './../../../redux/actions/businessActions';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'PRODUCT_MEDIA_UPDATE_FORM',
      isDeleteProductMediaModalShow: false,
      selectedProductMediaID: props.props.state.selectedProductMediaID,
      selectedProductID: props.props.state.selectedProductID,
      productMediaDescription: '',
    };
    this.openModal = this.openModal.bind(this);
    this.onDeleteProductMedia = this.onDeleteProductMedia.bind(this);
    this.onSetProductMediaMain = this.onSetProductMediaMain.bind(this);
  }
  componentDidMount() {
    this.props.getProductMedia(this.state.selectedProductMediaID);
  }
  openModal(modalName, modalState, modalData) {
    switch (modalName) {
      case 'PRODUCT_MEDIA_DELETE':
        modalState ? this.setState({ isDeleteProductMediaModalShow: true }) : this.setState({ isDeleteProductMediaModalShow: false });
        break;
      default:
    }
  }
  onSetProductMediaMain(e) {
    const data = {
      productMediaID: this.state.selectedProductMediaID,
      productID: this.state.selectedProductID,
      businessID: this.props.users.token.business_id,
    };
    this.props.setProductMediaMain(data, this);
  }
  onDeleteProductMedia(e) {
    e.preventDefault();
    const data = {
      productMediaIDArray: this.state.selectedProductMediaID ? [this.state.selectedProductMediaID] : this.state.selected,
      productID: this.state.selectedProductID,
      businessID: this.props.users.token.business_id,
    };
    this.props.deleteProductMedia(data, this);
  }
  onUpdateProductMedia(e) {
    e.preventDefault();
    const data = {
      productMediaID: this.state.selectedProductMediaID,
      productID: this.state.selectedProductID,
      businessID: this.props.users.token.business_id,
      productMediaDescription: this.props.business.product_media.description,
    };
    this.props.updateProductMedia(data, this);
  }
  onChangeProductMediaKeyValue(id, key, value) {
    const data = {
      id,
      key,
      value,
    };
    this.props.changeProductMediaKeyValue(data);
  }
  modules = {
    toolbar: [
      // [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ color: ['#4285F4', '#EA4335', '#FBBC05', '#34A853', '#000'] }],
      ['link'], //  ['link', 'image'],
      ['clean'],
    ],
  };
  render() {
    const { product_media } = this.props.business;
    let mediaPath = '/api/static/media/product/preview/';
    var fileSource = '';
    if (Object.keys(product_media).length !== 0) {
      if (product_media.is_image) {
        fileSource = mediaPath + product_media.source;
      } else {
        let sourceNameOnly = product_media.source.substr(0, product_media.source.lastIndexOf('.'));
        fileSource = mediaPath + sourceNameOnly + '.jpg';
      }
    }

    return product_media ? (
      <>
        <div>
          <img src={fileSource} className='width-100 border-radius-5' alt='' />
          <div className='margin-top-15'>
            {this.props.props.props.props.props.token ? (
              <>
                <ReactQuill
                  theme='snow'
                  name='formContent'
                  modules={this.modules}
                  value={product_media.description ? product_media.description : this.state.productMediaDescription}
                  onChange={(e) => this.onChangeProductMediaKeyValue(product_media.id, 'description', e)}
                  placeholder='Description'
                  className='margin-bottom-15'
                />{' '}
                <div className='display-flex justify-content-space-between margin-top-15'>
                  <Button
                    variant='secondary'
                    onClick={() => {
                      this.openModal('PRODUCT_MEDIA_DELETE', true);
                    }}
                  >
                    <MdDeleteForever size={25} className='color-tomato' /> Delete
                  </Button>
                  {!product_media.is_main ? (
                    <Button variant='secondary' onClick={(e) => this.onSetProductMediaMain(e)}>
                      <MdCheckCircle size={25} className='color-green' /> Set Main
                    </Button>
                  ) : (
                    ''
                  )}
                  <Button variant='secondary' onClick={(e) => this.onUpdateProductMedia(e)}>
                    <MdSave size={25} className='color-deepskyblue' /> Save
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className='react-quill' dangerouslySetInnerHTML={{ __html: product_media.description ? product_media.description : '' }} />
              </>
            )}
          </div>
        </div>
        <ModalDepot state={this.state} props={this.props} function={{ openModal: this.openModal, onDeleteProductMedia: this.onDeleteProductMedia }} />
      </>
    ) : (
      <>LOADING</>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  business: state.business,
});
export default connect(mapStateToProps, { getProductMedia, deleteProductMedia, setProductMediaMain, updateProductMedia, changeProductMediaKeyValue })(
  AppComponent
);
