import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import ModalDepot from '../components/ModalDepot';
import { getBusiness, changeBusinessKeyValue, updateBusiness } from '../../../redux/actions/businessActions';
import { clearErrors } from './../../../redux/actions/siteActions';
import { MdSave } from 'react-icons/md';

class AppComponent extends Component {
  constructor() {
    super();
    this.state = {
      isSubmitDisabled: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  componentDidMount() {
    this.props.getBusiness(this.props.users.token.business_id, this);
  }
  openModal(modalName, modalState, modalData) {
    switch (modalName) {
      case 'BUSINESS_UPDATE':
        modalState ? this.setState({ isUpdateBusinessModalShow: true }) : this.setState({ isUpdateBusinessModalShow: false });
        break;
      default:
    }
  }
  onChangeBusinessKeyValue(id, key, value) {
    if (key === 'vanity_name') {
      value = value.trim();
    }
    const data = {
      id,
      key,
      value,
    };
    this.props.changeBusinessKeyValue(data);
    if (
      this.props.business.business.business_name !== '' &&
      this.props.business.business.address !== '' &&
      this.props.business.business.city !== '' &&
      this.props.business.business.state !== '' &&
      this.props.business.business.zip !== '' &&
      this.props.business.business.phone !== '' &&
      this.props.business.business.booking_interval !== ''
    ) {
      this.setState({ isSubmitDisabled: false });
    } else {
      this.setState({ isSubmitDisabled: true });
    }
  }
  onUpdateBusiness(e) {
    e.preventDefault();
    const data = {
      businessID: this.props.business.business.id,
      businessName: this.props.business.business.business_name,
      vanityName: this.props.business.business.vanity_name,
      address: this.props.business.business.address,
      city: this.props.business.business.city,
      state: this.props.business.business.state,
      zip: this.props.business.business.zip,
      phone: this.props.business.business.phone,
      about: this.props.business.business.about,
      userToken: this.props.users.token,
    };
    this.props.updateBusiness(data, this);
  }
  modules = {
    toolbar: [
      // [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      [{ color: ['#4285F4', '#EA4335', '#FBBC05', '#34A853', '#000'] }],
      ['link'],
      ['clean'],
    ],
  };
  render() {
    const { business } = this.props.business;
    const { errors } = this.props.site;
    const StateList = () => {
      var states = [
        'AL',
        'AK',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'FL',
        'GA',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'OH',
        'OK',
        'OR',
        'PA',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY',
      ];
      let stateArray = [];
      states.map((state) =>
        stateArray.push(
          <option key={state} value={state}>
            {state}
          </option>
        )
      );
      return stateArray;
    };

    return (
      <>
        <div>
          <div className='display-flex margin-bottom-15'>
            <div>
              <b>Vanity URL and Name (Business Page):</b>
              <div>
                <br />
                onespace.link/page/<span>{business.vanity_name ? business.vanity_name : ''}</span>
              </div>
              <Form.Control
                type='text'
                name='vanity_name'
                placeholder='Vanity Name'
                maxLength={20}
                value={business.vanity_name ? business.vanity_name : ''}
                onChange={(e) => this.onChangeBusinessKeyValue(business.id, 'vanity_name', e.target.value.toLowerCase())}
                className='margin-top-15'
              />
            </div>
            <div className='display-flex margin-top-15'>
              <b>Business Name</b>
              <Form.Control
                type='text'
                name='name'
                placeholder='Name'
                value={business.business_name ? business.business_name : ''}
                onChange={(e) => this.onChangeBusinessKeyValue(business.id, 'business_name', e.target.value)}
                className='margin-top-15'
              />
            </div>
          </div>
          <b>About</b>
          <ReactQuill
            theme='snow'
            name='about'
            modules={this.modules}
            value={business.about ? business.about : ''}
            onChange={(e) => this.onChangeBusinessKeyValue(business.id, 'about', e)}
            placeholder='Description'
            className='margin-top-15 margin-bottom-15'
          />
          <div className='display-flex flex-direction-column margin-top-15'>
            <div className='flex-1 margin-bottom-15'>
              <div className='display-flex align-items-center justify-content-space-between'>
                <b>Address</b>
              </div>
              <hr></hr>
              <div>
                <Form.Control
                  type='text'
                  name='address'
                  placeholder='Address'
                  value={business.address ? business.address : ''}
                  onChange={(e) => this.onChangeBusinessKeyValue(business.id, 'address', e.target.value)}
                  className='margin-top-15'
                />
                <Form.Control
                  type='text'
                  name='city'
                  placeholder='City'
                  value={business.city ? business.city : ''}
                  onChange={(e) => this.onChangeBusinessKeyValue(business.id, 'city', e.target.value)}
                  className='margin-top-15 margin-bottom-15'
                />
                <div className='display-flex margin-bottom-15'>
                  <div className='flex-1 margin-right-15'>
                    <Form.Control
                      as='select'
                      name='state'
                      value={business.state ? business.state : ''}
                      onChange={(e) => this.onChangeBusinessKeyValue(business.id, 'state', e.target.value)}
                    >
                      <option value=''>State</option>
                      <StateList />
                    </Form.Control>
                  </div>
                  <div className='flex-1'>
                    <Form.Control
                      type='text'
                      name='zip'
                      placeholder='Zip'
                      value={business.zip ? business.zip : ''}
                      onChange={(e) => this.onChangeBusinessKeyValue(business.id, 'zip', e.target.value)}
                    />
                  </div>
                </div>
                <Form.Control
                  type='number'
                  name='phone'
                  value={business.phone ? business.phone : ''}
                  placeholder='Business Phone'
                  onChange={(e) => this.onChangeBusinessKeyValue(business.id, 'phone', e.target.value)}
                  className='flex-1 margin-bottom-15'
                />
              </div>
              <Alert
                variant='warning'
                show={errors.errorUpdateBusiness ? true : false}
                onClose={() => this.props.clearErrors()}
                dismissible
                className='margin-top-15'
              >
                {errors.errorUpdateBusiness}
              </Alert>
              <div className='display-flex justify-content-flex-end'>
                <Button variant='secondary' onClick={(e) => this.onUpdateBusiness(e)} disabled={this.state.isSubmitDisabled ? true : false}>
                  <MdSave size={25} className='color-deepskyblue' /> Save
                </Button>
              </div>
            </div>
          </div>
        </div>
        <ModalDepot state={this.state} function={{ openModal: this.openModal }} />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  users: state.users,
  business: state.business,
  site: state.site,
});
export default connect(mapStateToProps, { getBusiness, changeBusinessKeyValue, updateBusiness, clearErrors })(AppComponent);
