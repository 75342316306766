import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getStaffRoles, getStaff, updateStaff, changeStaffKeyValue } from '../../../redux/actions/businessActions';
// import { CirclePicker } from 'react-color';
import ModalDepot from './../components/ModalDepot';
import { MdSave } from 'react-icons/md';
class StaffUpdateForm extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedStaffID: props.props.state.selectedStaff.id,
      isSubmitDisabled: false,
    };
    props.getStaffRoles();
    props.getStaff(this.state.selectedStaffID);
    this.onUpdateStaff = this.onUpdateStaff.bind(this);
  }
  onChangeStaffKeyValue(id, key, value) {
    const data = {
      id,
      key,
      value,
    };
    this.props.changeStaffKeyValue(data);
    if (this.props.business.staff.role_id !== '' && this.props.business.staff.first_name !== '' && this.props.business.staff.last_name !== '') {
      this.setState({ isSubmitDisabled: false });
    } else {
      this.setState({ isSubmitDisabled: true });
    }
  }
  onUpdateStaff(e) {
    e.preventDefault();
    const data = {
      staffID: this.state.selectedStaffID,
      firstName: this.props.business.staff.first_name,
      lastName: this.props.business.staff.last_name,
      color: this.props.business.staff.color,
      phone: this.props.business.staff.phone,
      email: this.props.business.staff.email,
      roleID: this.props.business.staff.role_id,
      businessID: this.props.business.staff.business_id,
    };
    this.props.updateStaff(data, this);
  }
  render() {
    const { staff } = this.props.business;
    return (
      <>
        <div>
          <div>
            <center>
              <span className='font-size-medium'>{staff.role_id === 1 ? staff.role_name : ''}</span>
            </center>
            {staff.role_id !== 1 ? (
              <div>
                <div className='margin-bottom-5 margin-top-15'> First Name</div>
                <Form.Control
                  type='text'
                  name='firstName'
                  placeholder='First Name*'
                  value={staff.first_name ? staff.first_name : ''}
                  onChange={(e) => this.onChangeStaffKeyValue(staff.id, 'first_name', e.target.value)}
                  className='margin-bottom-15 width-100'
                />
                <div className='margin-bottom-5'>Last Name</div>
                <Form.Control
                  type='text'
                  name='lastName'
                  placeholder='Last Name*'
                  value={staff.last_name ? staff.last_name : ''}
                  onChange={(e) => this.onChangeStaffKeyValue(staff.id, 'last_name', e.target.value)}
                  className='margin-bottom-15'
                />
                <div className='margin-bottom-5'>Phone</div>
                <Form.Control
                  type='number'
                  name='phone'
                  placeholder='Optional'
                  value={staff.phone ? staff.phone : ''}
                  onChange={(e) => this.onChangeStaffKeyValue(staff.id, 'phone', e.target.value)}
                  className='margin-bottom-15'
                />
                <div className='margin-bottom-5'>Email</div>
                <Form.Control
                  type='text'
                  name='email'
                  placeholder='Optional'
                  value={staff.email ? staff.email : ''}
                  onChange={(e) => this.onChangeStaffKeyValue(staff.id, 'email', e.target.value)}
                  className='margin-bottom-15'
                />
                {/* <div className='margin-bottom-5'>Color Code</div>
                <div className='margin-bottom-15'>
                  <CirclePicker
                    color={staff.color ? staff.color : '#808080'}
                    onChange={(e) => this.onChangeStaffKeyValue(staff.id, 'color', e.hex)}
                  />
                </div> */}
                <div className='display-flex flex-direction-row flex-wrap-nowrap justify-content-flex-end'>
                  {/* <Button variant='secondary' onClick={(e) => this.props.props.function.openModal('STAFF_UPDATE', false)}>
                    Cancel
                  </Button> */}
                  <Button
                    variant='secondary'
                    onClick={(e) => this.onUpdateStaff(e)}
                    disabled={this.state.isSubmitDisabled ? true : false}
                    className='margin-top-15'
                  >
                    <MdSave size={25} className='color-deepskyblue' /> Save
                  </Button>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>{' '}
        <ModalDepot
          state={this.state}
          props={this.props}
          function={{
            openModal: this.openModal,
            onDeleteStaff: this.onDeleteStaff,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  business: state.business,
  users: state.users,
  site: state.site,
});
export default connect(mapStateToProps, { changeStaffKeyValue, getStaff, updateStaff, getStaffRoles })(StaffUpdateForm);
