import React, { Component } from 'react';
import { Button, DropdownButton, Dropdown, ButtonGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import Truncate from 'react-truncate';
import { MdAddCircle, MdShoppingBasket, MdAddAPhoto, MdEdit, MdInfo, MdRemoveCircle, MdCheckCircle, MdPhoto, MdArchive } from 'react-icons/md';
import { getProducts, deleteProducts, updateProduct, deleteProductCategory } from './../../../redux/actions/businessActions';
import { getStripeSubscriptionProducts } from './../../../redux/actions/subscriptionActions';
import ModalDepot from './../components/ModalDepot';
import { clearErrors } from '../../../redux/actions/siteActions';
import { FiBox, FiPlus } from 'react-icons/fi';

class ComponentTags extends Component {
  constructor() {
    super();
    this.state = {
      componentID: 'PAGE_PRODUCT_COMPONENT',
      currentPage: 1,
      currentPageSize: 100,
      isAddProductModalShow: false,
      isUpdateProductModalShow: false,
      isDeleteProductModalShow: false,
      isViewSubscriptionPlanModalShow: false,
      isAddProductMediaModalShow: false,
      isViewProductAlbumModalShow: false,
      isUpdateProductCategoryModalShow: false,
      isDeleteProductCategoryModalShow: false,
      isPageBookingModalShow: false,
      isUploadProgress: false,
      selectedBusiness: {},
      selectedProduct: {},
      selectedProductPositionAction: 0,
      selectedProductIDsArray: [],
      selectedProducts: [],
      expandedProductDescriptionIDArray: [],
      selectedProductCategory: {},
      expandedText: [],
      truncateText: [],
    };
    this.openModal = this.openModal.bind(this);
    this.onUploadProgress = this.onUploadProgress.bind(this);
    this.onCancelUpload = this.onCancelUpload.bind(this);
    this.onClearSelectedProducts = this.onClearSelectedProducts.bind(this);
  }
  componentDidMount() {
    this.props.getStripeSubscriptionProducts();
    this.props.getProducts(this.props.token ? this.props.token.business_id : this.props.business.business.id, this);
  }
  toggleProductDescription = (i) => {
    let expandedTexts = [...this.state.expandedText];
    let expandText = !expandedTexts[i];
    expandedTexts[i] = expandText;
    this.setState({ expandedText: expandedTexts });
  };
  // onToggleProductDescription = (e, rowID) => {
  //   e.stopPropagation();
  //   if (this.state.expandedProductDescriptionIDArray.includes(rowID)) {
  //     this.setState({ expandedProductDescriptionIDArray: this.state.expandedProductDescriptionIDArray.filter((id) => id !== rowID) });
  //   } else {
  //     this.setState(() => ({
  //       expandedProductDescriptionIDArray: [...this.state.expandedProductDescriptionIDArray, rowID],
  //     }));
  //   }
  // };
  openModal = (modalName, modalState, modalData) => {
    switch (modalName) {
      case 'PRODUCT_ADD':
        modalState ? this.setState({ isAddProductModalShow: true }) : this.setState({ isAddProductModalShow: false });
        break;
      case 'PAGE_BOOKING_COMPONENT':
        if (modalState) {
          this.setState({ isPageBookingModalShow: true });
        } else {
          this.setState({ isPageBookingModalShow: false });
          this.setState({ selectedProducts: [], selectedProductIDsArray: [] });
          this.props.clearErrors();
        }
        break;
      case 'PRODUCT_UPDATE':
        if (modalState) {
          this.setState({ isUpdateProductModalShow: true });
        } else {
          this.setState({ isUpdateProductModalShow: false });
          this.setState({ selectedProduct: {} });
          this.props.clearErrors();
        }
        break;
      case 'PRODUCT_ADD_MEDIA':
        if (modalState) {
          this.setState({ isAddProductMediaModalShow: true });
        } else {
          this.setState({ isAddProductMediaModalShow: false });
          this.props.clearErrors();
        }
        break;
      case 'PRODUCT_VIEW_ALBUM':
        if (modalState) {
          this.setState({ isViewProductAlbumModalShow: true });
        } else {
          this.setState({ isViewProductAlbumModalShow: false });
          this.props.clearErrors();
        }
        break;
      case 'PRODUCT_DELETE':
        modalState ? this.setState({ isDeleteProductModalShow: true }) : this.setState({ isDeleteProductModalShow: false });
        break;
      case 'PRODUCT_CATEGORY_UPDATE':
        if (modalState) {
          this.setState({ isUpdateProductCategoryModalShow: true });
        } else {
          this.setState({ isUpdateProductCategoryModalShow: false });
          this.props.clearErrors();
        }
        break;
      case 'PRODUCT_CATEGORY_DELETE':
        modalState ? this.setState({ isDeleteProductCategoryModalShow: true }) : this.setState({ isDeleteProductCategoryModalShow: false });
        break;
      case 'SUBSCRIPTION_PLANS':
        modalState ? this.setState({ isViewSubscriptionPlanModalShow: true }) : this.setState({ isViewSubscriptionPlanModalShow: false });
        break;
      default:
    }
  };
  onUploadProgress = () => {
    this.setState({ isUploadProgress: true });
  };
  onCancelUpload = () => {
    this.setState({ isUploadProgress: false });
  };
  onDeleteProducts = (e) => {
    e.preventDefault();
    const data = {
      productIDArray: this.state.selectedProduct ? [this.state.selectedProduct.id] : this.state.selectedProductIDsArray,
    };
    this.props.deleteProducts(data, this);
    // bug: currentPage doesn't go down when deleting the last item on a paginated page
    // this fix the bug that causes error when deleting the last item on that particular page
    if (this.state.currentPage > Math.ceil((this.props.business.products.length - 1) / this.state.currentPageSize) && this.state.currentPage !== 1) {
      this.setState({ currentPage: this.state.currentPage - 1 });
    }
  };
  onDeleteProductCategory = (e) => {
    e.preventDefault();
    const data = {
      category: this.state.selectedProductCategory,
      businessID: this.props.token ? this.props.token.business_id : this.props.business.business.id,
    };
    this.props.deleteProductCategory(data, this);
  };
  onUpdateProduct = () => {
    const data = {
      product: this.state.selectedProduct,
      positionAction: this.state.selectedProductPositionAction,
    };
    this.props.updateProduct(data, this);
  };
  onClearSelectedProducts = () => {
    this.setState({ selectedProductIDsArray: [], selectedProducts: [] });
  };
  render() {
    const { business, products } = this.props.business;
    const { expandedText, truncateText } = this.state;
    const { SearchBar } = Search;
    const nonExpandableRows = products.map((product) => {
      if (product.description === '') {
        return product.id;
      } else {
        return [];
      }
    }, []);
    // const productCategories = [];
    var productCategoryID = '';
    const columns = [
      {
        dataField: 'name',
        text: '',
        align: 'left',
        headerAlign: 'left',
        formatter: (cell, row, i) => {
          const ProductMedia = () => {
            return row.product_media_source ? (
              <div>
                <a
                  href='#!'
                  onClick={(e) => {
                    e.stopPropagation(); // prevent click through on select row or rowEvents
                    this.setState({ selectedProduct: row });
                    this.openModal('PRODUCT_VIEW_ALBUM', true);
                  }}
                >
                  <div style={{ width: 'fit-content' }}>
                    <img alt='product_thumbnail' src={row.product_media_thumbnail} width='60px' className='thumbnail border-radius-5' />
                  </div>
                </a>
              </div>
            ) : this.props.token && !this.props.liveMode ? (
              <a
                href='#!'
                onClick={(e) => {
                  e.stopPropagation(); // prevent click through on select row or rowEvents
                  this.setState({ selectedProduct: row });
                  this.openModal('PRODUCT_ADD_MEDIA', true);
                }}
              >
                <div className='padding-5 border-radius-5 border' style={{ width: 'fit-content' }}>
                  <MdAddAPhoto size={38} className='color-deepskyblue' />
                </div>
              </a>
            ) : (
              <div className='padding-5' style={{ width: 'fit-content' }}>
                <FiBox size={38} className='color-white' />
              </div>
            );
          };
          const ProductOptions = (i) => {
            return this.props.token && !this.props.liveMode ? (
              <>
                <div>
                  <DropdownButton
                    variant='secondary'
                    title={<>Edit</>}
                    onClick={(e) => {
                      e.stopPropagation(); // stop click from expanding row
                    }}
                  >
                    <Dropdown.Item>
                      <span className='margin-left-5 color-gray text-transform-capitalize'>
                        {row.type} | Rank {row.position}
                      </span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        this.setState({ selectedProduct: row });
                        this.openModal('PRODUCT_UPDATE', true);
                      }}
                    >
                      <MdEdit size={20} className='color-deepskyblue margin-right-5' />
                      Edit Item
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        e.stopPropagation(); // prevent click through on select row or rowEvents
                        this.setState({ selectedProduct: row });
                        this.openModal('PRODUCT_ADD_MEDIA', true);
                      }}
                    >
                      <MdPhoto size={20} className='color-deepskyblue margin-right-5' />
                      Add Photos
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        e.stopPropagation(); // prevent click through on select row or rowEvents
                        this.setState({ selectedProduct: row });
                        this.openModal('PRODUCT_DELETE', true);
                      }}
                    >
                      <MdArchive size={20} className='color-deepskyblue margin-right-5' />
                      Archive
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </>
            ) : (
              <>
                <div>
                  <>
                    {this.state.selectedProductIDsArray.includes(row.id) ? (
                      <Button
                        variant='primary'
                        onClick={(e) => {
                          e.stopPropagation(); // prevent click through on select row or rowEvents
                          this.setState(() => ({
                            selectedProductIDsArray: this.state.selectedProductIDsArray.filter((id) => id !== row.id),
                            selectedProducts: this.state.selectedProducts.filter((product) => product.id !== row.id),
                          }));
                        }}
                      >
                        <MdCheckCircle size={20} />
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant='secondary'
                          onClick={(e) => {
                            e.stopPropagation(); // prevent click through on select row or rowEvents
                            // this.setState({ selectedBusiness: business });
                            this.setState(() => ({
                              selectedProductIDsArray: [...this.state.selectedProductIDsArray, row.id],
                              selectedProducts: [...this.state.selectedProducts, row],
                            }));
                          }}
                        >
                          <div className='display-flex flex-wrap-nowrap align-items-center justify-content-center'>Select</div>
                        </Button>
                      </>
                    )}
                  </>
                </div>
              </>
            );
          };
          var price = [];
          if (row.price.includes('.')) {
            price = row.price.split('.');
            if (price[0] === '') {
              price[0] = 0;
            } else {
              price[0].toLocaleString();
            }
          } else {
            price[0] = row.price;
          }
          // grouping category
          // let categoryHeader = '';
          // let categorySubheading = '';
          // let nonCategoryHeader = null;
          // if the product category is not in array, push to the array and display it once
          // if (!productCategories.includes(row.category.id)) {
          //   productCategories.push(row.category.id);
          //   categoryHeader = row.category.value;
          //   categorySubheading = row.category.subheading;
          //   if (!row.category_id) {
          //     nonCategoryHeader = true;
          //   }
          // }

          let categoryHeader = '';
          let categorySubheading = '';
          let nonCategoryHeader = null;
          if (productCategoryID !== row.category.id) {
            productCategoryID = row.category.id;
            categoryHeader = row.category.value;
            categorySubheading = row.category.subheading;
            if (!row.category_id) {
              nonCategoryHeader = true;
            }
          }

          return (
            <>
              {categoryHeader ? (
                <div className='display-flex align-items-center justify-content-space-between padding-bottom-10 padding-top-10 cursor-default border-bottom-gray bg-whitesmoke'>
                  <div className='display-flex justify-content-space-between'>
                    <div className='display-inline-flex flex-direction-column align-items-center justify-content-center padding-left-10 padding-right-10'>
                      <div className='display-flex align-items-center font-size-medium'>{categoryHeader}</div>
                      {categorySubheading ? <div className='display-flex align-items-center  margin-top-5 color-gray'>{categorySubheading}</div> : ''}
                    </div>
                    <div className='padding-right-10'>
                      {this.props.token && !this.props.liveMode ? (
                        <>
                          <DropdownButton
                            variant='secondary'
                            title={<>Edit</>}
                            onClick={(e) => {
                              e.stopPropagation(); // stop click from expanding row
                            }}
                          >
                            <Dropdown.Item>
                              <span className='margin-left-5 color-gray'>Rank {row.category.position ? row.category.position : 0}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              variant='secondary'
                              onClick={(e) => {
                                e.stopPropagation();
                                this.setState({
                                  selectedProductCategory: {
                                    id: row.category.id,
                                    value: row.category.value,
                                    label: row.category.label,
                                    subheading: row.category.subheading,
                                    position: { id: row.category.position, value: row.category.position, label: row.category.position },
                                  },
                                });
                                this.openModal('PRODUCT_CATEGORY_UPDATE', true);
                              }}
                            >
                              <MdEdit size={20} className='color-deepskyblue margin-right-5' />
                              Edit Category | Rank
                            </Dropdown.Item>
                            <Dropdown.Item
                              variant='secondary'
                              onClick={(e) => {
                                e.stopPropagation();
                                this.setState({ selectedProductCategory: row.category });
                                this.openModal('PRODUCT_CATEGORY_DELETE', true);
                              }}
                            >
                              <MdArchive size={20} className='color-deepskyblue margin-right-5' />
                              Archive
                            </Dropdown.Item>
                          </DropdownButton>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              ) : nonCategoryHeader ? (
                <div className='display-flex align-items-center justify-content-space-between padding-bottom-15 padding-top-15 font-size-small border-bottom-gray'></div>
              ) : (
                ''
              )}
              <div className='display-flex flex-direction-column padding-10 cursor-default'>
                <div className='display-flex color-black'>
                  <div className='display-flex flex-direction-row flex-wrap-nowrap'>
                    <ProductMedia />
                    <div className='display-flex justify-content-space-between align-items-center margin-left-15'>
                      <div className='display-flex justify-content-space-between padding-right-10 padding-bottom-5'>
                        <div className='color-black font-size-normal'>{row.name}</div>
                        {row.price ? (
                          <div>
                            <span>
                              {row.duration ? row.duration + ' minutes |' : ''} <sup className='font-size-smaller'>$</sup>
                              <span className='font-size-medium-large color-black'>{price[0]}</span>
                              <sup className='font-size-smaller'>{price[1]}</sup>
                            </span>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div>
                        <div className='display-flex padding-right-10 text-align-left'>
                          <Truncate
                            lines={!expandedText[i] && 1}
                            ellipsis={
                              <div>
                                ...{' '}
                                <a
                                  href='#/'
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.toggleProductDescription(i);
                                  }}
                                >
                                  Show more
                                </a>
                              </div>
                            }
                            className='color-gray'
                          >
                            <div className='react-quill' dangerouslySetInnerHTML={{ __html: row.description ? row.description : '' }} />
                          </Truncate>
                          {!truncateText[i] && expandedText[i] && (
                            <div>
                              ...{' '}
                              <a
                                href='#/'
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.toggleProductDescription(i);
                                }}
                              >
                                Show less
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div>
                      <ProductOptions data={i} />
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        },
      },
      // {
      //   dataField: 'price',
      //   text: '',
      //   formatter: (cell) => selectOptions[cell],
      //   filter: selectFilter({
      //     options: selectOptions,
      //   }),
      // },
      // {
      //   dataField: 'description',
      //   text: '',
      //   align: 'center',
      //   formatter: (cell, row) => {
      //   },
      // },
    ];
    const paginationOptions = {
      onPageChange: (page, sizePerPage) => {
        this.setState({ currentPage: page });
        this.setState({ currentPageSize: sizePerPage });
      },
      page: this.state.currentPage,
      sizePerPage: this.state.currentPageSize,
      hideSizePerPage: true,
    };
    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: false,
      selected: this.state.selectedProductIDsArray,
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          this.setState(() => ({
            selectedProductIDsArray: [...this.state.selectedProductIDsArray, row.id],
            selectedProducts: [...this.state.selectedProducts, row],
          }));
          if (this.props.token) {
            this.setState({ selectedProduct: row });
            this.openModal('PRODUCT_UPDATE', true);
          }
        } else {
          this.setState(() => ({
            selectedProductIDsArray: this.state.selectedProductIDsArray.filter((id) => id !== row.id),
            selectedProducts: this.state.selectedProducts.filter((product) => product.id !== row.id),
          }));
          if (this.props.token) {
            this.setState({ selectedProduct: row });
            this.openModal('PRODUCT_UPDATE', true);
          }
        }
      },
      // hideSelectColumn: this.props.token ? true : false,
      hideSelectColumn: true,
      hideSelectAll: true,
    };
    // const rowEvents = {
    //   onClick: (e, row, rowIndex) => {
    //     this.setState({ selectedProduct: row });
    //     this.openModal('PRODUCT_UPDATE', true);
    //   },
    // };
    const expandRow = {
      //   onlyOneExpanding: false,
      parentClassName: 'products-table-expandedRow-header',
      className: 'products-table-expandedRow-body',
      // showExpandColumn: false,
      // nonExpandable: nonExpandableRows,
      expanded: this.state.expandedProductDescriptionIDArray,
      expandColumnPosition: 'left',
      renderer: (row) => {
        return (
          <>
            <div className='display-flex flex-direction-column flex-content-flex-start padding text-align-left color-black bg-whitesmoke'>
              <div className='react-quill' dangerouslySetInnerHTML={{ __html: row.description ? row.description : '' }} />
            </div>
          </>
        );
      },
      expandColumnRenderer: ({ expanded, rowKey }) => {
        if (nonExpandableRows.includes(rowKey)) {
          return '';
        } else {
          if (expanded) {
            return (
              <div className='border-radius-25 padding-5 border'>
                <MdRemoveCircle size={25} className='color-gray' />
              </div>
            );
          }
          return (
            <div className='border-radius-25 padding-5 border'>
              <MdInfo size={25} className='color-deepskyblue' />
            </div>
          );
        }
      },
    };
    const NoDataIndication = (props) => {
      if (props.searchProps.searchText.length > 0) {
        return (
          <div className='padding color-black'>
            Your search - <span className='font-weight-bold'>{props.searchProps.searchText}</span> - did not match any data.
          </div>
        );
      } else {
        return (
          <div className='padding display-flex justify-content-center align-items-center'>
            <center>
              <MdShoppingBasket size='200px' />
              <div className='font-size-large font-weight-bold margin-bottom-5'>No Products/Services Yet</div>
              <br />
              {this.props.token && !this.props.liveMode ? (
                <>
                  <div className='margin-bottom-15'>Let customers see what you're offering.</div>
                  <Button variant='secondary' onClick={() => this.openModal('PRODUCT_ADD', true)}>
                    <div className='display-flex flex-wrap-nowrap align-items-center justify-content-center'>
                      <MdAddCircle size='20px' className='color-deepskyblue' /> <span className='white-space-nowrap'>Add Your First Item</span>
                    </div>
                  </Button>
                </>
              ) : (
                <>
                  <div className='margin-bottom-15'></div>
                </>
              )}
            </center>
          </div>
        );
      }
    };
    const DataTable = (props) => {
      return (
        <>
          <BootstrapTable
            classes='products-table'
            // headerClasses={products.length > 0 ? '' : 'display-none'}
            {...props.props.baseProps}
            pagination={paginationFactory(paginationOptions)}
            noDataIndication={<NoDataIndication data={products} searchProps={props.props.searchProps} />}
            selectRow={selectRowProp}
            expandRow={expandRow}
            filter={filterFactory()}
            // rowEvents={rowEvents}
          />
        </>
      );
    };
    return (
      <>
        <div>
          <div>
            <ToolkitProvider keyField='id' data={products} columns={columns} search>
              {(toolkitprops) => {
                return (
                  <div>
                    <div className='display-flex flex-direction-column justify-content-center align-items-center'>
                      <div className='display-flex flex-direction-row flex-wrap-nowrap align-items-center justify-content-space-between padding'>
                        <div className='width-100'>
                          <SearchBar placeholder='Search Products &amp; Services' tableId='Search' {...toolkitprops.searchProps} />
                        </div>
                        {this.props.token && !this.props.liveMode ? (
                          <>
                            <div className='margin-left-15 white-space-nowrap'>
                              <ButtonGroup>
                                <Button onClick={() => this.openModal('PRODUCT_ADD', true)} style={{ padding: '5px' }}>
                                  <FiPlus size={25} className='color-white' /> <span className='white-space-nowrap'>Add Item</span>
                                </Button>
                              </ButtonGroup>
                            </div>
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div>
                      <div>
                        <DataTable props={toolkitprops} />
                      </div>
                    </div>
                  </div>
                );
              }}
            </ToolkitProvider>
          </div>
          {this.state.selectedProductIDsArray.length > 0 ? (
            <>
              <div className='footer-sticky-top border-bottom-gray padding bg-white display-flex justify-content-center'>
                <div className='width-980'>
                  <div className='display-flex justify-content-space-between align-items-center'>
                    <div className='flex-1'>{this.state.selectedProductIDsArray.length} Selected </div>
                    <Button
                      variant='primary'
                      onClick={() => {
                        this.setState({ selectedBusiness: business });
                        this.openModal('PAGE_BOOKING_COMPONENT', true);
                      }}
                      className='flex-2'
                    >
                      Start Booking
                    </Button>
                  </div>
                </div>
              </div>
              <div className='footer-sticky-bottom border-top-gray padding bg-white display-flex justify-content-center'>
                <div className='width-980'>
                  <div className='display-flex justify-content-space-between align-items-center'>
                    <div className='flex-1'>{this.state.selectedProductIDsArray.length} Selected </div>
                    <Button
                      variant='primary'
                      onClick={() => {
                        this.setState({ selectedBusiness: business });
                        this.openModal('PAGE_BOOKING_COMPONENT', true);
                      }}
                      className='flex-2'
                    >
                      Start Booking
                    </Button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ''
          )}
          <ModalDepot
            state={this.state}
            props={this.props}
            function={{
              openModal: this.openModal,
              onDeleteProducts: this.onDeleteProducts,
              onCancelUpload: this.onCancelUpload,
              onUploadProgress: this.onUploadProgress,
              onClearSelectedProducts: this.onClearSelectedProducts,
              onDeleteProductCategory: this.onDeleteProductCategory,
            }}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  subscriptions: state.subscriptions,
  business: state.business,
  site: state.site,
});

export default connect(mapStateToProps, {
  getProducts,
  deleteProducts,
  updateProduct,
  getStripeSubscriptionProducts,
  deleteProductCategory,
  clearErrors,
})(ComponentTags);
