import React, { Component } from 'react';
import * as Feather from 'react-feather';
import * as MD from 'react-icons/md';
import NavBadge from './NavBadge';
import NavSingleItem from './NavSingleItem';

export default class NavDropdownItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }
  toggle = (e) => {
    this.setState({ open: !this.state.open });
    // e.preventDefault();
    // e.stopPropagation();
  };
  render() {
    const { item } = this.props;

    let isExpanded = this.state.open ? 'open' : '';
    // this keeps the submenu item open on refresh of page \\ if the open state is close by default
    // if (item.children.length > 0) {
    //   item.children.map((submenuItem) => {
    //     if (submenuItem.url === this.props.location.pathname) {
    //       isExpanded = 'open';
    //     }
    //     return '';
    //   });
    // }
    const Icon = item.icon ? MD[item.icon] : null;
    const ExpandIcon = isExpanded ? Feather.ChevronDown : Feather.ChevronRight;
    return (
      <li className={`nav-item has-submenu ${isExpanded}`}>
        <a href='#!' role='button' onClick={this.toggle}>
          {item.icon && Icon && <Icon className='side-nav-icon' />}
          <span className='nav-item-label'>{item.name}</span> {item.badge && <NavBadge color={item.badge.variant} text={item.badge.text} />}
          <ExpandIcon className='menu-expand-icon' />
        </a>
        {(isExpanded || this.props.isSidebarCollapsed) && (
          <ul className='nav-submenu'>
            {item.children.map((item, index) => (
              <NavSingleItem item={item} key={index} />
            ))}
          </ul>
        )}
      </li>
    );
  }
}
