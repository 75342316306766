import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MdPrint } from 'react-icons/md';
import { Button, Form } from 'react-bootstrap';
import { QRCode } from 'react-qrcode-logo';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'PAGE_QRCODE_FORM',
      QRCodeSize: 200,
      isPageQRcodePrintModalShow: false,
    };
  }
  render() {
    return (
      <>
        Below is the QR code for your business page. You can print as many as needed and place it in convenient spots to let customers view your
        products/menu or services.
        <div className='display-flex flex-direction-column align-items-center justify-content-center margin-top-15'>
          <div className='padding-5'>
            <div className='padding-10 font-size-larger bg-black color-white border-radius-top-25px'>
              <center>Visit Our Page </center>
            </div>
            <div>
              <div className='width-auto padding-10 bg-white'>
                <center>
                  <QRCode
                    value={this.props.business.business.vanity_url}
                    qrStyle='dots'
                    size={this.state.QRCodeSize}
                    eyeRadius={[
                      [10, 0, 10, 10],
                      [10, 0, 10, 10], // top/right eye
                      [10, 0, 10, 10], // bottom/left
                    ]}
                    // logoImage={this.props.business.media ? this.props.business.media.preview : ''}
                    // logoWidth={this.state.QRCodeSize / 2.5}
                    // logoHeight={
                    //   this.props.business.media
                    //     ? ((this.state.QRCodeSize / 2.5) * this.props.business.media.height) / this.props.business.media.width
                    //     : ''
                    // }
                  />
                </center>
              </div>
              <div className='shape-triangle-container'>
                <div className='shape-triangle-middle-top'></div>
              </div>
              <div className='padding-10 font-size-larger bg-black border-radius-bottom-25px color-white'>
                <center>
                  For Digital Menu &amp; Services
                  <div className='font-size-large'>Scan Me</div>
                </center>
              </div>
            </div>
          </div>
          <center>
            <div className='display-flex align-items-center margin-top-15 margin-bottom-15'>
              <Form.Control
                as='select'
                name='QRCodeSize'
                onChange={(e) => {
                  this.setState({ QRCodeSize: e.target.value });
                }}
                value={this.state.QRCodeSize}
                className='width-auto'
              >
                <option value='100'>Quarter Page QR Code</option>
                <option value='200'>Half Page QR Code</option>
                <option value='300'>Full Page QR Code</option>
              </Form.Control>{' '}
            </div>
            <Button
              onClick={() => {
                localStorage.setItem('checkinQRprintsize', this.state.QRCodeSize / 50);
                localStorage.setItem('selectedBusinessID', this.props.business.business.id);
                window.open('/pageQRCodePrint');
              }}
              className='display-flex width-100'
            >
              <MdPrint size={25} className='color-white' /> Print
            </Button>
          </center>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  business: state.business,
  site: state.site,
});
export default connect(mapStateToProps, {})(AppComponent);
