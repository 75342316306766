import React, { Component } from 'react';
import { Alert, Button, Form, Container } from 'react-bootstrap';
import { connect } from 'react-redux'; // connecting redux to component
import queryString from 'query-string';
import TextField from '@material-ui/core/TextField';

import { signUpUser } from '../../redux/actions/userActions';
import { clearErrors } from './../../redux/actions/siteActions';
import logo from './../../assets/images/logo.png';

class Register extends Component {
  constructor(props) {
    super();
    const locationParams = queryString.parse(props.location.search);
    const locationEmail = props.history.location.userEmail ? props.history.location.userEmail : '';
    this.state = {
      first_name: '',
      last_name: '',
      email: locationEmail,
      dob: '',
      phone: '',
      refer_by_name: '',
      refer_by_code: '',
      password: '',
      password2: '',
      captcha: '',
      queryParams: locationParams,
      isSubmitDisabled: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  // redirect user if user is authenticated
  componentDidMount() {
    if (this.props.users.isAuthenticated) {
      this.props.history.push('/account/dashboard');
    }
    if (localStorage.getItem('localStorageUserEmail')) {
      this.setState({ email: localStorage.getItem('localStorageUserEmail') });
    } else {
      // this.props.history.push('/signup');
    }
  }
  onRedirect(e) {
    // clear local storage
    localStorage.removeItem('localStorageUserEmail');
    this.props.history.push('/login');
  }
  async onChange(e) {
    await this.setState({ [e.target.name]: e.target.value.trim() });
    // if (
    //   this.state.email !== '' &&
    //   this.state.first_name !== '' &&
    //   this.state.last_name !== '' &&
    //   this.state.phone !== '' &&
    //   this.state.password !== '' &&
    //   this.state.refer_by_name !== ''
    // ) {
    //   this.setState({ isSubmitDisabled: false });
    // } else {
    //   this.setState({ isSubmitDisabled: true });
    // }
  }
  onSubmit(e) {
    e.preventDefault();
    const data = {
      firstName: this.state.first_name,
      lastName: this.state.last_name,
      email: this.state.email,
      phone: this.state.phone,
      password: this.state.password,
      // password2: this.state.password2,
      referByName: this.state.refer_by_name,
      referByCode: this.state.refer_by_code,
      tokenType: this.state.queryParams.tokenType ? this.state.queryParams.tokenType : 'signup',
      token: this.state.queryParams.token ? this.state.queryParams.token : '',
    };
    this.props.signUpUser(data);
  }
  render() {
    const { errors } = this.props.site;
    // const Options = () => {
    //   const options = [
    //     'Google - Other Search Engines',
    //     'Facebook - Twitter - Other Social Media',
    //     'Youtube - TikTok - Instagram',
    //     'Post or Text from a Friend',
    //     'Postcard - Flyer',
    //     'Word of Mouth',
    //     'Radio',
    //     'TV',
    //     'Newspaper - Magazine',
    //     'Other',
    //   ];
    //   let optionArray = [];
    //   options.map((option) =>
    //     optionArray.push(
    //       <option key={option} value={option}>
    //         {option}
    //       </option>
    //     )
    //   );
    //   return optionArray;
    // };

    return (
      <div className='display-flex bg-whitesmoke'>
        <Container>
          <div className='container-box-small' style={{ marginBottom: '100px' }}>
            <div className='padding margin-15'>
              <center>
                <a href='/'>
                  <img src={logo} className='logo-static' alt='logo' style={{ filter: 'brightness(0%) invert()' }} />
                </a>
              </center>
            </div>
            <div className='font-size-large margin-bottom-15 text-align-center'>You're almost there.</div>
            {/* <div className='color-gray text-align-center'>Fill out the form below</div> */}
            <Form noValidate onSubmit={(e) => this.onSubmit(e)} className='margin-top-15'>
              <div className='display-flex'>
                <TextField
                  label='Email'
                  type='email'
                  name='email'
                  margin='normal'
                  variant='outlined'
                  onChange={this.onChange}
                  value={this.state.email}
                  // disabled
                  inputProps={{ style: { fontSize: 17 } }} // font size of input text
                  InputLabelProps={{ style: { fontSize: 17 } }} // font size of input label
                  className='display-flex bg-white border-radius-5'
                />
                <div className='display-flex justify-content-space-between'>
                  <div>
                    <TextField
                      label='Your First Name'
                      type='text'
                      name='first_name'
                      margin='normal'
                      variant='outlined'
                      value={this.state.first_name}
                      inputProps={{ style: { fontSize: 17 } }} // font size of input text
                      InputLabelProps={{ style: { fontSize: 17 } }} // font size of input label
                      className='display-flex bg-white border-radius-5'
                      onChange={this.onChange}
                    />
                  </div>
                  <div>
                    <TextField
                      label='Your Last Name'
                      type='text'
                      name='last_name'
                      margin='normal'
                      variant='outlined'
                      value={this.state.last_name}
                      inputProps={{ style: { fontSize: 17 } }} // font size of input text
                      InputLabelProps={{ style: { fontSize: 17 } }} // font size of input label
                      className='display-flex bg-white border-radius-5'
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>
              <TextField
                label='Cell Phone Number'
                type='number'
                name='phone'
                margin='normal'
                variant='outlined'
                value={this.state.phone}
                inputProps={{ style: { fontSize: 17 } }} // font size of input text
                InputLabelProps={{ style: { fontSize: 17 } }} // font size of input label
                className='display-flex bg-white border-radius-5'
                onChange={this.onChange}
                autoComplete='off'
              />
              <div className='margin-bottom-10'>
                <TextField
                  label='Create Your Password'
                  type='password'
                  name='password'
                  margin='normal'
                  variant='outlined'
                  value={this.state.password}
                  inputProps={{ style: { fontSize: 17 } }} // font size of input text
                  InputLabelProps={{ style: { fontSize: 17 } }} // font size of input label
                  className='display-flex bg-white border-radius-5'
                  onChange={this.onChange}
                />
              </div>

              {/* <div className='font-weight-bold margin-bottom-5'>Re-enter Password</div>
              <Form.Control type='password' name='password2' value={this.state.password2} onChange={this.onChange} className='margin-bottom-15' /> */}
              {/* <div className='font-size-medium font-weight-bold margin-top-15 margin-bottom-15 text-align-center'>How did you hear about us?</div> */}
              {/* <span className='color-gray'>(Google, Facebook, Postcard, Friends, Family, Radio, TV, Other)</span> */}
              {/* <Form.Control
                  type='text'
                  name='refer_by_name'
                  placeholder='Enter Your Response'
                  value={this.state.refer_by_name}
                  onChange={this.onChange}
                  className='margin-bottom-15 text-align-center'
                /> */}
              {/* <Form.Control as='select' name='refer_by_name' onChange={this.onChange} value={this.state.refer_by_name} className='margin-bottom-15'>
                <option value=''>Select your response</option>
                <Options />
              </Form.Control>
              <Form.Control
                type='text'
                name='refer_by_code'
                placeholder='Got a referral code? (optional)'
                value={this.state.queryParams.code ? this.state.queryParams.code : this.state.refer_by_code}
                onChange={this.onChange}
                className='margin-bottom-15'
              /> */}
              <br />
              <Alert variant='warning' show={errors.message ? true : false} onClose={() => this.props.clearErrors()} dismissible>
                {errors.message}
              </Alert>
              <Button
                variant='success'
                type='submit'
                disabled={this.state.isSubmitDisabled ? true : false}
                style={{ width: '100%', padding: '20px' }}
              >
                <span className='font-size-medium-large'>Sign Up</span>
              </Button>
              <br />
              <br />
              <div className='font-size-small text-align-center'>
                By signing up, you agree to our{' '}
                <a href='/legal/terms' target='_blank'>
                  Terms of Services
                </a>{' '}
                and{' '}
                <a href='/policy' target='_blank'>
                  Privacy Policy
                </a>
              </div>
            </Form>
            <br /> <br /> <br />
            <div className='color-white text-align-center font-size-medium-large'>
              <a href='/login' onClick={() => this.onRedirect()}>
                Already have an account?
              </a>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  site: state.site,
});
export default connect(mapStateToProps, { signUpUser, clearErrors })(Register);
