import React, { Component } from 'react';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
// import dateFormat from 'dateformat';
import { MdCheckCircle } from 'react-icons/md';
import ModalDepot from './../../components/ModalDepot';
import { getCheckInsHistory } from './../../../../redux/actions/businessActions';
import { clearErrors } from './../../../../redux/actions/siteActions';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      currentPage: 1,
      currentPageSize: 1000,
      selectedProducts: [],
      isAddProductModalShow: false,
      isSubmitDisabled: true,
    };
    this.openModal = this.openModal.bind(this);
  }
  componentDidMount() {
    this.props.getCheckInsHistory(this.props.business.customer.id);
  }
  openModal = (modalName, modalState, modalData) => {
    switch (modalName) {
      case 'PRODUCT_ADD':
        if (modalState) {
          this.setState({ isAddProductModalShow: true });
        } else {
          this.setState({ isAddProductModalShow: false });
          this.props.clearErrors();
        }
        break;
      default:
    }
  };
  confirmDone() {
    this.props.setProducts(this.state.selectedProducts);
    this.props.openModal('PRODUCTS_SELECT_TABLE', false);
  }
  async onSelect(e, isSelect) {
    if (isSelect) {
      await this.setState(() => ({
        selectedProducts: [...this.state.selectedProducts, e],
      }));
    } else {
      await this.setState(() => ({
        selectedProducts: this.state.selectedProducts.filter((product) => product.id !== e.id),
      }));
    }
    if (this.state.selectedProducts.length > 0) {
      this.setState({ isSubmitDisabled: false });
    } else {
      this.setState({ isSubmitDisabled: true });
    }
  }
  render() {
    const { checkins_history } = this.props.business;
    const { SearchBar } = Search;
    const columns = [
      {
        dataField: 'search_fields',
        text: 'Checked In Time',
        align: 'left',
        headerClasses: 'display-none',
        formatter: (cell, row, rowIndex) => {
          // let rowNumber = (this.state.currentPage - 1) * this.state.currentPageSize + (rowIndex + 1);
          return (
            <div className='display-flex'>
              <div className='padding'>{row.search_fields}</div>
            </div>
          );
        },
      },
    ];
    const paginationOptions = {
      onPageChange: (page, sizePerPage) => {
        this.setState({ currentPage: page });
        this.setState({ currentPageSize: sizePerPage });
      },
      page: this.state.currentPage,
      sizePerPage: this.state.currentPageSize,
      hideSizePerPage: true,
    };
    // const selectRow = {
    //   mode: 'checkbox',
    //   clickToSelect: true,
    //   onSelect: (row, isSelect, rowIndex, e) => {
    //     this.onSelect(row, isSelect);
    //   },
    //   style: { backgroundColor: '#e0e0e0' },
    //   hideSelectColumn: false,
    //   hideSelectAll: true,
    // };
    const NoDataIndication = (props) => {
      if (props.searchProps.searchText.length > 0) {
        return (
          <div className='padding color-black'>
            Your search - <span className='font-weight-bold'>{props.searchProps.searchText}</span> - did not match any data.
          </div>
        );
      } else {
        return (
          <div className='padding display-flex justify-content-center align-items-center'>
            <center>
              <MdCheckCircle size='200px' />
              <div className='font-size-large font-weight-bold margin-bottom-5'>No Check-In History Found</div>
              <br />
              <div className='margin-bottom-15'>This customer has no checked-in history</div>
              <br />
              {/* <Button onClick={() => (window.location.href = '/account/products')}>Go to Products/Services List</Button> */}
            </center>
          </div>
        );
      }
    };
    return (
      <>
        <div>
          <ToolkitProvider keyField='id' data={checkins_history} columns={columns} search>
            {(toolkitprops) => {
              return (
                <div>
                  <div className='display-flex flex-direction-column flex-wrap-nowrap justify-content-center align-items-center'>
                    <div className='width-100 margin-top-15'>
                      <SearchBar placeholder='Search' tableId='Search History' {...toolkitprops.searchProps} />
                    </div>
                  </div>
                  <BootstrapTable
                    keyField='id'
                    headerClasses={checkins_history.length > 0 ? '' : 'display-none'}
                    {...toolkitprops.baseProps}
                    data={checkins_history}
                    columns={columns}
                    // selectRow={selectRow}
                    pagination={paginationFactory(paginationOptions)}
                    noDataIndication={<NoDataIndication data={checkins_history} searchProps={toolkitprops.searchProps} />}
                  />
                </div>
              );
            }}
          </ToolkitProvider>
        </div>
        <ModalDepot state={this.state} function={{ openModal: this.openModal }} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  business: state.business,
  users: state.users,
  site: state.site,
});

export default connect(mapStateToProps, { getCheckInsHistory, clearErrors })(AppComponent);
