import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button } from 'react-bootstrap';
import { MdSearch } from 'react-icons/md';
// import TextField from '@material-ui/core/TextField';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

import { verifyPerson } from './../../../redux/actions/businessActions';
import { clearErrors } from './../../../redux/actions/siteActions';
import ModalDepot from '../components/ModalDepot';

class AppComponent extends Component {
  constructor() {
    super();
    this.state = {
      componentID: 'PERSON_VERIFY_FORM',
      firstName: '',
      dialCode: 1,
      phone: '',
      isAddPersonModalShow: false,
      isPersonSelectModalShow: false,
    };
    this.onChange = this.onChange.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onVerifyPerson = this.onVerifyPerson.bind(this);
    this.onViewVerifiedPersons = this.onViewVerifiedPersons.bind(this);
  }
  async onChange(e, targetName) {
    if (targetName) {
      await this.setState({ [targetName]: e });
    } else {
      await this.setState({ [e.target.name]: e.target.value.trim() });
    }
  }
  openModal = (modalName, modalState, modalData) => {
    switch (modalName) {
      case 'PERSON_ADD':
        modalState ? this.setState({ isAddPersonModalShow: true }) : this.setState({ isAddPersonModalShow: false });
        break;
      case 'PERSON_SELECT_TABLE':
        modalState ? this.setState({ isPersonSelectModalShow: true }) : this.setState({ isPersonSelectModalShow: false });
        break;
      default:
    }
  };
  onVerifyPerson = (e) => {
    const data = {
      businessID: this.props.users.token.business_id,
      // firstName: this.state.firstName,
      dialCode: this.state.dialCode,
      phone: this.state.phone.slice(this.state.dialCode.length),
    };
    this.props.verifyPerson(data, this);
  };
  onViewVerifiedPersons = async (props) => {
    this.props.clearErrors();
    await this.setState({ verifiedPersons: props.persons, businessID: props.businessID });
    this.openModal('PERSON_SELECT_TABLE', true);
  };
  render() {
    const { errors } = this.props.site;
    return (
      <>
        <div>
          <div className='margin-bottom-15'>Enter the phone number of the person to be added to your business.</div>
          {/* <div className='margin-bottom-10'>First name:</div>
          <Form.Control
            type='text'
            name='firstName'
            value={this.state.firstName}
            onChange={this.onChange}
            placeholder='First name only'
            className='margin-bottom-15'
          /> */}
          {/* <TextField
            label="Customer's First Name"
            type='text'
            name='firstName'
            margin='normal'
            variant='outlined'
            value={this.state.firstName}
            onChange={this.onChange}
            inputProps={{ style: { fontSize: 15 } }} // font size of input text
            InputLabelProps={{ style: { fontSize: 15 } }} // font size of input label
            className='display-flex'
          /> */}
          {/* <div className='margin-bottom-10'>Phone number:</div>
          <Form.Control
            type='number'
            name='phone'
            placeholder='(xxx) xxx xxxx'
            value={this.state.phone}
            onChange={this.onChange}
            className='margin-bottom-15'
          /> */}
          {/* <TextField
            label='Phone Number'
            type='number'
            name='phone'
            margin='normal'
            variant='outlined'
            value={this.state.phone}
            onChange={this.onChange}
            inputProps={{ style: { fontSize: 15 } }} // font size of input text
            InputLabelProps={{ style: { fontSize: 15 } }} // font size of input label
            className='display-flex'
          /> */}
          <PhoneInput
            country={'us'}
            value={this.state.phone}
            onChange={(value, data, event, formattedValue) => {
              this.setState({ phone: value, dialCode: data.dialCode });
            }}
            countryCodeEditable={false}
            disableDropdown={true}
            style={{ marginBottom: '10px' }}
          />
          <Alert variant='warning' show={errors.errorVerifyPerson ? true : false} onClose={() => this.props.clearErrors()} dismissible>
            {errors.errorVerifyPerson}
          </Alert>
          <Button variant='secondary' onClick={(e) => this.onVerifyPerson(e)} className='display-flex'>
            <MdSearch size={25} className='color-deepskyblue' /> Verify
          </Button>
        </div>
        <ModalDepot
          state={this.state}
          props={this.props}
          function={{
            openModal: this.openModal,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  site: state.site,
});
export default connect(mapStateToProps, { verifyPerson, clearErrors })(AppComponent);
