import { GET_SUBSCRIPTION_PRODUCTS, GET_ACCOUNT_SUBSCRIPTION, GET_CHECKOUT_SESSION } from '../types/types';
const initialState = {
  products: [],
  account_subscription: {},
  checkoutSession: {},
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SUBSCRIPTION_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case GET_ACCOUNT_SUBSCRIPTION:
      return { ...state, account_subscription: action.payload };
    case GET_CHECKOUT_SESSION:
      return { ...state, checkoutSession: action.payload };
    default:
      return state;
  }
}
