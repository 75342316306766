import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { MdAddAPhoto, MdChevronLeft, MdDeleteForever } from 'react-icons/md';
import { normalize } from 'phone-prettify';

import AppointmentAddForm from './../forms/AppointmentAddForm';
import AppointmentUpdateForm from '../forms/AppointmentUpdateForm';
import CalendarSettingsForm from './../forms/CalendarSettingsForm';
import SubscriptionPlansComponent from './SubscriptionPlansComponent';
import PersonAddForm from '../forms/PersonAddForm';
import CustomerUpdateForm from './../forms/CustomerUpdateForm';
import StaffAddForm from './../forms/StaffAddForm';
import StaffUpdateForm from './../forms/StaffUpdateForm';
import ProductAddForm from './../forms/ProductAddForm';
import ProductUpdateForm from './../forms/ProductUpdateForm';
import HoursAddForm from './../forms/HoursAddForm';
import HoursUpdateForm from '../forms/HoursUpdateForm';
import CustomerSelectTable from './../forms/tables/CustomerSelectTable';
import ProductSelectTable from './../forms/tables/ProductSelectTable';
import FormSelectTable from './../forms/tables/FormSelectTable';
import StaffSelectTable from './../forms/tables/StaffSelectTable';
import CheckinAppAgreementForm from './../forms/CheckinAppAgreementForm';
import PageBookingComponent from './PageBookingComponent';
import CheckinUpdateForm from '../forms/CheckinUpdateForm';
import CheckinNotifyForm from './../forms/CheckinNotifyForm';
import CheckInHistoryTable from '../forms/tables/CheckInHistoryTable';
import PersonVerifyForm from '../forms/PersonVerifyForm';
import PageHoursComponent from './PageHoursComponent';
import BusinessUpdateForm from './../forms/BusinessUpdateForm';
import BusinessHoursForm from '../forms/BusinessHoursForm';
import FormAddForm from '../forms/FormAddForm';
import FormUpdateForm from '../forms/FormUpdateForm';
import ProductAddMediaForm from '../forms/ProductMediaAddForm';
import ProductAlbumForm from '../forms/ProductAlbumForm';
import BusinessMediaAddForm from '../forms/BusinessMediaAddForm';
import PolicyViewComponent from './PolicyViewComponent';
import CheckinAppCustomizeForm from '../forms/CheckinAppCustomizeForm';
import StaffMediaAddForm from '../forms/StaffMediaAddForm';
import ProductMediaUpdateForm from '../forms/ProductMediaUpdateForm';
import StaffAlbumForm from '../forms/StaffAlbumForm';
import StaffMediaUpdateForm from '../forms/StaffMediaUpdateForm';
import StaffHoursAddForm from '../forms/StaffHoursAddForm';
import CheckinAppQRCodeForm from '../forms/CheckinAppQRCodeForm';
import PageBookingEmailForm from '../forms/PageBookingEmailForm';
import PageShareComponent from './PageShareComponent';
import ProductCategoryUpdateForm from '../forms/ProductCategoryUpdateForm';
import StaffHoursForm from '../forms/StaffHoursForm';
import StaffHoursUpdateForm from '../forms/StaffHoursUpdateForm';
import BookingVerifyForm from '../forms/BookingVerifyForm';
import CheckinAppMediaAddForm from '../forms/CheckinAppMediaAddForm';
import SubscriptionTerms from '../SubscriptionTerms';
import PlanSwitchConfirmationForm from '../forms/PlanSwitchConfirmationForm';
import PlanCancelConfirmationForm from '../forms/PlanCancelConfirmationForm';
import CustomerProfileComponent from './CustomerProfileComponent';
import CheckinWaitlist from './../components/CheckinWaitlist';
import CheckinGetFeedbackForm from '../forms/CheckinGetFeedbackForm';
import CheckinWaitlistLeaveForm from '../forms/CheckinWaitlistLeaveForm';
import CheckinSettingsForm from './../forms/CheckinSettingsForm';
import CheckinAppSplashScreenComponent from './CheckinAppSplashScreenComponent';
import CheckinFeedbackComponent from './CheckinFeedbackComponent';
import Terms from './../Terms';
import Policy from './../Policy';
import CheckinWaitlistMonitorComponent from './CheckinWaitlistMonitorComponent';
import PersonSelectTable from '../forms/tables/PersonSelectTable';
import BusinessPage from '../navigation/BusinessPage';
import PageQRCodeForm from '../forms/PageQRCodeForm';
import ServicesOverview from '../ServicesOverview';
import About from '../About';
import CustomerFilterTable from '../forms/tables/CustomerFilterTable';

class AppComponent extends Component {
  // constructor(props) {
  //   super();
  // }
  render() {
    return (
      <>
        {/*////////////////// HOME //////////////////*/}
        {this.props.state.componentID === 'HOME' ? (
          <>
            <Modal
              size={'xl'}
              show={this.props.state.isServicesOvewviewModalShow}
              onHide={() => this.props.function.openModal('SERVICES_OVERVIEW_WINDOW', false)}
              scrollable
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('SERVICES_OVERVIEW_WINDOW', false)} />
                    <span>Services Overview</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ServicesOverview props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'xl'}
              show={this.props.state.isAboutPageModalShow}
              onHide={() => this.props.function.openModal('ABOUT_PAGE', false)}
              scrollable
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('ABOUT_PAGE', false)} />
                    <span>About</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <About props={this.props} />
              </Modal.Body>
            </Modal>
          </>
        ) : (
          <></>
        )}
        {this.props.state.componentID === 'MARKETING' ? (
          <>
            <Modal
              size={'xl'}
              show={this.props.state.isCustomerFilterTableOpen}
              onHide={() => this.props.function.openModal('CUSTOMER_FILTER_TABLE', false)}
              scrollable
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('CUSTOMER_FILTER_TABLE', false)} />
                    <span></span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CustomerFilterTable props={this.props} />
              </Modal.Body>
            </Modal>
          </>
        ) : (
          ''
        )}

        {/*////////////////// PAGE BOOKING //////////////////*/}

        {this.props.state.componentID === 'PAGE_PRODUCT_COMPONENT' ||
        this.props.state.componentID === 'PAGE_BOOKING_COMPONENT' ||
        this.props.state.componentID === 'BOOKING_VERIFY_FORM' ? (
          <>
            <Modal
              size={'xl'}
              show={this.props.state ? this.props.state.isPageBookingModalShow : false}
              backdrop='static'
              onHide={() => this.props.function.openModal('PAGE_BOOKING_COMPONENT', false)}
              // className='fullscreen-modal'
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('PAGE_BOOKING_COMPONENT', false)} />
                    Book an Appointment
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <PageBookingComponent props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'md'}
              show={this.props.state ? this.props.state.isVerifyBookingModalShow : false}
              backdrop='static'
              onHide={() => this.props.function.openModal('PERSON_VERIFY', false)}
              // className='fullscreen-modal'
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('PERSON_VERIFY', false)} />
                    <span></span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <BookingVerifyForm props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'md'}
              show={this.props.state ? this.props.state.isPromptEmailModalShow : false}
              backdrop='static'
              onHide={() => this.props.function.openModal('EMAIL_PROMPT', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('EMAIL_PROMPT', false)} />
                    <span>Enter Your Email</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <PageBookingEmailForm props={this.props} />
              </Modal.Body>
            </Modal>
          </>
        ) : (
          ''
        )}
        {/*////////////////// PAGE BOOKING //////////////////*/}
        {/*////////////////// CHECKINAPP FORM//////////////////*/}
        {this.props.state.componentID === 'HOME' ||
        this.props.state.componentID === 'CHECKINAPP_FORM' ||
        this.props.state.componentID === 'PERSON_ADD_FORM' ? (
          <>
            <Modal
              show={this.props.state.isAgreementModalShow}
              backdrop='static'
              // scrollable={true}
              onHide={() => {
                this.props.function.openModal('AGREEMENT_FORM', false);
              }}
              dialogClassName='agreement-modal'
            >
              <Modal.Header>
                <Modal.Title className='width-100'>
                  <div className='display-flex flex-wrap-nowrap align-items-center justify-content-center'></div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CheckinAppAgreementForm state={this.state} props={this.props} />
              </Modal.Body>
              <Modal.Footer>
                <div className='display-flex justify-content-center'>
                  <center>By clicking "I accept", you agree to the form above.</center>
                </div>
                <div className='display-flex flex-wrap-nowrap align-items-center justify-content-space-between'>
                  <Button
                    variant='secondary'
                    onClick={(e) => {
                      this.props.function.openModal('AGREEMENT_FORM', false);
                    }}
                    style={{ marginRight: '10px' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='primary'
                    onClick={(e) => {
                      this.props.function.onAgree(this.props);
                    }}
                    className='width-100'
                  >
                    I Accept
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
            <Modal
              size={'md'}
              show={this.props.state.isCheckinAppGreetingModalShow}
              backdrop='static'
              // centered
              onHide={() => this.props.function.openModal('CHECKINAPP_GREETING', false)}
              // className='fullscreen-modal'
            >
              <Modal.Header>
                <Modal.Title className='width-100'>
                  <center>
                    <div className='font-size-large'>Thank You!</div>
                    <div className='font-size-medium'>Checked-In Successful</div>
                  </center>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='font-size-medium-large text-align-center margin-bottom-15'>
                  <center>
                    {this.props && this.props.props && this.props.props.business.checkinapp && this.props.props.business.checkinapp.greeting !== null
                      ? this.props.props.business.checkinapp.greeting
                      : 'We will be with you shortly.'}
                  </center>
                </div>
                <Button
                  // variant='primary'
                  onClick={(e) => {
                    this.props.function.openModal('CHECKINAPP_GREETING', false);
                    window.location.reload();
                  }}
                  className='width-100'
                >
                  <span className='text-transform-capitalize font-size-medium-large'>Done</span>
                </Button>
              </Modal.Body>
            </Modal>
            <Modal size={'xl'} show={this.props.state.isTermsModalShow} onHide={() => this.props.function.openModal('TERMS_COMPONENT', false)}>
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('TERMS_COMPONENT', false)} />
                    <span className='font-size-smallest'></span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div
                  className='display-flex justify-content-center align-items-center'
                  onClick={() => this.props.function.openModal('TERMS_COMPONENT', false)}
                >
                  <div>
                    <Terms props={this.props} />
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <Modal size={'xl'} show={this.props.state.isPolicyModalShow} onHide={() => this.props.function.openModal('POLICY_COMPONENT', false)}>
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('POLICY_COMPONENT', false)} />
                    <span className='font-size-smallest'></span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div
                  className='display-flex justify-content-center align-items-center'
                  onClick={() => this.props.function.openModal('POLICY_COMPONENT', false)}
                >
                  <div>
                    <Policy props={this.props} />
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </>
        ) : (
          ''
        )}
        {/*////////////////// CHECKINAPP FORM //////////////////*/}
        {/*////////////////// CHECKINAPP REAL //////////////////*/}
        {this.props.state.componentID === 'CHECKINAPP' ||
        this.props.state.componentID === 'CHECKINAPP_MOBILE' ||
        this.props.state.componentID === 'CHECKIN_OVERVIEW' ? (
          <>
            <Modal
              size={'xl'}
              show={this.props.state.isSplashModalShow}
              onHide={() => this.props.function.openModal('SPLASH_SCREEN', false)}
              className='fullscreen-modal'
            >
              <Modal.Body className='display-flex bg-deepskyblue'>
                <CheckinAppSplashScreenComponent props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal size={'md'} show={this.props.state.isViewList} onHide={() => this.props.function.openModal('VIEW_LIST', false)}>
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('VIEW_LIST', false)} />
                    <span>Full Waitlist</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CheckinWaitlist props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'md'}
              show={this.props.state.isFeedbackRequiredPromptModalShow}
              onHide={() => this.props.function.openModal('PROMPT_FEEDBACK_REQUIRE', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('PROMPT_FEEDBACK_REQUIRE', false)} />
                    <span>Tell Us About Your Experience</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='display-flex'>
                  <div className='margin-bottom-15'>In the text box, please tell us your experience and what can we do to improve.</div>
                  <Button
                    onClick={(e) => this.props.function.openModal('PROMPT_FEEDBACK_REQUIRE', false)}
                    style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}
                  >
                    OK
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              size={'sm'}
              show={this.props.state.isLeaveWaitlistFormModalShow}
              onHide={() => this.props.function.openModal('LEAVE_WAITLIST_FORM', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('LEAVE_WAITLIST_FORM', false)} />
                    <span>Leaving the Waitlist</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CheckinWaitlistLeaveForm props={this.props} />
              </Modal.Body>
            </Modal>
          </>
        ) : (
          ''
        )}
        {/*////////////////// ONE CHECKINAPP REAL//////////////////*/}
        {/*////////////////// ONE CHECKINAPP PREVIEW //////////////////*/}
        {this.props.state.componentID === 'CHECKINAPP_PREVIEW' || this.props.state.componentID === 'CHECKINAPP_CUSTOMIZE_FORM' ? (
          <>
            <Modal
              size={'md'}
              show={this.props.state.isCustomizeCheckinAppShow}
              onHide={() => this.props.function.openModal('CHECKINAPP_CUSTOMIZE', false)}
              className='one-checkinapp-custom-modal'
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('CHECKINAPP_CUSTOMIZE', false)} />
                    <span>Application Settings</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CheckinAppCustomizeForm props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'md'}
              show={this.props.state.isViewQRcodeShow}
              onHide={() => this.props.function.openModal('CHECKINAPP_QRCODE', false)}
              className='one-checkinapp-custom-modal'
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('CHECKINAPP_QRCODE', false)} />
                    <span>Check-In QR Code</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CheckinAppQRCodeForm props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'md'}
              backdrop='static'
              show={this.props.state.isAddCheckinMediaModalShow}
              onHide={() => this.props.function.openModal('CHECKINAPP_MEDIA_ADD', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('CHECKINAPP_MEDIA_ADD', false)} />
                    <span className='font-size-smallest'>Click Browse. Upload a Photo</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CheckinAppMediaAddForm props={this.props} />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant='secondary'
                  onClick={() => this.props.function.openModal('CHECKINAPP_MEDIA_ADD', false)}
                  disabled={this.props.state.isUploadProgress ? true : false}
                >
                  {this.props.state.isUploadProgress ? 'Please wait. Processing...' : 'Cancel'}
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              size={'sm'}
              show={this.props.state.isDeleteCheckinMediaModalShow}
              onHide={() => this.props.function.openModal('CHECKINAPP_MEDIA_DELETE', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('CHECKINAPP_MEDIA_DELETE', false)} />
                    <span>Remove Logo?</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <center>
                  <div className='margin-bottom-10'>This will remove the logo from the application.</div>
                  <Button variant='danger' onClick={(e) => this.props.function.onRemoveCheckinAppMedia(e)}>
                    Yes, remove
                  </Button>
                </center>
              </Modal.Body>
            </Modal>
          </>
        ) : (
          ''
        )}
        {/*////////////////// ONE CHECKINAPP PREVIEW//////////////////*/}
        {/*////////////////// CHECK-INS//////////////////*/}
        {this.props.state.componentID === 'CHECK_INS' ||
        this.props.state.componentID === 'BOOKING_VERIFY_FORM' ||
        this.props.state.componentID === 'PERSON_VERIFY_FORM' ||
        this.props.state.componentID === 'CHECKIN_UPDATE_FORM' ||
        this.props.state.componentID === 'CHECKINAPP_FORM' ||
        this.props.state.componentID === 'APPOINTMENT_ADD_FORM' ||
        this.props.state.componentID === 'APPOINTMENT_UPDATE_FORM' ? (
          <>
            <Modal
              size={'md'}
              show={this.props.state.isUpdateCheckinModalShow}
              onHide={() => this.props.function.openModal('CHECKIN_UPDATE_FORM', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('CHECKIN_UPDATE_FORM', false)} />
                    <span>
                      {this.props.state.selectedCheckin && this.props.state.selectedCheckin.status ? (
                        <span className='text-transform-capitalize'>{this.props.state.selectedCheckin.status}</span>
                      ) : (
                        'Party'
                      )}
                    </span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CheckinUpdateForm props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'md'}
              show={this.props.state.isTextCheckinNotificationModalShow}
              onHide={() => this.props.function.openModal('CHECKIN_TEXT_NOTIFICATION', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('CHECKIN_TEXT_NOTIFICATION', false)} />
                    <span>Call Up Customer</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CheckinNotifyForm props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'md'}
              show={this.props.state.isTextCheckinGetFeedbackModalShow}
              onHide={() => this.props.function.openModal('CHECKIN_TEXT_GET_FEEDBACK', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('CHECKIN_TEXT_GET_FEEDBACK', false)} />
                    <span>Invite Feedback &amp; Rating</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CheckinGetFeedbackForm props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'lg'}
              show={this.props.state.isSelectStaffModalShow}
              scrollable
              onHide={() => this.props.function.openModal('STAFF_SELECT_TABLE', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('STAFF_SELECT_TABLE', false)} />
                    <span>Select Staff</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <StaffSelectTable props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'md'}
              show={this.props.state.isViewCheckinFeedbackModalShow}
              onHide={() => this.props.function.openModal('CHECKIN_VIEW_FEEDBACK', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('CHECKIN_VIEW_FEEDBACK', false)} />
                    <span>Feedback</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CheckinFeedbackComponent props={this.props} />
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={() => this.props.function.openModal('CHECKIN_VIEW_FEEDBACK', false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              size={'md'}
              show={this.props.state.isCheckinSettingsModalShow}
              onHide={() => this.props.function.openModal('CHECKIN_SETTINGS', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('CHECKIN_SETTINGS', false)} />
                    <span>Settings</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CheckinSettingsForm props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'md'}
              backdrop='static'
              show={this.props.state.isPersonSelectModalShow}
              onHide={() => this.props.function.openModal('PERSON_SELECT_TABLE', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <span>{/* <MdChevronLeft onClick={() => this.props.function.openModal('PERSON_SELECT_TABLE', false)} /> */}</span>
                    <span>
                      {this.props.state && this.props.state.phone ? (
                        <>{normalize(this.props.state.phone.slice(this.props.state.dialCode.length))}</>
                      ) : (
                        ''
                      )}
                    </span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <PersonSelectTable props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'xl'}
              show={this.props.state.isWaitlistMonitorModalShow}
              onHide={() => this.props.function.openModal('CHECKIN_WAITLIST_MONITOR', false)}
              className='fullscreen-modal'
            >
              <Modal.Body className='bg-whitemoke'>
                <CheckinWaitlistMonitorComponent props={this.props} />
              </Modal.Body>
            </Modal>
          </>
        ) : (
          ''
        )}
        {/*////////////////// CHECK-INS//////////////////*/}
        {/*////////////////// CALENDAR //////////////////*/}
        {this.props.state.componentID === 'CALENDAR' ||
        this.props.state.componentID === 'APPOINTMENT_ADD_FORM' ||
        this.props.state.componentID === 'APPOINTMENT_UPDATE_FORM' ||
        this.props.state.componentID === 'CUSTOMERS' ||
        this.props.state.componentID === 'CUSTOMER_SELECT_TABLE' ||
        this.props.state.componentID === 'CHECKINAPP_FORM' ||
        this.props.state.componentID === 'CHECK_INS' ||
        this.props.state.componentID === 'CHECKIN_UPDATE_FORM' ||
        this.props.state.componentID === 'PAGE_BOOKING_COMPONENT' ||
        this.props.state.componentID === 'PAGE_COMPONENT' ? (
          <>
            <Modal
              size={'md'}
              show={this.props.state ? this.props.state.isViewCustomerProfileModalShow : false}
              // backdrop='static'
              onHide={() => this.props.function.openModal('CUSTOMER_VIEW_PROFILE', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('CUSTOMER_VIEW_PROFILE', false)} />
                    Customer Profile
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CustomerProfileComponent props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'lg'}
              show={this.props.state.isSelectFormsModalShow}
              scrollable
              backdrop='static'
              onHide={() => this.props.function.openModal('FORMS_SELECT_TABLE', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <Modal.Title className='width-100'>
                    <div className='display-flex align-items-center justify-content-space-between'>
                      <MdChevronLeft onClick={() => this.props.function.openModal('FORMS_SELECT_TABLE', false)} />
                      <span>Select Forms</span>
                      <span></span>
                    </div>
                  </Modal.Title>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormSelectTable props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal size={'md'} show={this.props.state.isVerifyPersonModalShow} onHide={() => this.props.function.openModal('PERSON_VERIFY', false)}>
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('PERSON_VERIFY', false)} />
                    <span>Person Verification</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <PersonVerifyForm props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'lg'}
              show={this.props.state.isSelectCustomerModalShow}
              scrollable
              onHide={() => this.props.function.openModal('CUSTOMER_SELECT_TABLE', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <center>Select Customer</center>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CustomerSelectTable setCustomer={this.props.function.setCustomer} openModal={this.props.function.openModal} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'lg'}
              show={this.props.state.isSelectProductsModalShow}
              scrollable
              backdrop='static'
              onHide={() => this.props.function.openModal('PRODUCTS_SELECT_TABLE', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('PRODUCTS_SELECT_TABLE', false)} />
                    <span>Select Products &amp; Services</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ProductSelectTable props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size='md'
              show={this.props.state.isAddAppointmentModalShow}
              backdrop='static'
              onHide={() => this.props.function.openModal('APPOINTMENT_ADD_FORM', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('APPOINTMENT_ADD_FORM', false)} />
                    New
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <AppointmentAddForm props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size='md'
              show={this.props.state.isUpdateAppointmentModalShow}
              backdrop='static'
              onHide={() => this.props.function.openModal('APPOINTMENT_UPDATE_FORM', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('APPOINTMENT_UPDATE_FORM', false)} />
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <AppointmentUpdateForm props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'sm'}
              show={this.props.state.isDeleteAppointmentModalShow}
              onHide={() => this.props.function.openModal('APPOINTMENT_DELETE', false)}
            >
              <Modal.Header closeButton>
                <div className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('APPOINTMENT_DELETE', false)} />
                    Delete Booking
                    <span></span>
                  </div>
                </div>
              </Modal.Header>
              <Modal.Body>
                <center>Are you sure?</center>
              </Modal.Body>
              <Modal.Footer>
                <div className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <Button
                      variant='secondary'
                      onClick={(e) => {
                        this.props.function.openModal('APPOINTMENT_DELETE', false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='danger'
                      onClick={(e) => {
                        this.props.function.onDeleteAppointments(e);
                      }}
                    >
                      Yes. Delete.
                    </Button>
                  </div>
                  <center></center>
                </div>
              </Modal.Footer>
            </Modal>
            <Modal
              size={'md'}
              show={this.props.state.isCalendarSettingsModalShow}
              onHide={() => this.props.function.openModal('CALENDAR_SETTINGS_FORM', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('CALENDAR_SETTINGS_FORM', false)} />
                    Calendar Settings
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CalendarSettingsForm props={this.props} />
              </Modal.Body>
            </Modal>
          </>
        ) : (
          ''
        )}
        {/*////////////////// CALENDAR //////////////////*/}
        {/*////////////////// PERSONS AND CUSTOMERS //////////////////*/}
        {this.props.state.componentID === 'PERSON_VERIFY_FORM' || 'CHECKINAPP_FORM' ? (
          <Modal
            size={'md'}
            show={this.props.state.isAddPersonModalShow}
            backdrop='static'
            onHide={() => this.props.function.openModal('PERSON_ADD', false)}
          >
            <Modal.Header closeButton>
              <Modal.Title className='width-100'>
                <div className='display-flex align-items-center justify-content-space-between'>
                  <MdChevronLeft onClick={() => this.props.function.openModal('PERSON_ADD', false)} />
                  One-Time Registration
                  <span></span>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PersonAddForm props={this.props} />
            </Modal.Body>
          </Modal>
        ) : (
          ''
        )}
        {this.props.state.componentID === 'CUSTOMER_PROFILE_COMPONENT' ? (
          <>
            <Modal
              size={'md'}
              show={this.props.state.isCheckInHistoryModalShow}
              onHide={() => this.props.function.openModal('CHECKIN_HISTORY_VIEW', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('STAFF_ADD', false)} />
                    Check-In History
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CheckInHistoryTable customerID={this.props.state.selectedCustomerID} openModal={this.props.function.openModal} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'md'}
              show={this.props.state.isUpdateCustomerModalShow}
              onHide={() => this.props.function.openModal('CUSTOMER_UPDATE', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('CUSTOMER_UPDATE', false)} />
                    Update Customer
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CustomerUpdateForm props={this.props} />
              </Modal.Body>
            </Modal>
          </>
        ) : (
          ''
        )}
        {this.props.state.componentID === 'CUSTOMER_UPDATE_FORM' ? (
          <>
            <Modal
              size={'sm'}
              show={this.props.state.isDeleteCustomerModalShow}
              onHide={() => this.props.function.openModal('CUSTOMER_DELETE', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <center>Delete Customer</center>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <center>Are you sure?</center>
              </Modal.Body>
              <Modal.Footer>
                <div className='width-100'>
                  <center>
                    <Button
                      variant='danger'
                      onClick={(e) => {
                        this.props.function.onDeleteCustomers(e);
                      }}
                    >
                      Yes, delete.
                    </Button>
                  </center>
                </div>
              </Modal.Footer>
            </Modal>
          </>
        ) : (
          ''
        )}
        {/*////////////////// STAFF //////////////////*/}
        {this.props.state.componentID === 'STAFF_SELECT_TABLE' ||
        this.props.state.componentID === 'STAFF' ||
        this.props.state.componentID === 'STAFF_ALBUM_FORM' ||
        this.props.state.componentID === 'STAFF_MEDIA_UPDATE_FORM' ||
        this.props.state.componentID === 'STAFF_HOURS_FORM' ? (
          <>
            <Modal size='md' show={this.props.state.isAddStaffModalShow} onHide={() => this.props.function.openModal('STAFF_ADD', false)}>
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('STAFF_ADD', false)} />
                    New Staff
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <StaffAddForm props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal size='md' show={this.props.state.isUpdateStaffModalShow} onHide={() => this.props.function.openModal('STAFF_UPDATE', false)}>
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('STAFF_UPDATE', false)} />
                    Update Staff
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <StaffUpdateForm props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal size='sm' show={this.props.state.isDeleteStaffModalShow} onHide={() => this.props.function.openModal('STAFF_DELETE', false)}>
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('STAFF_DELETE', false)} />
                    Delete Staff
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <center>Are you sure?</center>
              </Modal.Body>
              <Modal.Footer>
                <div className='width-100'>
                  <div className='display-flex justify-content-center'>
                    {/* <Button variant='secondary' onClick={(e) => this.props.function.openModal('STAFF_DELETE', false)}>
                      Cancel
                    </Button> */}
                    <Button variant='danger' onClick={(e) => this.props.function.onDeleteStaff(e)}>
                      Yes, delete
                    </Button>
                  </div>
                </div>
              </Modal.Footer>
            </Modal>
            <Modal
              size={'md'}
              backdrop='static'
              show={this.props.state.isAddStaffImageModalShow}
              onHide={() => this.props.function.openModal('STAFF_ADD_MEDIA', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('STAFF_ADD_MEDIA', false)} />
                    <span className='font-size-smallest'>Click Browse. Upload a Photo</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <StaffMediaAddForm props={this.props} />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant='secondary'
                  onClick={() => this.props.function.openModal('STAFF_ADD_MEDIA', false)}
                  disabled={this.props.state.isUploadProgress ? true : false}
                >
                  {this.props.state.isUploadProgress ? 'Please wait. Processing...' : 'Cancel'}
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              size={'lg'}
              show={this.props.state.isViewStaffAlbumModalShow}
              onHide={() => this.props.function.openModal('STAFF_VIEW_ALBUM', false)}
            >
              <Modal.Header>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('STAFF_VIEW_ALBUM', false)} />
                    {this.props.state.selectedStaff ? this.props.state.selectedStaff.first_name + "'s" : ''} Photos
                    <Button
                      variant='secondary'
                      onClick={() => {
                        this.props.function.openModal('STAFF_VIEW_ALBUM', false);
                        this.props.function.openModal('STAFF_ADD_MEDIA', true);
                      }}
                    >
                      <div className='display-flex align-items-center'>
                        <MdAddAPhoto size={25} className='color-deepskyblue' /> Add
                      </div>
                    </Button>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <StaffAlbumForm props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'md'}
              show={this.props.state.isUpdateStaffMediaModalShow}
              onHide={() => this.props.function.openModal('STAFF_MEDIA_UPDATE', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>Media</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <StaffMediaUpdateForm props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size='sm'
              show={this.props.state.isDeleteStaffMediaModalShow}
              onHide={() => this.props.function.openModal('STAFF_MEDIA_DELETE', false)}
            >
              <Modal.Header>
                <Modal.Title className='width-100'>
                  <div className='display-flex justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('STAFF_MEDIA_DELETE', false)} />
                    Delete Media
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <center>Are you sure?</center>
              </Modal.Body>
              <Modal.Footer>
                <div className='width-100'>
                  <div className='display-flex justify-content-space-between'>
                    <Button variant='secondary' onClick={(e) => this.props.function.openModal('STAFF_MEDIA_DELETE', false)}>
                      Cancel
                    </Button>
                    <Button variant='danger' onClick={(e) => this.props.function.onDeleteStaffMedia(e)}>
                      Yes, delete
                    </Button>
                  </div>
                </div>
              </Modal.Footer>
            </Modal>
            <Modal size={'md'} show={this.props.state.isStaffHoursModalShow} onHide={() => this.props.function.openModal('STAFF_HOURS', false)}>
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('STAFF_HOURS', false)} />
                    {this.props.state.selectedStaff ? this.props.state.selectedStaff.first_name + "'s" : ''} Hours
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <StaffHoursForm props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal size={'sm'} show={this.props.state.isAddStaffHourModalShow} onHide={() => this.props.function.openModal('STAFF_HOUR_ADD', false)}>
              <Modal.Header>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('STAFF_HOUR_ADD', false)} />
                    New Hours
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <StaffHoursAddForm props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'sm'}
              show={this.props.state.isUpdateStaffHourModalShow}
              onHide={() => this.props.function.openModal('STAFF_HOUR_UPDATE', false)}
            >
              <Modal.Header>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('STAFF_HOUR_UPDATE', false)} />
                    Update Hours
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <StaffHoursUpdateForm props={this.props} />
              </Modal.Body>
            </Modal>{' '}
            <Modal
              size={'sm'}
              show={this.props.state.isDeleteStaffHourModalShow}
              onHide={() => this.props.function.openModal('STAFF_HOUR_DELETE', false)}
            >
              <Modal.Header closeButton>Delete Hour</Modal.Header>
              <Modal.Body>
                <center>Are you sure?</center>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='danger' onClick={(e) => this.props.function.onDeleteHours(e)}>
                  Yes. Delete
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        ) : (
          ''
        )}
        {/*////////////////// PRODUCTS //////////////////*/}
        {this.props.state.componentID === 'PRODUCT_SELECT_TABLE' ||
        this.props.state.componentID === 'PAGE_PRODUCT_COMPONENT' ||
        this.props.state.componentID === 'PRODUCT_ALBUM_FORM' ||
        this.props.state.componentID === 'PRODUCT_MEDIA_UPDATE_FORM' ? (
          <>
            <Modal size={'md'} show={this.props.state.isAddProductModalShow} onHide={() => this.props.function.openModal('PRODUCT_ADD', false)}>
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('PRODUCT_ADD', false)} />
                    <span className='font-size-smallest'>New Item</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ProductAddForm props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal size={'md'} show={this.props.state.isUpdateProductModalShow} onHide={() => this.props.function.openModal('PRODUCT_UPDATE', false)}>
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('PRODUCT_UPDATE', false)} />
                    <span className='font-size-smallest'>Edit Item</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ProductUpdateForm props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal size={'md'} show={this.props.state.isDeleteProductModalShow} onHide={() => this.props.function.openModal('PRODUCT_DELETE', false)}>
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('PRODUCT_DELETE', false)} />
                    <span>Archive Item?</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='display-flex flex-direction-column align-items-center justify-content-center'>
                  <div>This will remove the item from the list and save to archives</div>
                  <div className='margin-top-10'>
                    <Button variant='danger' onClick={(e) => this.props.function.onDeleteProducts(e)}>
                      Yes, archive
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              size={'md'}
              show={this.props.state.isDeleteProductCategoryModalShow}
              onHide={() => this.props.function.openModal('PRODUCT_CATEGORY_DELETE', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('PRODUCT_CATEGORY_DELETE', false)} />
                    <span>Archive Category?</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='display-flex flex-direction-column align-items-center justify-content-center'>
                  <div>
                    <center>This will remove the category and dissociate it from associated products.</center>
                  </div>
                  <div className='margin-top-10'>
                    <Button variant='danger' onClick={(e) => this.props.function.onDeleteProductCategory(e)}>
                      Yes, archive
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              size={'md'}
              backdrop='static'
              show={this.props.state.isAddProductMediaModalShow}
              onHide={() => this.props.function.openModal('PRODUCT_ADD_MEDIA', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('PRODUCT_ADD_MEDIA', false)} />
                    <span className='font-size-smallest'>Click Browse. Upload a Photo</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ProductAddMediaForm props={this.props} />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant='secondary'
                  onClick={() => this.props.function.openModal('PRODUCT_ADD_MEDIA', false)}
                  disabled={this.props.state.isUploadProgress ? true : false}
                >
                  {this.props.state.isUploadProgress ? 'Please wait. Processing...' : 'Cancel'}
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              size={'lg'}
              show={this.props.state.isViewProductAlbumModalShow}
              onHide={() => this.props.function.openModal('PRODUCT_VIEW_ALBUM', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('PRODUCT_VIEW_ALBUM', false)} />
                    Photos
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ProductAlbumForm props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'sm'}
              show={this.props.state.isDeleteProductMediaModalShow}
              onHide={() => this.props.function.openModal('PRODUCT_MEDIA_DELETE', false)}
            >
              <Modal.Header closeButton>
                <div className='width-100'>
                  <center>Delete Media</center>
                </div>
              </Modal.Header>
              <Modal.Body>
                <center>Are you sure?</center>
              </Modal.Body>
              <Modal.Footer>
                <div className='display-flex justify-content-space-between'>
                  <Button variant='secondary' onClick={(e) => this.props.function.openModal('PRODUCT_MEDIA_DELETE', false)}>
                    Cancel
                  </Button>
                  <Button variant='danger' onClick={(e) => this.props.function.onDeleteProductMedia(e)}>
                    Delete
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
            <Modal
              size={'md'}
              show={this.props.state.isUpdateProductMediaModalShow}
              onHide={() => this.props.function.openModal('PRODUCT_MEDIA_UPDATE', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('PRODUCT_MEDIA_UPDATE', false)} />
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ProductMediaUpdateForm props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'md'}
              show={this.props.state.isUpdateProductCategoryModalShow}
              onHide={() => this.props.function.openModal('PRODUCT_CATEGORY_UPDATE', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('PRODUCT_CATEGORY_UPDATE', false)} />
                    <span>Edit Category</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ProductCategoryUpdateForm props={this.props} />
              </Modal.Body>
            </Modal>
          </>
        ) : (
          ''
        )}
        {/*////////////////// FORMS ////////////////////*/}
        <Modal size='lg' show={this.props.state.isAddFormModalShow} onHide={() => this.props.function.openModal('FORM_ADD', false)}>
          <Modal.Header closeButton>
            <Modal.Title className='width-100'>Create Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormAddForm props={this.props} />
          </Modal.Body>
        </Modal>
        <Modal size='xl' show={this.props.state.isUpdateFormModalShow} onHide={() => this.props.function.openModal('FORM_UPDATE', false)}>
          <Modal.Header closeButton>
            <Modal.Title className='width-100'>Preview and Update Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormUpdateForm props={this.props} />
          </Modal.Body>
        </Modal>
        <Modal size={'md'} show={this.props.state.isUploadCheckinAppFormModalShow} onHide={() => this.props.function.openModal('FORM_UPLOAD', false)}>
          <Modal.Body>
            <div className='font-size-medium margin-15'>
              <center>
                <div className='padding'>Upload Successful</div>
                <div className='font-size-smallest'>You can now select the file.</div>
              </center>
            </div>
            <Button variant='primary' onClick={(e) => this.props.function.openModal('FORM_UPLOAD', false)} className='width-100'>
              Ok
            </Button>
          </Modal.Body>
        </Modal>
        <Modal size={'sm'} show={this.props.state.isDeleteFormModalShow} onHide={() => this.props.function.openModal('FORM_DELETE', false)}>
          <Modal.Header closeButton>
            <div className='width-100'>
              <center>Delete Form</center>
            </div>
          </Modal.Header>
          <Modal.Body>
            <center>Are you sure?</center>
          </Modal.Body>
          <Modal.Footer>
            <div className='display-flex justify-content-space-between'>
              <Button variant='secondary' onClick={(e) => this.props.function.openModal('FORM_DELETE', false)}>
                Cancel
              </Button>
              <Button variant='danger' onClick={(e) => this.props.function.onDeleteForms(e)}>
                Delete
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        {/*////////////////// BUSINESS INFO AND HOURS //////////////////*/}
        {this.props.state.componentID === 'BUSINESS_HOURS_FORM' ? (
          <>
            <Modal size={'sm'} show={this.props.state.isAddHourModalShow} onHide={() => this.props.function.openModal('HOUR_ADD', false)}>
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <center>Add Hours</center>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <HoursAddForm props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal size={'sm'} show={this.props.state.isUpdateHourModalShow} onHide={() => this.props.function.openModal('HOUR_UPDATE', false)}>
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <Button variant='secondary' onClick={(e) => this.props.function.openModal('HOUR_DELETE', true)}>
                    <div className='display-flex align-items-center'>
                      <MdDeleteForever size={25} className='color-tomato' /> Delete
                    </div>
                  </Button>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <HoursUpdateForm props={this.props} />
              </Modal.Body>
            </Modal>{' '}
            <Modal size={'sm'} show={this.props.state.isDeleteHourModalShow} onHide={() => this.props.function.openModal('HOUR_DELETE', false)}>
              <Modal.Header closeButton>Delete Hour</Modal.Header>
              <Modal.Body>
                <center>Are you sure?</center>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='danger' onClick={(e) => this.props.function.onDeleteHours(e)}>
                  Yes. Delete
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        ) : (
          ''
        )}
        {/*////////////////// PROFILE //////////////////*/}
        <Modal size={'sm'} show={this.props.state.isUpdateProfileModalShow} onHide={() => this.props.function.openModal('PROFILE_UPDATE', false)}>
          <Modal.Body>
            <div className='font-size-medium margin-15'>
              <center>Save Successful</center>
            </div>
            <Button variant='primary' onClick={(e) => this.props.function.openModal('PROFILE_UPDATE', false)} className='width-100'>
              Ok
            </Button>
          </Modal.Body>
        </Modal>
        <Modal size={'sm'} show={this.props.state.isCodeCopyModalShow} onHide={() => this.props.function.openModal('CODE_COPY', false)}>
          <Modal.Body>
            <div className='font-size-medium margin-15'>
              <center>Code Copied!</center>
            </div>
            <Button variant='primary' onClick={(e) => this.props.function.openModal('PROFILE_UPDATE', false)} className='width-100'>
              Ok
            </Button>
          </Modal.Body>
        </Modal>
        {/*////////////////// SUPPORT //////////////////*/}
        <Modal
          size={'md'}
          show={this.props.state.isEmailSuccessModalShow}
          onHide={() => {
            this.props.function.openModal('EMAIL_SUCCESS', false);
            window.location.reload();
          }}
        >
          <Modal.Header>
            <Modal.Title className='width-100'>
              <center>Message Sent!</center>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='padding'>
              <center>
                <div className='font-size-medium margin-bottom-15'>Thank you for your message.</div>
                <br />
                We will respond to you as soon as possible.
                <br />
                <br />
                Have a wonderful day!
              </center>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                this.props.function.openModal('EMAIL_SUCCESS', false);
                window.location.reload();
              }}
            >
              Done
            </Button>
          </Modal.Footer>
        </Modal>
        {/*////////////////// SUBSCRIPTIONS //////////////////*/}
        <Modal
          size={'xl'}
          show={this.props.state.isViewSubscriptionPlanModalShow}
          onHide={() => this.props.function.openModal('SUBSCRIPTION_PLANS', false)}
        >
          <Modal.Header closeButton>
            <Modal.Title className='width-100'>
              <div className='display-flex justify-content-space-between'>
                <MdChevronLeft onClick={() => this.props.function.openModal('SUBSCRIPTION_PLANS', false)} />
                <span>Services</span>
                <span></span>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SubscriptionPlansComponent props={this.props} />
          </Modal.Body>
        </Modal>
        <Modal
          size={'md'}
          show={this.props.state.isSwitchPlanModalShow}
          backdrop='static'
          onHide={() => this.props.function.openModal('SWITCH_SUBSCRIPTION_CONFIRMATION', false)}
        >
          <Modal.Header closeButton>
            <Modal.Title className='width-100'>
              <div className='display-flex justify-content-space-between'>
                <MdChevronLeft onClick={() => this.props.function.openModal('SWITCH_SUBSCRIPTION_CONFIRMATION', false)} />
                <span>Selected Plan</span>
                <span></span>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='padding'>
            <PlanSwitchConfirmationForm props={this.props} />
          </Modal.Body>
        </Modal>
        <Modal
          size={'md'}
          show={this.props.state.isSubscriptionSuccessModalShow}
          backdrop='static'
          scrollable
          onHide={() => {
            this.props.function.openModal('SUBSCRIPTION_SUCCESS', false);
            window.location.href = '/account/subscription';
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title className='width-100'>
              <div className='display-flex justify-content-space-between'>
                <MdChevronLeft onClick={() => this.props.function.openModal('SUBSCRIPTION_SUCCESS', false)} />
                <span>Thank You!</span>
                <span></span>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='font-size-medium text-align-center padding'>Your new subscription is now active.</div>
            <div className='font-size-small text-align-center'>
              You can now access the features included with your subscription. If you have any questions, please text us via (713) 742-2971 or going
              to the support section in the sidebar for answers to our FAQs.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='display-flex justify-content-space-between'>
              <Button
                variant='primary'
                onClick={(e) => {
                  this.props.function.openModal('SUBSCRIPTION_SUCCESS', false);
                  window.location.href = '/account/subscription';
                }}
                className='width-100'
              >
                Continue
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          size={'md'}
          show={this.props.state.isSubscriptionDeleteModalShow}
          backdrop='static'
          scrollable
          onHide={() => {
            this.props.function.openModal('SUBSCRIPTION_DELETE', false);
            window.location.href = '/account/subscription';
          }}
        >
          <Modal.Header closeButton>
            <div className='width-100 font-size-xlarge text-align-center'>Subscription Cancelled!</div>
          </Modal.Header>
          <Modal.Body>
            <div className='padding'>
              <div className='font-size-medium text-align-center'>Your subscription is now cancelled.</div>
              <br />
              <br />
              <div className='font-size-smaller text-align-center'>
                We're sorry to see you go and hope that you will consider again when you need our service. Have a wonderful day!
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='display-flex justify-content-space-between'>
              <Button
                variant='primary'
                onClick={(e) => {
                  this.props.function.openModal('SUBSCRIPTION_DELETE', false);
                  window.location.href = '/account/subscription';
                }}
                className='width-100'
              >
                Done
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          size={'md'}
          show={this.props.state.isCancelSubscriptionModalShow}
          scrollable
          onHide={() => this.props.function.openModal('SUBSCRIPTION_CANCEL', false)}
        >
          <Modal.Header closeButton>
            <Modal.Title className='width-100'>
              <div className='display-flex align-items-center justify-content-space-between'>
                <MdChevronLeft onClick={() => this.props.function.openModal('SUBSCRIPTION_CANCEL', false)} />
                Cancel Subscription
                <span></span>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='padding'>
            <PlanCancelConfirmationForm props={this.props} />
          </Modal.Body>
        </Modal>
        {/* //////////////////////// SUBSCRIPTIONS/////////////////// */}
        {/* //////////////////////// BUSINESS PAGE /////////////////// */}
        {this.props.state.componentID === 'CALENDAR' ||
        this.props.state.componentID === 'PAGE_COMPONENT' ||
        this.props.state.componentID === 'BUSINESS_UPDATE' ||
        this.props.state.componentID === 'SHARE_PAGE' ||
        this.props.state.componentID === 'BUSINESS_VIEW_HOURS' ||
        this.props.state.componentID === 'BUSINESS_VIEW_POLICY' ? (
          <>
            <Modal
              size={'md'}
              backdrop='static'
              show={this.props.state.isAddMediaModalShow}
              onHide={() => this.props.function.openModal('BUSINESS_MEDIA_ADD', false)}
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('BUSINESS_MEDIA_ADD', false)} />
                    <span className='font-size-smallest'>Click Browse. Upload a Photo</span>
                    <span></span>{' '}
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <BusinessMediaAddForm props={this.props} />
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={() => this.props.function.openModal('BUSINESS_MEDIA_ADD', false)}>
                  {this.props.state.isUploadProgress ? 'Please wait. Processin...' : 'Cancel'}
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal size={'md'} show={this.props.state.isEditBusinessModalShow} onHide={() => this.props.function.openModal('BUSINESS_UPDATE', false)}>
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('BUSINESS_UPDATE', false)} />
                    <span>Update Information</span>
                    <span></span>{' '}
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <BusinessUpdateForm function={this.props.function} />
              </Modal.Body>
            </Modal>
            <Modal size={'md'} show={this.props.state.isHoursFormModalShow} onHide={() => this.props.function.openModal('HOURS_FORM', false)}>
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('HOURS_FORM', false)} />
                    Business Hours
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <BusinessHoursForm props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'sm'}
              show={this.props.state.isDeleteBusinessMediaShow}
              onHide={() => this.props.function.openModal('BUSINESS_MEDIA_DELETE', false)}
            >
              <Modal.Header closeButton>
                <div className='width-100'>
                  <center>Delete Media</center>
                </div>
              </Modal.Header>
              <Modal.Body>
                <center>Are you sure?</center>
              </Modal.Body>
              <Modal.Footer>
                <div className='display-flex justify-content-space-between'>
                  <Button variant='secondary' onClick={(e) => this.props.function.openModal('BUSINESS_MEDIA_DELETE', false)}>
                    Cancel
                  </Button>
                  <Button variant='danger' onClick={(e) => this.props.function.onDeleteBusinessMedia(e)}>
                    Delete
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
            <Modal
              size={'md'}
              show={this.props.state.isViewBusinessHoursShow}
              onHide={() => this.props.function.openModal('BUSINESS_VIEW_HOURS', false)}
            >
              <Modal.Header>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('BUSINESS_VIEW_HOURS', false)} />
                    Business Hours
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <PageHoursComponent props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal size={'md'} show={this.props.state.isSharePageModalShow} onHide={() => this.props.function.openModal('SHARE_PAGE', false)}>
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('SHARE_PAGE', false)} />
                    Share Business
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <PageShareComponent props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'lg'}
              show={this.props.state.isViewBusinessPolicyShow}
              centered
              onHide={() => this.props.function.openModal('BUSINESS_VIEW_POLICY', false)}
              className='fullscreen-modal'
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('BUSINESS_VIEW_POLICY', false)} />
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <PolicyViewComponent props={this.props} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'xl'}
              show={this.props.state.isBusinessPageModalShow}
              centered
              onHide={() => this.props.function.openModal('BUSINESS_PAGE_PREVIEW', false)}
              className='fullscreen-modal'
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('BUSINESS_PAGE_PREVIEW', false)} />
                    <span>Business Page Live</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <BusinessPage props={this.props} liveMode={true} />
              </Modal.Body>
            </Modal>
            <Modal
              size={'md'}
              show={this.props.state.isPageQRcodeModalShow}
              onHide={() => this.props.function.openModal('PAGE_QRCODE', false)}
              className='one-checkinapp-custom-modal'
            >
              <Modal.Header closeButton>
                <Modal.Title className='width-100'>
                  <div className='display-flex align-items-center justify-content-space-between'>
                    <MdChevronLeft onClick={() => this.props.function.openModal('PAGE_QRCODE', false)} />
                    <span>Page QR Code</span>
                    <span></span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <PageQRCodeForm props={this.props} />
              </Modal.Body>
            </Modal>
          </>
        ) : (
          ''
        )}
        {/* ///////////////// BOOKING PAGE ///////////// */}
        {/* ///////////////// SUBSCRIPTION PLANS COMPONENT ////////////// */}
        {this.props.state.componentID === 'SUBSCRIPTION_PLANS_COMPONENT' ? (
          <Modal
            size={'md'}
            show={this.props.state.isSubscriptionTermsModalShow}
            onHide={() => this.props.function.openModal('SUBSCRIPTION_TERMS', false)}
          >
            <Modal.Header closeButton>
              <Modal.Title className='width-100'>
                <div className='display-flex align-items-center justify-content-space-between'>
                  <MdChevronLeft onClick={() => this.props.function.openModal('SUBSCRIPTION_TERMS', false)} />
                  <span className='font-size-smallest'></span>
                  <span></span>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='display-flex justify-content-center align-items-center'>
                <div>
                  <SubscriptionTerms props={this.props} />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          ''
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps)(AppComponent);
