import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import NavSpacer from './components/NavSpacer';
import NavOverlay from './components/NavOverlay';
import NavDivider from './components/NavDivider';
import NavSingleItem from './components/NavSingleItem';
import NavDropdownItem from './components/NavDropdownItem';
import logo from './../../../assets/images/logo.png';

class SidebarNav extends Component {
  render() {
    const navItems = (items) => {
      return items.map((item, index) => itemType(item, index));
    };
    const itemType = (item, index) => {
      if (item.children) {
        // nav submenu
        return <NavDropdownItem key={index} item={item} isSidebarCollapsed={this.props.isSidebarCollapsed} {...this.props} />;
      } else if (item.divider) {
        // show/hide divider
        return <NavDivider key={index} />;
      } else {
        //nav
        return <NavSingleItem item={item} key={index} isSidebarCollapsed={this.props.isSidebarCollapsed} />;
      }
    };

    const NavBrand = ({ logoText }) => {
      return (
        <div className='site-logo-bar'>
          <NavLink to='/account/dashboard' className='navbar-brand margin-top-15'>
            <center>
              <img src={logo} className={this.props.isSidebarCollapsed ? 'logo-static-thumb' : 'logo-static'} alt='logo' />
              {this.props.isSidebarCollapsed ? (
                ''
              ) : (
                <div>
                  <div className='color-gray font-size-small padding'>One Space Link</div>
                </div>
              )}
            </center>
          </NavLink>
        </div>
      );
    };

    return (
      <div>
        <div className='app-sidebar'>
          <NavBrand logo={this.props.logo} logoText={this.props.logoText} />
          <nav>
            <ul id='main-menu'>
              {navItems(this.props.nav.top)}
              <NavSpacer />
              {navItems(this.props.nav.bottom)}
            </ul>
          </nav>
        </div>
        {this.props.isSidebarCollapsed && <NavOverlay onClick={this.props.toggleSidebar} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});

export default connect(mapStateToProps)(SidebarNav);
