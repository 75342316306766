import React, { Component } from 'react';
import { Button, ButtonGroup, ToggleButton, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { MdAirlineSeatReclineNormal, MdPeople, MdNotificationsActive, MdCancel, MdCheckCircle } from 'react-icons/md';
import { FiBox } from 'react-icons/fi';
import dateFormat from 'dateformat';
import phoneFormatter from 'phone-formatter';
import TextField from '@material-ui/core/TextField';
import {
  getCheckIn,
  updateCheckIn,
  getStaffs,
  getProducts,
  changeCheckinKeyValue,
  compareUserPlanToValidPlan,
} from './../../../redux/actions/businessActions';
import { getStripeSubscriptionProducts } from './../../../redux/actions/subscriptionActions';
import { clearErrors } from './../../../redux/actions/siteActions';
import ModalDepot from '../components/ModalDepot';
import staff_placeholder from './../../../assets/images/staff_placeholder.png';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'CHECKIN_UPDATE_FORM',
      checkinID: props.props.state.selectedcheckinID,
      checkin: {},
      isSelectStaffModalShow: false,
      isSelectProductsModalShow: false,
      isViewSubscriptionPlanModalShow: false,
      isViewCustomerProfileModalShow: false,
      isCheckOutModalShow: false,
      isReadyStatusCallUp: 0,
      selectedCustomer: {},
      allowAddProduct: true,
      selectedProducts: [],
      selectedProductIDsArray: [],
      selectedStaff: {},
      serviceOn: null,
    };
    this.openModal = this.openModal.bind(this);
    this.onUpdateCheckIn = this.onUpdateCheckIn.bind(this);
    this.setStaff = this.setStaff.bind(this);
    this.setProducts = this.setProducts.bind(this);
    this.setCheckin = this.setCheckin.bind(this);
    this.setCustomer = this.setCustomer.bind(this);
    this.setStateCallUpStatus = this.setStateCallUpStatus.bind(this);
  }
  componentDidMount() {
    this.props.getCheckIn(this.props.props.state.selectedcheckinID, this);
    this.props.getProducts(this.props.users.token.business_id);
    this.props.getStripeSubscriptionProducts();
  }
  openModal = (modalName, modalState, modalData) => {
    switch (modalName) {
      case 'STAFF_SELECT_TABLE':
        modalState ? this.setState({ isSelectStaffModalShow: true }) : this.setState({ isSelectStaffModalShow: false });
        break;
      case 'CHECKOUT_FORM':
        modalState ? this.setState({ isCheckOutModalShow: true }) : this.setState({ isCheckOutModalShow: false });
        break;
      case 'SUBSCRIPTION_PLANS':
        modalState ? this.setState({ isViewSubscriptionPlanModalShow: true }) : this.setState({ isViewSubscriptionPlanModalShow: false });
        break;
      case 'PRODUCTS_SELECT_TABLE':
        modalState ? this.setState({ isSelectProductsModalShow: true }) : this.setState({ isSelectProductsModalShow: false });
        break;
      case 'STAFF_DELETE_PROMPT':
        modalState ? this.setState({ isStaffDeletePromptModalShow: true }) : this.setState({ isStaffDeletePromptModalShow: false });
        break;
      case 'CUSTOMER_VIEW_PROFILE':
        modalState ? this.setState({ isViewCustomerProfileModalShow: true }) : this.setState({ isViewCustomerProfileModalShow: false });
        break;
      default:
    }
  };
  setCustomer = async (customer) => {
    await this.setState({ selectedCustomer: customer });
  };
  setStaff = async (staff) => {
    await this.setState({ checkin: { ...this.state.checkin, staff_id: staff.id, selectedStaff: staff } });
    this.props.clearErrors();
  };
  setProducts = async (products) => {
    var selectedProductIDs = [];
    products.forEach((product) => {
      selectedProductIDs.push(product.id);
    });
    await this.setState({ selectedProducts: products, selectedProductIDsArray: selectedProductIDs });
    this.props.clearErrors();
  };
  setCheckin = (checkin) => {
    this.setState({ checkin });
  };
  onChange = async (e, targetName) => {
    if (targetName) {
      await this.setState({ [targetName]: e });
    } else {
      if (e.target.name === 'firstName') {
        await this.setState({ [e.target.name]: e.target.value.trim() });
      } else {
        await this.setState({ [e.target.name]: e.target.value });
      }
    }
  };
  onChangeCheckinKeyValue(id, key, value) {
    const data = {
      id,
      key,
      value,
    };
    this.props.changeCheckinKeyValue(data);
  }
  onUpdateCheckIn = async () => {
    const data = {
      checkin: this.state.checkin,
      productIDArray: this.state.selectedProductIDsArray,
    };
    this.props.updateCheckIn(data, this);

    if (this.state.isReadyStatusCallUp) {
      this.props.props.function.onTextCheckinNotification();
    }
  };
  onDeleteCheckIn() {
    const data = {
      checkinIDArray: [this.state.checkinID],
    };
    this.props.deleteCheckIns(data, this);
  }
  onSelectStaff = (e) => {
    e.preventDefault();
    this.openModal('STAFF_SELECT_TABLE', true);
  };
  handleCallUpStatus = () => {
    const data = {
      validPlanPriceIDs: [
        'price_1HWwovKzT5gxpfj5JBTdZ6vX',
        'price_1ITdRDKzT5gxpfj5AiXicffz',
        'price_1ITdSwKzT5gxpfj5CsPBjOLJ',
        'price_1HWwnxKzT5gxpfj5agK8tAjw',
        'price_1ITddEKzT5gxpfj5OtfDj99M',
        'price_1HWwpZKzT5gxpfj5bBkg7nnv',
      ],
      token: this.props.users.token,
      action: 'HANDLE_CALL_UP_STATUS',
    };
    this.props.compareUserPlanToValidPlan(data, this);
  };
  setStateCallUpStatus = (e) => {
    // this.setState({ isReadyStatusCallUp: Number(e.target.value) === Number(1) ? 0 : 1 });
    this.setState({ isReadyStatusCallUp: !this.state.isReadyStatusCallUp || this.state.checkin.notified ? 1 : 0 });
  };
  render() {
    const { errors } = this.props.site;
    const radios = [
      { name: 'Checked-In', value: 'checked-in', icon: <MdCheckCircle size={25} className='color-deepskyblue' /> },
      { name: 'Ready', value: 'ready', icon: <MdNotificationsActive size={25} className='color-darkorange' /> },
      { name: 'Seated', value: 'seated', icon: <MdAirlineSeatReclineNormal size={25} className='color-gray' /> },
      { name: 'Canceled', value: 'canceled', icon: <MdCancel size={25} className='color-lightgray' /> },
    ];
    return this.state.checkin && this.state.checkin.id ? (
      <>
        <div className='display-flex width-100 align-items-center justify-content-center'>
          <div className='width-100'>
            <div className='display-flex flex-direction-column align-items-center justify-content-center'>
              <div className='font-size-medium margin-bottom-5'>
                <Button
                  variant='secondary'
                  onClick={async () => {
                    await this.setState({ checkin: { ...this.state.checkin, selectedCustomer: this.state.checkin.selectedCustomer } });
                    this.openModal('CUSTOMER_VIEW_PROFILE', true);
                  }}
                  style={{ padding: '15px', color: '#019fe8', border: 'solid 1px #019fe8' }}
                >
                  <span className='text-transform-capitalize font-size-medium'>
                    {this.state.checkin.selectedCustomer.first_name} {this.state.checkin.selectedCustomer.last_name}
                  </span>
                  <br />
                  <span className='font-size-medium'>{phoneFormatter.format(this.state.checkin.selectedCustomer.phone + '', '(NNN) NNN-NNNN')}</span>
                </Button>
                {this.state.checkin.temperature ? <>| ({this.state.checkin.temperature}&deg;)</> : ''}
              </div>
              {this.state.checkin.party_size > 1 ? (
                <div className='font-size-medium'>
                  <MdPeople size={30} className='color-deepskyblue' /> Party of {this.state.checkin.party_size}
                </div>
              ) : (
                ''
              )}
            </div>

            <div className='display-flex margin'>
              <div className='width-100'>
                <div className='margin-bottom-15'>
                  {this.state.selectedProducts.length > 0 ? (
                    <>
                      <div className='hr-text color-gray'>Selected Products &amp; Services</div>
                      {this.state.selectedProducts.map((product, i) => {
                        // const price = product.price.split('.');
                        var price = [];
                        if (product.price.includes('.')) {
                          price = product.price.split('.');
                          if (price[0] === '') {
                            price[0] = 0;
                          } else {
                            price[0].toLocaleString();
                          }
                        } else {
                          price[0] = product.price;
                        }
                        const ProductMedia = () => {
                          return product.product_media_source ? (
                            <div style={{ width: 'fit-content' }}>
                              <img alt='product_thumbnail' src={product.product_media_thumbnail} width='60px' className='thumbnail border-radius-5' />
                            </div>
                          ) : (
                            <div className='padding-5 border-radius-5' style={{ width: 'fit-content' }}>
                              <FiBox size={38} className='color-white' />
                            </div>
                          );
                        };
                        return (
                          <div key={i}>
                            <div className='display-flex align-items-center flex-direction-column padding-10 padding-left-0 padding-right-0'>
                              <div className='display-flex color-black font-size-small'>
                                <div className='display-flex justify-content-center align-items-center'>
                                  <div className='display-flex-inline margin-right-10'>
                                    <ProductMedia />
                                  </div>
                                  <div className='flex-grow-1 margin-right-10'>
                                    <div className='display-flex justify-content-space-between padding-right-10 padding-bottom-5'>
                                      <div className='color-black'>{product.name} </div>
                                      {product.price ? (
                                        <div>
                                          <span>
                                            {product.time ? product.time + ' minutes |' : ''} <sup className='font-size-smaller'>$</sup>
                                            <span className='font-size-medium-large color-black'>{price[0]}</span>
                                            <sup className='font-size-smaller'>{price[1]}</sup>
                                          </span>
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>
                                  <div className='display-flex-inline'>
                                    {/* {this.props.props.state.selectedSortType === 'Today' ? (
                                      <Button
                                        variant='secondary'
                                        onClick={() =>
                                          this.setState({
                                            selectedProductIDsArray: this.state.selectedProductIDsArray.filter((id) => id !== product.id),
                                            selectedProducts: this.state.selectedProducts.filter((p) => p.id !== product.id),
                                          })
                                        }
                                      >
                                        <span className='color-gray'>x</span>
                                      </Button>
                                    ) : (
                                      ''
                                    )} */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    ''
                  )}
                  {this.state.checkin.selectedStaff && this.state.checkin.selectedStaff.first_name ? (
                    <div>
                      <div className='hr-text color-gray'>Selected Staff</div>
                      <div className='display-flex justify-content-space-between'>
                        <div>
                          <img
                            alt='product_thumbnail'
                            src={
                              this.state.checkin.selectedStaff.staff_media_source
                                ? this.state.checkin.selectedStaff.staff_media_thumbnail
                                : staff_placeholder
                            }
                            className='thumbnail border-radius-25 border margin-right-15'
                          />
                          {this.state.checkin.selectedStaff.first_name + ' ' + this.state.checkin.selectedStaff.last_name}
                        </div>
                        {/* {this.props.props.state.selectedSortType === 'Today' ? (
                          <Button
                            variant='secondary'
                            onClick={() => {
                              this.setState({ checkin: { ...this.state.checkin, staff_id: null, selectedStaff: {} } });
                            }}
                          >
                            <span className='color-gray'>x</span>
                          </Button>
                        ) : (
                          ''
                        )} */}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {this.props.props.state.selectedSortType === 'Today' ? (
                <div className='display-flex justify-content-center'>
                  <ButtonGroup className='display-flex flex-wrap-nowrap'>
                    <Button variant='secondary' onClick={() => this.openModal('PRODUCTS_SELECT_TABLE', true)}>
                      {this.state.selectedProducts.length > 0 ? 'Edit' : 'Select'} Products &amp; Services
                    </Button>
                    <Button variant='secondary' onClick={() => this.openModal('STAFF_SELECT_TABLE', true)}>
                      {this.state.checkin.selectedStaff && this.state.checkin.selectedStaff.first_name ? 'Edit' : 'Select'} Staff
                    </Button>
                  </ButtonGroup>
                </div>
              ) : (
                ''
              )}
              <TextField
                label='Notes/Requests'
                multiline
                rows='3'
                name='notesEnabled'
                margin='normal'
                variant='outlined'
                value={this.state.checkin.notes ? this.state.checkin.notes : ''}
                onChange={(e) => {
                  this.setState({ checkin: { ...this.state.checkin, notes: e.target.value } });
                  this.props.clearErrors();
                }}
                InputLabelProps={{ style: { fontSize: 17 }, shrink: true }} // font size of input label
                className='display-flex'
              />
            </div>
            {this.props.props.state.selectedSortType === 'Today' ? (
              <>
                <div className='display-flex justify-content-center margin-bottom-15'>
                  <ButtonGroup toggle className='display-flex'>
                    {radios.map((radio, idx) => (
                      <ToggleButton
                        variant='secondary'
                        key={idx}
                        type='radio'
                        name='radio'
                        value={radio.value}
                        checked={radio.value === '' + this.state.checkin.status}
                        onChange={async (e) => {
                          await this.setState({
                            checkin: {
                              ...this.state.checkin,
                              status: e.currentTarget.value,
                              readied_time: e.currentTarget.value === radios[1].value ? Date.parse(new Date()) / 1000 : null,
                              serviced_time:
                                e.currentTarget.value === radios[2].value && !this.state.checkin.serviced_time ? Date.parse(new Date()) / 1000 : null,
                              canceled_time: e.currentTarget.value === radios[3].value ? Date.parse(new Date()) / 1000 : null,
                              cancel_by: e.currentTarget.value === radios[3].value ? 'host' : null,
                            },
                          });
                          if (this.state.checkin.status !== 'ready') {
                            this.setState({ isReadyStatusCallUp: 0 });
                          }
                          this.props.clearErrors();
                          // else {
                          //   this.setState({ isReadyStatusCallUp: 1 });
                          // }
                        }}
                      >
                        <div className='display-flex align-items-center justify-content-center'>
                          <center>
                            {radio.icon}
                            <br />
                            {radio.name}
                            <br />
                            {radio.value === radios[0].value ? dateFormat(this.state.checkin.created_time * 1000, 'h:MM TT') : ''}
                            {radio.value === radios[1].value && this.state.checkin.readied_time
                              ? dateFormat(this.state.checkin.readied_time * 1000, 'h:MM TT')
                              : ''}
                            {radio.value === radios[2].value && this.state.checkin.serviced_time
                              ? dateFormat(this.state.checkin.serviced_time * 1000, 'h:MM TT')
                              : ''}
                            {radio.value === radios[3].value && this.state.checkin.canceled_time
                              ? dateFormat(this.state.checkin.canceled_time * 1000, 'h:MM TT')
                              : ''}
                          </center>
                        </div>
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                  {this.state.checkin.status === 'ready' ? (
                    <>
                      <div className='display-flex align-items-center padding'>
                        <input
                          type='checkbox'
                          name='callUpStatus'
                          onChange={(e) => this.handleCallUpStatus(e)}
                          value={this.state.isReadyStatusCallUp === 1 ? 1 : 0}
                          checked={this.state.isReadyStatusCallUp || this.state.checkin.notified === 1 ? 1 : 0}
                          className='checkin-checkbox'
                          disabled={this.state.checkin.notified ? 1 : 0}
                          style={{ marginRight: '15px' }}
                        />
                        {/* <input
                          type='checkbox'
                          onChange={(e) => {
                            console.log('EEEEE', e.target.value);
                            this.setState({ isReadyStatusCallUp: e.target.value });
                          }}
                          onClick={(e) => {
                            console.log('EEE', e.target.value);
                          }}
                          checked={this.state.isReadyStatusCallUp}
                          value={this.state.isReadyStatusCallUp}
                          disabled={this.state.checkin.notified ? true : false}
                          className='checkin-checkbox'
                          style={{ marginRight: '15px' }}
                        />{' '} */}
                        {this.state.checkin.notified
                          ? this.state.checkin.selectedCustomer.first_name + ' has been notified'
                          : 'Call up ' + this.state.checkin.selectedCustomer.first_name + ' via text on Save'}
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </>
            ) : (
              ''
            )}
            <div className='margin-left-15 margin-right-15'>
              <Alert
                variant='warning'
                show={errors.errorInvalidPlan ? true : false}
                onClose={() => this.props.clearErrors()}
                dismissible
                className='margin-top-15'
              >
                {errors.errorInvalidPlan}
              </Alert>
            </div>
            <div className='display-flex margin-top-15'>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  this.onUpdateCheckIn();
                }}
                className='display-flex'
              >
                <span className='white-space-nowrap'>{this.props.props.state.selectedSortType === 'Today' ? 'Save' : 'Close'}</span>
              </Button>
            </div>
          </div>
        </div>
        <ModalDepot
          state={this.state}
          props={this.props}
          function={{
            openModal: this.openModal,
            setStaff: this.setStaff,
            setProducts: this.setProducts,
            setCheckin: this.setCheckin,
            setCustomer: this.setCustomer,
            onUpdateCheckIn: this.onUpdateCheckIn,
          }}
        />
      </>
    ) : (
      <>
        <div className='display-flex align-items-center justify-content-center font-size-large padding'>Loading...</div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  subscriptions: state.subscriptions,
  business: state.business,
  users: state.users,
  site: state.site,
});

export default connect(mapStateToProps, {
  getCheckIn,
  updateCheckIn,
  getStaffs,
  getProducts,
  getStripeSubscriptionProducts,
  changeCheckinKeyValue,
  compareUserPlanToValidPlan,
  clearErrors,
})(AppComponent);
