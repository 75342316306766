import React, { Component } from 'react';
import { connect } from 'react-redux';

import checkin_art from './../../assets/images/checkin_art.jpg';
import any_devices from './../../assets/images/any_devices.jpg';
import booking_art from './../../assets/images/booking_art.jpg';
import business_buildings_art from './../../assets/images/business_buildings_art.jpg';
import marketing_art from './../../assets/images/marketing_art.jpg';
import location_art from './../../assets/images/location_art.jpg';

class AppComponent extends Component {
  render() {
    return (
      <>
        <div>
          <div className='STICKYHEADER display-flex width-max-980 margin-auto padding'>
            <div className='display-flex flex-direction-column'>
              <div className='display-flex justify-content-space-evenly padding'>
                <a href='#checkins'>Check-In Services</a> | <a href='#booking'>Booking Services</a> | <a href='#marketing'>Marketing Services</a> |
                <a href='#pages'>Business Pages</a>
              </div>
              <div>
                <div className='display-flex align-items-center justify-content-center' id='checkins'>
                  <img src={checkin_art} alt='Check-In Services' />
                </div>
                <div className='display-flex justify-content-center font-size-large margin-15' id='checkins'>
                  CHECK-IN SERVICES
                </div>
                <div>
                  Today, our check-in services has been made simpler for your business and customers. The check-in experience is streamlined so you
                  can check in your customer quickly and safely.
                </div>
                <div className='margin-15'>
                  What’s Good:
                  <ul className='services-overview-ul'>
                    <li>
                      <b>Redesigned with a simpler design</b> for quick and more intuitive check in
                    </li>
                    <li>
                      <b>Customizable features</b> to fit your business's needs
                    </li>
                    <li>
                      <b>Implementation of contactless QR Code</b> lets you check in customers conveniently and safely
                    </li>
                    <li>
                      <b>Accessible and work</b> on multiple devices.
                    </li>
                    <li>
                      <b>Waitlist and feedback</b> to gauge business performance
                    </li>
                    <li>
                      <b>Safety and integrity features</b> to protect customer data
                    </li>
                  </ul>
                </div>
                <div className='font-size-medium'>Quick and Simple Design</div>
                <div className='margin-top-25 margin-bottom-25'>
                  Now it’s easy to not only start the application but also the check-in process has been reduced to just phone number. We’ve
                  redesigned the look and feel to be cleaner and more streamlined than before — including making it simple for people to check-in and
                  register other important info.{' '}
                </div>
                <div className='font-size-medium'>Customization</div>
                <div className='margin-top-25 margin-bottom-25'>
                  We are bringing customizable features to the check-in application. Now you can enable or disable features to fit your business
                  needs. Although a few features are only available on certain subscription plans, most features are available at the lowest tier plan
                  in an effort to support small and local businesses.
                </div>
                <div className='font-size-medium'>Works on Any Device</div>
                <div className='margin-top-25 margin-bottom-25'>
                  Getting our application to work on multiple devices is important to make it accessible to as many businesses as possible. Whether
                  you're on iOS or Android, you can simply launch our app using the built in web browser. No installation and updates needed. Our
                  application updates automatically from anywhere as long as it has internet access. Accessible and available also means no dedicated
                  or propriety devices which reduces expenses for your business.{' '}
                </div>
                <div>
                  <img src={any_devices} alt='Any Devices' />
                </div>
                <div className='font-size-medium'>Contactless QR Code</div>
                <div className='margin-top-25 margin-bottom-25'>
                  We’ve added contactless features — like the ability to print as many of your own check-in QR codes as you like and place them in
                  convenient locations for customer check in. For example, you’ll now be able to different size QR codes and place them where your
                  customers are seated. Have them scan the code with their phone to check in instead of at the helpdesk. QR codes helps add a level of
                  safety and convenience for your business and customers as it was built with the covid/pandemic period in mind.
                </div>
                <div className='font-size-medium'>Safe and Secure</div>
                <div className='margin-top-25 margin-bottom-25'>
                  We want our application to be a safe place to check in, so we only required customer data which are relevant to helping your
                  business needs. All of your customer data are visible only to your business account. Some customer data are kept private and not
                  editable unless they are verifiable by either your business or the customer.
                </div>
              </div>
              <div>
                <div className='display-flex align-items-center justify-content-center' id='booking'>
                  <img src={booking_art} alt='booking' />
                </div>
                <div className='display-flex justify-content-center font-size-large margin-15'>BOOKING SERVICES</div>
                <div>
                  Our booking services has been revamped to look cleaner than before, allowing for better visibility of in-person and online bookings.
                </div>
                <div className='margin-15'>
                  What’s Good:
                  <ul className='services-overview-ul'>
                    <li>
                      <b>A full calendar</b> for you and your staff
                    </li>
                    <li>
                      <b>Standard color coding</b> makes differentiate bookings easier
                    </li>
                    <li>
                      <b>Vanity booking url</b> for online booking
                    </li>
                    <li>
                      <b>Synchronize updates</b> across all opened scheduling calendar
                    </li>
                  </ul>
                </div>
                <div className='font-size-medium'>Full Calendar</div>
                <div className='margin-top-25 margin-bottom-25'>
                  Scheduling in person with a full calendar is now as easy as it looks. As additional staff is added to your account, the scheduling
                  view will show them next to each other on the calendar. You will be able to clearly see who has bookings and who has requested
                  bookings awaiting for them. The calendar syncs with all devices, so when you update on your side, anyone with their calendar open
                  will see the update.
                </div>
                <div className='font-size-medium'>Clear Color Coding</div>
                <div className='margin-top-25 margin-bottom-25'>
                  We have opted into keeping a standard set of colors for labeling events and bookings. This not only will help maximize productivity
                  but also makes it easier for you and all of your staff, especially new ones, to be able to quickly view and know what's going on.
                </div>
                <div className='font-size-medium'>Public Vanity Url</div>
                <div className='margin-top-25 margin-bottom-25'>
                  Having a vanity url is necessary for your customers to view your menu/services and be able to book your services or order your
                  products. We reserve certain vanity url for corporate businesses, but if your business's vanity url is taken, notify us. We will do
                  our best to help your business.
                </div>
                <div className='font-size-medium'>Instant Notification &amp; Synchronization</div>
                <div className='margin-top-25 margin-bottom-25'>
                  When your customers request a booking, you will get instant notification via text and the booking request will automatically show up
                  on your calendar without having to refreshing the calendar. This features also apply to updates from multiple devices - for example,
                  if a staff updates a booking on his or her device, the update will reflect immediately on your device.
                </div>
              </div>
              <div>
                <div className='display-flex align-items-center justify-content-center' id='marketing'>
                  <img src={marketing_art} alt='Marketing' />
                </div>
                <div className='display-flex justify-content-center font-size-large margin-15'>MARKETING SERVICES</div>
                <div>
                  We have expanded our marketing tools to include mass email marketing and direct text marketing. We're currently working on creating
                  marketing tools for digital screens which will be rolled out in our future updates.
                </div>
                <div className='margin-15'>
                  What’s Good:
                  <ul className='services-overview-ul'>
                    <li>
                      <b>Automatic promotional alerts</b> of your business services based on customer check-im activities
                    </li>
                    <li>
                      <b>Scheduled monthly/weekly marketing events</b> to helps your business get the words out
                    </li>
                  </ul>
                </div>
                <div className='font-size-medium'></div>
                <div className='margin-top-25 margin-bottom-25'>
                  Scheduling in person with a full calendar is now as easy as it looks. As additional staff is added to your account, the scheduling
                  view will show them next to each other on the calendar. You will be able to clearly see who has bookings and who has requested
                  bookings awaiting for them. The calendar syncs with all devices, so when you update on your side, anyone with their calendar open
                  will see the update.
                </div>
                <div className='font-size-medium'>Promotional Alerts</div>
                <div className='margin-top-25 margin-bottom-25'>
                  Every so often, our system will recommend your businesses to your customers via email or text messages based on your settings and
                  promotional message. This feature will help retain customers and also generate more revenue for your business.
                </div>
                <div className='font-size-medium'>Scheduled Events</div>
                <div className='margin-top-25 margin-bottom-25'>
                  When you need to promote a new product or service, you can do so by scheduling an event to have the promotion go out via email or
                  text at certain times. We have made scheduling easy and quick with guides that walk you through the process.
                </div>
              </div>
              <div>
                <div className='display-flex align-items-center justify-content-center' id='pages'>
                  <img src={business_buildings_art} alt='Business Pages' />
                </div>
                <div className='display-flex justify-content-center font-size-large margin-15'>BUSINESS PAGES</div>
                <div>
                  Your business page is designed to put your business image first. A great looking business page helps your business increase in value
                  and reputation. Your products and services take visible priority when customers visit your page, so we recommend listing them as
                  accurate and detailed as you can.
                </div>
                <div className='margin-15'>
                  What’s Good:
                  <ul className='services-overview-ul'>
                    <li>
                      <b>Business profile images</b> at the forefront
                    </li>
                    <li>
                      <b>Menu/Service items</b> for online booking and ordering
                    </li>
                    <li>
                      <b>Map and directions</b> simplified
                    </li>
                  </ul>
                </div>
                <div className='font-size-medium'>Business Image</div>
                <div className='margin-top-25 margin-bottom-25'>
                  You can now add a background image for your business page to personalize it. We're making profile business page more expressive and
                  fun. Try it out and have a blast!
                </div>
                <div className='font-size-medium'>Products and Services Listing</div>
                <div className='margin-top-25 margin-bottom-25'>
                  We have made it easier to organize and showcase your products and services on your business page. We put your listings first so your
                  customer can conveniently book or order from you.
                </div>
                <div className='font-size-medium'>Way to Your Business</div>
                <div className='margin-top-25 margin-bottom-25'>
                  We've added a dedicated map to help your customers navigate to your business. Putting your business on Google maps improves your
                  visibility to millions of potential customers.
                </div>
                <div>
                  <img src={location_art} alt='Location' className='width-100' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps)(AppComponent);
