import React, { Component } from 'react';
import { Form, Alert, Button, ToggleButton, ButtonGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { addProduct, getProductCategories } from '../../../redux/actions/businessActions';
import { clearErrors } from '../../../redux/actions/siteActions';

class AppComponent extends Component {
  constructor() {
    super();
    this.state = {
      componentID: 'PRODUCT_ADD_FORM',
      type: 'service',
      category: '',
      name: '',
      price: '',
      description: '',
      duration: '',
      isSubmitInProgress: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    this.props.getProductCategories(this.props.users.token.business_id);
  }
  async onChange(e) {
    await this.setState({ [e.target.name]: e.target.value });
  }
  onSubmit(e) {
    e.preventDefault();
    const dataPack = {
      type: this.state.type,
      category: this.state.category,
      name: this.state.name,
      price: this.state.price,
      description: this.state.description,
      duration: this.state.duration,
      businessID: this.props.users.token.business_id,
    };
    this.props.addProduct(dataPack, this);
  }
  modules = {
    toolbar: [
      // [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      [{ color: ['#4285F4', '#EA4335', '#FBBC05', '#34A853', '#000'] }],
      ['link'],
      ['clean'],
    ],
  };
  handleInputChange = (inputValue) => {};
  render() {
    const { product_categories } = this.props.business;
    const { errors } = this.props.site;
    const categoryOptions = [];
    product_categories.map((category) => {
      categoryOptions.push({ id: category.id, value: category.name, label: category.name, subheading: category.subheading });
      return categoryOptions;
    });

    return (
      <div className='display-flex align-items-center justify-content-center'>
        <Form noValidate onSubmit={(e) => this.onSubmit(e)} className='width-100'>
          <div className='margin-bottom-15'>
            <ButtonGroup toggle className='display-flex margin-bottom-10'>
              <ToggleButton
                variant='secondary'
                key={1}
                type='radio'
                name='radio'
                value={this.state.type}
                checked={this.state.type === 'service' ? true : false}
                onChange={(e) => {
                  this.setState({ type: 'service' });
                }}
              >
                Service
              </ToggleButton>
              <ToggleButton
                variant='secondary'
                key={2}
                type='radio'
                name='radio'
                value={this.state.type}
                checked={this.state.type === 'product' ? true : false}
                onChange={(e) => {
                  this.setState({ type: 'product' });
                }}
              >
                Product | Menu Item
              </ToggleButton>
            </ButtonGroup>
            <div className='margin-bottom-5'>Category</div>
            <CreatableSelect
              isClearable
              onChange={(e) => this.setState({ category: e })}
              onInputChange={this.handleInputChange}
              options={categoryOptions}
              placeholder='Select or Type New Category'
            />
            <div className='margin-bottom-5 margin-top-15'>Name</div>
            <Form.Control type='text' name='name' value={this.state.firstName} onChange={this.onChange} className='margin-bottom-15 width-100' />
            <div className='margin-bottom-5'>Price</div>
            <Form.Control
              type='number'
              name='price'
              placeholder='Optional'
              value={this.state.price}
              onChange={this.onChange}
              className='margin-bottom-15'
            />
            {this.state.type === 'service' ? (
              <>
                <div className='margin-bottom-5'>Service Duration (minutes)</div>
                <Form.Control
                  type='number'
                  max='10080'
                  name='duration'
                  onChange={this.onChange}
                  value={this.state.duration}
                  placeholder='Optional'
                  className='margin-bottom-15'
                />
              </>
            ) : (
              ''
            )}
            <ReactQuill
              theme='snow'
              name='description'
              modules={this.modules}
              value={this.state.description}
              onChange={(e) => {
                this.setState({ description: e });
              }}
              placeholder='Description'
              className='margin-bottom-15'
            />
            <Alert variant='warning' show={errors.errorNewProduct ? true : false} onClose={() => this.props.clearErrors()} dismissible>
              {errors.errorNewProduct}
            </Alert>
            <Button type='submit' disabled={this.state.isSubmitInProgress ? true : false} className='width-100'>
              Add
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  business: state.business,
  users: state.users,
  site: state.site,
});

export default connect(mapStateToProps, { addProduct, getProductCategories, clearErrors })(AppComponent);
