import { GET_ERRORS, CLEAR_ERRORS, GET_RESPONSE, CLEAR_RESPONSE, EMAIL_TOKEN_LINK, GET_ENV } from '../types/types';

const initialState = { errors: [], response: [], env: {} };

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: [],
      };
    case CLEAR_RESPONSE:
      return {
        ...state,
        response: [],
      };
    case GET_RESPONSE:
      return {
        ...state,
        response: action.payload,
      };
    case EMAIL_TOKEN_LINK:
      return {
        ...state,
        response: action.payload,
      };
    case GET_ENV:
      return {
        ...state,
        env: action.payload,
      };
    default:
      return state;
  }
}
