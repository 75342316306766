import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import isEmpty from './../../../../functions/isEmpty';
import { verifyCustomer } from './../../../../redux/actions/businessActions';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'PERSON_SELECT_TABLE',
      selectedPerson: {},
    };
    this.onVerifyCustomer = this.onVerifyCustomer.bind(this);
  }
  onVerifyCustomer = (person) => {
    const data = {
      businessID: this.props.props.state.businessID,
      person,
    };
    this.props.verifyCustomer(data, this);
  };
  render() {
    const selectRow = {
      mode: 'radio',
      clickToSelect: true,
      onSelect: (row, isSelect, rowIndex, e) => {
        // this.onSelect(row, isSelect);
        // this.props.function.onSelect(row, isSelect);
        // this.onSelect(row, isSelect);
      },
      // selected: this.state.selectedForms,
      // style: {backgroundColor: '#e0e0e0'},
      hideSelectColumn: true,
      hideSelectAll: true,
    };
    // const NoDataIndication = (props) => {};
    const columns = [
      {
        dataField: 'first_name',
        text: '',
        align: 'left',
        formatter: (cell, row) => {
          return (
            <div>
              <Button
                variant='secondary'
                onClick={() => this.setState({ selectedPerson: row })}
                className='display-flex'
                style={{ padding: '15px', color: '#019fe8', border: 'solid 1px #019fe8' }}
              >
                <span className='text-transform-capitalize font-size-medium-large'>
                  {row.first_name} {row.last_name.charAt(0)}.
                </span>
              </Button>
            </div>
          );
        },
      },
    ];
    const DataTable = () => {
      return (
        <>
          <div>
            <ToolkitProvider keyField='id' data={this.props.props.state.verifiedPersons} columns={columns} search>
              {(toolkitprops) => {
                return (
                  <div>
                    <div className='display-flex flex-direction-column flex-wrap-nowrap justify-content-center align-items-center'></div>
                    <BootstrapTable
                      keyField='id'
                      classes='customer_name_select_table'
                      headerClasses={this.props.props.state.verifiedPersons.length > 0 ? '' : 'display-none'}
                      {...toolkitprops.baseProps}
                      data={this.props.props.state.verifiedPersons}
                      columns={columns}
                      selectRow={selectRow}
                      // noDataIndication={<NoDataIndication data={forms} searchProps={toolkitprops.searchProps} />}
                    />
                  </div>
                );
              }}
            </ToolkitProvider>
          </div>
        </>
      );
    };
    return (
      <>
        <div>
          {!isEmpty(this.state.selectedPerson) ? (
            <>
              <div className='display-flex flex-direction-row justify-content-space-between align-items-center font-size-medium padding-top-none margin-bottom-10'>
                <div>
                  <a href='#/' onClick={() => this.setState({ selectedPerson: {} })}>
                    Go back
                  </a>
                </div>
                <div>You've selected:</div>
              </div>
              <div className='font-size-larger'>
                <span className='text-transform-capitalize'>
                  <hr></hr>
                  <div className='display-flex align-items-center justify-content-center font-weight-bold'>
                    {this.state.selectedPerson.first_name} {this.state.selectedPerson.last_name.charAt(0)}.
                  </div>
                </span>
              </div>
              <div className='display-flex margin-top-25 margin-bottom-15'>
                <Button variant='secondary' onClick={() => this.onVerifyCustomer(this.state.selectedPerson)} className='display-flex'>
                  <span className='text-transform-capitalize font-size-medium-large'>Confirm</span>
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className='display-flex justify-content-flex-end font-size-medium'>
                Select{' '}
                {this.props.props.state.componentID === 'CHECKINAPP_FORM' || this.props.props.state.componentID === 'BOOKING_VERIFY_FORM'
                  ? 'Your'
                  : ''}{' '}
                Name
              </div>
              <hr></hr>
              <DataTable />
              <hr></hr>
              <div className='display-flex flex-direction-row align-items-center justify-content-center font-size-medium padding-top-none margin-bottom-10'>
                <div>
                  <a
                    href='#/'
                    onClick={() => {
                      this.props.props.function.openModal('PERSON_SELECT_TABLE', false);
                      this.props.props.function.openModal('PERSON_ADD', true);
                    }}
                  >
                    Didn't see your name?
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps, { verifyCustomer })(AppComponent);
