import React, { Component } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { confirmSubscriptionCancel } from './../../../redux/actions/subscriptionActions';
import { clearErrors } from './../../../redux/actions/siteActions';
// import { ThisMonthInstance } from 'twilio/lib/rest/api/v2010/account/usage/record/thisMonth';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      userPassword: '',
      isConfirmedPlanCancel: false,
    };
    this.onConfirmPlanCancel = this.onConfirmPlanCancel.bind(this);
    this.onSetStateConfirmedSubscriptionCancel = this.onSetStateConfirmedSubscriptionCancel.bind(this);
  }
  onConfirmPlanCancel() {
    // check if the password is correct, if so, update and switch plan
    const userValues = {
      password: this.state.userPassword,
      userToken: this.props.users.token,
    };
    this.props.confirmSubscriptionCancel(userValues, this);
  }
  onSetStateConfirmedSubscriptionCancel = () => {
    this.setState({ isConfirmedPlanCancel: true });
  };
  render() {
    const { errors } = this.props.site;
    return (
      <>
        <center>
          <div>
            <div className='font-size-medium padding'>Are you sure you want to proceed?</div>
            <div className='padding'>
              Your subscription plan will be cancelled immediately. All features and controls available will be removed.
              <br />
              <br />
              Want to switch plans instead?{' '}
              <a
                href='#!'
                onClick={(e) => {
                  this.props.props.function.openModal('SUBSCRIPTION_CANCEL', false);
                  this.props.props.function.openModal('SUBSCRIPTION_PLANS', true);
                }}
              >
                Click here
              </a>
              . <br />
              <br />
              If you have any questions or concerns, please text via (713) 742-2971 or going to the support section in the sidebar for answers to our
              FAQs.
            </div>
          </div>
          <div className='font-weight-bold'>Enter your password to confirm your cancellation</div>
          <Form>
            <Form.Control
              type='password'
              name='userPassword'
              autoComplete='on'
              value={this.state.userPassword}
              placeholder='Account Password'
              onChange={(e) => this.setState({ userPassword: e.target.value })}
              className='margin-bottom-15 margin-top-15'
            />
          </Form>
          <Alert variant='warning' show={errors.message ? true : false} onClose={() => this.props.clearErrors()} dismissible>
            {errors.message}
          </Alert>
          <div className='display-flex justify-content-flex-end'>
            <Button
              variant='danger'
              onClick={(e) => this.onConfirmPlanCancel(e)}
              disabled={this.state.isConfirmedPlanCancel ? this.state.isConfirmedPlanCancel : false}
            >
              Cancel subscription
            </Button>
          </div>
        </center>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  site: state.site,
});
export default connect(mapStateToProps, { confirmSubscriptionCancel, clearErrors })(AppComponent);
