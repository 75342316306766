import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';

import { getBusiness } from './../../../redux/actions/businessActions';
import { getPublicEnv } from './../../../redux/actions/siteActions';

class AppComponent extends Component {
  constructor(props) {
    super();
  }
  componentDidMount() {
    this.props.getBusiness(this.props.users.token.business_id, this);
    this.props.getPublicEnv();
  }
  render() {
    const { business } = this.props.business;
    const { env } = this.props.site;
    const shareURL = env.clientUrl + '/page/' + business.vanity_name;
    const shareTitle = ' Sharing this with you: ' + business.business_name + ' on #onespacelink';
    return (
      <>
        <div className='display-flex justify-content-space-evenly'>
          <div className='padding'>
            <EmailShareButton title={shareTitle} url={`${shareURL}`}>
              <div className='display-flex align-items-center justify-content-center'>
                <EmailIcon size={32} round /> Email
              </div>
            </EmailShareButton>
          </div>
          <div className='padding'>
            <TwitterShareButton title={shareTitle} url={shareURL}>
              <TwitterIcon size={32} round /> Twitter
            </TwitterShareButton>
          </div>
          <div className='padding'>
            <FacebookShareButton title={shareTitle} url={shareURL}>
              <FacebookIcon size={32} round />
              Facebook
            </FacebookShareButton>
          </div>
          <div className='padding'>
            <LinkedinShareButton title={shareTitle} url={shareURL}>
              <LinkedinIcon size={32} round /> LinkedIn
            </LinkedinShareButton>{' '}
          </div>
          <div className='padding'>
            <RedditShareButton title={shareTitle} url={shareURL}>
              <RedditIcon size={32} round /> Reddit
            </RedditShareButton>{' '}
          </div>
          <div className='padding'>
            <WhatsappShareButton title={shareTitle} url={shareURL}>
              <WhatsappIcon size={32} round /> Whatsapp
            </WhatsappShareButton>{' '}
          </div>
          {/* <div className='padding'>
            <PinterestShareButton title={shareTitle} url={shareURL}>
              <PinterestIcon size={32} round /> Pinterest
            </PinterestShareButton>{' '}
          </div> */}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  business: state.business,
  site: state.site,
});
export default connect(mapStateToProps, { getBusiness, getPublicEnv })(AppComponent);
