import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { MdAddCircle } from 'react-icons/md';

import ModalDepot from './../../components/ModalDepot';
import { getStaffs } from '../../../../redux/actions/businessActions';
import { clearErrors } from './../../../../redux/actions/siteActions';
import { FaUserCircle } from 'react-icons/fa';
class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'STAFF_SELECT_TABLE',
      currentPage: 1,
      currentPageSize: 1000,
      selectedStaffID: '',
      isAddStaffModalShow: false,
    };
  }
  componentDidMount() {
    this.props.getStaffs(this.props.users.token.business_id);
  }
  openModal = (modalName, modalState, modalData) => {
    switch (modalName) {
      case 'STAFF_ADD':
        if (modalState) {
          this.setState({ isAddStaffModalShow: true });
        } else {
          this.setState({ isAddStaffModalShow: false });
          this.props.clearErrors();
        }
        break;
      default:
    }
  };
  render() {
    const { staffs } = this.props.business;
    const { SearchBar } = Search;
    var letterHeader = '';
    const columns = [
      {
        dataField: 'search_fields',
        text: '',
        formatter: (cell, row) => {
          let letterHeaderValue = '';
          if (letterHeader !== row.first_name.toLowerCase().charAt(0)) {
            letterHeader = row.first_name.toLowerCase().charAt(0);
            letterHeaderValue = row.first_name.toUpperCase().charAt(0);
          }
          const staff_media_source = row.staff_media_source ? '/api/static/media/staff/thumbnail/' + row.staff_media_source : '';
          return (
            <>
              {letterHeaderValue ? (
                <div className='display-flex align-items-center justify-content-space-between padding border-bottom-gray bg-whitesmoke'>
                  <div className='display-flex font-size-medium'>{letterHeaderValue}</div>
                </div>
              ) : (
                ''
              )}
              <div className='display-flex align-items-center'>
                <div className='margin-right-5'>
                  <div className='padding-5 border-radius-35'>
                    {staff_media_source ? (
                      <>
                        <img alt='staff_thumbnail' src={staff_media_source} width='60px' className='thumbnail border-radius-25' />
                      </>
                    ) : (
                      <>
                        <FaUserCircle size={50} className='color-whitesmoke border-gray border-radius-25' />
                      </>
                    )}
                  </div>
                </div>
                <div className='padding text-align-left'>
                  <div className='margin-bottom-5'>
                    {row.first_name ? row.first_name : ''} {row.last_name ? row.last_name : ''}
                  </div>
                </div>
              </div>
            </>
          );
        },
      },
    ];
    const paginationOptions = {
      onPageChange: (page, sizePerPage) => {
        this.setState({ currentPage: page, currentPageSize: sizePerPage });
      },
      page: this.state.currentPage,
      sizePerPage: this.state.currentPageSize,
      hideSizePerPage: true,
    };
    const selectRowProp = {
      mode: 'radio',
      clickToSelect: true,
      onSelect: (row, isSelect, rowIndex, e) => {
        this.props.props.function.setStaff(row);
        this.props.props.function.openModal('STAFF_SELECT_TABLE', false);
      },
      style: { backgroundColor: '#e0e0e0' },
      hideSelectColumn: true,
    };
    const NoDataIndication = (props) => {
      if (props.searchProps.searchText.length > 0) {
        return (
          <div className='padding color-black'>
            Your search - <span className='font-weight-bold'>{props.searchProps.searchText}</span> - did not match any data.
          </div>
        );
      } else {
        return (
          <div className='padding display-flex justify-content-center align-items-center'>
            <center>
              <FaUserCircle size='200px' />
              <br /> <span className='font-size-large font-weight-bold'>No Staff Yet</span>
              {this.props.token ? (
                <div>
                  <br />
                  <br />
                  Adding staff allows you to select them for customer requests and service jobs.
                  <br />
                  <br />
                  <Button variant='secondary' onClick={() => this.openModal('STAFF_ADD', true)}>
                    <MdAddCircle size={25} className='color-deepskyblue' />
                    Add a Staff Member
                  </Button>
                </div>
              ) : (
                ''
              )}
            </center>
          </div>
        );
      }
    };
    const DataTable = () => {
      return (
        <div>
          <ToolkitProvider keyField='id' data={staffs} columns={columns} search>
            {(toolkitprops) => {
              return (
                <div>
                  <div className='display-flex flex-direction-column flex-wrap-nowrap'>
                    {this.props.props.state.componentID === 'APPOINTMENT_ADD_FORM' ||
                    this.props.props.state.componentID === 'APPOINTMENT_UPDATE_FORM' ||
                    this.props.props.state.componentID === 'CHECKIN_UPDATE_FORM' ? (
                      <>
                        <Button variant='secondary' onClick={() => this.openModal('STAFF_ADD', true)}>
                          <MdAddCircle size={25} className='color-deepskyblue' /> New Staff
                        </Button>
                      </>
                    ) : (
                      ''
                    )}
                    <div className='width-100 margin-top-15 margin-bottom-15'>
                      <SearchBar placeholder='Search' tableId='Search' {...toolkitprops.searchProps} />
                    </div>
                  </div>
                  <BootstrapTable
                    keyField='id'
                    classes='staff-select-table'
                    headerClasses={'display-none'}
                    {...toolkitprops.baseProps}
                    data={staffs}
                    columns={columns}
                    selectRow={selectRowProp}
                    pagination={paginationFactory(paginationOptions)}
                    noDataIndication={<NoDataIndication data={staffs} searchProps={toolkitprops.searchProps} />}
                  />
                </div>
              );
            }}
          </ToolkitProvider>
        </div>
      );
    };
    return (
      <>
        <div>
          <DataTable />
          <ModalDepot state={this.state} props={this.props} function={{ openModal: this.openModal }} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  business: state.business,
  users: state.users,
  site: state.site,
});

export default connect(mapStateToProps, { getStaffs, clearErrors })(AppComponent);
