import React, { Component, forwardRef } from 'react';
import { Alert, Form, Button, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { connect } from 'react-redux';
import dateFormat from 'dateformat';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MdEdit } from 'react-icons/md';

import { addAppointment, getStaff } from '../../../redux/actions/businessActions';
import { clearErrors } from '../../../redux/actions/siteActions';

import ModalDepot from './../components/ModalDepot';
import { FiBox } from 'react-icons/fi';

const ReadonlyInput = forwardRef(({ value, onClick }, ref) => (
  <Button variant='secondary' onClick={onClick} style={{ padding: '10px', marginRight: '10px' }}>
    {value}
  </Button>
));
const TimeSelectInput = forwardRef(({ value, onClick, onChange }, ref) => (
  <>
    <Form.Control type='text' name='time' value={value} onClick={onClick} onChange={onChange} style={{ width: '75px' }} />
  </>
));

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'APPOINTMENT_ADD_FORM',
      selectedCustomer: {},
      selectedProducts: [],
      selectedProductIDsArray: [],
      selectedForms: [],
      selectedFormIDsArray: [],
      selectedStaff: props.props.state.selectedStaff && props.props.state.selectedStaff.id !== 'null' ? props.props.state.selectedStaff : {},
      startDate: props.props.state && props.props.state.selectedDateTime ? new Date(props.props.state.selectedDateTime.start) : new Date(),
      endDate: props.props.state && props.props.state.selectedDateTime ? new Date(props.props.state.selectedDateTime.end) : new Date(),
      startTime:
        props.props.state && props.props.state.selectedDateTime
          ? new Date(props.props.state.selectedDateTime.start).getTime()
          : new Date().setHours(9, 0, 0),
      endTime:
        props.props.state && props.props.state.selectedDateTime
          ? new Date(props.props.state.selectedDateTime.end).getTime()
          : new Date().setHours(9, props.props.props.business.business.booking_interval, 0),
      notes: '',
      color: '#019fe8',
      isSelectCustomerModalShow: false,
      isSelectProductsModalShow: false,
      isSelectStaffModalShow: false,
      isSelectFormsModalShow: false,
      isViewCustomerProfileModalShow: false,
      isSubmitInProgress: false,
      isEvent: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onAddAppointment = this.onAddAppointment.bind(this);
    this.openModal = this.openModal.bind(this);
    this.setCustomer = this.setCustomer.bind(this);
    this.setProducts = this.setProducts.bind(this);
    this.setStaff = this.setStaff.bind(this);
    this.setForms = this.setForms.bind(this);
  }
  setStaff(staff) {
    this.onChange(staff, 'selectedStaff');
  }
  setCustomer(customer) {
    this.onChange(customer, 'selectedCustomer');
    this.setState({ isEvent: false });
  }
  setProducts = async (products) => {
    // this.onChange(products, 'selectedProducts');
    var selectedProductIDs = [];
    products.forEach((product) => {
      selectedProductIDs.push(product.id);
    });
    await this.setState({ selectedProducts: products, selectedProductIDsArray: selectedProductIDs });
  };
  setForms = async (forms) => {
    var selectedFormIDs = [];
    forms.forEach((form) => {
      selectedFormIDs.push(form.id);
    });
    await this.setState({ selectedForms: forms, selectedFormIDsArray: selectedFormIDs });
  };
  openModal(modalName, modalState, modalData) {
    switch (modalName) {
      case 'CUSTOMER_SELECT_TABLE':
        if (modalState) {
          this.setState({ isSelectCustomerModalShow: true });
        } else {
          this.setState({ isSelectCustomerModalShow: false });
        }
        break;
      case 'CUSTOMER_VIEW_PROFILE':
        if (modalState) {
          this.setState({ isViewCustomerProfileModalShow: true });
        } else {
          this.setState({ isViewCustomerProfileModalShow: false });
        }
        break;
      case 'PRODUCTS_SELECT_TABLE':
        if (modalState) {
          this.setState({ isSelectProductsModalShow: true });
        } else {
          this.setState({ isSelectProductsModalShow: false });
        }
        break;
      case 'STAFF_SELECT_TABLE':
        if (modalState) {
          this.setState({ isSelectStaffModalShow: true });
        } else {
          this.setState({ isSelectStaffModalShow: false });
        }
        break;
      case 'FORMS_SELECT_TABLE':
        if (modalState) {
          this.setState({ isSelectFormsModalShow: true });
        } else {
          this.setState({ isSelectFormsModalShow: false });
        }
        break;
      default:
    }
  }
  async onChange(e, targetName) {
    if (targetName) {
      await this.setState({ [targetName]: e });
    } else {
      await this.setState({ [e.target.name]: e.target.value });
    }
  }
  onAddAppointment(e) {
    e.preventDefault();
    // const standardProductPrices = this.props.props.props.subscriptions.products[2].prices;
    // const plusProductPrices = this.props.props.props.subscriptions.products[3].prices;
    // const allowablePriceIDs = [];
    // standardProductPrices.map((price) => {
    //   return allowablePriceIDs.push(price.id);
    // });
    // plusProductPrices.map((price) => {
    //   return allowablePriceIDs.push(price.id);
    // });
    // if (allowablePriceIDs.includes(this.props.users.token.price_id)) {
    var startDateTime = new Date(this.state.startDate).setHours(
      new Date(this.state.startTime).getHours(),
      new Date(this.state.startTime).getMinutes(),
      0
    );
    var endDateTime = new Date(this.state.endDate).setHours(new Date(this.state.endTime).getHours(), new Date(this.state.endTime).getMinutes(), 0);
    const startDateTimeUTC = dateFormat(startDateTime, "yyyy-mm-dd'T'HH:MM:ss");
    const endDateTimeUTC = dateFormat(endDateTime, "yyyy-mm-dd'T'HH:MM:ss");
    const data = {
      customerID: this.state.selectedCustomer.id,
      productsArray: this.state.selectedProducts,
      formsArray: this.state.selectedForms,
      staffID: this.state.selectedStaff.id,
      startDate: startDateTimeUTC,
      endDate: endDateTimeUTC,
      notes: this.state.notes,
      color: this.state.color,
      isEvent: this.state.isEvent,
      token: this.props.users.token,
    };
    this.props.addAppointment(data, this);
    // } else {
    //   this.props.props.function.openModal('SUBSCRIPTION_PLANS', true);
    // }
  }
  handleChangeComplete = (color) => {
    this.setState({ color: color.hex });
  };
  // removeSelectedForm = (i) => {
  //   delete this.state.selectedForms[i];
  //   this.setState({ selectedForms: this.state.selectedForms });
  // };
  render() {
    const { errors } = this.props.site;
    const isWeekday = (date) => {
      const day = 4;
      return day !== 1;
    };
    if (this.props.props.props.business_hours && this.props.props.props.business_hours.length > 0) {
      var minHour = null,
        maxHour = null;
      if (this.props.props.props.business_hours && this.props.props.props.business_hours.length > 0) {
        minHour = Math.min.apply(
          Math,
          this.props.props.props.business_hours.map((hour) => {
            return hour.start;
          })
        );
        maxHour = Math.max.apply(
          Math,
          this.props.props.props.business_hours.map((hour) => {
            return hour.end;
          })
        );
      }
    }
    return (
      <>
        <div className='display-flex width-100 align-items-center justify-content-center'>
          <div className='width-100'>
            <div className='flex-table'>
              <div className='flex-table-row'>
                <div className='flex-table-cell font-size-normal padding text-align-right'>Type:</div>
                <div className='flex-table-cell padding'>
                  <ButtonGroup toggle>
                    <ToggleButton
                      variant='secondary'
                      key={1}
                      type='radio'
                      name='radio'
                      value='customer'
                      checked={!this.state.isEvent ? true : false}
                      onChange={async (e) => {
                        await this.setState({ isEvent: false });
                      }}
                      onClick={async () => {
                        await this.setState({ isEvent: false });
                      }}
                    >
                      <div className='display-flex align-items-center justify-content-center'>
                        <div className='margin-left-5'>Appointment/Reservation</div>
                      </div>
                    </ToggleButton>
                    <ToggleButton
                      variant='secondary'
                      key={2}
                      type='radio'
                      name='radio'
                      value='event'
                      checked={this.state.isEvent}
                      onChange={async (e) => {
                        await this.setState({
                          isEvent: true,
                          selectedCustomer: {},
                          selectedProducts: [],
                          selectedProductIDsArray: [],
                          selectedForms: [],
                          selectedFormIDsArray: [],
                        });
                      }}
                    >
                      <div className='display-flex align-items-center justify-content-center'>
                        <div className='margin-left-5'>Event/Holiday</div>
                      </div>
                    </ToggleButton>
                  </ButtonGroup>
                </div>
              </div>
              <div className='flex-table-row'>
                <div className='flex-table-cell font-size-normal padding text-align-right'>Date &amp; Time:</div>
                <div className='flex-table-cell display-flex flex-wrap-nowrap align-items-center padding' style={{ flexBasis: '0' }}>
                  <DatePicker
                    withPortal
                    name='startDate'
                    minDate={new Date()}
                    filterDate={isWeekday}
                    selected={this.state.startDate}
                    onChange={async (e) => {
                      await this.setState({ startDate: e, endDate: e });
                    }}
                    dateFormat='EE, MMM d'
                    customInput={<ReadonlyInput />}
                  />
                  <DatePicker
                    withPortal
                    selected={this.state.startTime}
                    name='startTime'
                    onChange={(e) => {
                      this.setState({ startTime: e });
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    minTime={minHour}
                    maxTime={maxHour}
                    // timeIntervals={this.props.props.props.business.business.booking_interval}
                    timeIntervals={'05'}
                    timeCaption='Start'
                    dateFormat='h:mm aa'
                    customInput={<TimeSelectInput />}
                  />
                  <div className='margin-left-5 margin-right-5'>to</div>
                  <DatePicker
                    withPortal
                    selected={this.state.endTime}
                    name='endTime'
                    onChange={(e) => {
                      this.setState({ endTime: e });
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    minTime={minHour}
                    maxTime={maxHour}
                    timeIntervals={'05'}
                    timeCaption='End'
                    dateFormat='h:mm aa'
                    customInput={<TimeSelectInput />}
                  />
                  <div id='EndDatePicker' style={{ display: 'none' }}>
                    <DatePicker
                      withPortal
                      name='endDate'
                      minDate={new Date()}
                      filterDate={isWeekday}
                      selected={this.state.endDate}
                      onChange={async (e) => {
                        await this.setState({ endDate: e });
                        // set start date to equal end date if end date is before start date
                        if (this.state.endDate < this.state.startDate) {
                          this.setState({ startDate: e });
                        }
                      }}
                      dateFormat='EE, MMM d'
                      customInput={<ReadonlyInput />}
                    />
                  </div>
                  <Button
                    variant='secondary'
                    id='EndDateLink'
                    onClick={() => {
                      // show endDatePicker  // hide link
                      document.getElementById('EndDateLink').style.display = 'none';
                      document.getElementById('EndDatePicker').style.display = 'block';
                      document.getElementById('EndDatePicker').style.marginLeft = '15px';
                    }}
                    style={{ marginLeft: '10px' }}
                  >
                    End Another Day?
                  </Button>
                </div>
              </div>
              {!this.state.isEvent ? (
                <>
                  <div className='flex-table-row'>
                    <div className='flex-table-cell font-size-normal margin-right-10 padding text-align-right'>Customer:</div>
                    <div className='flex-table-cell padding'>
                      {this.state.selectedCustomer.first_name ? (
                        <>
                          <div className='display-flex align-items-center'>
                            <div>
                              <Button
                                variant='secondary'
                                onClick={() => this.openModal('CUSTOMER_VIEW_PROFILE', true)}
                                style={{ padding: '15px', color: '#019fe8', border: 'solid 1px #019fe8' }}
                              >
                                <span className='text-transform-capitalize'>{this.state.selectedCustomer.first_name}</span>{' '}
                                <span className='text-transform-capitalize'>{this.state.selectedCustomer.last_name}</span>
                              </Button>{' '}
                              <Button variant='secondary' onClick={() => this.openModal('CUSTOMER_SELECT_TABLE', true)} style={{ padding: '5px' }}>
                                <MdEdit size={25} className='color-deepskyblue' />
                              </Button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <Button variant='secondary' onClick={() => this.openModal('CUSTOMER_SELECT_TABLE', true)}>
                            Select
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
              <div className='flex-table-row'>
                <div className='flex-table-cell font-size-normal padding text-align-right'>{this.state.isEvent ? 'Apply' : 'Assigned'} Staff:</div>
                <div className='flex-table-cell padding'>
                  {this.state.selectedStaff.first_name ? (
                    <div className='display-flex align-items-center'>
                      <div>{this.state.selectedStaff.first_name + ' ' + this.state.selectedStaff.last_name}</div>
                      <div>
                        <Button
                          variant='secondary'
                          onClick={() => this.openModal('STAFF_SELECT_TABLE', true)}
                          style={{ marginLeft: '10px', padding: '5px' }}
                        >
                          <MdEdit size={25} className='color-deepskyblue' />
                        </Button>
                        <Button
                          variant='secondary'
                          onClick={() => {
                            this.setStaff({});
                          }}
                          style={{ marginLeft: '10px' }}
                        >
                          X
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <Button variant='secondary' onClick={() => this.openModal('STAFF_SELECT_TABLE', true)}>
                        {this.state.isEvent ? 'All' : 'Any'}
                      </Button>
                    </>
                  )}
                </div>
              </div>

              <div className='flex-table-row'>
                <div className='flex-table-cell font-size-normal padding text-align-right'>Products &amp; Services:</div>
                <div className='flex-table-cell padding'>
                  <Button variant='secondary' onClick={() => this.openModal('PRODUCTS_SELECT_TABLE', true)}>
                    {this.state.selectedProducts.length > 0 ? <MdEdit size={25} className='color-deepskyblue' /> : 'Select'}
                  </Button>
                  {this.state.selectedProducts.map((product, i) => {
                    var price = [];
                    if (product.price.includes('.')) {
                      price = product.price.split('.');
                      if (price[0] === '') {
                        price[0] = 0;
                      } else {
                        price[0].toLocaleString();
                      }
                    } else {
                      price[0] = product.price;
                    }

                    const ProductMedia = () => {
                      const product_media_source = product.product_media_source
                        ? '/api/static/media/product/thumbnail/' + product.product_media_source
                        : '';
                      return product_media_source ? (
                        <div style={{ width: 'fit-content' }}>
                          <img alt='product_thumbnail' src={product_media_source} width='60px' className='thumbnail border-radius-5' />
                        </div>
                      ) : (
                        <div className='padding-5 border-radius-5 border' style={{ width: 'fit-content' }}>
                          <FiBox size={38} className='color-gray' />
                        </div>
                      );
                    };
                    return (
                      <div key={i}>
                        <div className='display-flex flex-direction-column padding-10 cursor-default'>
                          <div className='display-flex color-black font-size-smaller' style={{ fontFamily: 'NotoSans' }}>
                            <div className='display-flex flex-direction-row flex-wrap-nowrap'>
                              <ProductMedia />
                              <div className='display-flex justify-content-space-between align-items-center margin-left-15'>
                                <div className='display-flex justify-content-space-between padding-right-10 padding-bottom-5'>
                                  <div className='font-size-normal color-black'>{product.name} </div>
                                  {product.price ? (
                                    <div>
                                      <span>
                                        {product.time ? product.time + ' minutes |' : ''} <sup className='font-size-smaller'>$</sup>
                                        <span className='font-size-normal-large color-black'>{price[0]}</span>
                                        <sup className='font-size-smaller'>{price[1]}</sup>
                                      </span>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className='flex-table-row'>
                <div className='flex-table-cell font-size-normal padding text-align-right'>Forms:</div>
                <div className='flex-table-cell padding'>
                  <Button variant='secondary' name='forms' onClick={(e) => this.openModal('FORMS_SELECT_TABLE', true)}>
                    {this.state.selectedForms.length > 0 ? <MdEdit size={25} className='color-deepskyblue' /> : 'Select'}
                  </Button>
                  <div>
                    {this.state.selectedForms.length > 0 ? (
                      <>
                        <div className='margin-bottom-10'></div>
                        <div className='font-size-small margin-bottom-10'>
                          Upon booking, a link will be sent to this customer's email (if available) to review the following form(s):
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                    <div className='flex-table'>
                      {this.state.selectedForms.map((form, i) => {
                        return (
                          <div className='flex-table-row' key={i}>
                            <div className='flex-table-cell' style={{ verticalAlign: 'top' }}>
                              {i + 1}.{' '}
                            </div>
                            <div className='flex-table-cell'>
                              {form.name}
                              <br />
                              <span className='font-size-smallest'>Viewed/signed by customer:Not Yet</span>
                              <br />
                              <br />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex-table-row'>
                <div className='flex-table-cell font-size-normal padding text-align-right'>{this.state.isEvent ? 'Event Name:' : 'Notes:'}</div>
                <div className='flex-table-cell padding'>
                  <Form.Control
                    as='textarea'
                    rows='3'
                    type='text'
                    name='notes'
                    onChange={this.onChange}
                    value={this.state.notes}
                    style={{ padding: '5px' }}
                  />
                </div>
              </div>
            </div>

            {!this.state.isEvent ? (
              <div className='display-flex padding'>
                <div className='font-size-small'>A reminder will be sent to this customer via text/email one (1) day before the appointment.</div>
              </div>
            ) : (
              ''
            )}
            <div className='display-flex flex-direction-row margin-bottom-10 '></div>
            <Alert
              variant='warning'
              show={errors.errorAddAppointment ? true : false}
              onClose={() => this.props.clearErrors()}
              dismissible
              className='margin-top-15'
            >
              {errors.errorAddAppointment}
            </Alert>
            <div className='display-flex flex-wrap-nowrap justify-content-flex-end'>
              <Button
                onClick={(e) => {
                  this.onAddAppointment(e);
                }}
                disabled={this.state.isSubmitInProgress ? true : false}
                className='display-flex width-100'
              >
                {this.state.isEvent ? 'Set Event' : 'Book'}
              </Button>
            </div>
          </div>
        </div>
        <ModalDepot
          state={this.state}
          data={{
            selectedProducts: this.state.selectedProducts,
            selectedForms: this.state.selectedForms,
            allowAddProduct: true,
            allowAddStaff: true,
            allowAddForm: true,
          }}
          function={{
            openModal: this.openModal,
            setCustomer: this.setCustomer,
            setProducts: this.setProducts,
            setStaff: this.setStaff,
            setForms: this.setForms,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  site: state.site,
});

export default connect(mapStateToProps, { addAppointment, clearErrors, getStaff })(AppComponent);
