import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      isAutoFeedbackRequest: false,
    };
    this.onSaveSettings = this.onSaveSettings.bind(this);
  }

  onSaveSettings = () => {
    const data = {
      businessID: this.props.users.token.business_id,
      checkin_auto_feedback: this.state.isAutoFeedbackRequest,
    };
    this.onSaveCheckinSettings(data, this);
  };

  render() {
    return (
      <>
        <div>
          <div className='margin-top-15 display-flex justify-content-flex-end'>
            <Button variant='primary' onClick={() => this.onSaveSettings()} className='width-100'>
              Save
            </Button>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps)(AppComponent);
