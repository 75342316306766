import React, { Component } from 'react';
import { MdEmail, MdFeedback, MdPerson, MdPhoneAndroid, MdThumbDown, MdThumbUp } from 'react-icons/md';
import { connect } from 'react-redux';
import phoneFormatter from 'phone-formatter';
// import dateFormat from 'dateformat';
// import TimeAgo from 'react-timeago';

class AppComponent extends Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <>
        <div className='display-flex flex-direction-column align-items-centerr'>
          {/* Party: {this.props.props.state.selectedCheckin.first_name} {this.props.props.state.selectedCheckin.last_name}
          <br />
          Tel:{this.props.props.state.selectedCheckin.phone} */}
          <div className='font-weight-bold margin-bottom-5'>
            <MdPerson size={25} className='color-deepskyblue' /> {this.props.props.state.selectedCheckin.first_name}{' '}
            {this.props.props.state.selectedCheckin.last_name}
            {this.props.props.state.selectedCheckin.temperature ? <>| ({this.props.props.state.selectedCheckin.temperature}&deg;)</> : ''}
          </div>
          <div>
            <MdPhoneAndroid size={25} className='color-gray' />{' '}
            {phoneFormatter.format(this.props.props.state.selectedCheckin.phone + '', '(NNN) NNN-NNNN')}
          </div>
          {this.props.props.state.selectedCheckin.email ? (
            <div>
              <MdEmail size={25} className='color-gray' /> {this.props.props.state.selectedCheckin.email}{' '}
            </div>
          ) : (
            <></>
          )}
          <div className='border-top-gray margin-top-15 margin-bottom-15'></div>
          <div className='font-weight-bold'>
            {this.props.props.state.selectedCheckin.rating ? (
              <>
                <MdThumbUp size={25} className='color-deepskyblue' /> Great experience
              </>
            ) : (
              <>
                <MdThumbDown size={25} className='color-tomato' /> Poor experience
              </>
            )}
          </div>
          <div>
            <MdFeedback size={25} className='color-gray' /> "{this.props.props.state.selectedCheckin.feedback}"
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps)(AppComponent);
