import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { MdCheck, MdChevronRight } from 'react-icons/md';

import {
  createStripeCheckoutSession,
  getStripeSubscriptionProducts,
  getAccountSubscription,
  updateSubscription,
  addAccountSubscription,
} from '../../../redux/actions/subscriptionActions';
import { clearErrors } from './../../../redux/actions/siteActions';
import ModalDepot from './ModalDepot';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'SUBSCRIPTION_PLANS_COMPONENT',
      isSwitchPlanModalShow: false,
      isSwitchingInProgress: false,
      isSubscriptionSuccessModalShow: false,
      isSubscriptionTermsModalShow: false,
      selectedSubscriptionPlan: {},
    };
    this.openModal = this.openModal.bind(this);
    this.checkoutPlan = this.checkoutPlan.bind(this);
    this.onCreateSubscription = this.onCreateSubscription.bind(this);
  }
  componentDidMount() {
    this.props.getStripeSubscriptionProducts();
    const accountSubscriptionData = {
      accountID: this.props.users.token.account_id,
    };
    this.props.getAccountSubscription(accountSubscriptionData);
  }
  openModal(modalName, modalState, modalData) {
    switch (modalName) {
      case 'SWITCH_SUBSCRIPTION_CONFIRMATION':
        if (modalState) {
          this.setState({ isSwitchPlanModalShow: true });
        } else {
          this.setState({ isSwitchPlanModalShow: false });
          this.props.clearErrors();
        }
        break;
      case 'SUBSCRIPTION_SUCCESS':
        modalState ? this.setState({ isSubscriptionSuccessModalShow: true }) : this.setState({ isSubscriptionSuccessModalShow: false });
        break;
      case 'SUBSCRIPTION_TERMS':
        modalState ? this.setState({ isSubscriptionTermsModalShow: true }) : this.setState({ isSubscriptionTermsModalShow: false });
        break;
      default:
    }
  }
  checkoutPlan = async (e) => {
    e.preventDefault();
    const data = {
      priceID: e.target.id,
      accountID: this.props.users.token.account_id,
      email: this.props.users.token.email,
      isTrial: Object.keys(this.props.subscriptions.account_subscription).length === 0 ? true : false,
    };
    // Call your backend to create the Checkout session.
    console.log('checkoutPlan', data);
    await this.props.createStripeCheckoutSession(data, this);
  };
  onCreateSubscription = (e) => {
    // before creating a customer, we need to check to see if that customer exists yet
    // to create a subscription, we need to create a customer id
    // once we have the customer id, we create the subscription
    const current_date_time = new Date().getTime();
    const session_id = 'cs_' + this.props.users.token.business_id + '_' + current_date_time;
    const data = {
      session: {
        id: session_id,
        client_reference_id: this.props.users.token.business_id,
        customer: null,
      },
      subscription: {
        price_id: e.target.id,
      },
      userToken: this.props.users.token,
    };
    this.props.addAccountSubscription(data, this);
  };
  switchSubscription = async (e) => {
    e.preventDefault();
    this.setState({ isSwitchingInProgress: true });
    // if there is a subscription payment available, update the scription, else create new checkout
    if (this.props.subscriptions.account_subscription.payment_method_id) {
      const data = {
        priceID: e.target.id,
        accountSubscriptionID: this.props.subscriptions.account_subscription.subscription_id,
        userToken: this.props.users.token,
      };
      // Call your backend to create the Checkout session.
      await this.props.updateSubscription(data, this);
    } else {
      const data = {
        priceID: e.target.id,
        accountID: this.props.users.token.account_id,
        email: this.props.users.token.email,
      };
      // Call your backend to create the Checkout session.
      await this.props.createStripeCheckoutSession(data, this);
    }
  };
  render() {
    const { products, account_subscription } = this.props.subscriptions;

    const productCheckinAdSupport = Object.keys(products).length > 0 ? products[0] : '';
    const productCheckinNoAds = Object.keys(products).length > 0 ? products[3] : '';
    const productCheckinBooking = Object.keys(products).length > 0 ? products[2] : '';
    const productMarketing = Object.keys(products).length > 0 ? products[1] : '';

    const pricesCheckinAdSupport = productCheckinAdSupport.prices;
    const pricesCheckinNoAds = productCheckinNoAds.prices;
    const pricesCheckinBooking = productCheckinBooking.prices;
    const pricesMarketing = productMarketing.prices;

    const priceIDsCheckinAdSupport =
      pricesCheckinAdSupport &&
      pricesCheckinAdSupport.map((price) => {
        return price.id;
      }, []);
    const priceIDsCheckinNoAds =
      pricesCheckinNoAds &&
      pricesCheckinNoAds.map((price) => {
        return price.id;
      }, []);
    const priceIDsCheckinBooking =
      pricesCheckinBooking &&
      pricesCheckinBooking.map((price) => {
        return price.id;
      }, []);
    const priceIDsMarketing =
      pricesMarketing &&
      pricesMarketing.map((price) => {
        return price.id;
      }, []);

    return (
      <>
        <div>
          <div className='margin-15'>
            <center>
              <h2>
                {Object.keys(account_subscription).length > 0 && account_subscription.status !== 'canceled'
                  ? 'Need a different plan? Just switch.'
                  : Object.keys(account_subscription).length > 0 && account_subscription.status === 'canceled'
                  ? 'Get the services you need again'
                  : 'Choose a plan. Try it free for 14 days.'}
              </h2>
              <span className='color-gray'>Pricing built around services you need. Get a plan that fits your need.</span>
            </center>
          </div>
          <div className='display-flex width-100'>
            <div className='flex-grid-1x4 display-flex'>
              <div className='border'>
                <center>
                  <div className='padding'>
                    <div className='font-size-larger margin-bottom-15'>{productCheckinAdSupport ? productCheckinAdSupport.name : ''}</div>
                    <sup className='font-size-larger'>$</sup>
                    <span className='white-space-nowrap'>
                      <span className='font-size-largest color-deekskyblue'>
                        {productCheckinAdSupport ? productCheckinAdSupport.prices[0].unit_amount / 100 : ''}
                      </span>
                      <span>/mo.</span>
                    </span>
                  </div>
                  <div>
                    {Object.keys(account_subscription).length > 0 && account_subscription.status !== 'canceled' ? (
                      <div>
                        <Button
                          variant='secondary'
                          onClick={async (e) => {
                            await this.setState({ selectedSubscriptionPlan: productCheckinAdSupport });
                            this.openModal('SWITCH_SUBSCRIPTION_CONFIRMATION', true);
                          }}
                          disabled={priceIDsCheckinAdSupport && priceIDsCheckinAdSupport.includes(account_subscription.price_id) ? true : false}
                        >
                          {priceIDsCheckinAdSupport && priceIDsCheckinAdSupport.includes(account_subscription.price_id) ? 'Purchased' : 'Switch'}
                        </Button>
                      </div>
                    ) : (
                      <>
                        {Object.keys(account_subscription).length > 0 && account_subscription.status === 'canceled' ? (
                          ''
                        ) : (
                          <div className='margin-bottom-15'>14 Days Free Trial</div>
                        )}
                        <Button
                          variant='secondary'
                          id={productCheckinAdSupport ? productCheckinAdSupport.prices[0].id : ''}
                          onClick={(e) => this.checkoutPlan(e)}
                        >
                          Get Started
                        </Button>
                      </>
                    )}
                  </div>
                  <div className='margin-top-15'>
                    <a href='#/' onClick={() => this.openModal('SUBSCRIPTION_TERMS', true)} className='margin-top-15'>
                      Offer Details &amp; Terms <MdChevronRight />
                    </a>
                  </div>
                  <hr></hr>
                  {productCheckinAdSupport ? productCheckinAdSupport.name : ''} Includes All Below:
                  <hr></hr>
                  <div className='text-align-left'>
                    <div>
                      <MdCheck size={20} className='color-deepskyblue' /> Text/Chat Support
                    </div>
                    <div>
                      <MdCheck size={20} className='color-deepskyblue' /> Check-In (Ad Supported)
                    </div>
                    <div>
                      <MdCheck size={20} className='color-deepskyblue' /> Forms Management
                    </div>
                  </div>
                </center>
              </div>
              <div className='border'>
                <center>
                  <div className='padding'>
                    <div className='font-size-larger margin-bottom-15'>{productCheckinNoAds ? productCheckinNoAds.name : ''}</div>
                    <sup className='font-size-larger'>$</sup>
                    <span className='white-space-nowrap'>
                      <span className='font-size-largest'>{productCheckinNoAds ? productCheckinNoAds.prices[0].unit_amount / 100 : ''}</span>
                      <span>/mo.</span>
                    </span>
                  </div>
                  <div>
                    {Object.keys(account_subscription).length > 0 && account_subscription.status !== 'canceled' ? (
                      <div>
                        <Button
                          variant='secondary'
                          onClick={async (e) => {
                            await this.setState({ selectedSubscriptionPlan: productCheckinNoAds });
                            this.openModal('SWITCH_SUBSCRIPTION_CONFIRMATION', true);
                          }}
                          disabled={priceIDsCheckinNoAds && priceIDsCheckinNoAds.includes(account_subscription.price_id) ? true : false}
                        >
                          {priceIDsCheckinNoAds && priceIDsCheckinNoAds.includes(account_subscription.price_id) ? 'Purchased' : 'Switch'}
                        </Button>
                      </div>
                    ) : (
                      <>
                        {Object.keys(account_subscription).length > 0 && account_subscription.status === 'canceled' ? (
                          ''
                        ) : (
                          <div className='margin-bottom-15'>14 Days Free Trial</div>
                        )}
                        <Button
                          variant='secondary'
                          id={productCheckinNoAds ? productCheckinNoAds.prices[0].id : ''}
                          onClick={(e) => this.checkoutPlan(e)}
                        >
                          Get Started
                        </Button>
                      </>
                    )}
                  </div>
                  <div className='margin-top-15'>
                    <a href='#/' onClick={() => this.openModal('SUBSCRIPTION_TERMS', true)}>
                      Offer Details &amp; Terms <MdChevronRight />
                    </a>
                  </div>
                  <hr></hr>
                  {productCheckinNoAds ? productCheckinNoAds.name : ''} Includes All In {productCheckinAdSupport ? productCheckinAdSupport.name : ''},
                  And:
                  <hr></hr>
                  <div className='text-align-left'>
                    <div>
                      <MdCheck size={20} className='color-deepskyblue' /> 1,000 Text/Unlimited Email
                    </div>
                    <div>
                      <MdCheck size={20} className='color-deepskyblue' /> Check-In (No Ads)
                    </div>
                    <div>
                      <MdCheck size={20} className='color-deepskyblue' /> Business Page
                    </div>
                    <div>
                      <MdCheck size={20} className='color-deepskyblue' /> Menu Item Listing
                    </div>
                    <div>
                      <MdCheck size={20} className='color-deepskyblue' /> Customer Management
                    </div>
                    <div>
                      <MdCheck size={20} className='color-deepskyblue' /> Staff Management
                    </div>
                  </div>
                </center>
              </div>
              <div className='border'>
                <center>
                  <div className='padding'>
                    <div className='font-size-larger margin-bottom-15'>{productCheckinBooking ? productCheckinBooking.name : ''}</div>
                    <sup className='font-size-larger'>$</sup>
                    <span className='font-size-largest'>{productCheckinBooking ? productCheckinBooking.prices[0].unit_amount / 100 : ''}</span>
                    <span>/mo.</span>
                  </div>
                  <div>
                    {Object.keys(account_subscription).length > 0 && account_subscription.status !== 'canceled' ? (
                      <div>
                        <Button
                          variant='secondary'
                          onClick={async (e) => {
                            await this.setState({ selectedSubscriptionPlan: productCheckinBooking });
                            this.openModal('SWITCH_SUBSCRIPTION_CONFIRMATION', true);
                          }}
                          disabled={priceIDsCheckinBooking && priceIDsCheckinBooking.includes(account_subscription.price_id) ? true : false}
                        >
                          {priceIDsCheckinBooking && priceIDsCheckinBooking.includes(account_subscription.price_id) ? 'Purchased' : 'Switch'}
                        </Button>
                      </div>
                    ) : (
                      <>
                        {Object.keys(account_subscription).length > 0 && account_subscription.status === 'canceled' ? (
                          ''
                        ) : (
                          <div className='margin-bottom-15'>14 Days Free Trial</div>
                        )}
                        <Button id={productCheckinBooking ? productCheckinBooking.prices[0].id : ''} onClick={(e) => this.checkoutPlan(e)}>
                          Get Started
                        </Button>
                      </>
                    )}
                  </div>
                  <div className='margin-top-15'>
                    <a href='#/' onClick={() => this.openModal('SUBSCRIPTION_TERMS', true)} className='margin-top-15'>
                      Offer Details &amp; Terms <MdChevronRight />
                    </a>
                  </div>
                  <hr></hr>
                  {productCheckinBooking ? productCheckinBooking.name : ''} Includes All In {productCheckinNoAds ? productCheckinNoAds.name : ''},
                  And:
                  <hr></hr>
                  <div className='text-align-left'>
                    <div>
                      <MdCheck size={20} className='color-deepskyblue' /> 1,500 Text/Unlimited Email
                    </div>
                    <div>
                      <MdCheck size={20} className='color-deepskyblue' /> Online Booking
                    </div>
                    <div>
                      <MdCheck size={20} className='color-deepskyblue' /> Reservation Scheduling
                    </div>
                    <div>
                      <MdCheck size={20} className='color-deepskyblue' /> Reports &amp; Statistics
                    </div>
                  </div>
                </center>
              </div>
              <div className='border'>
                <center>
                  <div className='padding'>
                    <div className='font-size-larger margin-bottom-15'>{productMarketing ? productMarketing.name : ''}</div>
                    <sup className='font-size-larger'>$</sup>
                    <span className='font-size-largest'>{productMarketing ? productMarketing.prices[0].unit_amount / 100 : ''}</span>
                    <span>/mo.</span>
                  </div>
                  <div>
                    {Object.keys(account_subscription).length > 0 && account_subscription.status !== 'canceled' ? (
                      <div>
                        <Button
                          variant='secondary'
                          onClick={async (e) => {
                            await this.setState({ selectedSubscriptionPlan: productMarketing });
                            this.openModal('SWITCH_SUBSCRIPTION_CONFIRMATION', true);
                          }}
                          disabled={priceIDsMarketing && priceIDsMarketing.includes(account_subscription.price_id) ? true : false}
                        >
                          {priceIDsMarketing && priceIDsMarketing.includes(account_subscription.price_id) ? 'Purchased' : 'Switch'}
                        </Button>
                      </div>
                    ) : (
                      <>
                        {Object.keys(account_subscription).length > 0 && account_subscription.status === 'canceled' ? (
                          ''
                        ) : (
                          <div className='margin-bottom-15'>—</div>
                        )}
                        <Button
                          variant='secondary'
                          className='margin-top-15'
                          id={productMarketing ? productMarketing.prices[0].id : ''}
                          onClick={(e) => this.checkoutPlan(e)}
                        >
                          Get Started
                        </Button>
                      </>
                    )}
                  </div>
                  <div className='margin-top-15'>
                    <a href='#/' onClick={() => this.openModal('SUBSCRIPTION_TERMS', true)} className='margin-top-15'>
                      Offer Details &amp; Terms <MdChevronRight />
                    </a>
                  </div>
                  <hr></hr>
                  {productMarketing ? productMarketing.name : ''} Includes All In {productCheckinBooking ? productCheckinBooking.name : ''}, And:
                  <hr></hr>
                  <div className='text-align-left'>
                    <div>
                      <MdCheck size={20} className='color-deepskyblue' /> 5,000 Text/Unlimited Email
                    </div>
                    <div>
                      <MdCheck size={20} className='color-deepskyblue' /> Phone Support
                    </div>
                    <div>
                      <MdCheck size={20} className='color-deepskyblue' /> Check-In Feedbacks
                    </div>
                    <div>
                      <MdCheck size={20} className='color-deepskyblue' /> Customer Marketing
                    </div>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </div>
        <ModalDepot state={this.state} props={this.props} function={{ openModal: this.openModal, switchSubscription: this.switchSubscription }} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  subscriptions: state.subscriptions,
  users: state.users,
});
export default connect(mapStateToProps, {
  createStripeCheckoutSession,
  getStripeSubscriptionProducts,
  getAccountSubscription,
  updateSubscription,
  addAccountSubscription,
  clearErrors,
})(AppComponent);
