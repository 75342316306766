import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup } from 'react-bootstrap';
import { MdEdit, MdAddCircle, MdDeleteForever } from 'react-icons/md';
import ModalDepot from '../components/ModalDepot';
import { getStaff, changeBusinessKeyValue, updateBusiness, getStaffHours, deleteStaffHours } from '../../../redux/actions/businessActions';
import { clearErrors } from '../../../redux/actions/siteActions';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'STAFF_HOURS_FORM',
      selectedHour: {},
      selectedStaff: props.props.state.selectedStaff,
      isAddStaffHourModalShow: false,
      isUpdateStaffHourModalShow: false,
      isDeleteStaffHourModalShow: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  componentDidMount() {
    this.props.getStaffHours(this.state.selectedStaff.id);
  }
  openModal(modalName, modalState, modalData) {
    switch (modalName) {
      case 'STAFF_HOUR_ADD':
        modalState ? this.setState({ isAddStaffHourModalShow: true }) : this.setState({ isAddStaffHourModalShow: false });
        break;
      case 'STAFF_HOUR_UPDATE':
        if (modalState) {
          this.setState({ isUpdateStaffHourModalShow: true });
        } else {
          this.setState({ isUpdateStaffHourModalShow: false });
          this.setState({ selectedHour: {} });
        }
        break;
      case 'STAFF_HOUR_DELETE':
        modalState ? this.setState({ isDeleteStaffHourModalShow: true }) : this.setState({ isDeleteStaffHourModalShow: false });
        break;
      default:
    }
  }
  onDeleteHours = (e) => {
    e.preventDefault();
    const data = {
      hourIDArray: [this.state.selectedHour.id],
    };
    this.props.deleteStaffHours(data, this);
  };
  render() {
    const { staff_hours } = this.props.business;
    return (
      <>
        <div>
          <div className='flex-1'>
            {staff_hours.map((hour, i) => {
              return (
                <div key={i}>
                  <div className='display-flex align-items-center padding-5 '>
                    <div className='flex-1'>{hour.dayName}</div>
                    <div className='flex-1 white-space-nowrap'>
                      <center>{hour.open}</center>
                    </div>
                    <div className='flex-1 display-flex justify-content-center'>—</div>
                    <div className='flex-1 justify-content-left padding-5 white-space-nowrap'>
                      <center>{hour.closed}</center>
                    </div>
                    <div className='flex-1 display-flex justify-content-flex-end'>
                      <ButtonGroup>
                        <Button
                          variant='secondary'
                          onClick={() => {
                            this.setState({ selectedHour: hour });
                            this.openModal('STAFF_HOUR_UPDATE', true);
                          }}
                        >
                          <div className='white-space-nowrap'>
                            <MdEdit size={25} className='color-deepskyblue' />
                          </div>
                        </Button>
                        <Button
                          variant='secondary'
                          onClick={() => {
                            this.setState({ selectedHour: hour });
                            this.openModal('STAFF_HOUR_DELETE', true);
                          }}
                        >
                          <div className='white-space-nowrap'>
                            <MdDeleteForever size={25} className='color-red' />
                          </div>
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className='display-flex justify-content-space-between margin-top-15'>
              <Button variant='secondary' onClick={() => this.openModal('STAFF_HOUR_ADD', true)}>
                <MdAddCircle size={25} className='color-deepskyblue' /> Add
              </Button>
              <Button variant='secondary' onClick={() => this.props.props.function.openModal('STAFF_HOURS', false)}>
                Done
              </Button>
            </div>
          </div>
        </div>
        <ModalDepot state={this.state} function={{ openModal: this.openModal, onDeleteHours: this.onDeleteHours }} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  business: state.business,
});
export default connect(mapStateToProps, { getStaff, changeBusinessKeyValue, updateBusiness, getStaffHours, deleteStaffHours, clearErrors })(
  AppComponent
);
