import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { getProduct, getProductAlbum, deleteProductMedia, setProductMediaMain } from '../../../redux/actions/businessActions';
import ModalDepot from '../components/ModalDepot';
import { MdAddAPhoto, MdCheckCircle } from 'react-icons/md';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'PRODUCT_ALBUM_FORM',
      isUpdateProductMediaModalShow: false,
      selectedProductMediaID: '',
      selected: [],
      selectedProductID: props.props.state.selectedProduct.id,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal(modalName, modalState, modalData) {
    switch (modalName) {
      case 'PRODUCT_MEDIA_UPDATE':
        modalState ? this.setState({ isUpdateProductMediaModalShow: true }) : this.setState({ isUpdateProductMediaModalShow: false });
        break;
      default:
    }
  }
  componentDidMount() {
    this.props.getProductAlbum(this.state.selectedProductID);
    this.props.getProduct(this.state.selectedProductID, this);
  }
  render() {
    const { product_album } = this.props.business;
    return (
      <>
        <div>
          <div className='margin-bottom-15'>
            <div className='display-flex align-items-center justify-content-space-between '>
              {/* <div className='font-size-large'>{product.name}</div> */}
              {this.props.props.props.token && !this.props.props.props.liveMode ? (
                <div>
                  <Button
                    variant='secondary'
                    onClick={() => {
                      this.props.props.function.openModal('STAFF_VIEW_ALBUM', false);
                      this.props.props.function.openModal('PRODUCT_ADD_MEDIA', true);
                    }}
                  >
                    <MdAddAPhoto size={25} className='color-deepskyblue' /> Add More Images
                  </Button>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className='square-container'>
            {product_album.map((media, i) => {
              return (
                <div className='square-small' key={i}>
                  <div>
                    <Button
                      style={{ all: 'unset', cursor: 'pointer' }}
                      onClick={() => {
                        this.setState({ selectedProductMediaID: media.id });
                        // this.openModal('PRODUCT_MEDIA_UPDATE', true);
                      }}
                    >
                      <img src={media.url_thumbnail} alt={'product_image'} className='content border-radius-5' />
                    </Button>
                    {media.is_main ? (
                      <div className='position-absolute' style={{ bottom: '0', left: '0' }}>
                        <MdCheckCircle size={45} className='color-white' />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <div className='display-flex align-items-start justify-content-center'>
          <div className='display-flex' style={{ width: '1080px' }}>
            {product_album.map((media, i) => {
              const product_album_source = media.source ? '/api/static/media/product/thumbnail/' + media.source : '';
              return (
                <div key={i} style={{ margin: '5px' }}>
                  <div className='position-relative'>
                    <img src={product_album_source} className='border-radius-5 border' />
                    <Button
                      variant='secondary'
                      className='position-absolute'
                      onClick={() => {
                        this.setState({ selectedProductMediaID: media.id });
                        this.openModal('PRODUCT_MEDIA_DELETE', true);
                      }}
                      style={{ bottom: '0', left: '0' }}
                    >
                      Delete
                    </Button>
                    {!media.is_main ? (
                      <Button
                        variant='secondary'
                        className='position-absolute'
                        onClick={async (e) => {
                          await this.setState({ selectedProductMediaID: media.id });
                          this.onSetProductMediaMain(e);
                        }}
                        style={{ bottom: '0', right: '0' }}
                      >
                        Set as Main
                      </Button>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div> */}
        <ModalDepot state={this.state} props={this.props} function={{ openModal: this.openModal }} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  business: state.business,
});
export default connect(mapStateToProps, { getProduct, getProductAlbum, deleteProductMedia, setProductMediaMain })(AppComponent);
