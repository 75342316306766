import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCheckIns, authenticateCheckinApp, getCheckinAppMedia } from '../../redux/actions/businessActions';
import CheckinAppForm from './forms/CheckinAppForm';
import ModalDepot from './components/ModalDepot';
// import { getPublicEnv } from '../../redux/actions/siteActions';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'CHECKINAPP',
      isSplashModalShow: false,
      isCheckinAppAuthenticated: false,
    };
    this.openModal = this.openModal.bind(this);
    this.onAuthenticateCheckinApp = this.onAuthenticateCheckinApp.bind(this);
    this.onGetCheckinAppMedia = this.onGetCheckinAppMedia.bind(this);
  }
  componentDidMount() {
    if (localStorage.getItem('localStorageCheckinAppPrivateToken')) {
      const privateToken = localStorage.getItem('localStorageCheckinAppPrivateToken');
      this.props.authenticateCheckinApp(privateToken, this);
    }
  }
  onAuthenticateCheckinApp(isAuthenticated) {
    this.setState({ isCheckinAppAuthenticated: isAuthenticated });
  }
  openModal(modalName, modalState, modalData) {
    switch (modalName) {
      case 'SPLASH_SCREEN':
        modalState ? this.setState({ isSplashModalShow: true }) : this.setState({ isSplashModalShow: false });
        break;
      default:
    }
  }
  onGetCheckinAppMedia(mediaID) {
    this.props.getCheckinAppMedia(mediaID, this);
  }
  render() {
    return (
      this.state.isCheckinAppAuthenticated && (
        <>
          <div className='display-flex flex-direction-column align-items-flex-start justify-content-center'>
            <CheckinAppForm state={this.state} props={this.props} />
          </div>
          <ModalDepot
            state={this.state}
            props={this.props}
            function={{
              openModal: this.openModal,
              onAuthenticateCheckinApp: this.onAuthenticateCheckinApp,
              onGetCheckinAppMedia: this.onGetCheckinAppMedia,
            }}
          />
        </>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  business: state.business,
  users: state.users,
});

export default connect(mapStateToProps, { getCheckIns, authenticateCheckinApp, getCheckinAppMedia })(AppComponent);
