import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getBusinessPolicy } from './../../../redux/actions/businessActions';

class AppComponent extends Component {
  constructor(props) {
    super();
  }
  componentDidMount() {
    this.props.getBusinessPolicy(this.props.props.state.selectedBusinessPolicyID);
  }
  render() {
    const { business_policy } = this.props.business;
    return (
      <>
        <div>
          <center>
            <div className='font-weight-bold margin-bottom-15'>{business_policy.name}</div>
          </center>
          <div className='react-quill' dangerouslySetInnerHTML={{ __html: business_policy.form_content ? business_policy.form_content : '' }} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  business: state.business,
});
export default connect(mapStateToProps, { getBusinessPolicy })(AppComponent);
