import React, { Component } from 'react';
import { connect } from 'react-redux';
import dateFormat from 'dateformat';
import { MdChevronLeft } from 'react-icons/md';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      currentTime: dateFormat(new Date(), 'h:MM TT'),
    };
    this.setTime = this.setTime.bind(this);
  }
  componentDidMount = () => {
    this.currentTimeTicker = setInterval(this.setTime, 1000);
  };
  componentWillUnmount = () => {
    clearInterval(this.currentTimeTicker);
  };
  setTime = () => {
    this.setState({ currentTime: dateFormat(new Date(), 'h:MM TT') });
  };

  render() {
    return (
      <>
        <div className='display-flex checkin-waitlist-monitor'>
          <div className='flex-table width-100'>
            <div className='flex-table-row checkin-waitlist-monitor-table'>
              <div className='flex-table-cell padding-25'>
                <MdChevronLeft onClick={() => this.props.props.function.openModal('CHECKIN_WAITLIST_MONITOR', false)} />
              </div>
              <div className='flex-table-cell padding-25 border-radius-10 margin-left-15 margin-right-15 white-space-nowrap text-align-center'>
                Live Waitlist
              </div>
              <div className='flex-table-cell padding-25'>
                <div className='white-space-nowrap text-align-right'>{this.state.currentTime}</div>
              </div>
            </div>
            <div className='flex-table-row bg-deepskyblue color-white'>
              <div className='flex-table-cell padding'>Party</div>
              <div className='flex-table-cell padding text-align-center'>Status</div>
              <div className='flex-table-cell padding'></div>
            </div>
            {this.props.props.props.business.checkins.length > 0 ? (
              this.props.props.props.business.checkins
                .sort((a, b) => (a.created_on > b.created_on ? 1 : -1))
                .sort((a, b) => (a.status === 'checked-in' ? 1 : -1))
                .map((checkin, i) => {
                  return checkin.status === 'ready' || checkin.status === 'checked-in' ? (
                    <div key={i} className='flex-table-row'>
                      <div className='flex-table-cell padding-25'>
                        {checkin.first_name} {checkin.last_name.charAt(0)}
                      </div>
                      <div className='flex-table-cell padding-25 text-align-center'>
                        {checkin.status === 'ready' ? (
                          <div className='bg-darkorange padding-25 border-radius-10 color-white text-transform-capitalize'>{checkin.status}</div>
                        ) : checkin.status === 'checked-in' ? (
                          <>In line</>
                        ) : (
                          <div>{checkin.status}</div>
                        )}
                      </div>
                      <div className='flex-table-cell padding'>
                        {checkin.status === 'ready' ? <div className='display-flex bg-sandybrown padding border-radius-10'>See Host</div> : ''}
                      </div>
                    </div>
                  ) : (
                    <div className='flex-table-row' key={i}>
                      <div className='flex-table-cell'></div>
                      <div className='flex-table-cell'></div>
                      <div className='flex-table-cell'></div>
                    </div>
                  );
                })
            ) : (
              <div className='flex-table-row'>
                <div className='flex-table-cell'></div>
                <div className='flex-table-cell padding'>
                  <center>Awaiting Check-In</center>
                </div>
                <div className='flex-table-cell'></div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps)(AppComponent);
