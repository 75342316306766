import React, { Component } from 'react';
import { connect } from 'react-redux';
// import dateFormat from 'dateformat';
import { Button } from 'react-bootstrap';
import { QRCode } from 'react-qrcode-logo';
// import TimeAgo from 'react-timeago';

import { getCheckIns } from './../../../redux/actions/businessActions';
import { getPublicEnv } from './../../../redux/actions/siteActions';
// import logo from './../../../assets/images/logo.png';

class AppComponent extends Component {
  componentDidMount() {
    this.props.getPublicEnv();
  }
  render() {
    // const { checkins } = this.props.business;
    // const Waitlist = () => {
    //   return checkins.length > 0 ? (
    //     <>
    //       <div className='margin-15 font-size-medium font-weight-bold'>
    //         <center>Parties in line: {checkins.length}</center>
    //       </div>
    //       <div>
    //         <div className='display-flex justify-content-center border border-radius-5 padding bg-white'>
    //           <table className='table-waitlist'>
    //             <thead>
    //               <tr>
    //                 <td className='font-weight-bold'>Name</td>
    //                 <td className='font-weight-bold text-align-right'>{this.props.business.checkinapp.party_enabled ? 'Party Size' : 'Date Time'}</td>
    //               </tr>
    //             </thead>
    //             <tbody>
    //               {checkins.map((checkin, i) => {
    //                 return (
    //                   <tr key={i}>
    //                     <td>
    //                       <div className='display-flex justify-content-space-between'>
    //                         <div>
    //                           {checkin.first_name} {checkin.last_name.charAt(0)}.
    //                         </div>{' '}
    //                       </div>
    //                     </td>
    //                     <td className='text-align-right'>
    //                       {this.props.business.checkinapp.party_enabled ? (
    //                         checkin.party_size
    //                       ) : (
    //                         <>
    //                           <div>
    //                             {dateFormat(checkin.created_on, 'h:MM TT')} (<TimeAgo date={checkin.created_on} />)
    //                           </div>
    //                         </>
    //                       )}
    //                     </td>
    //                   </tr>
    //                 );
    //               })}
    //             </tbody>
    //           </table>
    //         </div>
    //       </div>
    //     </>
    //   ) : (
    //     <>
    //       <div></div>
    //     </>
    //   );
    // };
    return (
      <>
        <div
          className='display-flex flex-direction-column justify-content-center align-items-center'
          onClick={() => this.props.props.function.openModal('SPLASH_SCREEN', false)}
        >
          <div className='flex-1'></div>
          <div className='flex-1 display- flex-direction-column justify-content-center'>
            <div className='display-flex justify-content-center color-white' style={{ fontSize: '2.5em' }}>
              {this.props.business.checkinapp.business ? this.props.business.checkinapp.business.business_name : ''}
            </div>
            <div className='padding color-white text-align-center'>
              <span style={{ fontSize: '4.5em' }}>
                Check In
                {/* &amp; <span className='white-space-nowrap'>Waitlist</span> */}
              </span>
            </div>
            <div className='display-flex flex-direction-column align-items-center'>
              <center>
                <Button
                  variant='secondary'
                  onClick={() => this.props.props.function.openModal('SPLASH_SCREEN', false)}
                  style={{ padding: '15px 50px', fontSize: '1.5em' }}
                >
                  Tap Anywhere to Start
                </Button>
                <div>
                  <div className='margin-top-15 mobile-hidden color-white'>
                    <>
                      <div className='margin-top-15 margin-bottom-15'>____________________ OR ____________________</div>
                      <div className='margin-top-15 margin-bottom-15 font-size-medium'>Scan QR Code</div>
                      <div className='width-auto padding-5 border-radius-5 border' style={{ backgroundColor: '#fff', display: 'inline-block' }}>
                        {/* <QRCode
                          value={env.clientUrl + '/checkinapp/mobile/redirect/' + this.props.props.props.business.checkinapp.public_token}
                          size={175}
                          bgColor='#000'
                          fgColor='#fff'
                          level='H'
                        /> */}

                        <QRCode
                          value={this.props.business.checkinapp.url_mobile}
                          qrStyle='dots'
                          size={175}
                          eyeRadius={[
                            [10, 10, 0, 10],
                            [10, 10, 10, 0], // top/right eye
                            [10, 0, 10, 10], // bottom/left
                          ]}
                        />
                      </div>
                    </>
                  </div>
                </div>
              </center>
            </div>
          </div>
          <div className='flex-1'></div>
          <div className='flex-1 display-flex flex-direction-column justify-content-center align-items-flex-end margin-top-15whitesm'>
            <div className='display-flex flex-direction-column justify-content-center align-items-center'>
              <div>Powered By</div>
              <div className='font-size-large color-white'>One Space Link</div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  business: state.business,
  site: state.site,
});
export default connect(mapStateToProps, { getCheckIns, getPublicEnv })(AppComponent);
