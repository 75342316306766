import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
// need for token checks

import BusinessSearch from './routes/pages/BusinessSearch';
import DashboardLayout from './layouts/DashboardLayout';
import Home from './routes/pages/Home';
import Login from './routes/pages/Login';
import LoginStarted from './routes/pages/LoginStarted';
import PasswordForgot from './routes/pages/PasswordForgot';
import Signup from './routes/pages/Signup';
import SignupStarted from './routes/pages/SignupStarted';
import SignupWelcome from './routes/pages/SignupWelcome';
import VerifySignupToken from './routes/pages/VerifySignupToken';
import PasswordReset from './routes/pages/PasswordReset';
import CheckinApp from './routes/pages/CheckinApp';
import Terms from './routes/pages/Terms';
import Policy from './routes/pages/Policy';
import Page404 from './routes/pages/404';
import BusinessPage from './routes/pages/BusinessPage';
import AppointmentForm from './routes/pages/AppointmentForms';
import CheckinAppMobileRedirect from './routes/pages/CheckinAppMobileRedirect';
import CheckinAppQRCodePrint from './routes/pages/CheckinAppQRCodePrint';
import PageQRCodePrint from './routes/pages/PageQRCodePrint';
import CheckinAppMobile from './routes/pages/CheckinAppMobile';
import CheckinOverview from './routes/pages/CheckinOverview';

class App extends Component {
  render() {
    return (
      <div className='display-flex width-100 height-100'>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/page/:vanity' component={BusinessPage} />
          <Route exact path='/checkinapp' component={CheckinApp} />
          <Route exact path='/checkinapp/mobile/redirect/:public_token' component={CheckinAppMobileRedirect} />
          <Route exact path='/checkinapp/mobile' component={CheckinAppMobile} />
          <Route exact path='/ck/:public_id' component={CheckinOverview} />
          <Route exact path='/checkinQRcodePrint' component={CheckinAppQRCodePrint} />
          <Route exact path='/pageQRcodePrint' component={PageQRCodePrint} />
          <Route exact path='/search' component={BusinessSearch} />
          <Route exact path='/appointment/:id/:uuid' component={AppointmentForm} />
          <Route exact path='/legal/terms' component={Terms} />
          <Route exact path='/policy' component={Policy} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/started/login' component={LoginStarted} />
          <Route exact path='/signup' component={Signup} />
          <Route exact path='/started/signup' component={SignupStarted} />
          <Route exact path='/verification/signup/:token' component={VerifySignupToken} />
          <Route exact path='/password_reset/:token' component={PasswordReset} />
          <Route exact path='/welcome/:tokenType/:token' component={SignupWelcome} />
          <Route exact path='/forgot_password' component={PasswordForgot} />
          <Route exact path='/account/*' component={DashboardLayout} />
          <Route path='*' component={Page404} />
          <Redirect to='/' /> {/*all other paths lead to login */}
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);
