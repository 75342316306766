import React, { Component } from 'react';
import { Form, Alert, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { addStaff } from '../../../redux/actions/businessActions';
import { clearErrors } from '../../../redux/actions/siteActions';
// import { CirclePicker } from 'react-color';
import { MdAddCircle } from 'react-icons/md';

class AppComponent extends Component {
  constructor() {
    super();
    this.state = {
      roleID: 4,
      firstName: '',
      lastName: '',
      color: '#019fe8',
      phone: '',
      email: '',
      isSubmitInProgress: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    if (this.props.firstName) {
      this.setState({ firstName: this.props.firstName });
    }
  }
  async onChange(e) {
    if (e.hex) {
      await this.setState({ color: e.hex });
    } else {
      await this.setState({ [e.target.name]: e.target.value });
    }
  }
  onSubmit(e) {
    e.preventDefault();
    const data = {
      roleID: this.state.roleID,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      color: this.state.color,
      phone: this.state.phone,
      email: this.state.email,
      businessID: this.props.users.token.business_id,
    };
    this.props.addStaff(data, this);
  }
  render() {
    const { errors } = this.props.site;
    return (
      <div>
        <Form noValidate onSubmit={(e) => this.onSubmit(e)}>
          <div className='justify-contents-space-between'>
            {/* 
            <div className='margin-bottom-5'>Title</div>
            <Form.Control as='select' name='roleID' onChange={this.onChange} value={this.state.roleID} className='margin-bottom-15 width-100'>
              <option value='4'>Employee - Contractor</option>
              <option value='3'>Manager</option>
              <option value='2'>Admininstrator</option>
            </Form.Control> */}
            <div className='margin-bottom-5'>First Name</div>
            <Form.Control
              type='text'
              name='firstName'
              placeholder=''
              value={this.state.firstName}
              onChange={this.onChange}
              className='margin-bottom-15 width-100'
            />
            <div className='margin-bottom-5'>Last Name</div>
            <Form.Control
              type='text'
              name='lastName'
              placeholder=''
              value={this.state.lastName}
              onChange={this.onChange}
              className='margin-bottom-15'
            />
            <div className='margin-bottom-5'>Phone</div>
            <Form.Control
              type='number'
              name='phone'
              placeholder=''
              onChange={this.onChange}
              value={this.state.phone}
              className='margin-bottom-15'
            />{' '}
            <div className='margin-bottom-5'>Email</div>
            <Form.Control
              type='text'
              name='email'
              placeholder=''
              onChange={this.onChange}
              value={this.state.email}
              className='margin-bottom-15'
            />{' '}
            {/* <div className='margin-bottom-10'>Color Code</div>
            <div className='margin-bottom-15'>
              <CirclePicker color={this.state.color} onChange={this.onChange} />
            </div> */}
            <Alert variant='warning' show={errors.errorAddStaff ? true : false} onClose={() => this.props.clearErrors()} dismissible>
              {errors.errorAddStaff}
            </Alert>
            <div className='display-flex'>
              <Button variant='secondary' type='submit' disabled={this.state.isSubmitInProgress ? true : false} className='width-100'>
                <MdAddCircle size={25} className='color-deepskyblue' /> Add Staff
              </Button>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  site: state.site,
});

export default connect(mapStateToProps, { addStaff, clearErrors })(AppComponent);
