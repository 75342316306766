import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, ButtonGroup } from 'react-bootstrap';
import dateFormat from 'dateformat';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { FiBox } from 'react-icons/fi';
import TextField from '@material-ui/core/TextField';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

import { verifyPerson, getCheckinApp, getCheckIns, addCheckIn, getCustomer, getCheckinAppMedia } from '../../../redux/actions/businessActions';
import { clearErrors } from '../../../redux/actions/siteActions';
import ModalDepot from '../components/ModalDepot';

import staff_placeholder from './../../../assets/images/staff_placeholder.png';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'CHECKINAPP_FORM',
      firstName: '',
      dialCode: 1,
      phone: '',
      temperature: '',
      partySize: 1,
      notes: '',
      selectedStaff: {},
      selectedProducts: [],
      selectedProductIDsArray: [],
      verifiedPersons: [],
      businessID: this.props ? this.props.business.checkinapp.business_id : '',
      isCheckinAppGreetingModalShow: false,
      isAddPersonModalShow: false,
      isSelectProductsModalShow: false,
      isSelectStaffModalShow: false,
      isAgreementModalShow: false,
      isTermsModalShow: false,
      isPolicyModalShow: false,
      isPersonSelectModalShow: false,
      allowAddProduct: false,
    };
    this.baseState = this.state; // preserve the initial state in a new object
    this.resetState = this.resetState.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onVerifyPerson = this.onVerifyPerson.bind(this);
    this.onAgree = this.onAgree.bind(this);
    this.setProducts = this.setProducts.bind(this);
    this.setStaff = this.setStaff.bind(this);
    this.onAddCheckIn = this.onAddCheckIn.bind(this);
    this.onViewVerifiedPersons = this.onViewVerifiedPersons.bind(this);
  }
  componentDidMount() {
    if (this.props.business.checkinapp.media_id) {
      this.props.getCheckinAppMedia(this.props.business.checkinapp.media_id, this);
    }
  }
  setProducts(products) {
    this.onChange(products, 'selectedProducts');
    var selectedProductIDs = [];
    products.forEach((product) => {
      selectedProductIDs.push(product.id);
    });
    this.setState({ selectedProductIDsArray: selectedProductIDs });
  }
  setStaff = (staff) => {
    this.onChange(staff, 'selectedStaff');
  };
  async onChange(e, targetName) {
    if (targetName) {
      await this.setState({ [targetName]: e });
    } else {
      await this.setState({ [e.target.name]: e.target.value.trim() });
    }
  }
  openModal = (modalName, modalState, modalData) => {
    switch (modalName) {
      case 'PERSON_ADD':
        modalState ? this.setState({ isAddPersonModalShow: true }) : this.setState({ isAddPersonModalShow: false });
        break;
      case 'PRODUCTS_SELECT_TABLE':
        modalState ? this.setState({ isSelectProductsModalShow: true }) : this.setState({ isSelectProductsModalShow: false });
        break;
      case 'STAFF_SELECT_TABLE':
        modalState ? this.setState({ isSelectStaffModalShow: true }) : this.setState({ isSelectStaffModalShow: false });
        break;
      case 'CHECKINAPP_GREETING':
        if (modalState) {
          this.setState({ isCheckinAppGreetingModalShow: true });
        } else {
          this.setState({ isCheckinAppGreetingModalShow: false });
          window.location.reload();
        }
        break;
      case 'AGREEMENT_FORM':
        modalState ? this.setState({ isAgreementModalShow: true }) : this.setState({ isAgreementModalShow: false });
        break;
      case 'TERMS_COMPONENT':
        modalState ? this.setState({ isTermsModalShow: true }) : this.setState({ isTermsModalShow: false });
        break;
      case 'POLICY_COMPONENT':
        modalState ? this.setState({ isPolicyModalShow: true }) : this.setState({ isPolicyModalShow: false });
        break;
      case 'PERSON_SELECT_TABLE':
        modalState ? this.setState({ isPersonSelectModalShow: true }) : this.setState({ isPersonSelectModalShow: false });
        break;
      default:
    }
  };
  onVerifyPerson = (e) => {
    const data = {
      // firstName: this.state.firstName,
      businessID: this.props.business.checkinapp.business_id,
      dialCode: this.state.dialCode,
      phone: this.state.phone.slice(this.state.dialCode.length),
    };
    this.props.verifyPerson(data, this);
  };
  onAgree(props) {
    this.onAddCheckIn(props);
  }
  onAddCheckIn(props) {
    var newProps =
      props.state.componentID === 'CHECKINAPP_FORM'
        ? props
        : props.state.componentID === 'PERSON_SELECT_TABLE' || props.state.componentID === 'PERSON_ADD_FORM'
        ? props.props.props
        : props.props;

    let businessID = newProps.props.business.checkinapp.business_id;
    let customerID = newProps.props.business.customer.id;
    let formID = newProps.props.business.form.id;
    let temperature = newProps.state.temperature;
    let productIDArray = [];
    let staffID = null;
    let partySize = 1;
    let notes = '';

    var checkinDateTime = new Date(new Date().setHours(new Date().getHours(), new Date().getMinutes(), new Date().getSeconds()));
    const checkinDateTimeUTC = dateFormat(checkinDateTime, "yyyy-mm-dd'T'HH:MM:ss");

    // if (props.props.state.componentID === 'PERSON_ADD_FORM') {
    //   temperature = props.props.props.state.temperature;
    //   productIDArray = props.props.props.state.selectedProducts.map((product) => {
    //     return product.id;
    //   });
    //   staffID = props.props.props.state.selectedStaff.id;
    //   partySize = props.props.props.state.partySize;
    //   notes = props.props.props.state.notes;
    // } else {
    productIDArray = newProps.state.selectedProducts.map((product) => {
      return product.id;
    });
    staffID = newProps.state.selectedStaff.id;
    partySize = newProps.state.partySize;
    notes = newProps.state.notes;
    // }

    const data = {
      businessID,
      customerID,
      formID,
      temperature,
      productIDArray,
      staffID,
      partySize,
      notes,
      checkinDateTime: checkinDateTimeUTC,
    };
    this.props.addCheckIn(data, this);
  }
  onViewVerifiedPersons = async (props) => {
    await this.setState({ verifiedPersons: props.persons, businessID: props.businessID });
    this.openModal('PERSON_SELECT_TABLE', true);
  };
  resetState = () => {
    this.setState(this.baseState);
  };
  render() {
    const { checkinapp } = this.props.business;
    const { errors } = this.props.site;
    return (
      <>
        {this.props.state && this.props.state.componentID !== 'CHECKINAPP_PREVIEW' ? (
          <div className='display-flex justify-content-space-between align-items-center border-bottom bg-white padding-5'>
            <MdChevronLeft size={30} onClick={() => window.location.reload()} />
            <div>
              <center>One Space Link</center>
            </div>
            <span>
              <MdChevronRight size={30} onClick={() => window.location.reload()} />
            </span>
          </div>
        ) : (
          ''
        )}
        <div className='display-flex justify-content-center align-items-flex-start flex-1'>
          <div className='display-flex justify-content-center align-items-center flex-direction-column'>
            <div className='display-flex align-items-center justify-content-center width-max-640 margin-top-15'>
              <div className='margin-bottom-15'>
                {checkinapp.media && checkinapp.media_id && checkinapp.media.preview ? (
                  <div className='padding-50 display-flex justify-content-center' style={{ paddingBottom: '0px' }}>
                    <img
                      alt={checkinapp.media.media_name}
                      src={checkinapp.media.preview}
                      className='border-radius-5 margin-bottom-15'
                      style={{ maxHeight: '150px', width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                  </div>
                ) : (
                  <>
                    <div
                      className='margin-bottom-15 text-align-center'
                      style={{
                        fontFamily: checkinapp.font_family ? checkinapp.font_family : '',
                        fontSize: checkinapp.font_size ? checkinapp.font_size : 35,
                      }}
                    >
                      <div>{checkinapp.business ? checkinapp.business.business_name : ''}</div>
                    </div>
                    {/* {Object.keys(checkinapp).length > 0 && checkinapp.full_address ? (
                      <div className='font-size-smaller text-align-center'>{checkinapp.full_address}</div>
                    ) : (
                      ''
                    )} */}
                  </>
                )}
                {Object.keys(checkinapp).length > 0 && checkinapp.header_text ? (
                  <div className='font-size-normal text-align-center'>{checkinapp.header_text}</div>
                ) : (
                  ''
                )}
              </div>
              {checkinapp.party_enabled ? (
                <>
                  <div className='display-flex flex-direction-column align-items-center margin-bottom-15 margin-top-15'>
                    <div className='margin-bottom-15 font-size-medium-large text-align-center'>Select your party size</div>
                    <div>
                      <ButtonGroup>
                        <Button
                          variant='secondary'
                          onClick={(e) => {
                            this.setState({ partySize: 1 });
                          }}
                          style={{ fontSize: '1.5em', padding: '10px 25px' }}
                          active={this.state.partySize === 1 ? true : false}
                        >
                          1
                        </Button>
                        <Button
                          variant='secondary'
                          onClick={(e) => {
                            this.setState({ partySize: 2 });
                          }}
                          style={{ fontSize: '1.5em', padding: '10px 25px' }}
                          active={this.state.partySize === 2 ? true : false}
                        >
                          2
                        </Button>
                        <Button
                          variant='secondary'
                          onClick={(e) => {
                            this.setState({ partySize: 3 });
                          }}
                          style={{ fontSize: '1.5em', padding: '10px 25px' }}
                          active={this.state.partySize === 3 ? true : false}
                        >
                          3
                        </Button>
                        <Button
                          variant='secondary'
                          onClick={(e) => {
                            this.setState({ partySize: 4 });
                          }}
                          style={{ fontSize: '1.5em', padding: '10px 25px' }}
                          active={this.state.partySize === 4 ? true : false}
                        >
                          4
                        </Button>
                        <Button
                          variant='secondary'
                          onClick={(e) => {
                            this.setState({ partySize: 5 });
                          }}
                          style={{ fontSize: '1.5em', padding: '10px 25px' }}
                          active={this.state.partySize === 5 ? true : false}
                        >
                          5+
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
              <div className='display-flex align-items-center justify-content-center padding'>
                <div className='font-size-medium-large text-align-center margin-bottom-15'>Enter Your Phone Number</div>
                <PhoneInput
                  country={checkinapp.country_iso ? checkinapp.country_iso : 'us'}
                  value={this.state.phone}
                  onChange={(value, data, event, formattedValue) => {
                    this.setState({ phone: value, dialCode: data.dialCode });
                  }}
                  countryCodeEditable={false}
                  disableDropdown={true}
                />
                {checkinapp.temp_enabled ? (
                  <>
                    <TextField
                      label='Temperature'
                      type='number'
                      name='temperature'
                      margin='normal'
                      variant='outlined'
                      onChange={this.onChange}
                      value={this.state.temperature}
                      inputProps={{ style: { fontSize: 17 } }} // font size of input text
                      InputLabelProps={{ style: { fontSize: 17 } }} // font size of input label
                      className='display-flex'
                    />
                  </>
                ) : (
                  ''
                )}
                {checkinapp.notes_enabled ? (
                  <>
                    <TextField
                      label='Notes or Requests'
                      multiline
                      rows='1'
                      name='notesEnabled'
                      margin='normal'
                      variant='outlined'
                      value={this.state.notes}
                      onChange={(e) => {
                        this.setState({ notes: e.target.value });
                      }}
                      inputProps={{ style: { fontSize: 17 } }} // font size of input text
                      InputLabelProps={{ style: { fontSize: 17 } }} // font size of input label
                      className='display-flex bg-white'
                    />
                  </>
                ) : (
                  ''
                )}

                <div className='display-flex flex-direction-column margin-bottom-15'>
                  {this.state.selectedProducts.length > 0 ? (
                    <>
                      <div className='hr-text color-gray'>Selected Products &amp; Services</div>
                      {this.state.selectedProducts.map((product, i) => {
                        // const price = product.price.split('.');
                        var price = [];
                        if (product.price.includes('.')) {
                          price = product.price.split('.');
                          if (price[0] === '') {
                            price[0] = 0;
                          } else {
                            price[0].toLocaleString();
                          }
                        } else {
                          price[0] = product.price;
                        }
                        const ProductMedia = () => {
                          const product_media_source = product.product_media_source ? product.product_media_thumbnail : '';
                          return product_media_source ? (
                            <div style={{ width: 'fit-content' }}>
                              <img alt='product_thumbnail' src={product_media_source} width='60px' className='thumbnail border-radius-5' />
                            </div>
                          ) : (
                            <div className='padding-5 border-radius-5' style={{ width: 'fit-content' }}>
                              <FiBox size={38} className='color-white' />
                            </div>
                          );
                        };
                        return (
                          <div key={i}>
                            <div className='display-flex align-items-center flex-direction-column padding-10 padding-left-0 padding-right-0'>
                              <div className='display-flex color-black font-size-small'>
                                <div className='display-flex justify-content-center align-items-center'>
                                  <div className='display-flex-inline margin-right-10'>
                                    <ProductMedia />
                                  </div>
                                  <div className='flex-grow-1 margin-right-10'>
                                    <div className='display-flex justify-content-space-between padding-bottom-5'>
                                      <div className='color-black'>{product.name}</div>
                                      {product.price ? (
                                        <div>
                                          <span>
                                            {product.time ? product.time + ' minutes |' : ''} <sup className='font-size-smaller'>$</sup>
                                            <span className='font-size-medium-large color-black'>{price[0]}</span>
                                            <sup className='font-size-smaller'>{price[1]}</sup>
                                          </span>
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>
                                  <div className='display-flex-inline'>
                                    <Button
                                      variant='secondary'
                                      onClick={() =>
                                        this.setState({
                                          selectedProductIDsArray: this.state.selectedProductIDsArray.filter((id) => id !== product.id),
                                          selectedProducts: this.state.selectedProducts.filter((p) => p.id !== product.id),
                                        })
                                      }
                                    >
                                      <span className='color-gray'>x</span>
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    ''
                  )}
                  {this.state.selectedStaff.first_name ? (
                    <div>
                      <div className='hr-text color-gray'>Selected Staff</div>
                      <div className='display-flex justify-content-space-between'>
                        <div>
                          <img
                            alt='product_thumbnail'
                            src={this.state.selectedStaff.staff_media_source ? this.state.selectedStaff.staff_media_thumbnail : staff_placeholder}
                            className='thumbnail border-radius-25 border margin-right-15'
                          />
                          {this.state.selectedStaff.first_name + ' ' + this.state.selectedStaff.last_name}
                        </div>
                        <Button variant='secondary' onClick={() => this.setStaff({})}>
                          <span className='color-gray'>x</span>
                        </Button>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>

                <div className='display-flex'>
                  <ButtonGroup className='display-flex'>
                    {checkinapp.service_enabled ? (
                      <Button
                        variant='secondary'
                        onClick={() => this.openModal('PRODUCTS_SELECT_TABLE', true)}
                        style={{ padding: '15px', fontSize: '16px' }}
                      >
                        {/* <MdShoppingBasket size={45} className='color-deepskyblue' />
                        <br /> */}
                        Select Products &amp; Services
                      </Button>
                    ) : (
                      ''
                    )}
                    {checkinapp.staff_enabled ? (
                      <Button
                        variant='secondary'
                        onClick={() => this.openModal('STAFF_SELECT_TABLE', true)}
                        style={{ padding: '15px', fontSize: '16px' }}
                      >
                        Select Staff
                      </Button>
                    ) : (
                      ''
                    )}
                  </ButtonGroup>
                </div>
                <Alert
                  variant='warning'
                  show={errors.errorVerifyPerson ? true : false}
                  onClose={() => this.props.clearErrors()}
                  dismissible
                  style={{ padding: '10px', fontSize: '20px', marginTop: '15px' }}
                  className='display-flex'
                >
                  {errors.errorVerifyPerson}
                </Alert>
                <div className='display-flex margin-top-15'>
                  <Button
                    variant='primary'
                    onClick={(e) => {
                      this.props.state
                        ? this.props.state.componentID === 'CHECKINAPP_PREVIEW'
                          ? alert(`You are in preview mode. To start checking in on your device, click the Launch App button.`)
                          : this.onVerifyPerson(e)
                        : this.onVerifyPerson(e);
                    }}
                    style={{ padding: '20px', fontSize: '2em', width: '100%' }}
                  >
                    CHECK IN
                  </Button>
                </div>
                <div className='display-flex justify-content-center margin-15'>
                  By checking in, you agree to One Space Link's{' '}
                  <a href='#!' onClick={() => this.openModal('TERMS_COMPONENT', true)} className='margin-left-5 margin-right-5'>
                    Terms of Services
                  </a>{' '}
                  and{' '}
                  <span className='whitespace-nowrap'>
                    <a href='#!' onClick={() => this.openModal('POLICY_COMPONENT', true)} className='margin-left-5 margin-right-5'>
                      Privacy Policy
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalDepot
          state={this.state}
          props={this.props}
          function={{
            openModal: this.openModal,
            onChange: this.onChange,
            onVerifyPerson: this.onVerifyPerson,
            onAgree: this.onAgree,
            setProducts: this.setProducts,
            setStaff: this.setStaff,
            onAddCheckIn: this.onAddCheckIn,
            onViewVerifiedPersons: this.onViewVerifiedPersons,
          }}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  business: state.business,
  site: state.site,
  users: state.users,
});
export default connect(mapStateToProps, { verifyPerson, getCheckinApp, addCheckIn, getCheckIns, getCustomer, clearErrors, getCheckinAppMedia })(
  AppComponent
);
