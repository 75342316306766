import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';

class PrivacyPolicy extends Component {
  render() {
    return (
      <div className='display-flex'>
        <Container>
          <h1 className='h1_custom_index'>Privacy Policy</h1>
          <div className='font-size-18'>
            <br />
            This privacy policy (the &ldquo;Privacy Policy&rdquo;) informs you of Pink Dot Media and One Space and it&rsquo;s affiliates&rsquo; policy
            with respect to the online collection, use, and disclosure of personal information on the websites owned or managed by Pink Dot Media and
            One Space Media Corp. or its Affiliates (&ldquo;Pink Dot Media and One Space&rdquo;) and that link to or reference the Privacy Policy
            (each individually a &ldquo;Website&rdquo; and collectively the &ldquo;Websites&rdquo;). As used in the Agreement, the term
            &ldquo;Affiliates&rdquo; means, any other Person that directly or indirectly controls, is controlled by, or is under common control with
            Pink Dot Media and One Space Media Corp. The term &ldquo;control&rdquo; (including, with correlative meanings, the terms &ldquo;controlled
            by&rdquo; and &ldquo;under common control with&rdquo;) as applied to any Person means the possession, direct or indirect, of the power to
            direct or cause the direction of the management and policies of such Person, whether through the ownership of voting securities or other
            ownership interest. The term &ldquo;Person&rdquo; means any individual, partnership, corporation, limited liability company,
            unincorporated organization or association, any trust, or any other business entity.
            <div>
              <br />
            </div>
            <div>
              <span className='h3_custom_index'>Modification of Privacy Policy</span>
              <br />
              Pink Dot Media and One Space may amend, change, modify, add, or remove provisions of this Privacy Policy. Any changes to the Privacy
              Policy will be posted on this site. You are encouraged to check this site from time to time. By continuing to access or use of the
              Website following the posting of any amended, changed, or otherwise modified provisions to the Privacy Policy, you acknowledge that you
              agree with the changes, modification, and amendments.
              <br />
            </div>
            <div>
              <br />
            </div>
            <div>
              <span className='h3_custom_index'>Information Collected and Stored Automatically</span>
              <br />
            </div>
            <div>
              If you do nothing during your visit but browse/search through the website, read pages, or download information, Pink Dot Media and One
              Space will gather and store certain information about your visit automatically. This information does not identify you personally. Pink
              Dot Media and One Space automatically collects and stores only the following information about your visit:
              <ul>
                <li>The Internet domain and IP address from which you access the Website</li>
                <li>The type of browser and operating system used to access the Website</li>
                <li>The date and time you access the Website</li>
                <li>The length of time you spent on the Website</li>
                <li>The pages you visit</li>
                <li>The search term(s) used in our search engine</li>
                <li>If you linked to the Website from another website, the address of that website.</li>
              </ul>
              The above is collectively referred to as &ldquo;Usage Information.&rdquo; The Usage Information is used to make the Website more useful
              to visitors, to learn about the number of visitors to the Website and the types of technology that visitors use.
              <br />
              <br />
              <span className='h3_custom_index'>Types of Information Collected</span>
            </div>
            <div>
              The Website has certain areas where you may submit information. &ldquo; Personal Information,&rdquo; as used in this Privacy Policy,
              means any information that may be used to identify an individual, including, but not limited to, your name, company name, address,
              telephone number, email address, and other information that personally identifies you or would permit Pink Dot Media and One Space to
              contact you. Personal information does not include cookies or Usage Information.&nbsp;
            </div>
            <div>
              <br />
            </div>
            <div>
              The Websites do not target and are not intended for children under the age of 13. Pink Dot Media and One Space does not knowingly
              solicit personal information from children under the age of 13 or send them requests for personal information. No information should be
              submitted to Pink Dot Media by a child under the age of 13.
            </div>
            <div>
              <br />
            </div>
            <div className='h3_custom_index'>IP Address and Log Files</div>
            <div>
              Pink Dot Media and One Space may use your IP address and Usage Information to administer the Websites, to help diagnose problems with
              servers, to analyze trends, to track users&rsquo; webpage movements, to help identify you, and to gather broad demographic information
              for aggregate use.
              <br />
              <br />
              <span className='h3_custom_index'>Cookies</span>
            </div>
            <div>
              The Website may use technology called a &ldquo;cookie&rdquo; to collect information about how you use the Website. Cookies reside on
              your computer and help the Website to recognize your computer&rsquo;s browser as a previous visitor. In addition, on occasion, the
              Website may also set a &ldquo;session cookie&rdquo; which helps Pink Dot Media and One Space administer the Website. The session cookie
              expires when you close your browser and does not retain any information about you after it expires.
              <span className='h3_custom_index'>Information You Voluntarily Provide</span>
              <br />
              If you choose to provide Pink Dot Media and One Space with Personal Information by sending an e-mail to Pink Dot Media and One Space or
              by filling out a form with your Personal Information and submitting it through the Website, Pink Dot Media and One Space will use that
              information to respond to your message, to fulfill your request, and to assist Pink Dot Media and One Space in providing you with
              information.
              <br />
              <br />
              Pink Dot Media and One Space does not disclose, share, release, publish, disseminate, rent, or sell any Personal Information to any
              third parties.
              <br />
              <br />
              <span className='h3_custom_index'>Commercial Services</span>
              <br />
              The Websites may offer products and/or services, which may be offered by Pink Dot Media and One Space. You may you use a customer order
              form on the Website to request information, products, and services. The customer order form may ask you to give Pink Dot Media and One
              Space contact information, financial information, such as a credit card number, and demographic information, such as your age or zip
              code.
              <br />
              <br />
              Pink Dot Media and One Space will use the financial information provided on the customer order form to bill you for the products and/or
              services which you order. By providing your financial information and related Personal Information, you are authorizing Pink Dot Media
              and One Space to give that information to the merchant and/or the credit card company in order to confirm and fulfill your order. Pink
              Dot Media and One Space may use a third party to process online payments and therefore you authorize Pink Dot Media and One Space to
              transfer your financial information and related Personal Information to such third party to process your order. Pink Dot Media and One
              Space will use the Personal Information from the customer order form to fulfill your order.
              <br />
              <br />
              Pink Dot Media and One Space may use the Personal Information to contact you when necessary.
              <br />
              <br />
              <span className='h3_custom_index'>Third Party Sites</span>
              <br />
              While the Website may provide links to external sites as a matter of convenience, Pink Dot Media and One Space is not responsible for
              any third-party website, the privacy policy of any third party website, or how the third party treats information about their users.
              Pink Dot Media strongly advises that you review the privacy policy of any third party websites to find out how they are using your
              information.
              <br />
              <br />
              Sharing of Information with Affiliates and for Mergers and Sales
              <br />
              In the event that Pink Dot Media and One Space is merged with another legal entity or in the event of a transfer of Pink Dot Media and
              One Space&rsquo;s assets, Website or operations, Pink Dot Media and One Space may disclose or transfer your Personal Information as well
              as some or all of the information collected from the Website in connection with such merger or transfer.
              <br />
              <br />
              Additionally, Pink Dot Media and One Space may share information with third parties on an aggregate or other basis that does not
              disclose your identity or contact information.
              <br />
              <br />
              <span className='h3_custom_index'>Legally Compelled Disclosure</span>
              <br />
              Pink Dot Media and One Space may disclose Personally Information if required to do so by law or in the good faith belief that such
              preservations or disclosure is reasonably necessary to: (a) comply with legal process or other governmental inquiry; (b) enforce any
              agreements, such as the website terms of use agreement; (c) take action regarding suspected illegal activities; (d) protect Pink Dot
              Media and One Space&rsquo;s rights, reputation, and property, or that of Pink Dot Media and One Space&rsquo;s affiliates, or the public.
              <br />
              <br />
              Court of equity, such as U.S. Bankruptcy Courts, may have authority, in certain circumstances, to permit your Personal Information to be
              shared or transferred to third parties without your permission.
              <br />
              <br />
              <div className='h3_custom_index'>Consent to Processing</div>
              <br />
              By providing Personal Information, you fully understand and unambiguously agree to the transfer of such Personal Information to, and the
              collection and processing of such Personal Information in, the United States. Your Personal Information will be stored and processed on
              Pink Dot Media and One Space&rsquo;s computers in the United States. The laws on holding Personal Information in the United States may
              vary and be less stringent than laws of your state or country. Pink Dot Media and One Space will use commercially reasonable efforts to
              hold and transmit your Personal Information in a safe, confidential and secure environment. If you do not agree to your Personal
              Information being transferred or used in this manner, please do not submit Personal Information to Pink Dot Media and One Space or use
              the Website.
            </div>
          </div>
          <br />
          <br />
        </Container>
      </div>
    );
  }
}

export default PrivacyPolicy;
