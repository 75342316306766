import React, { Component } from 'react';
import { connect } from 'react-redux';
import socketIOClient from 'socket.io-client';
import { FcCloseUpMode, FcSurvey, FcDeleteDatabase, FcAlarmClock } from 'react-icons/fc';
import {
  MdMap,
  MdPeople,
  MdPerson,
  MdPlace,
  MdPhone,
  MdStore,
  MdNotificationsActive,
  MdSentimentNeutral,
  MdSentimentSatisfied,
  MdSentimentDissatisfied,
  MdSentimentVeryDissatisfied,
  MdSentimentVerySatisfied,
  MdAccessTime,
} from 'react-icons/md';
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';
import dateFormat from 'dateformat';
import compareTime from '../../functions/compareTime';
import ModalDepot from './components/ModalDepot';
import { updateCheckIn, updateCheckinRating, getCheckinOverview } from '../../redux/actions/businessActions';
import { TextField } from '@material-ui/core';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'CHECKIN_OVERVIEW',
      checkinExpireTimeHour: 4,
      waitlist: [],
      waitlistPosition: 1,
      isViewList: false,
      isFeedbackRequiredPromptModalShow: false,
      isLeaveWaitlistFormModalShow: false,
      checkinRating: null,
      checkinFeedback: '',
      isFeedbackSubmitted: false,
      selectedCheckin: {},
      endpoint:
        process.env.REACT_APP_PRODUCTION_ENV === '1' ? process.env.REACT_APP_PRODUCTION_LOCALSERVER : process.env.REACT_APP_DEVELOPMENT_LOCALSERVER,
    };
    this.openModal = this.openModal.bind(this);
    this.onSetStateCheckinRating = this.onSetStateCheckinRating.bind(this);
    this.onLeaveWaitlist = this.onLeaveWaitlist.bind(this);
    this.isEmptyObject = this.isEmptyObject.bind(this);
  }
  componentDidMount = () => {
    const data = {
      public_id: this.props.match.params.public_id,
    };
    this.props.getCheckinOverview(data, this);
    const socket = socketIOClient(this.state.endpoint);
    socket.on('SOCKET_UPDATE_CHECKINS', () => {
      const data = {
        public_id: this.props.match.params.public_id,
      };
      this.props.getCheckinOverview(data, this);
    });
  };
  openModal = (modalName, modalState, modalData) => {
    switch (modalName) {
      case 'VIEW_LIST':
        if (modalState) {
          this.setState({ isViewList: true });
        } else {
          this.setState({ isViewList: false });
        }
        break;
      case 'PROMPT_FEEDBACK_REQUIRE':
        if (modalState) {
          this.setState({ isFeedbackRequiredPromptModalShow: true });
        } else {
          this.setState({ isFeedbackRequiredPromptModalShow: false });
        }
        break;
      case 'LEAVE_WAITLIST_FORM':
        if (modalState) {
          this.setState({ isLeaveWaitlistFormModalShow: true });
        } else {
          this.setState({ isLeaveWaitlistFormModalShow: false });
        }
        break;
      default:
    }
  };
  onGetValidPositionFromCheckins = (checkins) => {
    var checkinPosition = this.state.waitlistPosition;
    var validList = [];
    checkins.map((checkin) => {
      var timeDifferenceInHours = compareTime(new Date(checkin.created_on), new Date(), 'hour');
      if (timeDifferenceInHours <= this.state.checkinExpireTimeHour && checkin.status === 'checked-in') {
        validList.push(checkin);
      }
      return validList;
    });
    validList.map((checkin, i) => {
      if (checkin.public_id === this.props.match.params.public_id) {
        checkinPosition = i + 1;
      }
      return checkinPosition;
    });
    this.setState({ waitlist: validList, waitlistPosition: checkinPosition });
  };
  onUpdateCheckinRating = async () => {
    await this.setState({
      selectedCheckin: {
        ...this.state.selectedCheckin,
        rating: this.state.checkinRating,
        feedback: this.state.checkinFeedback,
      },
    });
    const data = {
      checkin: this.state.selectedCheckin,
    };
    this.props.updateCheckIn(data, this);
  };
  onSetStateCheckinRating = (checkin) => {
    this.setState({
      selectedCheckin: checkin,
      checkinRating: checkin.rating,
      checkinFeedback: checkin.feedback,
      isFeedbackSubmitted: checkin.rating ? true : false,
    });
  };
  onLeaveWaitlist = async () => {
    await this.setState({
      selectedCheckin: {
        ...this.state.selectedCheckin,
        status: 'canceled',
        cancel_on: new Date(),
        cancel_by: 'party',
      },
    });
    const data = {
      checkin: this.state.selectedCheckin,
    };
    this.props.updateCheckIn(data, this);
  };
  isEmptyObject(value) {
    return value && Object.keys(value).length === 0 && value.constructor === Object;
  }
  render() {
    const { checkinapp, checkin } = this.props.business;
    // this page expires after 5 hours after check in
    const timeDifferenceInHours = compareTime(new Date(checkin.created_on), new Date(), 'hour');
    return checkinapp && checkinapp.business && !this.isEmptyObject(checkin) && checkin.id ? (
      <>
        <div className='display-flex flex-direction-column justify-content-flex-start'>
          <div className='margin-bottom-50'></div>
          <div className='footer-sticky-top bg-white display-flex flex-direction-column align-items-center justify-content-center padding border-bottom'>
            <div className='font-size-large'>{checkinapp.business.business_name}</div>
          </div>
          {checkin.status === 'canceled' ? (
            // canceled checked-in
            <>
              <div className='display-flex justify-content-center align-items-center padding'>
                <div className='padding'>
                  <div className='margin-bottom-15'>
                    <center>
                      <FcDeleteDatabase size={100} />
                    </center>
                  </div>
                  <center>
                    <div className='font-size-large margin-bottom-15'>You've left the queue</div>
                    <div className='margin-bottom-50'>To get back in line, please check in again at {checkin.business_name}</div>
                  </center>
                </div>
              </div>
            </>
          ) : (
            <>
              {checkin.status === 'checked-in' ? (
                timeDifferenceInHours <= this.state.checkinExpireTimeHour && this.state.waitlist.length > 0 ? (
                  <div>
                    <div className='display-flex padding justify-content-center'>
                      {checkinapp.media && checkinapp.media.preview ? (
                        <div className='padding'>
                          <img
                            alt={checkinapp.media.media_name}
                            src={checkinapp.media.preview}
                            className='border-radius-5 margin-bottom-15'
                            style={{ maxHeight: '175px', width: '100%', height: '100%', objectFit: 'contain' }}
                          />
                        </div>
                      ) : (
                        <>
                          <FcSurvey size={100} />
                        </>
                      )}
                    </div>
                    <div className='border-top-whitesmoke'></div>
                    <div className='padding font-size-medium'>
                      You are <span className='color-green'>#{this.state.waitlistPosition}</span> on the list.
                    </div>
                    <div className='padding-left-15 color-gray'>Based on all party sizes</div>
                    <div className='padding display-flex flex-direction-row justify-content-space-between'>
                      <ButtonGroup className='display-flex'>
                        <Button variant='secondary' onClick={() => this.openModal('VIEW_LIST', true)} className='flex-grow-1'>
                          View List
                        </Button>
                        <Button
                          variant='secondary'
                          onClick={() => {
                            this.openModal('LEAVE_WAITLIST_FORM', true);
                          }}
                          className='flex-grow-1'
                        >
                          Leave List
                        </Button>
                      </ButtonGroup>
                    </div>

                    <div className='border-top-gray'></div>
                    <div className='display-flex flex-direction-column align-items-center justify-content-center padding'>
                      <div className='margin-bottom-15 font-size-medium'>Checked-In Details</div>
                      <div>
                        <MdPerson size={20} className='color-gray' /> {checkin.selectedCustomer.first_name} {checkin.selectedCustomer.last_name}
                        <br />
                        <MdPeople size={20} className='color-gray' /> Party of {checkin.party_size}
                        <br />
                        <MdAccessTime size={20} className='color-gray' /> {dateFormat(checkin.created_time * 1000, 'mmmm dd, yyyy "at" h:MM TT')}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className='padding'>
                      <center>
                        <FcAlarmClock size='100' />
                      </center>
                    </div>
                    <div className='border-top-whitesmoke'></div>
                    <div className='padding'>
                      <center>
                        <div>Live Position Closed</div>
                      </center>
                    </div>
                    <div className='border-top-gray'></div>
                    <div className='display-flex flex-direction-column align-items-center justify-content-center padding'>
                      <div className='margin-bottom-15 font-size-medium'>Checked-In Details</div>
                      <div>
                        <MdPerson size={20} className='color-gray' /> {checkin.selectedCustomer.first_name} {checkin.selectedCustomer.last_name}
                        <br />
                        <MdPeople size={20} className='color-gray' /> Party of {checkin.party_size}
                        <br />
                        <MdAccessTime size={20} className='color-gray' /> {dateFormat(checkin.created_time * 1000, 'mmmm dd, yyyy "at" h:MM TT')}
                      </div>
                    </div>
                  </>
                )
              ) : checkin.status === 'ready' ? (
                <>
                  <div className='padding'>
                    <center>
                      <MdNotificationsActive size='100' className='color-darkorange' />
                    </center>
                  </div>
                  <div className='padding'>
                    <center>
                      <div className='font-size-large'>We're Ready for You!</div>
                    </center>
                  </div>
                  <div className='padding display-flex flex-direction-row justify-content-space-between'>
                    <ButtonGroup className='display-flex'>
                      <Button
                        variant='secondary'
                        onClick={() => {
                          this.openModal('LEAVE_WAITLIST_FORM', true);
                        }}
                        className='flex-grow-1'
                      >
                        Wanna Cancel?
                      </Button>
                    </ButtonGroup>
                  </div>
                  <div className='border-top-gray'></div>
                  <div className='display-flex flex-direction-column align-items-center justify-content-center padding'>
                    <div className='margin-bottom-15 font-size-medium'>Checked-In Details</div>
                    <div>
                      <MdPerson size={20} className='color-gray' /> {checkin.selectedCustomer.first_name} {checkin.selectedCustomer.last_name}
                      <br />
                      <MdPeople size={20} className='color-gray' /> Party of {checkin.party_size}
                      <br />
                      <MdAccessTime size={20} className='color-gray' /> {dateFormat(checkin.created_time * 1000, 'mmmm dd, yyyy "at" h:MM TT')}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className='padding'>
                    <center>
                      <FcCloseUpMode size='100' />
                    </center>
                  </div>
                  <div className='padding'>
                    <center>
                      <div className='font-size-large'>Enjoy</div>
                    </center>
                  </div>
                </>
              )}
              {checkinapp.auto_feedback_enabled ? (
                <>
                  <div className='padding bg-whitesmoke'></div>
                  {this.state.isFeedbackSubmitted ? (
                    <div className='margin-bottom-50'>
                      <center>
                        <div className='padding font-size-medium'>Thank you for your feedback!</div>
                        <div className='display-flex flex-direction-column align-items-center justify-content-center color-gray'>
                          <div className='margin-right-5'>Your overall experience on {dateFormat(checkin.created_time * 1000, 'mmmm dd, yyyy')}:</div>
                          <div className='padding'>
                            {checkin.rating === 1 ? (
                              <MdSentimentVeryDissatisfied size={75} className='color-tomato' />
                            ) : checkin.rating === 2 ? (
                              <MdSentimentDissatisfied size={75} className='color-darkorange' />
                            ) : checkin.rating === 3 ? (
                              <MdSentimentNeutral size={75} className='color-gray' />
                            ) : checkin.rating === 4 ? (
                              <MdSentimentSatisfied size={75} className='color-green' />
                            ) : checkin.rating === 5 ? (
                              <MdSentimentVerySatisfied size={75} className='color-darkcyan' />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <div className='padding'>
                          <ButtonGroup className='display-flex'>
                            <Button
                              variant='secondary'
                              onClick={() => {
                                this.setState({ isFeedbackSubmitted: false });
                              }}
                              className='flex-grow-1'
                            >
                              Edit Feedback
                            </Button>
                          </ButtonGroup>
                        </div>
                      </center>
                    </div>
                  ) : (
                    <>
                      <div className='padding font-size-medium'>We would love to hear from you. How did we do?</div>
                      <div className='display-flex align-items-center padding-left-15 color-gray'>
                        {checkin && checkin.created_on && checkin.status === 'seated' ? (
                          'Regarding your recent visit on ' + dateFormat(new Date(checkin.created_on), 'mmmm dd, yyyy')
                        ) : (
                          <>How was your experience with us?</>
                        )}
                      </div>
                      <div className='padding display-flex flex-direction-row justify-content-space-between margin-bottom-50'>
                        <>
                          <ButtonGroup toggle className='display-flex'>
                            <ToggleButton
                              variant='secondary'
                              key={1}
                              type='radio'
                              name='radio'
                              value={this.state.checkinRating ? this.state.checkinRating : ''}
                              checked={this.state.checkinRating === 1 ? true : false}
                              onClick={(e) => {
                                this.setState({ checkinRating: 1 });
                              }}
                            >
                              <div className='display-flex flex-direction-column align-items-center justify-content-center'>
                                <MdSentimentVeryDissatisfied size={30} className='color-tomato' />
                                Very
                                <br />
                                Unhappy
                              </div>
                            </ToggleButton>
                            <ToggleButton
                              variant='secondary'
                              key={2}
                              type='radio'
                              name='radio'
                              value={this.state.checkinRating ? this.state.checkinRating : ''}
                              checked={this.state.checkinRating === 2 ? true : false}
                              onClick={(e) => {
                                this.setState({ checkinRating: 2 });
                              }}
                            >
                              <div className='display-flex flex-direction-column align-items-center justify-content-center'>
                                <MdSentimentDissatisfied size={30} className='color-darkorange' />
                                <br />
                                Unhappy
                              </div>
                            </ToggleButton>
                            <ToggleButton
                              variant='secondary'
                              key={3}
                              type='radio'
                              name='radio'
                              value={this.state.checkinRating ? this.state.checkinRating : ''}
                              checked={this.state.checkinRating === 3 ? true : false}
                              onClick={async (e) => {
                                this.setState({ checkinRating: 3 });
                              }}
                            >
                              <div className='display-flex flex-direction-column align-items-center justify-content-center'>
                                <MdSentimentNeutral size={30} className='color-gray' />
                                <br />
                                Neutral
                              </div>
                            </ToggleButton>
                            <ToggleButton
                              variant='secondary'
                              key={4}
                              type='radio'
                              name='radio'
                              value={this.state.checkinRating ? this.state.checkinRating : ''}
                              checked={this.state.checkinRating === 4 ? true : false}
                              onClick={(e) => {
                                this.setState({ checkinRating: 4 });
                              }}
                            >
                              <div className='display-flex flex-direction-column align-items-center justify-content-center'>
                                <MdSentimentSatisfied size={30} className='color-green' />
                                <br />
                                Happy
                              </div>
                            </ToggleButton>
                            <ToggleButton
                              variant='secondary'
                              key={5}
                              type='radio'
                              name='radio'
                              value={this.state.checkinRating ? this.state.checkinRating : ''}
                              checked={this.state.checkinRating === 5 ? true : false}
                              onClick={(e) => {
                                this.setState({ checkinRating: 5 });
                              }}
                            >
                              <div className='display-flex flex-direction-column align-items-center justify-content-center'>
                                <MdSentimentVerySatisfied size={30} className='color-darkcyan' />
                                Very
                                <br />
                                Happy
                              </div>
                            </ToggleButton>
                          </ButtonGroup>
                          <TextField
                            label='Tell us about your experience'
                            multiline
                            rows='3'
                            name='feedback'
                            margin='normal'
                            variant='outlined'
                            value={this.state.checkinFeedback ? this.state.checkinFeedback : ''}
                            onChange={(e) => {
                              this.setState({ checkinFeedback: e.target.value });
                            }}
                            inputProps={{ style: { fontSize: 17 } }} // font size of input text
                            InputLabelProps={{ style: { fontSize: 17 } }} // font size of input label
                            className='display-flex'
                          />
                          <div className='display-flex margin-top-15'>
                            <Button
                              onClick={async (e) => {
                                if ((this.state.checkinRating === 1 || this.state.checkinRating === 2) && !this.state.checkinFeedback) {
                                  this.openModal('PROMPT_FEEDBACK_REQUIRE', true);
                                } else {
                                  await this.setState({ checkinRating: this.state.checkinRating, isFeedbackSubmitted: true });
                                  this.onUpdateCheckinRating();
                                }
                              }}
                              className='display-flex'
                            >
                              Submit
                            </Button>
                          </div>
                        </>
                      </div>
                    </>
                  )}
                </>
              ) : (
                ''
              )}
            </>
          )}
          <div style={{ height: '50px' }}></div>
          <div className='footer-sticky-bottom bg-whitesmoke display-flex align-items-center justify-content-center border-top-gray'>
            <div className='display-flex justify-content-space-evenly align-items-flex-end padding-top-10 padding-bottom-10'>
              <div>
                <a
                  href={`tel:${checkinapp.business.phone}`}
                  className='display-flex flex-direction-column justify-content-center align-items-center text-decoration-none'
                >
                  <MdPhone size={25} className='color-deepskyblue' />
                  Call
                </a>
              </div>
              {checkinapp.business.vanity_name ? (
                <div>
                  <a
                    href={`/page/${checkinapp.business.vanity_name}`}
                    className='display-flex flex-direction-column justify-content-center align-items-center text-decoration-none'
                  >
                    <MdStore size={30} className='color-deepskyblue' />
                    Business
                  </a>
                </div>
              ) : (
                ''
              )}
              <div>
                <a
                  href={`https://maps.google.com/maps?q=${checkinapp.full_address}`}
                  className='display-flex flex-direction-column justify-content-center align-items-center text-decoration-none'
                >
                  <MdMap size={30} className='color-deepskyblue' />
                  Map
                </a>
              </div>
              <div>
                <a
                  href={`https://maps.google.com/maps/dir/?api=1&destination=${checkinapp.full_address}`}
                  className='display-flex flex-direction-column justify-content-center align-items-center text-decoration-none'
                >
                  <MdPlace size={30} className='color-deepskyblue' />
                  Directions
                </a>
              </div>
            </div>
          </div>
        </div>
        <ModalDepot state={this.state} props={this.props} function={{ openModal: this.openModal, onLeaveWaitlist: this.onLeaveWaitlist }} />
      </>
    ) : (
      <>
        <div className='display-flex align-items-center justify-content-center font-size-large padding'>Loading...</div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  business: state.business,
});
export default connect(mapStateToProps, { updateCheckIn, updateCheckinRating, getCheckinOverview })(AppComponent);
