import React, { Component } from 'react';
import { connect } from 'react-redux';
import TimeAgo from 'react-timeago';
import { getCheckIns } from './../../../redux/actions/businessActions';

class AppComponent extends Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <>
        <div>
          {this.props.props.state.waitlist.map((checkin, i) => {
            var classObjectCheckinID = 'padding border-bottom';
            if (i === this.props.props.state.waitlist.length - 1) {
              classObjectCheckinID = 'padding';
            }
            return (
              <div key={i} className={this.props.props.state.waitlistPosition === i + 1 ? classObjectCheckinID : 'color-gray padding border-bottom'}>
                <div className={this.props.props.state.waitlistPosition === i + 1 ? 'font-weight-bold' : ''}>
                  {i + 1}. {this.props.props.state.waitlistPosition === i + 1 ? checkin.first_name + ', Your party' : 'Party'} of {checkin.party_size}{' '}
                </div>
                {this.props.props.state.waitlistPosition === i + 1 ? (
                  <div>
                    Joined <TimeAgo date={checkin.created_on} />
                  </div>
                ) : (
                  ''
                )}
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  business: state.business,
});
export default connect(mapStateToProps, { getCheckIns })(AppComponent);
