import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProductsComponent from '../components/ProductsComponent';

class AppComponent extends Component {
  render() {
    return (
      <>
        <ProductsComponent state={this.state} props={this.props} />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps)(AppComponent);
