import { combineReducers } from 'redux';
import siteReducer from './../reducers/siteReducer';
import userReducer from './../reducers/userReducer';
import businessReducer from './../reducers/businessReducer';
import subscriptionReducer from './../reducers/subscriptionReducer';

export default combineReducers({
  site: siteReducer,
  users: userReducer,
  business: businessReducer,
  subscriptions: subscriptionReducer,
});
