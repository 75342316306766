import React, { Component } from 'react';
import { connect } from 'react-redux';
import { validateSignupToken } from '../../redux/actions/userActions';

class VerifyToken extends Component {
  componentWillMount() {
    if (this.props.users.isAuthenticated) {
      this.props.history.push('/account/dashboard');
    }
    // get the page verification token from query url and pass to back end for check
    const data = {
      token: this.props.match.params.token,
      type: 'signup',
    };
    this.props.validateSignupToken(data);
  }
  render() {
    return <></>;
  }
}

// use this to get reducer state into components, gets the state."auth" from combineRootReducer.js
// and put it into this auth. if "auth" from that file is changed to another word, then
// this state."auth" also needs to change. To get data, use this.props.users.whatever
const mapStateToProps = (state) => ({
  users: state.users,
  site: state.site,
});
export default connect(mapStateToProps, { validateSignupToken })(VerifyToken);
