import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { getStaff, getStaffAlbum, deleteStaffMedia, setStaffMediaMain } from '../../../redux/actions/businessActions';
import ModalDepot from '../components/ModalDepot';
import { MdStars } from 'react-icons/md';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'STAFF_ALBUM_FORM',
      isUpdateStaffMediaModalShow: false,
      selectedStaffMedia: {},
      selected: [],
      selectedStaff: props.props.state.selectedStaff,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal(modalName, modalState, modalData) {
    switch (modalName) {
      case 'STAFF_MEDIA_UPDATE':
        modalState ? this.setState({ isUpdateStaffMediaModalShow: true }) : this.setState({ isUpdateStaffMediaModalShow: false });
        break;
      default:
    }
  }
  componentDidMount() {
    this.props.getStaffAlbum(this.state.selectedStaff.id);
    this.props.getStaff(this.state.selectedStaff.id);
  }
  render() {
    const { staff_album } = this.props.business;
    return (
      <>
        <div>
          <div className='square-container'>
            {staff_album.map((media, i) => {
              let thumbnailPath = '/api/static/media/staff/thumbnail/';
              var fileSource = '';
              if (!media.is_image) {
                let sourceNameOnly = media.source.substr(0, media.source.lastIndexOf('.'));
                fileSource = thumbnailPath + sourceNameOnly + '.jpg';
              } else {
                fileSource = thumbnailPath + media.source;
              }
              return (
                <div className='square' key={i}>
                  <div>
                    <Button
                      style={{ all: 'unset', cursor: 'pointer' }}
                      onClick={() => {
                        this.setState({ selectedStaffMedia: media });
                        this.openModal('STAFF_MEDIA_UPDATE', true);
                      }}
                    >
                      <img src={fileSource} className='content border-radius-5' alt='' />
                    </Button>
                    {media.is_main ? (
                      <div className='position-absolute' style={{ bottom: '0', left: '0' }}>
                        <MdStars size={45} className='color-white' />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <ModalDepot state={this.state} props={this.props} function={{ openModal: this.openModal }} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  business: state.business,
});
export default connect(mapStateToProps, { getStaff, getStaffAlbum, deleteStaffMedia, setStaffMediaMain })(AppComponent);
