import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getForm } from './../../../redux/actions/businessActions';

class AppComponent extends Component {
  constructor(props) {
    super();
    props.getForm(props.business.checkinapp.form_id ? props.business.checkinapp.form_id : 1);
  }
  render() {
    const { form } = this.props.business;
    return (
      <>
        <div className='checkinapp-agreement-parent'>
          <div className='checkinapp-agreement-child'>
            <div className='react-quill' dangerouslySetInnerHTML={{ __html: form.form_content ? form.form_content : '' }} />
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({ business: state.business });
export default connect(mapStateToProps, { getForm })(AppComponent);
