import { GET_ERRORS, GET_SUBSCRIPTION_PRODUCTS, GET_ACCOUNT_SUBSCRIPTION } from '../types/types';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import setAuthToken from './../../functions/setAuthToken';
import { authenticateUser } from './../actions/userActions';
import { loadStripe } from '@stripe/stripe-js';
// const stripePromise = loadStripe(
//   process.env.REACT_APP_PRODUCTION_ENV === 1 ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST
// );
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

async function redirectStripeSession(data) {
  const stripe = await stripePromise;
  const { error } = await stripe
    .redirectToCheckout({
      sessionId: data.id,
    })
    .then((result) => {
      if (result.error) {
        console.log(result.error.message);
      } else {
        console.log('NO ERROR');
      }
    });

  console.log('HELLOW ROLD INTERNAL', data);
}

// SUBSCRIPTIONS
export const getStripeSubscriptionProducts = () => (dispatch) => {
  axios
    .get(`/api/subscription/get-subscription-products`)
    .then((res) => {
      dispatch({ type: GET_SUBSCRIPTION_PRODUCTS, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const createStripeCheckoutSession = (data) => (dispatch) => {
  axios
    .post('/api/subscription/create-checkout-session', data)
    .then((res) => {
      console.log('createStripeCheckoutSession RESDATA', res.data);
      if (res.data) {
        console.log('HELLOW ROLD EXTERNAL', res.data);
        redirectStripeSession(res.data);

        // const stripe = await stripePromise;
        // //   // redirect them to Checkout.
        // const { error } = await stripe
        //   .redirectToCheckout({
        //     sessionId: res.data.id,
        //   })
        //   .then((result) => {
        //     if (result.error) {
        //       console.log(result.error.message);
        //     } else {
        //       console.log('NO ERROR');
        //     }
        //   });

        // if (error) {
        //   // If `redirectToCheckout` fails due to a browser or network
        //   // error, display the localized error message to your customer
        //   // using `error.message`.
        //   console.log(error);
        // }
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const getCheckedOutSession = (data, caller) => (dispatch) => {
  axios
    .get(`/api/subscription/get-checkout-session`, { params: { queryData: data } })
    .then((res) => {
      dispatch(addAccountSubscription(res.data, caller));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const addAccountSubscription = (data, caller) => (dispatch) => {
  axios
    .post('/api/subscription/add-account-subscription', data)
    .then((res) => {
      const { success, loginToken } = res.data;
      if (success && loginToken) {
        localStorage.setItem('loginToken', loginToken); // set token to local storage
        setAuthToken(loginToken); // set token to auth header
        const decoded = jwt_decode(loginToken); // decode token to get user data
        dispatch(authenticateUser(decoded)); // set current user authentication
        caller.openModal('SUBSCRIPTION_SUCCESS', true);
      } else {
        window.location.href = '/account/subscription';
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const getAccountSubscription = (data) => (dispatch) => {
  axios
    .get(`/api/subscription/get-account-subscription`, { params: { queryData: data } })
    .then((res) => {
      dispatch({ type: GET_ACCOUNT_SUBSCRIPTION, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const updateSubscription = (data, caller) => (dispatch) => {
  axios
    .post('/api/subscription/update-subscription', data)
    .then((res) => {
      const { success, loginToken } = res.data;
      if (success && loginToken) {
        localStorage.setItem('loginToken', loginToken); // set token to local storage
        setAuthToken(loginToken); // set token to auth header
        const decoded = jwt_decode(loginToken); // decode token to get user data
        dispatch(authenticateUser(decoded)); // set current user authentication
        caller.openModal('SUBSCRIPTION_SUCCESS', true);
      } else {
        window.location.href = '/account/subscription';
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const confirmSubscriptionSwitch = (data, caller) => (dispatch) => {
  axios
    .post('/api/subscription/confirm-subscription-switch', data)
    .then((res) => {
      // caller.onSetStateConfirmedSwitch();
      const switchPlanData = {
        selectedSubscriptionPlan: caller.props.props.state.selectedSubscriptionPlan,
        userToken: caller.props.props.users.token,
      };
      dispatch(switchSubscription(switchPlanData, caller));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const switchSubscription = (data, caller) => (dispatch) => {
  axios
    .post('/api/subscription/switch-subscription', data)
    .then((res) => {
      const { success, loginToken } = res.data;
      if (success && loginToken) {
        localStorage.setItem('loginToken', loginToken); // set token to local storage
        setAuthToken(loginToken); // set token to auth header
        const decoded = jwt_decode(loginToken); // decode token to get user data
        dispatch(authenticateUser(decoded)); // set current user authentication
        window.location.href = `/account/subscription?session_id=${caller.props.props.props.subscriptions.account_subscription.session_id}`;
      } else {
        window.location.href = '/account/subscription';
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const confirmSubscriptionCancel = (data, caller) => (dispatch) => {
  axios
    .post('/api/subscription/confirm-subscription-cancel', data)
    .then((res) => {
      caller.onSetStateConfirmedSubscriptionCancel();
      const cancelSubscriptionData = {
        userToken: caller.props.props.users.token,
      };
      dispatch(cancelSubscription(cancelSubscriptionData, caller));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const cancelSubscription = (data, caller) => (dispatch) => {
  axios
    .post('/api/subscription/cancel-subscription', data)
    .then((res) => {
      const { success, loginToken } = res.data;
      if (success && loginToken) {
        localStorage.setItem('loginToken', loginToken); // set token to local storage
        setAuthToken(loginToken); // set token to auth header
        const decoded = jwt_decode(loginToken); // decode token to get user data
        dispatch(authenticateUser(decoded)); // set current user authentication
        window.location.reload();
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
