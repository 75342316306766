import React, { Component } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { FaUserCircle } from 'react-icons/fa';
import { connect } from 'react-redux';
import { normalize } from 'phone-prettify';

import { getCustomer } from '../../../redux/actions/businessActions';
import { MdMailOutline, MdPhone, MdPhoneAndroid, MdSend } from 'react-icons/md';
import ModalDepot from './ModalDepot';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'CUSTOMER_PROFILE_COMPONENT',
      customerID: props.props.state.selectedCustomer.id,
      customer: {},
      customerCheckinHistory: [],
      isCheckInHistoryModalShow: false,
      isUpdateCustomerModalShow: false,
    };
  }
  componentDidMount() {
    this.props.getCustomer(this.state.customerID, this);
  }
  openModal = (modalName, modalState, modalData) => {
    switch (modalName) {
      case 'CHECKIN_HISTORY_VIEW':
        modalState ? this.setState({ isCheckInHistoryModalShow: true }) : this.setState({ isCheckInHistoryModalShow: false });
        break;
      case 'CUSTOMER_UPDATE':
        if (modalState) {
          this.setState({ isUpdateCustomerModalShow: true });
        } else {
          this.setState({ isUpdateCustomerModalShow: false });
        }
        break;
      default:
    }
  };
  render() {
    const { customer } = this.props.business;
    return Object.keys(customer).length !== 0 && customer.constructor === Object ? (
      <>
        <div className='padding'>
          <div className='display-flex'>
            <div className='display-flex align-items-center padding-bottom-15'>
              <div className='padding-5 border-radius-35 margin-right-10'>
                <FaUserCircle size={50} className='color-whitesmoke border-gray border-radius-25' />
              </div>
              <div>
                <div className='font-size-large'>
                  {customer.first_name} {customer.last_name}
                  <Button
                    variant='secondary'
                    onClick={() => {
                      this.openModal('CUSTOMER_UPDATE', true);
                    }}
                    style={{ marginLeft: '10px' }}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            </div>
            <div className='display-flex justify-content-center padding border-top-gray'>
              <div className='flex-1 display-flex justify-content-center text-align-center'>
                <Button
                  variant='secondary'
                  onClick={() => {
                    this.openModal('CHECKIN_HISTORY_VIEW', true);
                  }}
                >
                  {customer.totalCheckins}
                  <br />
                  Check-Ins
                </Button>
              </div>
              <div className='flex-1 display-flex justify-content-center text-align-center'>
                <Button variant='secondary'>
                  {customer.totalConfirmBookings}
                  <br />
                  Bookings
                </Button>
              </div>
              <div className='flex-1 display-flex justify-content-center text-align-center'>
                <Button variant='secondary'>
                  {customer.totalCanceledBookings}
                  <br />
                  Canceled
                </Button>
              </div>
              <div className='flex-1 display-flex justify-content-center text-align-center'>
                <Button variant='secondary'>
                  {customer.totalNoShowBookings}
                  <br />
                  No-shows
                </Button>
              </div>
            </div>
            <div className='width-100'>
              <Tabs defaultActiveKey='contact' className='display-flex'>
                <Tab eventKey='contact' title='Contact'>
                  <div className='display-flex align-items-center margin-top-10'>
                    <div>
                      <MdPhoneAndroid size={25} className='color-gray' />
                    </div>{' '}
                    <div>{normalize(customer.phone)} </div>{' '}
                    <div>
                      <Button
                        variant='secondary'
                        onClick={() => {
                          window.location.href = `tel:${customer.phone}`;
                        }}
                        style={{ padding: '5px', marginLeft: '10px' }}
                      >
                        <MdPhone size={25} className='color-deepskyblue' /> Call
                      </Button>
                    </div>
                  </div>
                  {customer.email ? (
                    <div className='display-flex align-items-center margin-top-10'>
                      <div>
                        <MdMailOutline size={25} className='color-gray' />
                      </div>{' '}
                      <div>{normalize(customer.email)} </div>{' '}
                      <div>
                        <Button
                          variant='secondary'
                          onClick={() => {
                            window.location.href = `mailto:${customer.email}`;
                          }}
                          style={{ padding: '5px', marginLeft: '10px' }}
                        >
                          <MdSend size={25} className='color-deepskyblue' /> Send
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
        <ModalDepot state={this.state} prop={this.props} function={{ openModal: this.openModal }} />
      </>
    ) : (
      <div>This customer profile is no longer available.</div>
    );
  }
}

const mapStateToProps = (state) => ({
  business: state.business,
  users: state.users,
});
export default connect(mapStateToProps, { getCustomer })(AppComponent);
