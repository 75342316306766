import React, { Component } from 'react';
import { connect } from 'react-redux'; // connecting redux to component
import { verifyPasswordToken, resetPassword } from '../../redux/actions/userActions';
import { clearErrors } from './../../redux/actions/siteActions';
import { Alert, Container, Button, Form } from 'react-bootstrap';
import isEmpty from './../../functions/isEmpty';
import { MdLockOpen } from 'react-icons/md';

class PassResetPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      password2: '',
      isResetPasswordDisabled: true,
    };

    this.onResetPassword = this.onResetPassword.bind(this);
  }
  componentWillMount() {
    // get the page verification token from query url and pass to back end for check
    const data = {
      token: this.props.match.params.token,
      tokenType: 'password',
    };
    this.props.verifyPasswordToken(data);
  }
  async onChange(e) {
    await this.setState({ [e.target.name]: e.target.value });

    if (this.state.password !== '' && this.state.password2 !== '') {
      this.setState({ isResetPasswordDisabled: false });
    } else {
      this.setState({ isResetPasswordDisabled: true });
    }
  }
  onResetPassword(e) {
    e.preventDefault();
    const data = {
      userID: this.props.site.response.userID,
      password: this.state.password,
      password2: this.state.password2,
    };
    this.props.resetPassword(data);
  }
  render() {
    const { errors, response } = this.props.site;
    const { isAuthenticated } = this.props.users;

    if (isAuthenticated) {
      window.location.href = '/account/dashboard';
    }
    if (!isEmpty(response) && response.isTokenValid) {
      return (
        <Container>
          <center>
            <div className='container-box-small'>
              <div className='font-size-large margin-bottom-15'>Password Reset</div>
              <MdLockOpen size='100' color='#019fe8' />
              <br />
              <br />
              {response.isPasswordReset ? (
                <>
                  <span className='color-gray'>You have successfully reset your password. Please login.</span>
                  <br />
                  <br />
                  <div>
                    <Button onClick={() => (window.location.href = '/login')}>LOGIN</Button>
                  </div>
                </>
              ) : (
                <>
                  <span className='color-gray'>Enter a new password below to change your password.</span>
                  <Form className='margin-top-15' onSubmit={this.onResetPassword}>
                    <Form.Control
                      type='password'
                      name='password'
                      placeholder='New password'
                      value={this.state.password}
                      onChange={(e) => this.onChange(e)}
                      className='margin-bottom-15'
                    />
                    <Form.Control
                      type='password'
                      name='password2'
                      placeholder='Re-enter new password'
                      value={this.state.password2}
                      onChange={(e) => this.onChange(e)}
                      className='margin-bottom-15'
                    />
                    <Alert variant='warning' show={errors.message ? true : false} onClose={() => this.props.clearErrors()} dismissible>
                      {errors.message}
                    </Alert>
                    <Button type='submit' style={{ width: '100%' }} disabled={this.state.isResetPasswordDisabled ? true : false}>
                      Reset Password
                    </Button>
                  </Form>
                  <br />
                  <span className='color-gray'>
                    Remembered your password? <a href='/login'>Log in</a>
                  </span>
                </>
              )}
            </div>
          </center>
        </Container>
      );
    } else {
      return (
        <Container>
          <div className='container-box-medium font-family-proximanova'>
            <span className='font-size-xlarge'>There's a small problem</span>
            <br />
            <span className='font-size-medium'>The link you requested isn't valid. It seems you may have taken a wrong turn. </span>
            <br />
            <br />
            <Button type='button' href='/' className='font-weight-bold'>
              Go Home
            </Button>
          </div>
        </Container>
      );
    }
  }
}

// use this to get reducer state into components, gets the state."auth" from combineRootReducer.js
// and put it into this auth. if "auth" from that file is changed to another word, then
// this state."auth" also needs to change. To get data, use this.props.users.whatever
const mapStateToProps = (state) => ({
  users: state.users,
  site: state.site,
});
export default connect(mapStateToProps, { resetPassword, verifyPasswordToken, clearErrors })(PassResetPage);
