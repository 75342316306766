import React, { Component } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { MdAccountCircle, MdRefresh } from 'react-icons/md';
import { changeUserKeyValue, updateUser, getUser } from '../../../redux/actions/userActions';
import { emailTokenLink, getPublicEnv } from '../../../redux/actions/siteActions';
import { clearErrors, clearResponse } from '../../../redux/actions/siteActions';
// import isEmpty from './../../../../functions/isEmpt';
import ModalDepot from '../components/ModalDepot';

class AccountProfile extends Component {
  constructor(props) {
    super();
    this.state = {
      isSubmitDisabled: false,
      isUpdateProfileModalShow: false,
      isCodeCopyModalShow: false,
      isSendDisable: false,
    };
    this.onUpdateUser = this.onUpdateUser.bind(this);
    this.openModal = this.openModal.bind(this);
  }
  componentDidMount() {
    this.props.getUser(this.props.users.token.user_id);
    this.props.getPublicEnv();
  }
  openModal(modalName, modalState, modalData) {
    switch (modalName) {
      case 'PROFILE_UPDATE':
        modalState ? this.setState({ isUpdateProfileModalShow: true }) : this.setState({ isUpdateProfileModalShow: false });
        break;
      default:
    }
  }
  onChangeUserKeyValue(id, key, value) {
    const data = {
      id,
      key,
      value,
    };
    this.props.changeUserKeyValue(data);
    if (this.props.users.user.first_name && this.props.users.user.last_name && this.props.users.user.phone) {
      this.setState({ isSubmitDisabled: false });
    } else {
      this.setState({ isSubmitDisabled: true });
    }
  }
  onUpdateUser(e) {
    e.preventDefault();
    const data = {
      firstName: this.props.users.user.first_name,
      lastName: this.props.users.user.last_name,
      phone: this.props.users.user.phone,
      userID: this.props.users.user.id,
      emailAddress: this.props.users.user.email,
      accountID: this.props.users.user.account_id,
      roleID: this.props.users.user.role_id,
      businessID: this.props.users.user.business_id,
      businessName: this.props.users.token.business_name,
      subcriptionID: this.props.users.token.subscription_id,
      priceID: this.props.users.token.price_id,
    };
    this.props.updateUser(data, this);
  }
  onEmailTokenLink(e) {
    e.preventDefault();
    this.setState({ isSendDisable: true });
    const data = {
      email: this.props.users.token.email,
      tokenType: 'password',
    };
    this.props.emailTokenLink(data, this);
  }

  render() {
    const { user } = this.props.users;
    const { errors, response } = this.props.site;
    return (
      <>
        <div className='height-100vh-minus-header'>
          <Form onSubmit={this.onUpdateUser}>
            <div className='display-flex flex-direction-row align-items-center padding'>
              <MdAccountCircle size='100px' className='color-gray' />
              <div>
                <div className='display-flex margin-left-15'>
                  <div className='margin-right-15 font-size-medium'>
                    First Name
                    <Form.Control
                      type='text'
                      name='firstName'
                      value={user.first_name ? user.first_name : ''}
                      onChange={(e) => this.onChangeUserKeyValue(user.id, 'first_name', e.target.value)}
                      className='margin-top-15'
                    />
                  </div>
                  <div className='font-size-medium'>
                    Last Name
                    <Form.Control
                      type='text'
                      name='lastName'
                      value={user.last_name ? user.last_name : ''}
                      onChange={(e) => this.onChangeUserKeyValue(user.id, 'last_name', e.target.value)}
                      className='margin-top-15'
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr></hr>
            <div className='display-flex'>
              <div className='flex-1 padding font-size-medium'>
                Phone <br />
                <Form.Control
                  type='number'
                  name='phone'
                  value={user.phone ? user.phone : ''}
                  onChange={(e) => this.onChangeUserKeyValue(user.id, 'phone', e.target.value)}
                  className='margin-top-15 margin-bottom-15'
                />
                Email <br />
                <Form.Control type='text' name='email' placeholder='Email' value={user.email ? user.email : ''} className='margin-top-15' disabled />
                <div className='margin-top-15'>
                  <Button type='submit' disabled={this.state.isSubmitDisabled ? true : false}>
                    Save
                  </Button>
                </div>
              </div>
              <div className='flex-1 padding'>
                <span className='font-size-medium'>Change Password</span>
                <div className='margin-top-15 margin-bottom-15'>
                  To change your password, simply click the button below and we will send you an email with instructions on how to change your
                  password.
                </div>
                <Alert
                  variant={errors.message ? 'warning' : 'success'}
                  show={errors.message || response.message ? true : false}
                  onClose={() => {
                    if (errors.message) {
                      this.props.clearErrors();
                    } else {
                      this.props.clearResponse();
                    }
                    this.setState({ isSendDisable: false });
                  }}
                  dismissible
                >
                  {errors.message ? errors.message : response.message ? response.message : ''}
                </Alert>
                <Button
                  variant='secondary'
                  onClick={(e) => this.onEmailTokenLink(e)}
                  className='btn btn-primary'
                  disabled={this.state.isSendDisable ? true : false}
                >
                  {this.state.isSendDisable ? <MdRefresh size={30} className='processing' /> : ''}
                  Send Reset Instructions
                </Button>
                <br />
                <br />
                {/* <div className='margin-top-15 font-weight-bold'>Share Your Referral Link With Your Friends</div>
                <div className='margin-top-15 margin-bottom-15'>
                  <b>Get a $30 credit*</b> everytime someone signs up using your referral code and becomes a paying member. Post your referral link on
                  social media or text it to your friends.
                </div>
                <div className='display-flex display-flex flex-direction-row flex-wrap-nowrap  align-items-center'>
                  <div className='margin-top-15 margin-bottom-15'>Your referral code is:</div>
                  <div className='font-weight-bold padding'>{user.refer_code}</div>
                  <Clipboard
                    data-clipboard-text={user.refer_code}
                    className='margin-15 border-radius-5'
                    onSuccess={() => {
                      alert('Refer code copied. Paste it using control+V OR command+V on your keyboard.');
                    }}
                  >
                    <MdContentCopy size='25' color='#ccc' /> Copy
                  </Clipboard>
                </div>
                <div className='margin-bottom-15 font-weight-bold'></div>
                <div>Your referral link is:</div>
                <div className='display-flex display-flex flex-direction-row flex-wrap-nowrap align-items-center'>
                  <div className='font-weight-bold'>
                    {!isEmpty(env) ? (
                      <span className='color-blue'>
                        {env.clientUrl}/started/signup?code={user.refer_code}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  <Clipboard
                    data-clipboard-text={env.clientUrl + '/started/signup?code=' + user.refer_code}
                    className='margin-15 border-radius-5'
                    onSuccess={() => {
                      alert('Refer link copied. Paste it using control+V OR command+V on your keyboard.');
                    }}
                  >
                    <MdContentCopy size='25' color='#ccc' /> Copy
                  </Clipboard>
                </div>
                <br />
                <div className='margin-top-15 margin-bottom-15 color-gray'>*It may take up to 180 days for credits to show up on your account</div> */}
              </div>
            </div>
          </Form>
        </div>
        <ModalDepot state={this.state} function={{ openModal: this.openModal }} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  site: state.site,
});
export default connect(mapStateToProps, { getPublicEnv, changeUserKeyValue, updateUser, emailTokenLink, clearErrors, clearResponse, getUser })(
  AccountProfile
);
