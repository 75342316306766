import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Accordion, Button, Card } from 'react-bootstrap';
import { MdQuestionAnswer, MdHelp, MdDehaze } from 'react-icons/md';
import queryString from 'query-string';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      eventKey: props.parentProps ? queryString.parse(props.parentProps.history.location.search).eventkey : 0,
    };
  }
  render() {
    return (
      <>
        <div className='display-flex flex-direction-column width-100'>
          <div className='padding'>
            <h1>Need Help?</h1>
            You can find solutions here.
          </div>
          <>
            <div>
              <div className='padding'>
                <span className='font-weight-bold'>
                  <MdQuestionAnswer size='40px' color='#3681ff' /> Frequently Ask Topics and Tutorials
                </span>
              </div>
              <div>
                <Accordion defaultActiveKey={this.state.eventKey}>
                  <div>
                    <Accordion.Toggle as={Card.Header} variant='primary' eventKey='1' className='cursor-pointer color-blue'>
                      Check-In
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='1' className='padding'>
                      <>
                        <div className='margin-bottom-15'>
                          <div className='font-weight-bold margin-bottom-15'>How do I start checking in my customers?</div>
                          On the side menu, click Check-In App. Click launch to start the app. If you don't see the side menu, click the top left
                          button that looks like this:{' '}
                          <Button variant='secondary'>
                            <MdDehaze size={15} />{' '}
                          </Button>
                        </div>
                        <div className='margin-bottom-15'>
                          <div className='font-weight-bold margin-bottom-15'>What kind of device do I need?</div>
                          We recommend using an iPad (10 inch) or Samsung Galaxy Tab (10 inch), but you can use any device you like including laptop
                          or desktop.
                        </div>
                        <div className='margin-bottom-15'>
                          <div className='font-weight-bold margin-bottom-15'>Can I purchase a device already setup?</div>
                          Yes, we can set it up for you and send you one. Just contact support@onespace.link or text us at (713) 742-2971.
                        </div>
                        <div className='margin-bottom-15'>
                          <div className='font-weight-bold margin-bottom-15'>How do I add a custom form to my check-in?</div>
                          On the side menu, click Check-In App. Click customize. Choose the pdf form you want to upload. Click upload. Once uploaded,
                          select the form. Click Save changes to apply the form to your check-in.
                        </div>
                        <div className='margin-bottom-15'>
                          <div className='font-weight-bold margin-bottom-15'>How do I view recent check-ins?</div>
                          On the side menu, click View Check-Ins. You can see a list of check-ins daily, for the month, or year.
                        </div>
                      </>
                    </Accordion.Collapse>
                  </div>
                  <div>
                    <Accordion.Toggle as={Card.Header} variant='secondary' eventKey='2' className='cursor-pointer color-blue'>
                      Booking &amp; Appointments
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='2' className='padding'>
                      <>
                        <div className='margin-bottom-15'>
                          <div className='font-weight-bold margin-bottom-15'>How do I book a customer?</div>
                          On the side menu, click Appointments. Click the button + New Appointment.
                        </div>
                        <div className='margin-bottom-15'>
                          <div className='font-weight-bold margin-bottom-15'>How do I setup business hours for booking?</div>
                          On the side menu, click Business Page. Click Update Info. Click Add Hours.
                        </div>
                        <div className='margin-bottom-15'>
                          <div className='font-weight-bold margin-bottom-15'>How do I update an appointment?</div>
                          On the side menu, click Appointments. Navigate to the date which the appointment is on. Click on the appointment event.
                        </div>
                        <div className='margin-bottom-15'>
                          <div className='font-weight-bold margin-bottom-15'>How do I change the time per booking?</div>
                          On the side menu, click Appointments. Click the button Settings. There you can change the time per slot.
                        </div>
                        <div className='margin-bottom-15'>
                          <div className='font-weight-bold margin-bottom-15'>How do I allow more than one booking per spot?</div>
                          On the side menu, click Appointments. Click the button Settings. Change booking per slot to the amount of time that spot can
                          be booked before it becomes unavailable.
                        </div>
                        <div className='margin-bottom-15'>
                          <div className='font-weight-bold margin-bottom-15'>Can my customer book an appointment on their own?</div>
                          Yes, direct them to <a href='/search'>onespacebooking.com</a> where they can search for your business or direct them to your
                          business page by providing them with your vanity url.
                        </div>
                        <div className='margin-bottom-15'>
                          <div className='font-weight-bold margin-bottom-15'>How do I register a customer?</div>
                          On the side menu, click Customers tab. Click the button New Customer. Fill out the form and register the customer.
                        </div>
                      </>
                    </Accordion.Collapse>
                  </div>
                  <div>
                    <Accordion.Toggle as={Card.Header} variant='secondary' eventKey='3' className='cursor-pointer color-blue'>
                      Business Page &amp; Marketing
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='3' className='padding'>
                      <>
                        <div className='margin-bottom-15'>
                          <div className='font-weight-bold margin-bottom-15'>What's a business page?</div>
                          It is a built-in feature for your businesses to increase brand awareness and generate sales. To build your business page,
                          simply click on the Business Page tab on the side menu.
                        </div>
                        <div className='margin-bottom-15'>
                          <div className='font-weight-bold margin-bottom-15'>How can customers find my business page?</div>
                          There are several ways:
                          <br />
                          <br />
                          <ol>
                            <li>Through Google and other search engine.</li>
                            <li>
                              When they search for your business on <a href='/search'>onespacebooking.com</a>
                            </li>
                            <li>Provide them your direct vanity url</li>
                            <li>If you have a domain name, redirect your domain name to your business page</li>
                          </ol>
                        </div>
                        <div className='margin-bottom-15'>
                          <div className='font-weight-bold margin-bottom-15'>How do I send customers birthday wish?</div>
                          On the side menu, click Customers tab. Click set customer birthday wish. Once you set the message, our system will
                          automatically send this message to your customers on their birthday.
                        </div>
                      </>
                    </Accordion.Collapse>
                  </div>
                </Accordion>
              </div>
              <div className='padding'>
                <span className='font-weight-bold'>
                  <MdHelp size='40px' color='#3681ff' /> Didn't find what you're looking for?
                </span>
                <div className='padding'>Text us your questions and feedback to ‪(713) 742-2971.</div>
              </div>
            </div>
          </>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps)(AppComponent);
