import React, { Component } from 'react';
import { Alert, Form, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import CreatableSelect from 'react-select/creatable';

import { updateProductCategory } from '../../../redux/actions/businessActions';
import { clearErrors } from './../../../redux/actions/siteActions';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'PRODUCT_CATEGORY_UPDATE_FORM',
      selectedProductCategory: props.props.state.selectedProductCategory,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }
  onSubmit = (e) => {
    e.preventDefault();
    const data = {
      category: this.state.selectedProductCategory,
      businessID: this.props.users.token.business_id,
    };
    this.props.updateProductCategory(data, this);
  };

  render() {
    const { errors } = this.props.site;
    let positionOptions = [];
    Array.from(new Array(50), (e, i) => {
      positionOptions.push({ id: i, value: i, label: i });
      return positionOptions;
    });
    return (
      <>
        <div className='display-flex align-items-center justify-content-center'>
          <Form noValidate onSubmit={(e) => this.onSubmit(e)} className='width-100'>
            <div className='display-flex'>
              <div className='display-flex align-items-center'>
                <div className='flex-2 margin-right-10 margin-top-3'>
                  <TextField
                    label='Name'
                    type='text'
                    name='name'
                    margin='normal'
                    variant='outlined'
                    onChange={(e) =>
                      this.setState({
                        selectedProductCategory: {
                          ...this.state.selectedProductCategory,
                          id: this.state.selectedProductCategory.id,
                          value: e.target.value,
                          label: e.target.value,
                        },
                      })
                    }
                    value={this.state.selectedProductCategory.value}
                    inputProps={{ style: { fontSize: 17, padding: '10px' } }} // font size of input text
                    InputLabelProps={{ style: { fontSize: 17 } }} // font size of input label
                    className='display-flex'
                  />
                </div>
                <div className='flex-1 color-gray'>
                  Rank
                  <CreatableSelect
                    value={this.state.selectedProductCategory.position}
                    onChange={(e) => {
                      this.setState({ selectedProductCategory: { ...this.state.selectedProductCategory, position: e } });
                    }}
                    options={positionOptions}
                    placeholder='Select or Enter'
                  />
                </div>
              </div>
              <div className='display-flex'>
                <TextField
                  label='Subheading'
                  multiline
                  rows='2'
                  name='subheading'
                  margin='normal'
                  variant='outlined'
                  value={this.state.selectedProductCategory.subheading ? this.state.selectedProductCategory.subheading : ''}
                  onChange={(e) =>
                    this.setState({
                      selectedProductCategory: { ...this.state.selectedProductCategory, subheading: e.target.value },
                    })
                  }
                  inputProps={{ style: { fontSize: 17 } }} // font size of input text
                  InputLabelProps={{ style: { fontSize: 17 } }} // font size of input label
                  className='display-flex'
                />
                <Alert variant='warning' show={errors.errorUpdateProductCategory ? true : false} onClose={() => this.props.clearErrors()} dismissible>
                  {errors.errorUpdateProductCategory}
                </Alert>
                <div className='display-flex flex-direction-row flex-wrap-nowrap justify-content-space-between'>
                  <Button type='submit' className='width-100'>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  business: state.business,
  users: state.users,
  site: state.site,
});

export default connect(mapStateToProps, { updateProductCategory, clearErrors })(AppComponent);
