module.exports = function compareTime(earlier_date, later_date, option) {
  earlier_date = new Date(earlier_date);
  later_date = new Date(later_date);
  var diff_in_millis = later_date - earlier_date; // milliseconds
  var total_difference;

  if (option === 'day') {
    total_difference = Math.round(diff_in_millis / (1000 * 60 * 60 * 24)); // days
  }
  if (option === 'hour') {
    total_difference = Math.round(diff_in_millis / 3600000); // hours
  }
  if (option === 'minute') {
    total_difference = Math.round(diff_in_millis / 60000); // minutes
  }

  return total_difference;
};
