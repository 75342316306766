import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MdPrint } from 'react-icons/md';
import { getPublicEnv } from './../../../redux/actions/siteActions';
import { getCheckinApp, authenticateCheckinApp } from './../../../redux/actions/businessActions';
import { Button, Form } from 'react-bootstrap';
import { QRCode } from 'react-qrcode-logo';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      QRCodeSize: 200,
    };
  }
  componentDidMount() {
    if (localStorage.getItem('localStorageCheckinAppPrivateToken')) {
      const privateToken = localStorage.getItem('localStorageCheckinAppPrivateToken');
      this.props.authenticateCheckinApp(privateToken, this);
      this.props.getPublicEnv();
    }
  }
  render() {
    const { checkinapp } = this.props.business;
    if (checkinapp.public_token) {
      return (
        <>
          Your customers can check in via their mobile phone by scanning the QR code below.
          <br />
          <br />
          <div>Print the QR code as many times as you like and place it in a convenient spot for contactless check-in.</div>
          <div className='display-flex flex-direction-column align-items-center justify-content-center margin-top-15'>
            <div className='padding-5'>
              <div className='padding-10 font-size-larger bg-black color-white border-radius-top-25px'>
                <center>Check In Here</center>
              </div>
              <div>
                <div className='width-auto padding-10 bg-white'>
                  <center>
                    <QRCode
                      value={checkinapp.url_mobile}
                      qrStyle='dots'
                      size={this.state.QRCodeSize}
                      eyeRadius={[
                        [10, 0, 10, 10], // top left
                        [10, 0, 10, 10], // top right
                        [10, 0, 10, 10], // bottom left
                      ]}
                      // ecLevel={'Q'}
                      logoImage={checkinapp.media ? checkinapp.media.preview : ''}
                      logoWidth={this.state.QRCodeSize / 2.5}
                      logoHeight={checkinapp.media ? ((this.state.QRCodeSize / 2.5) * checkinapp.media.height) / checkinapp.media.width : ''}
                    />
                  </center>
                </div>
                <div className='shape-triangle-container'>
                  <div className='shape-triangle-middle-top'></div>
                </div>
              </div>
              <div className='padding-10 font-size-larger bg-black border-radius-bottom-25px color-white'>
                <center>Scan Me</center>
              </div>
            </div>
            <center>
              <div className='display-flex align-items-center margin-top-15 margin-bottom-15'>
                <Form.Control
                  as='select'
                  name='QRCodeSize'
                  onChange={(e) => {
                    this.setState({ QRCodeSize: e.target.value });
                  }}
                  value={this.state.QRCodeSize}
                  className='width-auto'
                >
                  <option value='100'>Quarter Page QR Code</option>
                  <option value='200'>Half Page QR Code</option>
                  <option value='300'>Full Page QR Code</option>
                </Form.Control>{' '}
              </div>
              <Button
                onClick={() => {
                  localStorage.setItem('checkinQRprintsize', this.state.QRCodeSize / 50);
                  window.open('/checkinQRcodePrint');
                }}
                className='display-flex width-100'
              >
                <MdPrint size={25} className='color-white' /> Print
              </Button>
            </center>
          </div>
        </>
      );
    } else {
      return (
        <>
          <center>
            <div className='display-flex flex-direction-column align-items-center justify-content-center height-100vh'>
              <div className='font-size-largest margin-bottom-50'>
                QR CODE PAGE RESTART REQUIRED.
                <br />
                <br />
                PLEASE CANCEL PRINT AND REFRESH THIS PAGE UNTIL YOU SEE THE QR CODE APPEAR ON YOUR PRINT PAGE.
              </div>
            </div>
          </center>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  business: state.business,
  site: state.site,
});
export default connect(mapStateToProps, { getCheckinApp, getPublicEnv, authenticateCheckinApp })(AppComponent);
