import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import CookieConsent from 'react-cookie-consent';
import { MdBusiness, MdContentCut, MdRestaurant } from 'react-icons/md';
import logo from './../../assets/images/logo.png';
import qrcode_checkin from './../../assets/images/qrcode_checkin.jpg';
import booking_feature from './../../assets/images/booking_feature.jpg';
import marketing_feature from './../../assets/images/marketing_feature.jpg';
import ModalDepot from './components/ModalDepot';

class Home extends Component {
  constructor() {
    super();
    this.state = {
      componentID: 'HOME',
      email: '',
      password: '',
      isTermsModalShow: false,
      isPolicyModalShow: false,
      isServicesOvewviewModalShow: false,
      isAboutPageModalShow: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  componentDidMount() {
    // if (localStorage.getItem('localStorageUserEmail')) {
    //   this.setState({ email: localStorage.getItem('localStorageUserEmail') });
    // }
  }
  openModal(modalName, modalState, modalData) {
    switch (modalName) {
      case 'SERVICES_OVERVIEW_WINDOW':
        modalState ? this.setState({ isServicesOvewviewModalShow: true }) : this.setState({ isServicesOvewviewModalShow: false });
        break;
      case 'ABOUT_PAGE':
        modalState ? this.setState({ isAboutPageModalShow: true }) : this.setState({ isAboutPageModalShow: false });
        break;
      case 'TERMS_COMPONENT':
        modalState ? this.setState({ isTermsModalShow: true }) : this.setState({ isTermsModalShow: false });
        break;
      case 'POLICY_COMPONENT':
        modalState ? this.setState({ isPolicyModalShow: true }) : this.setState({ isPolicyModalShow: false });
        break;

      default:
    }
  }
  render() {
    return (
      <>
        <div className='GLOBAL display-flex flex-direction-column width-100'>
          <div className='bg-darkgray'>
            <div className='display-flex width-max-980 margin-auto padding-10'>
              <div className='HEADER display-flex align-items-center justify-content-space-between'>
                <div>
                  <span>
                    <img src={logo} className='logo-static' alt='logo' style={{ height: '30px', filter: 'brightness(0%) invert()' }} />
                  </span>
                </div>
                <div>
                  <a href='#!' onClick={() => (window.location.href = '/signup')} className='color-white'>
                    SIGN UP
                  </a>
                  <Button variant='primary' onClick={() => (window.location.href = '/login')} style={{ color: '#fff', marginLeft: '10px' }}>
                    LOGIN
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className='BODY display-flex flex-1'>
            <div className='display-flex flex-direction-column'>
              <div className='display-flex width-max-980 margin-auto padding'>
                <div className='OSL-SERVICES-BANNER display-flex align-items-center justify-content-space-between '>
                  <div className='font-size-medium-large'>OneSpace.Link Services</div>
                  <div>
                    <Button style={{ borderRadius: '10px' }} onClick={() => (window.location.href = '/account/subscriptions')}>
                      Try it free
                    </Button>
                  </div>
                </div>
              </div>
              <div className='bg-whitesmoke'>
                <div className='display-flex width-max-980 margin-auto padding'>
                  <div className='OSL-SERVICES-BANNER display-flex flex-wrap-nowrap align-items-center justify-content-center'>
                    <div className='margin-right-10'>
                      Introducing OSL Business Pages. Let your customers discover your business and see what you have to offer.
                    </div>
                    <div>
                      <a href='#pages' onClick={() => this.openModal('SERVICES_OVERVIEW_WINDOW', true)}>
                        Learn more &#8250;
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex-1 display-flex flex-direction-column justify-content-center'>
                <div className='text-align-center padding margin-top-50 margin-bottom-50'>
                  <div style={{ fontSize: '5vmax', fontWeight: 'bold' }}>
                    Build better
                    <br />
                    customer relationships.
                  </div>
                  <div className='text-align-center margin-top-25 font-size-medium-large color-gray'>
                    From start to end. Let us help your business during and beyond the COVID/pandemic period.
                  </div>
                </div>
                <div className='display-flex flex-1 width-max-980 margin-auto'>
                  <div className='display-flex align-items-flex-start'>
                    <div className='display-flex align-items-center mobile-justify-content'>
                      <div className='border-radius-25 box-shadow-light margin-10 card-content'>
                        <a href='#checkins' onClick={() => this.openModal('SERVICES_OVERVIEW_WINDOW', true)}>
                          <div className='display-flex flex-direction-column'>
                            <div className='padding'>
                              <div className='color-gray font-size-small font-weight-bold'>QUICK. SECURE. CONTACTLESS.</div>
                              <div className='font-size-medium-large margin-top-10 margin-bottom-10'>Check-In Services</div>
                              <div>Check-in your customers on any device. Yours or theirs.</div>
                            </div>
                            <div className='display-block width-100'>
                              <img src={qrcode_checkin} alt='qr code' className='border-radius-25 card-img-full' />
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className='border-radius-25 box-shadow-light margin-10 card-content' style={{ background: '#45c5e8' }}>
                        <a href='#booking' onClick={() => this.openModal('SERVICES_OVERVIEW_WINDOW', true)}>
                          <div className='display-flex flex-direction-column'>
                            <div className='padding'>
                              <div className='color-gray font-size-small font-weight-bold color-white'>SIMPLE AND ORGANIZE.</div>
                              <div className='font-size-medium-large margin-top-10 margin-bottom-10'>Booking Services</div>
                              <div>Your customers can book online or in-person.</div>
                            </div>
                            <div>
                              <img src={booking_feature} alt='booking' className='border-radius-25' />
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className='border-radius-25 box-shadow-light margin-10 card-content' style={{ background: '#f7f7f7' }}>
                        <a href='#marketing' onClick={() => this.openModal('SERVICES_OVERVIEW_WINDOW', true)}>
                          <div className='display-flex flex-direction-column'>
                            <div className='padding'>
                              <div className='color-gray font-size-small font-weight-bold'>AFFORDABLE AND POWERFUL.</div>
                              <div className='font-size-medium-large margin-top-10 margin-bottom-10'>Marketing Services</div>
                              <div>Reach out to customers through fast and effective medium. Improve your ROI.</div>
                            </div>
                            <div>
                              <img src={marketing_feature} alt='marketing' className='border-radius-25' />
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='display-flex'>
                  <div className='bg-whitesmoke display-flex flex-direction-column align-items-center justify-content-center margin-top-50'>
                    <div style={{ fontSize: '3vmax', fontWeight: 'bold' }} className='margin-top-50'>
                      Start your free trial?
                    </div>
                    <div className=' margin-top-15 margin-bottom-50'>No commitment. Cancel anytime.</div>
                    <div className='flex-table'>
                      <div className='flex-table-row'>
                        <div className='flex-table-cell padding'></div>
                        <div className=' flex-table-cell text-align-center padding-5'>
                          <div>Basic</div>
                          <div className='margin-bottom-15'>$9.99 /mo</div>
                          <div>
                            <Button style={{ borderRadius: '10px' }} onClick={() => (window.location.href = '/account/subscription')}>
                              <span>Try it free</span>
                            </Button>
                          </div>
                        </div>
                        <div className='flex-table-cell text-align-center padding-5'>
                          <div>Standard</div>
                          <div className='margin-bottom-15'>$19.99 /mo</div>
                          <div>
                            <Button style={{ borderRadius: '10px' }} onClick={() => (window.location.href = '/account/subscription')}>
                              <span>Try it free</span>
                            </Button>
                          </div>
                        </div>
                        <div className='flex-table-cell text-align-center padding-5'>
                          <div>Plus</div>
                          <div className='margin-bottom-15'>$27.99 /mo</div>
                          <div>
                            <Button style={{ borderRadius: '10px' }} onClick={() => (window.location.href = '/account/subscription')}>
                              <span>Try it free</span>
                            </Button>
                          </div>
                        </div>
                        <div className='flex-table-cell text-align-center padding-5'>
                          <div>Business</div>
                          <div className='margin-bottom-15'>$99.99 /mo</div>
                          <div>
                            <Button style={{ borderRadius: '10px' }} onClick={() => (window.location.href = '/account/subscription')}>
                              <span>Try it free</span>
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className='flex-table-row border-bottom-gray'>
                        <div className='flex-table-cell padding font-weight-bold'>Check-in Application (Ad Supported)</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                        <div className='flex-table-cell text-align-center padding'>-</div>
                        <div className='flex-table-cell text-align-center padding'>-</div>
                        <div className='flex-table-cell text-align-center padding'>-</div>
                      </div>
                      <div className='flex-table-row border-bottom-gray'>
                        <div className='flex-table-cell padding'>Forms Management</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                      </div>
                      <div className='flex-table-row border-bottom-gray'>
                        <div className='flex-table-cell padding'>Text/Chat Support</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                      </div>
                      <div className='flex-table-row border-bottom-gray'>
                        <div className='flex-table-cell padding font-weight-bold'>Check-in Application (No Ads)</div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                      </div>
                      <div className='flex-table-row border-bottom-gray'>
                        <div className='flex-table-cell padding'>1,000 Text / Unlimited Email</div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                      </div>
                      <div className='flex-table-row border-bottom-gray'>
                        <div className='flex-table-cell padding'>Staff Management</div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                      </div>
                      <div className='flex-table-row border-bottom-gray'>
                        <div className='flex-table-cell padding'>Customer Management</div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                      </div>
                      <div className='flex-table-row border-bottom-gray'>
                        <div className='flex-table-cell padding'>Menu/Service Listing</div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                      </div>
                      <div className='flex-table-row border-bottom-gray'>
                        <div className='flex-table-cell padding'>Business Page</div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                      </div>
                      <div className='flex-table-row border-bottom-gray'>
                        <div className='flex-table-cell padding'>Staff Management</div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                      </div>
                      <div className='flex-table-row border-bottom-gray'>
                        <div className='flex-table-cell padding font-weight-bold'>Booking (Online &amp; In-Person)</div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                      </div>
                      <div className='flex-table-row border-bottom-gray'>
                        <div className='flex-table-cell padding'>1,500 Text / Unlimited Email</div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                      </div>
                      <div className='flex-table-row border-bottom-gray'>
                        <div className='flex-table-cell padding'>Reports &amp; Stats</div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                      </div>
                      <div className='flex-table-row border-bottom-gray'>
                        <div className='flex-table-cell padding font-weight-bold'>Multimedia Marketing Services</div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                      </div>
                      <div className='flex-table-row border-bottom-gray'>
                        <div className='flex-table-cell padding'>5,000 Text/Unlimited Email</div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                      </div>
                      <div className='flex-table-row border-bottom-gray'>
                        <div className='flex-table-cell padding'>Check-in Customer Feedback</div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                      </div>
                      <div className='flex-table-row border-bottom-gray'>
                        <div className='flex-table-cell padding'>Phone Support</div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'></div>
                        <div className='flex-table-cell text-align-center padding'>&#10003;</div>
                      </div>
                    </div>
                    <div className='display-flex align-items-center justify-content-center margin-top-50 padding margin-bottom-50'>
                      <div className='width-max-980 margin-auto'>
                        <div className='display-flex flex-direction-column align-items-center justify-content-center padding-50 border-radius-25 bg-white'>
                          <img src={logo} alt='logo' style={{ height: '100px' }} className='margin-top-15 margin-bottom-15' />
                          <div style={{ fontSize: '2vmax', fontWeight: 'bold' }}>OSL Services</div>
                          <div style={{ fontSize: '1.25vmax' }} className='text-align-center margin-top-15'>
                            Design and made
                            <br />
                            for restaurants, salons, and organizations
                          </div>
                          <div className='margin-top-15 margin-bottom-15'>
                            <Button variant='secondary' style={{ margin: '20px' }}>
                              <MdRestaurant size={50} />
                            </Button>
                            <Button variant='secondary' style={{ margin: '20px' }}>
                              <MdContentCut size={50} />
                            </Button>
                            <Button variant='secondary' style={{ margin: '20px' }}>
                              <MdBusiness size={50} />
                            </Button>
                          </div>
                          <div className='margin-top-10 margin-bottom-15'>
                            <Button style={{ borderRadius: '10px' }} onClick={() => (window.location.href = '/account/subscription')}>
                              Get Started
                            </Button>
                          </div>
                          <div>
                            <a href='#!' onClick={() => this.openModal('SERVICES_OVERVIEW_WINDOW', true)}>
                              Learn More &#8250;
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='FOOTER display-flex margin-bottom-25'>
            <div className='display-flex flex-1 width-max-980 margin-auto'>
              <div className='flex-table display-flex justify-content-space-between'>
                <div className='flex-table-row display-flex justify-content-space-between'>
                  <div className='flex-table-cell padding-25'>
                    <div className='footer-links'>
                      <div className='font-weight-bold'>Account</div>
                      <div>
                        <a href='/login'>Login</a>
                      </div>
                    </div>
                  </div>
                  <div className='flex-table-cell padding-25'>
                    <div className='footer-links'>
                      <div className='font-weight-bold'>Products &amp; Services</div>
                      <div>
                        <a href='#checkins' onClick={() => this.openModal('SERVICES_OVERVIEW_WINDOW', true)}>
                          Check-ins
                        </a>
                      </div>
                      <div>
                        <a href='#booking' onClick={() => this.openModal('SERVICES_OVERVIEW_WINDOW', true)}>
                          Booking
                        </a>
                      </div>
                      <div>
                        <a href='#marketing' onClick={() => this.openModal('SERVICES_OVERVIEW_WINDOW', true)}>
                          Marketing
                        </a>
                      </div>
                      <div>
                        <a href='#pages' onClick={() => this.openModal('SERVICES_OVERVIEW_WINDOW', true)}>
                          Business Pages
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='flex-table-cell padding-25'>
                    <div className='footer-links'>
                      <div className='font-weight-bold'>Values</div>
                      <div>
                        <a href='#pages' onClick={() => this.openModal('TERMS_COMPONENT', true)}>
                          Terms
                        </a>
                      </div>
                      <div>
                        <a href='#pages' onClick={() => this.openModal('POLICY_COMPONENT', true)}>
                          Privacy
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='flex-table-cell padding-25'>
                    <div className='footer-links'>
                      <div className='font-weight-bold'>About</div>
                      <div>
                        <a href='#mission' onClick={() => this.openModal('ABOUT_PAGE', true)}>
                          Core and Mission
                        </a>
                      </div>
                      <div>
                        <a href='#newsroom' onClick={() => this.openModal('ABOUT_PAGE', true)}>
                          Newsroom
                        </a>
                      </div>
                      <div>
                        <a href='#ethics' onClick={() => this.openModal('ABOUT_PAGE', true)}>
                          Ethics
                        </a>
                      </div>
                      <div>
                        <a href='#contact' onClick={() => this.openModal('ABOUT_PAGE', true)}>
                          Contact
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='hr-text'>OneSpace.Link</div>
              <div className='padding-10'>
                <div className='display-flex align-items-center justify-content-space-between margin-bottom-15'>
                  <div>Copyright &#169; {new Date().getFullYear()} One Space Link. All rights reservered</div>
                  <div>United States</div>
                </div>
                <div className='margin-bottom-50'>
                  For questions, support, or feedback, text us at 1-713-742-2971. Alternatively, you can email us at support@onespace.link{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalDepot state={this.state} props={this.props} function={{ openModal: this.openModal }} />
        <CookieConsent
          location='bottom'
          buttonText='Dismiss'
          cookieName='cookie'
          style={{ background: '#f8f8f8', color: '#000', paddingLeft: '20px', opacity: '80%' }}
          buttonStyle={{ background: '#019fe8', color: '#fff', fontSize: '15px', borderRadius: '5px', padding: '10px 25px ' }}
          expires={365}
          acceptOnScroll
          acceptOnScrollPercentage={75}
        >
          We use cookies to ensure you get the best experience on our site. For more information, see our <a href='/policy'>privacy policy</a>{' '}
          published on this site.
        </CookieConsent>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  users: state.users,
  business: state.business,
});
export default connect(mapStateToProps)(Home);

// export default Home;
