import React, { Component } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import queryString from 'query-string';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { MdDelete, MdAddCircle, MdLibraryBooks } from 'react-icons/md';
import { getForms, deleteForms } from './../../../redux/actions/businessActions';
import { getStripeSubscriptionProducts } from './../../../redux/actions/subscriptionActions';
import ModalDepot from './../components/ModalDepot';
import { clearErrors } from '../../../redux/actions/siteActions';

class ComponentTags extends Component {
  constructor(props) {
    super();
    this.state = {
      actionParam: props.parentProps ? queryString.parse(props.parentProps.history.location.search).action : '',
      currentPage: 1,
      currentPageSize: 25,
      isAddFormModalShow: false,
      isUpdateFormModalShow: false,
      isDeleteFormModalShow: false,
      isViewSubscriptionPlanModalShow: false,
      selectedFormID: '',
      selected: [],
    };
    this.openModal = this.openModal.bind(this);
    this.onCRUDModal = this.onCRUDModal.bind(this);
  }
  componentDidMount() {
    this.props.getStripeSubscriptionProducts();
    this.props.getForms(this.props.users.token.business_id);
    if (this.state.actionParam === 'add-form') {
      this.openModal('FORM_ADD', true);
    }
  }
  openModal(modalName, modalState, modalData) {
    switch (modalName) {
      case 'FORM_ADD':
        modalState ? this.setState({ isAddFormModalShow: true }) : this.setState({ isAddFormModalShow: false });
        break;
      case 'FORM_UPDATE':
        if (modalState) {
          this.setState({ isUpdateFormModalShow: true });
        } else {
          this.setState({ isUpdateFormModalShow: false });
          this.setState({ selectedFormID: '' });
          this.props.clearErrors();
        }
        break;
      case 'FORM_DELETE':
        modalState ? this.setState({ isDeleteFormModalShow: true }) : this.setState({ isDeleteFormModalShow: false });
        break;
      case 'SUBSCRIPTION_PLANS':
        modalState ? this.setState({ isViewSubscriptionPlanModalShow: true }) : this.setState({ isViewSubscriptionPlanModalShow: false });
        break;
      default:
    }
  }
  onCRUDModal = (modalName, modalState, modalData) => {
    this.openModal(modalName, true);
  };
  onDeleteForms = (e) => {
    e.preventDefault();
    const data = {
      formIDArray: this.state.selectedFormID ? [this.state.selectedFormID] : this.state.selected,
    };
    this.props.deleteForms(data, this);
    // bug: currentPage doesn't go down when deleting the last item on a paginated page
    // this fix the bug that causes error when deleting the last item on that particular page
    if (this.state.currentPage > Math.ceil((this.props.business.forms.length - 1) / this.state.currentPageSize) && this.state.currentPage !== 1) {
      this.setState({ currentPage: this.state.currentPage - 1 });
    }
  };
  render() {
    const { forms } = this.props.business;
    const { SearchBar } = Search;
    const columns = [
      {
        dataField: 'name',
        text: 'Name',
        align: 'left',
        headerAlign: 'left',
        formatter: (cell, row) => {
          return (
            <div className='padding'>
              <div className='margin-bottom-5 color-black'>{row.name}</div>
            </div>
          );
        },
      },
      {
        dataField: 'signature_required',
        text: 'Signature Required',
        headerAlign: 'right',
        align: 'right',
        formatter: (cell, row) => {
          // return <div className='padding'>{row.signature_required ? 'Yes' : 'No'}</div>;
        },
      },
    ];
    const paginationOptions = {
      onPageChange: (page, sizePerPage) => {
        this.setState({ currentPage: page });
        this.setState({ currentPageSize: sizePerPage });
      },
      page: this.state.currentPage,
      sizePerPage: this.state.currentPageSize,
      hideSizePerPage: true,
    };
    const selectRowProp = {
      mode: 'checkbox',
      selected: this.state.selected,
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          this.setState(() => ({
            selected: [...this.state.selected, row.id],
          }));
        } else {
          this.setState(() => ({
            selected: this.state.selected.filter((x) => x !== row.id),
          }));
        }
      },
      onSelectAll: (isSelect, rows, e) => {
        const ids = rows.map((r) => r.id);
        if (isSelect) {
          this.setState({ selected: ids });
        } else {
          this.setState({ selected: [] });
        }
      },
      hideSelectColumn: true,
    };
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({ selectedFormID: row.id });
        this.openModal('FORM_UPDATE', true);
      },
    };
    const NoDataIndication = (props) => {
      if (props.searchProps.searchText.length > 0) {
        return (
          <div className='padding color-black'>
            Your search - <span className='font-weight-bold'>{props.searchProps.searchText}</span> - did not match any data.
          </div>
        );
      } else {
        return (
          <div className='padding display-flex justify-content-center align-items-center'>
            <center>
              <MdLibraryBooks size='200px' />
              <div className='font-size-large font-weight-bold margin-bottom-5'>No Forms Available</div>
              <br />
              <div className='margin-bottom-15'>You can add your own waiver/consent forms for customer check-in and appointments.</div>
            </center>
          </div>
        );
      }
    };
    const DataTable = () => {
      return (
        <div>
          <ToolkitProvider keyField='id' data={forms} columns={columns} search>
            {(toolkitprops) => {
              return (
                <div>
                  <div className='display-flex flex-direction-column justify-content-center align-items-center padding'>
                    <div className='display-flex flex-direction-row flex-wrap-nowrap margin-bottom-15'>
                      {/* <MdShoppingBasket size='50px' color='#8b4ee8' /> */}
                      <Button variant='secondary' onClick={() => this.onCRUDModal('FORM_ADD')}>
                        <MdAddCircle size='25px' className='color-deepskyblue' /> New Waiver/Consent Form
                      </Button>
                    </div>
                    <div className='width-100' style={{ minWidth: '200px' }}>
                      <SearchBar placeholder='Search' tableId='Search' {...toolkitprops.searchProps} />
                    </div>
                  </div>
                  <div>
                    <div className={this.state.selected.length ? 'padding color-white' : 'display-none'}>
                      <OverlayTrigger placement='top' overlay={<Tooltip>Delete</Tooltip>}>
                        <button className='border-radius-5 padding-5 margin-3' onClick={(e) => this.onCRUDModal('FORM_DELETE')}>
                          <MdDelete size='25px' />
                        </button>
                      </OverlayTrigger>
                    </div>
                    <BootstrapTable
                      headerClasses={forms.length > 0 ? '' : 'display-none'}
                      {...toolkitprops.baseProps}
                      pagination={paginationFactory(paginationOptions)}
                      noDataIndication={<NoDataIndication data={forms} searchProps={toolkitprops.searchProps} />}
                      selectRow={selectRowProp}
                      rowEvents={rowEvents}
                    />
                  </div>
                </div>
              );
            }}
          </ToolkitProvider>
        </div>
      );
    };
    return (
      <>
        <DataTable /> <ModalDepot state={this.state} props={this.props} function={{ openModal: this.openModal, onDeleteForms: this.onDeleteForms }} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  subscriptions: state.subscriptions,
  business: state.business,
  users: state.users,
  site: state.site,
});

export default connect(mapStateToProps, { getForms, getStripeSubscriptionProducts, deleteForms, clearErrors })(ComponentTags);
