import React, { Component } from 'react';
import { Col, Button, Form } from 'react-bootstrap';
import { MdSave } from 'react-icons/md';
import { connect } from 'react-redux';
import { getCustomer, updateCustomer, changeCustomerKeyValue, deleteCustomers } from '../../../redux/actions/businessActions';
import ModalDepot from './../components/ModalDepot';

class CustomerUpdateForm extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'CUSTOMER_UPDATE_FORM',
      customerID: props.business.customer.id,
      customer: {},
      isSubmitDisabled: false,
      isDeleteCustomerModalShow: false,
    };
    props.getCustomer(this.state.customerID, this);
    this.onUpdateCustomer = this.onUpdateCustomer.bind(this);
  }
  openModal = (modalName, modalState, modalData) => {
    switch (modalName) {
      case 'CUSTOMER_DELETE':
        modalState ? this.setState({ isDeleteCustomerModalShow: true }) : this.setState({ isDeleteCustomerModalShow: false });
        break;
      default:
    }
  };
  setCustomer(customer) {
    this.setState({ customer });
  }
  onChangeCustomerKeyValue(id, key, value) {
    const data = {
      id,
      key,
      value,
    };
    this.props.changeCustomerKeyValue(data);
    // if (this.props.business.customer.email !== '') {
    //   this.setState({ isSubmitDisabled: false });
    // } else {
    //   this.setState({ isSubmitDisabled: true });
    // }
  }
  onUpdateCustomer = (e) => {
    e.preventDefault();
    // const standardProductPrices = this.props.props.props.subscriptions.products[1].prices;
    // const plusProductPrices = this.props.props.props.subscriptions.products[0].prices;

    // const allowablePriceIDs = [];
    // standardProductPrices.map((price) => {
    //   return allowablePriceIDs.push(price.id);
    // });
    // plusProductPrices.map((price) => {
    //   return allowablePriceIDs.push(price.id);
    // });
    // if (allowablePriceIDs.includes(this.props.users.token.price_id)) {
    const data = {
      customerID: this.state.customerID,
      firstName: this.props.business.customer.first_name,
      lastName: this.props.business.customer.last_name,
      email: this.props.business.customer.email,
      phone: this.props.business.customer.phone,
      month: this.props.business.customer.month,
      day: this.props.business.customer.day,
      offerDisabled: this.props.business.customer.offer_disabled,
      birthdayDisabled: this.props.business.customer.birthday_disabled,
    };
    this.props.updateCustomer(data, this);
    // } else {
    //   this.props.openModal('SUBSCRIPTION_PLANS', true);
    // }
  };
  onDeleteCustomers = (e) => {
    e.preventDefault();
    // const standardProductPrices = this.props.props.props.subscriptions.products[1].prices;
    // const plusProductPrices = this.props.props.props.subscriptions.products[0].prices;

    // const allowablePriceIDs = [];
    // standardProductPrices.map((price) => {
    //   return allowablePriceIDs.push(price.id);
    // });
    // plusProductPrices.map((price) => {
    //   return allowablePriceIDs.push(price.id);
    // });
    // if (allowablePriceIDs.includes(this.props.users.token.price_id)) {
    const data = {
      customerIDArray: this.state.customerID ? [this.state.customerID] : this.props.props.state.selected,
    };
    this.props.deleteCustomers(data, this);
    // bug: currentPage doesn't go down when deleting the last item on a paginated page
    // this fix the bug that causes error when deleting the last item on that particular page
    if (this.state.currentPage > Math.ceil((this.props.business.customers.length - 1) / this.state.currentPageSize) && this.state.currentPage !== 1) {
      this.setState({ currentPage: this.state.currentPage - 1 });
    }
    // } else {
    //   this.props.function.openModal('SUBSCRIPTION_PLANS', true);
    // }
  };
  render() {
    const { customer } = this.props.business;
    const Birthday = () => {
      var daysArray = [];
      for (let i = 1; i <= 31; i++) {
        daysArray.push(
          <option key={i} value={i}>
            {i}
          </option>
        );
      }
      return daysArray;
    };
    return (
      <>
        <div>
          <div>
            <div>
              <div className='margin-bottom-15'>
                <div className='justify-contents-space-between'>
                  <Form.Row>
                    {/* <Form.Control as='select' name='title' onChange={this.onChange} value={this.state.title} className='margin-bottom-15 width-100'>
                    <option value=''>Title</option>
                    <option value='Mr.'>Mr.</option>
                    <option value='Ms.'>Mrs.</option>
                    <option value='Mrs.'>Mrs.</option>
                    <option value='Dr.'>Dr.</option>
                  </Form.Control> */}
                  </Form.Row>
                  <Form.Row>
                    <Form.Control
                      type='text'
                      name='first_name'
                      placeholder='First Name*'
                      value={customer.first_name ? customer.first_name : ''}
                      onChange={(e) => {
                        this.onChangeCustomerKeyValue(customer.id, 'first_name', e.target.value);
                      }}
                      className='margin-bottom-15 width-100'
                      disabled={true}
                    />
                  </Form.Row>
                  <Form.Row>
                    <Form.Control
                      type='text'
                      name='last_name'
                      placeholder='Last Name*'
                      value={customer.last_name ? customer.last_name : ''}
                      onChange={(e) => {
                        this.onChangeCustomerKeyValue(customer.id, 'last_name', e.target.value);
                      }}
                      className='margin-bottom-15'
                      disabled={true}
                    />
                  </Form.Row>
                  <Form.Row>
                    <Form.Control
                      type='number'
                      name='phone'
                      placeholder='Phone (10-digit)*'
                      value={customer.phone ? customer.phone : ''}
                      onChange={(e) => {
                        this.onChangeCustomerKeyValue(customer.id, 'phone', e.target.value);
                      }}
                      className='margin-bottom-15'
                      disabled={true}
                    />
                  </Form.Row>
                  <Form.Row>
                    <Form.Control
                      type='text'
                      name='email'
                      placeholder='Email'
                      value={customer.email ? customer.email : ''}
                      onChange={(e) => {
                        this.onChangeCustomerKeyValue(customer.id, 'email', e.target.value);
                      }}
                      className='margin-bottom-15'
                      // disabled
                    />{' '}
                  </Form.Row>
                  <div className='margin-bottom-15'>
                    <center>Date of Birth*</center>
                  </div>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Control
                        as='select'
                        name='month'
                        value={customer.month ? customer.month : ''}
                        onChange={(e) => {
                          this.onChangeCustomerKeyValue(customer.id, 'month', e.target.value);
                        }}
                        disabled
                      >
                        <option value=''>Month</option>
                        <option value='01'>Jan</option>
                        <option value='02'>Feb</option>
                        <option value='03'>Mar</option>
                        <option value='04'>Apr</option>
                        <option value='05'>May</option>
                        <option value='06'>Jun</option>
                        <option value='07'>Jul</option>
                        <option value='08'>Aug</option>
                        <option value='09'>Sep</option>
                        <option value='10'>Oct</option>
                        <option value='11'>Nov</option>
                        <option value='12'>Dec</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Control
                        as='select'
                        name='day'
                        value={customer.day ? customer.day : ''}
                        onChange={(e) => {
                          this.onChangeCustomerKeyValue(customer.id, 'day', e.target.value);
                        }}
                        disabled
                      >
                        <option value=''>Day</option>
                        <Birthday />
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                  {/* <div className='display-flex flex-wrap-nowrap align-items-center margin-bottom-15'>
                    <input
                      type='checkbox'
                      name='offerDisabled'
                      onChange={async (e) => {
                        if (e.target.value === 'true') {
                          this.onChangeCustomerKeyValue(customer.id, 'offer_disabled', 0);
                        } else {
                          this.onChangeCustomerKeyValue(customer.id, 'offer_disabled', 1);
                        }
                      }}
                      value={customer.offer_disabled === 1 ? true : false}
                      checked={customer.offer_disabled === 1 ? true : false}
                      className='largerCheckbox margin-right-15'
                    />{' '}
                    Stop sending special offer for this customer
                  </div> */}
                  {/* <div className='display-flex flex-wrap-nowrap align-items-center margin-bottom-15'>
                    <input
                      type='checkbox'
                      name='birthdayDisabled'
                      onChange={(e) => {
                        if (e.target.value === 'true') {
                          this.onChangeCustomerKeyValue(customer.id, 'birthday_disabled', 0);
                        } else {
                          this.onChangeCustomerKeyValue(customer.id, 'birthday_disabled', 1);
                        }
                      }}
                      value={customer.birthday_disabled ? true : false}
                      checked={customer.birthday_disabled ? true : false}
                      className='largerCheckbox margin-right-15'
                    />{' '}
                    Stop sending birthday wish for this customer
                  </div>
                  <br />
                  <br /> */}
                  <div className='display-flex flex-direction-row flex-wrap-nowrap justify-content-space-between'>
                    {/* <Alert variant='warning' show={errors.errorNewCustomer ? true : false} onClose={() => this.props.clearErrors()} dismissible>
                          {errors.errorNewCustomer}
                        </Alert> */}
                    {/* <Button variant='secondary' onClick={(e) => this.openModal('CUSTOMER_DELETE', true)}>
                      <MdDeleteForever size={25} className='color-tomato' /> Delete
                    </Button> */}
                    <Button variant='secondary' onClick={(e) => this.onUpdateCustomer(e)} disabled={this.state.isSubmitDisabled ? true : false}>
                      <MdSave size={25} className='color-deepskyblue' /> Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalDepot
          state={this.state}
          function={{
            openModal: this.openModal,
            onDeleteCustomers: this.onDeleteCustomers,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  business: state.business,
  users: state.users,
  site: state.site,
});
export default connect(mapStateToProps, { changeCustomerKeyValue, getCustomer, updateCustomer, deleteCustomers })(CustomerUpdateForm);
