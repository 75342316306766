import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { MdQueryBuilder, MdAdd } from 'react-icons/md';
import { IoMdSettings } from 'react-icons/io';
import queryString from 'query-string';
import dateFormat from 'dateformat';
import socketIOClient from 'socket.io-client';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGridPlugin from '@fullcalendar/timegrid';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimeGridFourDay from '@fullcalendar/resource-timegrid';
import resourceDayGridDay from '@fullcalendar/resource-daygrid';

import { getAppointments, deleteAppointments, getHours, getBusiness, getStaffs } from '../../../redux/actions/businessActions';
import { getStripeSubscriptionProducts } from '../../../redux/actions/subscriptionActions';
import { clearErrors } from '../../../redux/actions/siteActions';
import ModalDepot from '../components/ModalDepot';

class Calendar extends Component {
  calendarResourceRef = React.createRef();
  calendarMiniGridRef = React.createRef();
  calendarListRef = React.createRef();
  calendarHeaderRef = React.createRef();
  constructor(props) {
    super();
    this.state = {
      componentID: 'CALENDAR',
      actionParam: props.parentProps ? queryString.parse(props.parentProps.history.location.search).action : '',
      isAddAppointmentModalShow: false,
      isUpdateAppointmentModalShow: false,
      isHoursFormModalShow: false,
      isViewSubscriptionPlanModalShow: false,
      isCalendarSettingsModalShow: false,
      selected: [],
      selectedDateTime: { start: new Date().setHours(9, 0, 0), end: new Date().setHours(9, 30, 0) },
      selectedAppointmentID: props.parentProps ? queryString.parse(props.parentProps.history.location.search).ai : '',
      urlBid: props.parentProps ? queryString.parse(props.parentProps.history.location.search).bi : '',
      selectedStaff: {},
      selectedViewDayWeek: 'day',
      endpoint:
        process.env.REACT_APP_PRODUCTION_ENV === '1' ? process.env.REACT_APP_PRODUCTION_LOCALSERVER : process.env.REACT_APP_DEVELOPMENT_LOCALSERVER,
    };
    this.openModal = this.openModal.bind(this);
    this.onAddAppointment = this.onAddAppointment.bind(this);
    this.handleDateClick = this.handleDateClick.bind(this);
  }
  componentDidMount() {
    this.props.getStripeSubscriptionProducts();
    this.props.getAppointments(this.props.users.token.business_id);
    this.props.getHours(this.props.users.token.business_id);
    this.props.getBusiness(this.props.users.token.business_id, this);
    this.props.getStaffs(this.props.users.token.business_id);

    if (this.state.actionParam === 'add-appointment') {
      this.openModal('APPOINTMENT_ADD_FORM', true);
    }

    if (this.state.selectedAppointmentID && this.state.urlBid) {
      // open appointment, check for bid in url, then match it with user's business ID - security purpose
      if (Number(this.state.urlBid) === Number(this.props.users.token.business_id)) {
        this.openModal('APPOINTMENT_UPDATE_FORM', true);
      }
    }

    const socket = socketIOClient(this.state.endpoint);
    socket.on('SOCKET_UPDATE_BOOKINGS', () => {
      console.log('BOOKING CLIENT SOCKET HAS UPDATED');
      this.props.getAppointments(this.props.users.token.business_id);
    });

    // collapse the sidebar on calendar load
    // this.props.function.toggleSideCollapse(true);
  }
  openModal = (modalName, modalState, modalData) => {
    switch (modalName) {
      case 'CALENDAR_SETTINGS_FORM':
        modalState ? this.setState({ isCalendarSettingsModalShow: true }) : this.setState({ isCalendarSettingsModalShow: false });
        break;
      case 'HOURS_FORM':
        if (modalState) {
          this.setState({ isHoursFormModalShow: true });
        } else {
          this.setState({ isHoursFormModalShow: false });
        }
        break;
      case 'APPOINTMENT_ADD_FORM':
        if (modalState) {
          this.setState({ isAddAppointmentModalShow: true });
        } else {
          this.setState({ isAddAppointmentModalShow: false });
          this.setState({ selectedStaff: {} });
          this.props.clearErrors();
        }
        break;
      case 'APPOINTMENT_UPDATE_FORM':
        if (modalState) {
          this.setState({ isUpdateAppointmentModalShow: true });
        } else {
          this.setState({ isUpdateAppointmentModalShow: false });
          this.setState({ selectedDate: '' });
          this.props.clearErrors();
        }
        break;
      case 'SUBSCRIPTION_PLANS':
        if (modalState) {
          this.setState({ isViewSubscriptionPlanModalShow: true });
          // if (window.confirm('Please choose a plan that allows for booking and scheduling to continue.')) {
          // }
        } else {
          this.setState({ isViewSubscriptionPlanModalShow: false });
        }
        break;
      default:
    }
  };
  onAddAppointment = (e) => {
    this.openModal('APPOINTMENT_ADD_FORM', true);
  };
  handleSelect = (e) => {
    // only allow booking for current and future dates
    // const selectedDate = new Date(new Date(e.start).toLocaleDateString()).getTime();
    // const currentDate = new Date(new Date().toLocaleDateString()).getTime();
    // only allow booking from current date
    // if (selectedDate >= currentDate) {
    this.setState({ selectedDateTime: e });
    // auto fill staff when add appointments
    if (e.resource) {
      const selectedStaff = {
        id: e.resource.id,
        ...e.resource.extendedProps,
      };
      this.setState({ selectedStaff });
    }
    this.onAddAppointment();
    // } else {
    //   // alert(`You've selected a time from a past date. Booking for previous date is disabled by default`);
    // }
  };
  handleEventClick = (e) => {
    this.setState({ selectedAppointmentID: e.event.id });
    this.openModal('APPOINTMENT_UPDATE_FORM', true);
  };
  handleDateClick = async (e) => {
    // console.log('DATE CLICKED', e.date);
    if (e.resource) {
      if (e.jsEvent.target.classList.contains('fc-non-business')) {
        if (e.resource._resource.id === 'null') {
          if (window.confirm(`${dateFormat(e.date, 'HH:MM TT, dddd')} is a time outside of your normal business hours. Continue booking anyway?`)) {
            const addMinutes = this.props.business.business.booking_interval ? this.props.business.business.booking_interval : 30;
            const endDate = new Date(new Date(e.date).setMinutes(e.date.getMinutes() + addMinutes));
            var selectedDateTime = {
              start: e.date,
              end: endDate,
            };
            this.setState({ selectedDateTime });
            this.onAddAppointment();
          }
        } else {
          if (
            window.confirm(
              `${dateFormat(e.date, 'HH:MM TT, dddd')} is outside of ${
                e.resource._resource.extendedProps.first_name
              }'s working hours. Continue booking anyway?`
            )
          ) {
            const addMinutes = this.props.business.business.booking_interval ? this.props.business.business.booking_interval : 30;
            const endDate = new Date(new Date(e.date).setMinutes(e.date.getMinutes() + addMinutes));
            var staffSelectedDateTime = {
              start: e.date,
              end: endDate,
            };
            this.setState({ selectedDateTime: staffSelectedDateTime });
            var selectedStaff = {
              id: e.resource._resource.id,
              first_name: e.resource._resource.extendedProps.first_name,
              last_name: e.resource._resource.extendedProps.last_name,
            };
            this.setState({ selectedStaff });
            this.onAddAppointment();
          }
        }
      }
    }
    // change mini calendar to selected date
    let miniCalendar = this.calendarMiniGridRef.current.getApi();
    let resourceCalendar = this.calendarResourceRef.current !== null ? this.calendarResourceRef.current.getApi() : null;
    let eventList = this.calendarListRef.current.getApi();
    let headerCalendar = this.calendarHeaderRef.current.getApi();

    if (e.event) {
      // when an event in mini calendar day is clicked, it trigger changes to resource calendar and highlights the selected cell in the mini calendar
      await eventList.gotoDate(e.event.start);
      await miniCalendar.gotoDate(e.event.start);
      this.setState({
        selectedDateTime: { start: new Date(e.event.start).setHours(9, 0, 0), end: new Date(e.event.start).setHours(9, 30, 0) },
      });
      let selectedResourceView = this.state.selectedViewDayWeek === 'day' ? 'resourceTimeGridDay' : 'resourceTimeGridWeek';
      await resourceCalendar.changeView(selectedResourceView, e.event.start);
      this.highlightDate(e.event.start);
    } else {
      // when any date is clicked
      await eventList.gotoDate(e.date);
      await miniCalendar.gotoDate(e.date);
      await headerCalendar.gotoDate(e.date);
      let selectedResourceView = this.state.selectedViewDayWeek === 'day' ? 'resourceTimeGridDay' : 'resourceTimeGridWeek';
      if (resourceCalendar) {
        await resourceCalendar.changeView(selectedResourceView, e.date);
      }
      this.setState({
        selectedDateTime: {
          start: new Date(e.date).setHours(9, 0, 0),
          end: new Date(e.date).setHours(9, this.props.business.business.booking_interval, 0),
        },
      });
      this.highlightDate(e.date);
    }
    // change resource to date
  };
  handlePrevMonthClick = async (e) => {
    let miniCalendar = this.calendarMiniGridRef.current.getApi();
    await miniCalendar.prev();
    this.highlightDate(this.state.selectedDateTime.start);
  };
  handleNextMonthClick = async (e) => {
    let miniCalendar = this.calendarMiniGridRef.current.getApi();
    await miniCalendar.next();
    this.highlightDate(this.state.selectedDateTime.start);
  };
  handlePrevDayClick = async (e) => {
    let headerCalendar = this.calendarHeaderRef.current.getApi();
    let miniCalendar = this.calendarMiniGridRef.current.getApi();
    let eventList = this.calendarListRef.current.getApi();
    let resourceGridCalendar = this.calendarResourceRef.current.getApi();

    resourceGridCalendar.prev(); // this must come first before getting the date
    let previousDate = resourceGridCalendar.getDate();
    await headerCalendar.gotoDate(previousDate);
    await miniCalendar.gotoDate(previousDate); // await for the minicaledar to load before highlighting
    await eventList.gotoDate(previousDate);
    await this.setState({ selectedDateTime: { start: previousDate, end: previousDate } });
    this.highlightDate(previousDate);
  };
  handleNextDayClick = async (e) => {
    let headerCalendar = this.calendarHeaderRef.current.getApi();
    let miniCalendar = this.calendarMiniGridRef.current.getApi();
    let eventList = this.calendarListRef.current.getApi();
    let resourceGridCalendar = this.calendarResourceRef.current.getApi();

    resourceGridCalendar.next(); // this must come first before getting the date
    let nextDate = resourceGridCalendar.getDate();
    await headerCalendar.gotoDate(nextDate);
    await miniCalendar.gotoDate(nextDate);
    await eventList.gotoDate(nextDate);
    await this.setState({ selectedDateTime: { start: nextDate, end: nextDate } }); // await for state to load before highlighting
    this.highlightDate(nextDate);
  };
  handleTodayClick = async (e) => {
    let headerCalendar = this.calendarHeaderRef.current.getApi();
    let miniCalendar = this.calendarMiniGridRef.current.getApi();
    let listCalendar = this.calendarListRef.current.getApi();
    let resourceCalendar = this.calendarResourceRef.current.getApi();

    headerCalendar.today();
    miniCalendar.today();
    resourceCalendar.today();

    await this.setState({
      selectedDateTime: {
        start: new Date(miniCalendar.getDate()).setHours(9, 0, 0),
        end: new Date().setHours(9, this.props.business.business.booking_interval, 0),
      },
    });
    let selectedListView = this.state.selectedViewDayWeek === 'day' ? 'listDay' : 'listWeek';
    listCalendar.changeView(selectedListView, this.state.selectedDateTime.start);
  };
  handleDayClick = async () => {
    await this.setState({ selectedViewDayWeek: 'day' });
    let resourceGridCalendar = this.calendarResourceRef.current.getApi();
    resourceGridCalendar.changeView('resourceTimeGridDay', this.state.selectedDateTime.start);
    let calendarList = this.calendarListRef.current.getApi();
    calendarList.changeView('listDay', this.state.selectedDateTime.start);
    this.highlightDate(this.state.selectedDateTime.start);
  };
  handleWeekClick = async () => {
    await this.setState({ selectedViewDayWeek: 'week' });
    let resourceGridCalendar = this.calendarResourceRef.current.getApi();
    resourceGridCalendar.changeView('resourceTimeGridWeek', this.state.selectedDateTime.start);
    let calendarList = this.calendarListRef.current.getApi();

    calendarList.changeView('listWeek', this.state.selectedDateTime.start);
    this.highlightDate(this.state.selectedDateTime.start);
  };
  highlightDate = async (date) => {
    var selectedDate = new Date(this.state.selectedDateTime.start);
    let year = selectedDate.getFullYear();
    let month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
    let day = selectedDate.getDate().toString().padStart(2, '0');
    let selectedDateString = year + '-' + month + '-' + day;

    var dayDate = new Date(date);
    let dayYear = dayDate.getFullYear();
    let dayMonth = (dayDate.getMonth() + 1).toString().padStart(2, '0');
    let dayDay = dayDate.getDate().toString().padStart(2, '0');
    let dayDateString = dayYear + '-' + dayMonth + '-' + dayDay;

    if (selectedDateString === dayDateString) {
      // remove all highlighted class
      var days = await document.querySelectorAll('.selectedDate');
      days.forEach(async (day) => {
        await day.classList.remove('selectedDate');
      });
      var weekRows = await document.querySelectorAll('.week-highlight');
      weekRows.forEach(async (weekRow) => {
        await weekRow.classList.remove('week-highlight');
      });
      var selectedDateCell = await document.querySelector('.fc-daygrid-day[data-date="' + dayDateString + '"]');
      if (selectedDateCell) {
        if (this.state.selectedViewDayWeek === 'day') {
          await selectedDateCell.classList.add('selectedDate');
        } else {
          selectedDateCell.parentNode.className = 'week-highlight';
        }
      }
    }
    // reset and update the css for non business hours
    // $('div.fc-daygrid-day-top').css('color', '#000'); // reset to default
    // if ($('div.fc-non-business').parent().parent().siblings()) {
    //   $('div.fc-non-business').parent().parent().siblings().css('color', '#ccc'); // update
    // }
  };
  dayRender = async (e) => {
    if (e.isToday) {
      this.highlightDate(e.date);
    }
  };
  getSlotTime = (business_hours) => {
    var minTime = '9:00';
    var maxTime = '17:00';

    // used to be map()
    business_hours.forEach((hour) => {
      let startHour = Math.min.apply(
        Math,
        business_hours.map((hour) => {
          return hour.start;
        })
      );
      let endHour = Math.max.apply(
        Math,
        business_hours.map((hour) => {
          return hour.end;
        })
      );

      if (Number(hour.start) === Number(startHour)) {
        minTime = hour.startTime;
      }
      if (Number(hour.end) === Number(endHour)) {
        maxTime = hour.endTime;
      }
    });
    return { minTime, maxTime };
  };
  render() {
    var { appointments, business_hours, business, staffs } = this.props.business;
    // add Anyone to the list of staff, the last name leave blank to move it to the beginning of that resource calendar slots
    // It's order by the staff's last name
    const defaultStaff = {
      id: null, // must be null
      first_name: 'Anyone',
      last_name: '',
      businessHours: business_hours, // set the default business hours for this staff
    };
    staffs = [defaultStaff, ...staffs];
    staffs.map((staff) => {
      staff.title = staff.first_name + ' ' + staff.last_name;
      // if staff doesn't have business hours, then it will take on the account's business hours
      if (staff.businessHours.length <= 0) {
        staff.businessHours = business_hours;
      }
      return staff;
    });
    var slotDuration = '00:30:00';
    if (business.booking_interval) {
      slotDuration = '00:' + business.booking_interval + ':00';
    }
    const slotTime = this.getSlotTime(business_hours);
    return (
      <>
        <div className='display-flex flex-direction-row'>
          <div className='display-flex justify-content-center align-items-center padding'>
            <div className='fc-schedule-header display-flex justify-content-center align-items-center'>
              <FullCalendar
                ref={this.calendarHeaderRef}
                plugins={[resourceTimeGridPlugin, interactionPlugin]}
                initialView='resourceTimeGridDay'
                headerToolbar={{
                  center: 'resourceTimeGridDay,resourceTimeGridWeek today',
                  left: 'settings hours',
                  right: 'book',
                }}
                customButtons={{
                  settings: {
                    text: <IoMdSettings size={20} />,
                    click: (e) => {
                      this.openModal('CALENDAR_SETTINGS_FORM', true);
                    },
                  },
                  hours: {
                    text: (
                      <div className='display-flex align-items-center'>
                        <MdQueryBuilder size={20} /> Hours
                      </div>
                    ),
                    click: (e) => {
                      this.openModal('HOURS_FORM', true);
                    },
                  },
                  resourceTimeGridDay: {
                    text: 'Day',
                    click: (e, cell, more) => {
                      cell.parentNode.childNodes[1].classList.remove('fc-button-active');
                      cell.classList.add('fc-button-active');
                      this.handleDayClick();
                    },
                  },
                  resourceTimeGridWeek: {
                    text: 'Week',
                    click: async (e, cell, more) => {
                      cell.parentNode.childNodes[0].classList.remove('fc-button-active');
                      cell.classList.add('fc-button-active');
                      this.handleWeekClick();
                    },
                  },
                  today: {
                    text: 'Today',
                    click: (e) => {
                      this.handleTodayClick(e);
                    },
                  },
                  book: {
                    text: (
                      <div className='display-flex align-items-center'>
                        <MdAdd size={20} /> New
                      </div>
                    ),
                    click: (e) => {
                      // set the correct ending time for selectedDateTime based on booking_interval
                      this.setState({
                        selectedDateTime: {
                          start: this.state.selectedDateTime.start,
                          end: new Date(this.state.selectedDateTime.start).setHours(9, this.props.business.business.booking_interval, 0),
                        },
                      });
                      this.openModal('APPOINTMENT_ADD_FORM', true);
                    },
                  },
                }}
              />
              <div className='display-flex flex-direction-row justify-content-center padding-top-15'>
                <div className='margin-right-5'>
                  <span style={{ color: '#cccccc' }}>●</span> Past
                </div>
                <div className='margin-right-5'>
                  <span style={{ color: '#bcefff' }}>●</span> Confirmed
                </div>
                <div className='margin-right-5'>
                  <span style={{ color: '#ffda73' }}>●</span> Canceled
                </div>
                <div className='margin-right-5'>
                  <span style={{ color: '#ffa6a6' }}>●</span> No Show
                </div>
                <div className='margin-right-5'>
                  <span style={{ color: '#ff8a00' }}>●</span> Request
                </div>
              </div>
            </div>
          </div>
          <div className='calendar-sidebar fc-calendar-container padding-left-15 padding-right-15'>
            <div className='fc-schedule-calendar'>
              <FullCalendar
                ref={this.calendarMiniGridRef}
                plugins={[dayGridPlugin, interactionPlugin]}
                height='auto'
                contentHeight='auto'
                headerToolbar={{
                  center: 'prev,title,next',
                  left: false,
                  right: false,
                }}
                customButtons={{
                  prev: {
                    text: 'Prev',
                    click: (e) => {
                      this.handlePrevMonthClick(e);
                    },
                  },
                  next: {
                    text: 'Next',
                    click: (e) => {
                      this.handleNextMonthClick(e);
                    },
                  },
                }}
                //data
                businessHours={business_hours}
                events={appointments}
                //constraints
                // selectConstraint='businessHours'
                fixedWeekCount={true}
                showNonCurrentDates={true}
                eventTimeFormat={{
                  hour: 'numeric',
                  minute: '2-digit',
                  meridiem: 'short',
                }}
                dayMaxEvents={1}
                // selectable={true}
                //actions
                // select={this.handleSelect}
                dateClick={(e) => {
                  this.handleDateClick(e);
                }}
                eventClick={(e) => this.handleDateClick(e)}
                dayCellClassNames={(e) => this.dayRender(e)}
              />
            </div>
            <div className='fc-schedule-list'>
              <FullCalendar
                ref={this.calendarListRef}
                plugins={[listPlugin]}
                initialView='listDay'
                height='auto'
                contentHeight='auto'
                headerToolbar={{
                  start: false,
                  center: false,
                  end: false,
                }}
                eventClick={this.handleEventClick}
                listDayFormat={{ weekday: 'short' }}
                listDaySideFormat={{ month: 'long', day: 'numeric' }}
                events={appointments}
              />
            </div>
          </div>
          <div className='flex-1 display-flex padding-left-15 padding-right-15'>
            <div className='fc-schedule-resource'>
              {business_hours.length > 0 ? (
                <>
                  <FullCalendar
                    ref={this.calendarResourceRef}
                    schedulerLicenseKey='0450166259-fcs-1589059539'
                    plugins={[interactionPlugin, resourceTimeGridPlugin, resourceDayGridDay, resourceTimeGridFourDay, scrollGridPlugin]}
                    resourceOrder='last_name'
                    height='auto'
                    // contentHeight='auto'
                    // stickyHeaderDates='auto'
                    dayMinWidth={'100'}
                    // initialView='resourceTimeGridDay'
                    initialView={this.state.selectedViewDayWeek === 'day' ? 'resourceTimeGrid' : 'resourceTimeLine'}
                    headerToolbar={{
                      center: 'prev,title,next',
                      left: false,
                      // center: false,
                      right: false,
                    }}
                    customButtons={{
                      prev: {
                        text: 'Prev',
                        click: (e) => {
                          this.handlePrevDayClick(e);
                        },
                      },
                      next: {
                        text: 'Next',
                        click: (e) => {
                          this.handleNextDayClick(e);
                        },
                      },
                    }}
                    // buttonText={{ today: 'Today', month: 'Month', week: 'Week', day: 'Day', list: 'List' }}
                    views={{
                      resourceTimeGridDay: { titleFormat: { weekday: 'short', month: 'long', day: 'numeric' } },
                    }}
                    //slots
                    allDaySlot={false}
                    slotDuration={slotDuration}
                    slotLabelInterval={business.booking_interval} // displays the time in weekview
                    slotLabelFormat={{
                      hour: 'numeric',
                      minute: '2-digit',
                      omitZeroMinute: true,
                      meridiem: 'short',
                    }}
                    // //actions
                    select={this.handleSelect}
                    // //data
                    events={appointments}
                    // timeZone='local'
                    // resource
                    resources={staffs}
                    businessHours={business_hours}
                    // //constraints
                    selectConstraint='businessHours'
                    slotMinTime={slotTime.minTime}
                    slotMaxTime={slotTime.maxTime}
                    // //settings
                    selectable={true}
                    selectLongPressDelay={100}
                    eventLongPressDelay={100}
                    fixedWeekCount={false}
                    showNonCurrentDates={false}
                    nowIndicator={true}
                    eventTimeFormat={{
                      hour: 'numeric',
                      minute: '2-digit',
                      meridiem: 'short',
                    }}
                    //actions
                    dateClick={this.handleDateClick}
                    eventClick={this.handleEventClick}
                    // dayCellClassNames={(e) => this.dayRender(e)}
                  />
                </>
              ) : (
                <>
                  <div className='padding width-100'>
                    <center>
                      <div className='padding'>Add your business hours to make time slots available</div>
                      <br />
                      <Button variant='secondary' onClick={() => this.openModal('HOURS_FORM', true)}>
                        <MdQueryBuilder size={25} color='#019fe8' /> Add Business Hours
                      </Button>
                    </center>
                  </div>
                </>
              )}
            </div>
          </div>
          <ModalDepot state={this.state} props={this.props} function={{ openModal: this.openModal, handleDateClick: this.handleDateClick }} />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  subscriptions: state.subscriptions,
  business: state.business,
  users: state.users,
  site: state.site,
});
export default connect(mapStateToProps, {
  getAppointments,
  deleteAppointments,
  getHours,
  getStripeSubscriptionProducts,
  clearErrors,
  getBusiness,
  getStaffs,
})(Calendar);
