import React, { Component } from 'react';
import { connect } from 'react-redux';
import PageProductsComponent from './PageProductsComponent';

class AppComponent extends Component {
  render() {
    return (
      <>
        <PageProductsComponent state={this.state} props={this.props} token={this.props.users.token} />
      </>
    );
  }
}
const mapStateToProps = (state) => ({ users: state.users });
export default connect(mapStateToProps)(AppComponent);
