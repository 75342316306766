import Dashboard from './pages/navigation/Dashboard';
import CheckInApp from './pages/navigation/CheckInApp';
import CheckIns from './pages/navigation/CheckIns';
import Customers from './pages/navigation/Customers';
import Profile from './pages/navigation/Profile';
import BusinessPage from './pages/navigation/BusinessPage';
import Staff from './pages/navigation/Staff';
import Products from './pages/navigation/Products';
import Calendar from './pages/navigation/Calendar';
import Subscription from './pages/navigation/Subscription';
import Support from './pages/navigation/Support';
import Forms from './pages/navigation/Forms';
import Marketing from './pages/navigation/Marketing';
import Logout from './pages/navigation/Logout';

const pageList = [
  {
    name: 'Dashboard',
    path: '/account/dashboard',
    component: Dashboard,
  },
  {
    name: ' Application',
    path: '/account/checkinapp',
    component: CheckInApp,
  },
  {
    name: 'Check-Ins & Waitlist',
    path: '/account/checkins',
    component: CheckIns,
  },
  {
    name: 'Bookings',
    path: '/account/bookings',
    component: Calendar,
  },
  {
    name: 'Marketing',
    path: '/account/marketing',
    component: Marketing,
  },
  {
    name: 'Customers',
    path: '/account/customers',
    component: Customers,
  },
  {
    name: 'Staff',
    path: '/account/staff',
    component: Staff,
  },
  {
    name: 'Forms',
    path: '/account/forms',
    component: Forms,
  },
  {
    name: 'Products & Services',
    path: '/account/products',
    component: Products,
  },
  {
    name: 'Page',
    path: '/account/page',
    component: BusinessPage,
  },
  {
    name: 'Profile',
    path: '/account/profile',
    component: Profile,
  },
  {
    name: 'Subscription Plan',
    path: '/account/subscription',
    component: Subscription,
  },
  {
    name: 'Support',
    path: '/account/support',
    component: Support,
  },
  {
    name: 'Logout',
    path: '/account/logout',
    component: Logout,
  },
];

export default pageList;
