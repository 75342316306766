import React, { Component } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { MdLaunch, MdSettings } from 'react-icons/md';
import { RiQrCodeLine } from 'react-icons/ri';

import { getCheckinApp, updateCheckinAppPrivateToken } from '../../../redux/actions/businessActions';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import CheckinAppForm from '../forms/CheckinAppForm';
import ModalDepot from '../components/ModalDepot';
import { clearErrors } from './../../../redux/actions/siteActions';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'CHECKINAPP_PREVIEW',
      actionParam: props.parentProps ? queryString.parse(props.parentProps.history.location.search).action : '',
      isCustomizeCheckinAppShow: false,
      isViewQRcodeShow: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  componentDidMount() {
    if (this.props.users.token) {
      this.props.getCheckinApp(this.props.users.token.business_id, this);
    }
    if (this.state.actionParam === 'customize-checkinapp') {
      this.openModal('CHECKINAPP_CUSTOMIZE', true);
    }
    if (!this.props.business.checkinapp.public_token) {
      const data = {
        businessID: this.props.users.token.business_id,
      };
      this.props.updateCheckinAppPrivateToken(data);
    }
  }
  openModal(modalName, modalState, modalData) {
    switch (modalName) {
      case 'CHECKINAPP_CUSTOMIZE':
        if (modalState) {
          this.setState({ isCustomizeCheckinAppShow: true });
        } else {
          this.setState({ isCustomizeCheckinAppShow: false });
          this.props.clearErrors();
        }
        break;
      case 'CHECKINAPP_QRCODE':
        modalState ? this.setState({ isViewQRcodeShow: true }) : this.setState({ isViewQRcodeShow: false });
        break;
      default:
    }
  }
  render() {
    const { checkinapp } = this.props.business;
    return checkinapp.business_id ? (
      <>
        <div className='height-100vh-minus-header'>
          <div className='padding'>
            <center>
              <div className='display-flex justify-content-center'>
                <div className='margin-15 bg-lemonchiffon padding border-radius-5'>
                  <div className='font-size-large padding-5'>Preview</div>
                  <br />
                  To start the live application on your device, click Launch App.
                  <br />
                </div>
              </div>
              <div className='display-flex justify-content-space-evenly'>
                <ButtonGroup>
                  <Button variant='secondary' onClick={() => this.openModal('CHECKINAPP_CUSTOMIZE', true)}>
                    <div className='display-flex align-items-center justify-content-center'>
                      <MdSettings size={25} color='#019fe8' />
                      Settings
                    </div>
                  </Button>
                  <Button
                    variant='secondary'
                    onClick={() => {
                      window.open(`/checkinapp`, '_blank');
                    }}
                  >
                    <div className='display-flex align-items-center justify-content-center'>
                      <MdLaunch size={25} color='#019fe8' />
                      Launch App
                    </div>
                  </Button>
                  <Button
                    variant='secondary'
                    onClick={async () => {
                      this.openModal('CHECKINAPP_QRCODE', true);
                    }}
                  >
                    <div className='display-flex align-items-center justify-content-center'>
                      <RiQrCodeLine size={25} color='#019fe8' /> QR Code
                    </div>
                  </Button>
                </ButtonGroup>
              </div>
            </center>
          </div>
          <div className='display-flex flex-direction-column align-items-center'>
            <CheckinAppForm state={this.state} props={this.props} />
          </div>
        </div>
        <ModalDepot state={this.state} props={this.props} function={{ openModal: this.openModal }} />
      </>
    ) : (
      ''
    );
  }
}
const mapStateToProps = (state) => ({
  business: state.business,
  users: state.users,
  site: state.site,
});
export default connect(mapStateToProps, { getCheckinApp, updateCheckinAppPrivateToken, clearErrors })(AppComponent);
