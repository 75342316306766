import React, { Component } from 'react';
import { Button, Dropdown, DropdownButton, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { MdDelete, MdAddCircle, MdAddAPhoto, MdUpdate, MdDeleteForever, MdQueryBuilder } from 'react-icons/md';
import { FaUserCircle } from 'react-icons/fa';

import staff_placeholder from './../../../assets/images/staff_placeholder.png';
import phoneFormatter from 'phone-formatter';
import { getStaffs, updateStaff, deleteStaff } from './../../../redux/actions/businessActions';
import { getStripeSubscriptionProducts } from './../../../redux/actions/subscriptionActions';
import ModalDepot from './../components/ModalDepot';
import { FiPlus } from 'react-icons/fi';

class ComponentStaff extends Component {
  constructor(props) {
    super();
    this.state = {
      componentID: 'STAFF',
      currentPage: 1,
      currentPageSize: 25,
      collapseOpen: false,
      isAddStaffModalShow: false,
      isUpdateStaffModalShow: false,
      isDeleteStaffModalShow: false,
      isViewSubscriptionPlanModalShow: false,
      isAddStaffImageModalShow: false,
      isViewStaffAlbumModalShow: false,
      isStaffHoursModalShow: false,
      selectedStaff: {},
      selected: [],
      isUploadProgress: false,
    };
    this.openModal = this.openModal.bind(this);
    this.onUploadProgress = this.onUploadProgress.bind(this);
    this.onCancelUpload = this.onCancelUpload.bind(this);
  }
  componentDidMount() {
    this.props.getStripeSubscriptionProducts();
    this.props.getStaffs(this.props.users.token.business_id);
    // bug: currentPage doesn't go down when deleting the last item on a paginated page
    // this fix the bug that causes error when deleting the last item on that particular page
    if (this.state.currentPage > Math.ceil((this.props.business.staffs.length - 1) / this.state.currentPageSize) && this.state.currentPage !== 1) {
      this.setState({ currentPage: this.state.currentPage - 1 });
    }
  }
  onUploadProgress() {
    this.setState({ isUploadProgress: true });
  }
  onCancelUpload() {
    this.setState({ isUploadProgress: false });
  }
  openModal = (modalName, modalState, modalData) => {
    switch (modalName) {
      case 'STAFF_ADD':
        modalState ? this.setState({ isAddStaffModalShow: true }) : this.setState({ isAddStaffModalShow: false });
        break;
      case 'STAFF_UPDATE':
        if (modalState) {
          this.setState({ isUpdateStaffModalShow: true });
        } else {
          this.setState({ isUpdateStaffModalShow: false });
          this.setState({ selectedStaff: '' });
        }
        break;
      case 'STAFF_DELETE':
        modalState ? this.setState({ isDeleteStaffModalShow: true }) : this.setState({ isDeleteStaffModalShow: false });
        break;
      case 'STAFF_ADD_MEDIA':
        modalState ? this.setState({ isAddStaffImageModalShow: true }) : this.setState({ isAddStaffImageModalShow: false });
        break;
      case 'STAFF_HOURS':
        modalState ? this.setState({ isStaffHoursModalShow: true }) : this.setState({ isStaffHoursModalShow: false });
        break;
      case 'STAFF_VIEW_ALBUM':
        modalState ? this.setState({ isViewStaffAlbumModalShow: true }) : this.setState({ isViewStaffAlbumModalShow: false });
        break;
      case 'SUBSCRIPTION_PLANS':
        modalState ? this.setState({ isViewSubscriptionPlanModalShow: true }) : this.setState({ isViewSubscriptionPlanModalShow: false });
        break;
      default:
    }
  };
  onDeleteStaff = (e) => {
    e.preventDefault();
    const data = {
      staffIDArray: this.state.selectedStaff ? [this.state.selectedStaff.id] : this.state.selected,
    };
    this.props.deleteStaff(data, this);
  };
  render() {
    const { staffs } = this.props.business;
    const { SearchBar } = Search;
    var letterHeader = '';
    const columns = [
      {
        dataField: 'search_fields',
        text: '',
        formatter: (cell, row) => {
          let letterHeaderValue = '';
          if (letterHeader !== row.first_name.toLowerCase().charAt(0)) {
            letterHeader = row.first_name.toLowerCase().charAt(0);
            letterHeaderValue = row.first_name.toUpperCase().charAt(0);
          }
          return (
            <>
              {letterHeaderValue ? (
                <div className='display-flex align-items-center justify-content-space-between padding border-bottom-gray bg-whitesmoke'>
                  <div className='font-size-medium'>{letterHeaderValue}</div>
                </div>
              ) : (
                ''
              )}
              <div className='display-flex align-items-center padding-10'>
                <div className='margin-right-15'>
                  <div className='padding-5 border-radius-35'>
                    {row.staff_media_source ? (
                      <>
                        <a
                          href='#!'
                          onClick={(e) => {
                            e.stopPropagation(); // prevent click through on select row or rowEvents
                            this.setState({ selectedStaff: row });
                            this.openModal('STAFF_VIEW_ALBUM', true);
                          }}
                        >
                          <img alt='staff_thumbnail' src={row.staff_media_thumbnail} width='60px' className='thumbnail border-radius-25 border' />
                        </a>
                      </>
                    ) : (
                      <>
                        {' '}
                        <a
                          href='#!'
                          onClick={(e) => {
                            e.stopPropagation(); // prevent click through on select row or rowEvents
                            this.setState({ selectedStaff: row });
                            this.openModal('STAFF_ADD_MEDIA', true);
                          }}
                        >
                          <img alt='product_thumbnail' src={staff_placeholder} className='thumbnail border-radius-25 border' />
                        </a>
                      </>
                    )}
                  </div>
                </div>
                <div className='text-align-left'>
                  <div className='display-flex align-items-center margin-bottom-5 color-blue'>
                    <DropdownButton
                      variant='secondary'
                      title={
                        <>
                          {row.first_name ? row.first_name : ''} {row.last_name ? row.last_name : ''}{' '}
                        </>
                      }
                      onClick={(e) => {
                        e.stopPropagation(); // stop click from expanding row
                      }}
                    >
                      <Dropdown.Item
                        onClick={() => {
                          this.setState({ selectedStaff: row });
                          this.openModal('STAFF_UPDATE', true);
                        }}
                      >
                        <MdUpdate size={25} className='color-deepskyblue' /> Update Info
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          e.stopPropagation(); // prevent click through on select row or rowEvents
                          this.setState({ selectedStaff: row });
                          this.openModal('STAFF_ADD_MEDIA', true);
                        }}
                      >
                        <MdAddAPhoto size={25} className='color-deepskyblue' /> Add Photos
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          e.stopPropagation(); // prevent click through on select row or rowEvents
                          this.setState({ selectedStaff: row });
                          this.openModal('STAFF_HOURS', true);
                        }}
                      >
                        <MdQueryBuilder size={25} className='color-deepskyblue' /> {row.first_name ? row.first_name + "'s" : ''} Hours
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          e.stopPropagation(); // prevent click through on select row or rowEvents
                          this.setState({ selectedStaff: row });
                          this.openModal('STAFF_DELETE', true);
                        }}
                      >
                        <MdDeleteForever size={25} className='color-tomato' /> Delete
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                  <div>
                    <span className='font-size-smaller'>{row.phone ? phoneFormatter.format(row.phone + '', '(NNN) NNN-NNNN') : row.email}</span>
                  </div>
                  {row.businessHours && row.businessHours.length > 0 ? (
                    <div>
                      <hr></hr>
                      {row.businessHours.map((hour, i) => {
                        return (
                          <div key={i}>
                            <div className='display-flex align-items-center'>
                              <div className='flex-1'>{hour.dayName}</div>
                              <div className='flex-1 white-space-nowrap'>
                                <center>{hour.open}</center>
                              </div>
                              <div className='flex-1 display-flex justify-content-center'>—</div>
                              <div className='flex-1 white-space-nowrap'>
                                <center>{hour.closed}</center>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </>
          );
        },
      },
    ];
    const paginationOptions = {
      onPageChange: (page, sizePerPage) => {
        this.setState({ currentPage: page });
        this.setState({ currentPageSize: sizePerPage });
      },
      page: this.state.currentPage,
      sizePerPage: this.state.currentPageSize,
      hideSizePerPage: true,
    };
    const selectRowProp = {
      mode: 'checkbox',
      selected: this.state.selected,
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          this.setState(() => ({
            selected: [...this.state.selected, row.id],
          }));
          this.setState({ selectedStaff: row });
          this.openModal('STAFF_UPDATE', true);
        } else {
          this.setState(() => ({
            selected: this.state.selected.filter((x) => x !== row.id),
          }));
        }
      },
      onSelectAll: (isSelect, rows, e) => {
        const ids = rows.map((r) => r.id);
        if (isSelect) {
          this.setState({ selected: ids });
        } else {
          this.setState({ selected: [] });
        }
      },
      hideSelectColumn: true,
    };
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        // this.setState({ selectedStaff: row });
        // this.openModal('STAFF_UPDATE', true);
      },
    };
    const NoDataIndication = (props) => {
      if (props.searchProps.searchText.length > 0) {
        return (
          <div className='padding color-black'>
            Your search - <span className='font-weight-bold'>{props.searchProps.searchText}</span> - did not match any data.
          </div>
        );
      } else {
        return (
          <div className='padding display-flex justify-content-center align-items-center'>
            <center>
              <FaUserCircle size='200px' />
              <br /> <span className='font-size-large font-weight-bold'>No Staff Yet</span>
              <br />
              <br />
              Adding staff allows you to select them for appointment requests and service jobs.
              <br />
              <br />
              <Button variant='secondary' onClick={() => this.openModal('STAFF_ADD', true)}>
                <MdAddCircle size={25} className='color-deepskyblue' />
                Add a Staff Member
              </Button>
            </center>
          </div>
        );
      }
    };
    const DataTable = (props) => {
      return (
        <div>
          <ToolkitProvider keyField='id' data={staffs} columns={columns} search>
            {(toolkitprops) => {
              return (
                <div>
                  <div className='display-flex flex-direction-column justify-content-center align-items-center padding'>
                    <div className='display-flex flex-direction-row flex-wrap-nowrap align-items-center justify-content-space-between'>
                      <div className='width-100'>
                        <SearchBar placeholder='Search' tableId='Search' {...toolkitprops.searchProps} />
                      </div>
                      <div className='margin-left-15 white-space-nowrap'>
                        <div>
                          <Button onClick={() => this.openModal('STAFF_ADD', true)} style={{ padding: '5px' }}>
                            <FiPlus size={25} className='color-white' /> <span className='white-space-nowrap'>Add Staff</span>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={this.state.selected.length ? 'padding color-white' : 'display-none'}>
                      <OverlayTrigger placement='top' overlay={<Tooltip>Delete</Tooltip>}>
                        <button className='border-radius-5 padding-5 margin-3' onClick={(e) => this.openModal('STAFF_DELETE', true)}>
                          <MdDelete size='25px' />
                        </button>
                      </OverlayTrigger>
                    </div>
                    <BootstrapTable
                      headerClasses={staffs.length > 0 ? '' : 'display-none'}
                      classes='staff-select-table'
                      {...toolkitprops.baseProps}
                      keyField='id'
                      pagination={paginationFactory(paginationOptions)}
                      noDataIndication={<NoDataIndication data={staffs} searchProps={toolkitprops.searchProps} />}
                      rowEvents={rowEvents}
                      selectRow={selectRowProp}
                    />
                  </div>
                </div>
              );
            }}
          </ToolkitProvider>
        </div>
      );
    };
    return (
      <>
        <div>
          <DataTable />
          <ModalDepot
            state={this.state}
            props={this.props}
            function={{
              openModal: this.openModal,
              onDeleteStaff: this.onDeleteStaff,
              onCancelUpload: this.onCancelUpload,
              onUploadProgress: this.onUploadProgress,
            }}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  subscriptions: state.subscriptions,
  business: state.business,
  users: state.users,
  site: state.site,
});

export default connect(mapStateToProps, { getStaffs, updateStaff, getStripeSubscriptionProducts, deleteStaff })(ComponentStaff);
