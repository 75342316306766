import React, { Component } from 'react';
import { connect } from 'react-redux';
import PageComponent from '../components/PageComponent';

class AppComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      businessID: props.users.token.business_id,
    };
  }
  render() {
    return (
      <>
        <div className='display-flex'>
          <PageComponent state={this.state} props={this.props} token={this.props.users.token} liveMode={this.props.liveMode} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps)(AppComponent);
