import React, { Component } from 'react';
import { connect } from 'react-redux'; // connecting redux to component
import { validateAccount } from '../../redux/actions/userActions';
import { addBusiness } from './../../redux/actions/businessActions';
import { emailTokenLink } from '../../redux/actions/siteActions';
import { clearErrors, clearResponse } from './../../redux/actions/siteActions';
import { Row, Col, Alert, Container, Button, Form } from 'react-bootstrap';
import { MdEmail, MdStore, MdRefresh } from 'react-icons/md';

class SignupWelcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      businessName: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      isSendDisable: false,
      isSubmitDisabled: true,
    };
    this.onChange = this.onChange.bind(this);
    this.onEmailTokenLink = this.onEmailTokenLink.bind(this);
  }
  componentWillMount() {
    if (this.props.users.isAuthenticated) {
      window.location.href = '/account/dashboard';
    }
    const data = {
      token: this.props.match.params.token,
      tokenType: this.props.match.params.tokenType ? this.props.match.params.tokenType : 'signup',
    };
    this.props.validateAccount(data);
  }
  onEmailTokenLink(email, tokenType) {
    this.setState({ isSendDisable: true });
    const data = {
      email,
      tokenType,
    };
    this.props.emailTokenLink(data, this);
  }
  async onChange(e) {
    await this.setState({ [e.target.name]: e.target.value });
    if (
      this.state.businessName !== '' &&
      this.state.address !== '' &&
      this.state.city !== '' &&
      this.state.state !== '' &&
      this.state.zip !== '' &&
      this.state.phone !== '' &&
      this.state.phone.length >= 10
    ) {
      this.setState({ isSubmitDisabled: false });
    } else {
      this.setState({ isSubmitDisabled: true });
    }
  }
  onAddBusiness(e) {
    e.preventDefault();
    const data = {
      businessName: this.state.businessName,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      phone: this.state.phone,
      token: this.props.match.params.token,
      tokenType: 'signup',
    };
    this.props.addBusiness(data);
  }
  onRedirect(e) {
    // clear local storage
    localStorage.removeItem('localStorageUserEmail');
    this.props.history.push('/signup');
  }
  render() {
    const { account } = this.props.users;
    const { errors, response } = this.props.site;

    const StateList = () => {
      var states = [
        'AL',
        'AK',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'FL',
        'GA',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'OH',
        'OK',
        'OR',
        'PA',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY',
      ];
      let stateArray = [];
      states.map((state) =>
        stateArray.push(
          <option key={state} value={state}>
            {state}
          </option>
        )
      );
      return stateArray;
    };
    if (account && account.success) {
      window.location.href = '/account/dashboard';
    } else if (account && account.isUserVerified) {
      return (
        <div className='display-flex'>
          <Container>
            <center>
              <div className='container-box-small'>
                <div className='font-size-large margin-bottom-15'>Let's Setup Your Business</div>
                <MdStore size='100' color='#019fe8' />
                <div className='color-gray'>Fill out your business information below</div>
                <br />
                <br />
                <Form noValidate onSubmit={(e) => this.onAddBusiness(e)} className='margin-top-15'>
                  <Form.Control
                    type='text'
                    name='businessName'
                    placeholder='Business Name'
                    value={this.state.businessName}
                    onChange={this.onChange}
                    className='flex-1 margin-bottom-15'
                  />
                  <Form.Control
                    type='text'
                    name='address'
                    placeholder='Address'
                    value={this.state.address}
                    onChange={this.onChange}
                    className='flex-1 margin-bottom-15'
                  />
                  <Form.Control
                    type='text'
                    name='city'
                    placeholder='City'
                    value={this.state.city}
                    onChange={this.onChange}
                    className='flex-1 margin-bottom-15'
                  />
                  <Row>
                    <Form.Group as={Col}>
                      <Form.Control as='select' name='state' onChange={this.onChange} value={this.state.state}>
                        <option value=''>State</option>
                        <StateList />
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Control type='text' name='zip' placeholder='Zip' value={this.state.zip} onChange={this.onChange} className='flex-1' />
                    </Form.Group>
                  </Row>
                  <Form.Control
                    type='number'
                    name='phone'
                    placeholder='Business Phone # (10-digit)'
                    value={this.state.phone}
                    onChange={this.onChange}
                    className='flex-1 margin-bottom-15'
                  />
                  <Alert
                    variant={errors.message ? 'warning' : 'success'}
                    show={errors.message || response.message ? true : false}
                    onClose={() => (errors.message ? this.props.clearErrors() : this.props.clearResponse())}
                    dismissible
                  >
                    {errors.message ? errors.message : response.message ? response.message : ''}
                  </Alert>
                  <Button type='submit' disabled={this.state.isSubmitDisabled ? true : false} className='margin-top-15' style={{ width: '100%' }}>
                    Next
                  </Button>
                </Form>
              </div>
            </center>
          </Container>
        </div>
      );
    } else {
      return (
        <div className='display-flex'>
          <Container>
            <center>
              <div className='container-box-small'>
                <div className='font-size-large margin-bottom-15'>Verify Your Email</div>
                <br />
                <br />
                <MdEmail size='100' color='#e74c4b' />
                <br />
                <br />
                <span className='color-gray'>
                  We've sent you a link to confirm your email address.
                  <br />
                  Please check your inbox. It may take 5 to 10 minutes to show up in your inbox.
                </span>
                <br />
                <br />
                <br />
                <b>Didn't receive the email?</b>
                <br />
                <br />
                <ol>
                  <li className='padding text-align-left'>
                    Please make sure that <span className='text-decoration-underline'>{account.email}</span> is your correct email. If not,{' '}
                    <a href='/signup' onClick={() => this.onRedirect()}>
                      you can restart the signup process
                    </a>
                    .
                  </li>
                  <li className='padding text-align-left'>Check your spam folder</li>
                  <li className='padding text-align-left'>
                    Add <span className='text-decoration-underline'>support@onespace.link</span> to your contacts
                  </li>
                  <li className='padding text-align-left'>
                    <Button
                      type='button'
                      onClick={() => this.onEmailTokenLink(account.email, 'signup')}
                      className='margin-top-15'
                      style={{ width: '100%' }}
                      disabled={this.state.isSendDisable ? true : false}
                    >
                      {this.state.isSendDisable ? <MdRefresh size={30} className='processing' /> : ''} Resend Email
                    </Button>
                  </li>
                </ol>
                <Alert
                  variant={errors.message ? 'warning' : 'success'}
                  show={errors.message || response.message ? true : false}
                  onClose={() => (errors.message ? this.props.clearErrors() : this.props.clearResponse())}
                  dismissible
                >
                  {errors.message ? errors.message : response.message ? response.message : ''}
                </Alert>
              </div>
            </center>
          </Container>
        </div>
      );
    }
  }
}
// use this to get reducer state into components, gets the state."auth" from combineRootReducer.js
// and put it into this auth. if "auth" from that file is changed to another word, then
// this state."auth" also needs to change. To get data, use this.props.users.whatever
const mapStateToProps = (state) => ({
  businesses: state.businesses,
  users: state.users,
  site: state.site,
});
export default connect(mapStateToProps, { validateAccount, addBusiness, emailTokenLink, clearErrors, clearResponse })(SignupWelcome);
