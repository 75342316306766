import React, { Component } from 'react';
import { Alert, Button, Form, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';

import { loginUser } from '../../redux/actions/userActions';
import logo from './../../assets/images/logo.png';
import { clearErrors } from './../../redux/actions/siteActions';

class LoginStarted extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onRedirect = this.onRedirect.bind(this);
  }

  async componentDidMount() {
    if (this.props.users.isAuthenticated) {
      if (localStorage.getItem('localStorageUserLastUrl') && localStorage.getItem('localStorageUserLastUrl') !== window.location.href) {
        // Go to previous url
        window.location.href = localStorage.getItem('localStorageUserLastUrl');
        localStorage.removeItem('localStorageUserLastUrl'); // remove it
      } else {
        // Go to dashboard
        this.props.history.push('/account/dashboard');
      }
    }
    // if email is passed over, set email state, otherwise redirect back to login
    if (localStorage.getItem('localStorageUserEmail')) {
      this.setState({ email: localStorage.getItem('localStorageUserEmail') });
      if (localStorage.getItem('localStorageUserEmail'));
      if (localStorage.getItem('localStorageUserPassword')) {
        await this.setState({ password: localStorage.getItem('localStorageUserPassword') });
        this.onSubmit();
      }
    } else {
      this.props.history.push('/login');
    }
  }
  onChange(e) {
    this.setState({ password: e.target.value });
  }
  onSubmit(e) {
    if (e) e.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password,
    };
    this.props.loginUser(userData, this.props.history);
  }
  onRedirect(e) {
    // clear local storage
    localStorage.removeItem('localStorageUserEmail');
    this.props.history.push('/login');
  }
  render() {
    const { errors } = this.props.site;
    const { isAuthenticated } = this.props.users;

    if (isAuthenticated) {
      window.location.href = '/account/dashboard';
    }

    return (
      <div className='display-flex'>
        <Container>
          <center>
            <div className='container-box-small border-radius-25'>
              <div
                className='display-flex align-items-center justify-content-center padding margin-15 bg-deepskyblue box-shadow-light'
                style={{ borderRadius: '50%', width: '150px', height: '150px' }}
              >
                <a href='/'>
                  <img src={logo} className='logo-static' alt='logo' style={{ filter: 'brightness(0%) invert()' }} />
                </a>
              </div>
              <div className='margin-bottom-15 font-size-medium-large'>Enter Your Password</div>
              <span className='color-gray'>
                <span className='color-black font-weight-bold'>{this.state.email}</span> |{' '}
                <a href='#!' onClick={() => this.onRedirect()}>
                  Not you?
                </a>
              </span>
              <Form onSubmit={this.onSubmit}>
                <TextField
                  label={'Enter your password'}
                  type='password'
                  name='password'
                  margin='normal'
                  variant='outlined'
                  onChange={this.onChange}
                  value={this.state.password}
                  inputProps={{ style: { fontSize: 17 } }} // font size of input text
                  InputLabelProps={{ style: { fontSize: 17 } }} // font size of input label
                  className='display-flex bg-white border-radius-5'
                />
                <Alert variant='warning' show={errors.login ? true : false} onClose={() => this.props.clearErrors()} dismissible>
                  {errors.login}
                </Alert>
                <Button
                  // variant='info'
                  type='submit'
                  disabled={this.state.password.length === 0 ? true : false}
                  style={{ width: '100%' }}
                  className='btn btn-primary'
                >
                  <span>Log In</span>
                </Button>
              </Form>
              <div className='margin-top-25 color-gray'>
                <a href='/forgot_password'>Forgot Password?</a>
              </div>
            </div>
          </center>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  site: state.site,
});

export default connect(mapStateToProps, { loginUser, clearErrors })(LoginStarted);
